
export const PARTY_TYPE_GRANTOR = 'grantor'
export const PARTY_TYPE_GRANTEE = 'grantee'

export const PARTY_TYPES = {
    GRANTOR: PARTY_TYPE_GRANTOR,
    GRANTEE: PARTY_TYPE_GRANTEE,
}

export const ALL_PARTY_TYPES = [
    PARTY_TYPE_GRANTOR,
    PARTY_TYPE_GRANTEE,
]
