import { useState, useEffect, useCallback } from 'react'
import * as actions from '$actions'
import { formatDate } from '@utils/date'
import { toast } from 'react-toastify'
import cn from 'classnames'

import StatBox from '$components/StatBox'
import CircleSpinner from '@components/shared/CircleSpinner'
import { MdOutlineError } from 'react-icons/md'

import './HealthInfo.css'

const GreenLight = () => (
    <div className="bg-green-400 rounded-full w-7 h-7" />
)

const healthIcon = stat => stat
    ? <GreenLight />
    : <MdOutlineError className="text-3xl text-red-400" />

const HealthInfo = ({
    className,
}) => {
    
    const [healthChecks, setHealthChecks] = useState(null)
    
    const fetchHealthChecks = useCallback(async () => {
        
        try {
            
            const res = await actions.fetchHealthChecks()
            
            setHealthChecks(res)
            
        } catch (e) {
            
            console.error('fetchHealthChecks', e)
            toast.error('Failed to fetch health checks')
            
        }
        
    }, [])
    
    const formatLastCheckedDate = key =>
        formatDate(healthChecks[key].lastCheck.createdAt, 'YYYY-MM-DD hh:mm a')
    
    useEffect(() => { fetchHealthChecks() }, [])
    
    return (
        
        <div className={cn('HealthInfo', className)}>
            
            <h6 className="column-header">SERVICE STATUSES</h6>
            
            <div className="relative service-statuses">
                
                {!healthChecks ? (
                    <StatBox
                        className="relative w-32"
                        stat={<CircleSpinner className="absolute-centered" />}
                        info=""/>
                ) : (<>
                    {/* @todo */}
                    <StatBox
                        className="w-32"
                        stat={healthIcon(true)}
                        info="API"/>
                    <StatBox
                        className="w-32"
                        stat={healthIcon(healthChecks.queue.healthy)}
                        info="QUEUE"
                        title={`Last checked: ${formatLastCheckedDate('queue')}`} />
                </>)}
            
            </div>
            
            {/* <pre><code className="text-white">{JSON.stringify(health, null, 4)}</code></pre> */}
        
        </div>
        
    )
    
}

export default HealthInfo
