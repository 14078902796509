import Logger from '@utils/log'
import ParseAPI from '@api/ParseAPI'
import { saveMergedUserLocal } from '@store'

const log = new Logger('actions/settings')

export const updateLocalUserCredits = remainingBalance => {
    
    log.w('@todo this was legacy/missing, so just guessing what it was supposed to do')
    
    saveMergedUserLocal({
        credits: remainingBalance,
    })
    
}

export const fetchStripeCustomer = async () => {
    
    const res = await ParseAPI.get('/stripe/customer')
    
    return res.data
    
}
