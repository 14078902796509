import { Suspense } from 'react'
import PropTypes from 'prop-types'

const CenteredSuspense = ({ children = null }) => (
    
    <Suspense fallback={
        <div className="absolute-centered">
            &nbsp;
        </div>
    }>
        
        {children}
    
    </Suspense>
    
)

CenteredSuspense.propTypes = {
    children: PropTypes.node.isRequired,
}

export default CenteredSuspense
