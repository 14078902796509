import api from '$lib/api'

export const getRoles = async () => {
    
    const res = await api.get('/admin/roles')
    
    if (res.data) {
        console.info('Fetched', res.data?.length, 'roles')
        
        // store.annotators.setValue(res.data)
    }
    
    return res
    
}
