import * as store from '@store'

const cryptoOptions = {
    algorithm: {
        name: 'AES-GCM',
        length: 256, // can be 128, 192, or 256
    },
    extractable: true, // whether the key is extractable (i.e. can be used in exportKey)
    keyUsages: ['encrypt', 'decrypt'], // can be "encrypt", "decrypt", "wrapKey", or "unwrapKey"
}

export const hashStringSha256 = async str => {
    
    const encoder = new TextEncoder()
    const data = encoder.encode(str)
    const hashBuffer = await crypto.subtle.digest('SHA-256', data)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    
    // hashHex
    return hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('')
    
}

/**
 * Serializes a CryptoKey to a JSON string in JWK format.
 * @param {CryptoKey} cryptoKey The CryptoKey to serialize.
 * @returns {Promise<string>} A promise that resolves to a JSON string.
 */
export const serializeCryptoKeyToJson = async cryptoKey => {
    
    // Export the CryptoKey to JWK format
    const jwk = await window.crypto.subtle.exportKey('jwk', cryptoKey)
    
    // Serialize the JWK to a JSON string
    return JSON.stringify(jwk)
    
}

/**
 * Deserializes a JSON string to a CryptoKey.
 * @param {string|object} jsonString The JSON string or object to deserialize.
 * @returns {Promise<CryptoKey>} A promise that resolves to a CryptoKey.
 */
export const deserializeJsonToCryptoKey = async jsonString => {
    
    // Parse the JSON string to get the JWK object
    const jwk = typeof jsonString === 'object'
        ? jsonString
        : JSON.parse(jsonString)
    
    // Import the key from the JWK object
    return await window.crypto.subtle.importKey(
        'jwk', // Format of the key
        jwk, // The JWK object
        cryptoOptions.algorithm,
        cryptoOptions.extractable,
        cryptoOptions.keyUsages,
    )
    
}

/**
 * Retrieves a CryptoKey from storage or generates a new one if it doesn't exist.
 * @returns {Promise<CryptoKey>} A promise that resolves to a CryptoKey.
 */
export const getSubtleCryptKey = async () => {
    
    let key = store.subtleCryptoKey.getValue()
    
    if (key) {
        
        key = deserializeJsonToCryptoKey(key)
        
    } else {
        
        key = await window.crypto.subtle.generateKey(
            cryptoOptions.algorithm,
            cryptoOptions.extractable,
            cryptoOptions.keyUsages,
        )
        
        const serialized = await serializeCryptoKeyToJson(key)
        
        store.subtleCryptoKey.setValue(serialized)
        
    }
    
    return key
    
}

/**
 * Encrypts a text string using the AES-GCM algorithm.
 * @param {string} text The text to encrypt.
 * @returns {Promise<SubtleEncryptedPayload>} A promise that resolves to an object
 * containing the initialization vector and the encrypted data.
 */
export const subtleEncrypt = async text => {
    
    const key = await getSubtleCryptKey()
    const iv = window.crypto.getRandomValues(new Uint8Array(12)) // 12 bytes for AES-GCM
    const encoder = new TextEncoder()
    const data = encoder.encode(text)
    const encrypted = await window.crypto.subtle.encrypt(
        {
            name: cryptoOptions.algorithm.name,
            iv, // initialization vector
        },
        key,
        data,
    )
    
    return { iv, data: encrypted }
    
}

/**
 * Decrypts encrypted data using the AES-GCM algorithm.
 * @param {Uint8Array} iv The initialization vector used for encryption.
 * @param {ArrayBuffer} encrypted The encrypted data.
 * @returns {Promise<string>} A promise that resolves to the decrypted text string.
 */
export const subtleDecrypt = async (iv, encrypted) => {
    
    const key = await getSubtleCryptKey()
    const decrypted = await window.crypto.subtle.decrypt(
        {
            name: cryptoOptions.algorithm.name,
            iv,
        },
        key,
        encrypted,
    )
    
    const decoder = new TextDecoder()
    
    return decoder.decode(decrypted)
    
}

/**
 * Converts a Uint8Array to a base64-encoded string.
 * @param {Uint8Array} data The Uint8Array to convert.
 * @returns {string} The base64-encoded string.
 */
export const stringifyUint8Array = data => {
    
    return btoa(String.fromCharCode.apply(null, new Uint8Array(data)))
    
}

/**
 * Converts encrypted data and its initialization vector into a JSON string.
 * @param {SubtleEncryptedPayload} payload The encrypted payload and its initialization vector.
 * @param {Uint8Array} payload.iv The initialization vector.
 * @param {ArrayBuffer} payload.data The encrypted payload.
 * @returns {SerializedSubtleEncryptedPayload} A JSON object representing the
 * encrypted data and its initialization vector.
 */
export const serializeEncryptedData = payload => {
    
    if (!payload.iv)
        throw new Error(`Invalid param "iv": ${JSON.stringify(payload, null, 4)}`)
    
    if (!payload.data)
        throw new Error(`Invalid param "encrypted": ${JSON.stringify(payload, null, 4)}`)
    
    return {
        iv: stringifyUint8Array(payload.iv),
        data: stringifyUint8Array(payload.data),
    }
    
}

/**
 * Converts a JSON string back into encrypted data and its initialization vector.
 * @param {string|object} payload The JSON string or object to convert.
 * @returns {SubtleEncryptedPayload} The initialization vector.
 */
export const deserializeEncryptedData = payload => {
    
    const json = typeof payload === 'string' ? JSON.parse(payload) : payload
    
    if (!json.iv)
        throw new Error(`Invalid param "iv": ${JSON.stringify(json, null, 4)}`)
    if (!json.data)
        throw new Error(`Invalid param "data": ${JSON.stringify(json, null, 4)}`)
    
    const iv = Uint8Array.from(atob(json.iv), c => c.charCodeAt(0))
    const data = Uint8Array.from(atob(json.data), c => c.charCodeAt(0))
    
    return {
        iv,
        data: data.buffer,
    }
    
}
