import { memo, useState } from 'react'
import { AppCheckStatus } from '@constants'
import { useWireValue } from '@forminator/react-wire'
import * as store from '@store'
import { FaExclamationTriangle, FaCaretUp, FaCaretDown } from 'react-icons/fa'
import './AppUpdateAvailableAlert.css'

const AppUpdateAvailableAlert = () => {
    
    const [open, setOpen] = useState(true)
    
    const remoteConfig = useWireValue(store.remoteConfig)
    const appCheckStatus = useWireValue(store.appCheckStatus)
    
    // @todo @featureflag
    if (!remoteConfig?.appVersionCheckEnabled)
        return null
    
    if (appCheckStatus !== AppCheckStatus.OutOfDate)
        return null
    
    return (
        
        <div id="AppUpdateAvailableAlert" className={open ? '' : 'minimized'} role="alert">
            
            <div className="flex justify-between items-center content-center gap-3 mb-2">
                <div className="flex items-center gap-3">
                    <FaExclamationTriangle className="mb-1 text-2xl"/>
                    <h4>App Update Available</h4>
                </div>
                <button
                    className="btn btn-ghost btn-sm"
                    onClick={() => setOpen(!open)}>
                    {open ? <FaCaretDown className="text-xl" /> : <FaCaretUp className="text-xl" />}
                </button>
            </div>
            
            <div className="flex flex-col gap-2 text-base">
                <p>A new version of the app is available.</p>
                <p>
                    You should reload the page before saving any additional work
                    to prevent data loss.
                </p>
                <p className="flex justify-end mt-2">
                    <button
                        className="btn btn-primary btn-sm"
                        onClick={e => {
                            e.preventDefault()
                            window.location.reload(true)
                        }}>
                        Update Now
                    </button>
                </p>
            </div>
        
        </div>
        
    )
    
}

export default memo(AppUpdateAvailableAlert)
