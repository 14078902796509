import { useState, useMemo, useEffect } from 'react'
import cn from 'classnames'

import Checkbox from '$components/Forms/Checkbox'
import { Link } from 'react-router-dom'
import { MdRefresh, MdBolt } from 'react-icons/md'
import { SiElasticsearch } from 'react-icons/si'

const ESCollectionsTable = ({
    className,
    wrapClassName,
    viewModel,
}) => {
    
    const {
        
        error,
        loading,
        collections,
        
        setConfirmReindexCollection,
        
        fetchCollections,
        
    } = viewModel
    
    const [query, setQuery] = useState('')
    const [onlyParseCollections, setOnlyParseCollections] = useState(true)
    
    const filteredCollections = useMemo(() => (
        onlyParseCollections
            ? collections?.filter(it => it.isParse)
            : collections
    ), [onlyParseCollections, collections])
    
    useEffect(() => { fetchCollections(query, onlyParseCollections) }, [query])
    
    return (
        
        <div className={cn('ESCollectionsTable-wrap', wrapClassName)}>
            
            <header>
                <div className="">Collections</div>
                <div className="flex items-center content-center gap-3">
                    <input
                        className="w-full shrink"
                        type="text"
                        autoComplete="off"
                        value={query}
                        placeholder={`Search ${onlyParseCollections ? 'index ' : ''}collections...`}
                        onKeyUp={e => e.key === 'Escape' && setQuery('')}
                        onChange={e => setQuery(e.target.value?.toLowerCase() ?? '')} />
                    <div className="shrink-0 grow">
                        <Checkbox
                            label="Only Parse"
                            checked={onlyParseCollections}
                            onChange={() => setOnlyParseCollections(!onlyParseCollections)} />
                    </div>
                    <button
                        className="action-button shrink-0"
                        onClick={() => fetchCollections(query)}>
                        <MdRefresh className="text-xl" />
                    </button>
                </div>
            </header>
            
            {error && (
                <div className="">
                    {error}
                </div>
            )}
            
            {loading && (
                <div className="">
                    <i>Fetching collections...</i>
                </div>
            )}
            
            {!error && !loading && filteredCollections?.length < 1 && (
                <div className="">
                    No collections found
                </div>
            )}
            
            {!error && !loading && filteredCollections?.length > 0 && (
                <table className={cn('ESCollectionsTable table-pin-rows', className)}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Documents</th>
                            <th>
                                {/* Actions */}
                                <div className="flex !justify-center">
                                    <MdBolt className="text-lg" />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredCollections.map(it => (
                            <tr key={it.id}>
                                <td>
                                    <Link
                                        to={`/collection/${it.id}/browse`}
                                        target="_blank">
                                        {it.name}
                                    </Link>
                                </td>
                                <td>{(it.stats?.documentsCount ?? '0').toLocaleString('en-US')}</td>
                                <td>
                                    <div className="flex items-center content-center justify-center">
                                        <button
                                            className="w-auto btn btn-link"
                                            title="Re-index Collection"
                                            onClick={() => setConfirmReindexCollection(it)}>
                                            <SiElasticsearch />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        
        </div>
    )
    
}

export default ESCollectionsTable
