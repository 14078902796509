import Logger from '@utils/log'

// eslint-disable-next-line no-unused-vars
const log = new Logger('config')

const defaultOptions = {
    required: true,
    defaultValue: undefined,
}

//region Env Methods

/**
 * Reads a integer env var
 *
 * @param {String} name Env key name
 * @param {String} value Env key value (process.env.FOO)
 * @param {Object} [options]
 * @param {Boolean} [options.required]
 * @param {Boolean} [options.defaultValue]
 * @returns {Number}
 */
const intEnv = (name, value, options = defaultOptions) => {
    
    const opts = { ...defaultOptions, ...options }
    let result = undefined
    
    try {
        result = parseInt(value, 10) || null
    } catch (e) {
        result = undefined
    }
    
    if (opts.required && result === undefined)
        if (opts.defaultValue !== undefined)
            return opts.defaultValue
        else
            throw new Error(`Env var ${name} is required (int)`)
    
    return result
    
}

// eslint-disable-next-line
const floatEnv = (name, value, options = defaultOptions) => {
    
    const opts = { ...defaultOptions, ...options }
    let result = undefined
    
    try {
        result = parseFloat(value) || null
    } catch (e) {
        result = undefined
    }
    
    if (opts.required && result === undefined)
        if (opts.defaultValue !== undefined)
            return opts.defaultValue
        else
            throw new Error(`Env var ${name} is required (float)`)
    
    return result
    
}

/**
 * Reads a string env var
 *
 * @param {String} name Env key name
 * @param {String} value Env key value (process.env.FOO)
 * @param {Object} [options]
 * @param {Boolean} [options.required]
 * @param {Boolean} [options.defaultValue]
 * @returns {String}
 */
const stringEnv = (name, value, options = defaultOptions) => {
    
    const opts = { ...defaultOptions, ...options }
    const result = value?.trim()?.length > 0 ? value : undefined
    
    if (opts.required && !result?.length)
        if (opts.defaultValue !== undefined)
            return opts.defaultValue
        else
            throw new Error(`Env var ${name} is required (string)`)
    
    return result
    
}

/**
 * Reads a boolean env var
 * 
 * @param {String} name Env key name
 * @param {String} value Env key value (process.env.FOO)
 * @param {Object} [options]
 * @param {Boolean} [options.required]
 * @param {Boolean} [options.defaultValue]
 * @returns {Boolean}
 */
const boolEnv = (name, value, options = defaultOptions) => {
    
    const opts = { ...defaultOptions, ...options }
    const _value = value?.toLowerCase()
    
    if (opts.required && _value !== 'true' && _value !== 'false')
        if (opts.defaultValue !== undefined)
            return opts.defaultValue
        else
            throw new Error(`Env var ${name} is required (bool)`)
    
    return _value === 'true'
    
}

//endregion Env Methods

// log.debug('Initializing config')

const config = {
    mode: stringEnv('NODE_ENV', process.env.NODE_ENV),
    parseApiBaseUrl: stringEnv('PARSE_API_BASE_URL', process.env.PARSE_API_BASE_URL),
    enableInternalDashboard: boolEnv(
        'ENABLE_INTERNAL_DASHBOARD',
        process.env.ENABLE_INTERNAL_DASHBOARD,
        { defaultValue: false },
    ),
    logRocket: {
        enabled: boolEnv('LOGROCKET_ENABLED', process.env.LOGROCKET_ENABLED, { defaultValue: false }),
        id: stringEnv('LOGROCKET_ID', process.env.LOGROCKET_ID, { defaultValue: null }),
    },
    enablePendo: boolEnv('ENABLE_PENDO', process.env.ENABLE_PENDO, { defaultValue: true }),
    enableHubSpot: boolEnv('ENABLE_HUBSPOT', process.env.ENABLE_HUBSPOT, { defaultValue: true }),
    enablePrivacyPolicyScript: boolEnv(
        'ENABLE_PRIVACY_POLICY',
        process.env.ENABLE_PRIVACY_POLICY,
        { defaultValue: false },
    ),
    enableLinkedInTracking: boolEnv(
        'ENABLE_LINKEDIN_TRACKING',
        process.env.ENABLE_LINKEDIN_TRACKING,
        { defaultValue: false },
    ),
    stripePublicKey: stringEnv('STRIPE_PUBLIC_KEY', process.env.STRIPE_PUBLIC_KEY),
    labelStudioBaseUrl: stringEnv('LS_BASE_URL', process.env.LS_BASE_URL),
    docStatusPollInterval: intEnv('DOC_STATUS_POLL_INTERVAL', process.env.DOC_STATUS_POLL_INTERVAL),
    checkStatusPollInterval: intEnv('CHECK_STATUS_POLL_INTERVAL', process.env.CHECK_STATUS_POLL_INTERVAL),
    elasticSearchIndexJobPollInterval: intEnv(
        'ELASTIC_SEARCH_INDEX_JOB_POLL_INTERVAL',
        process.env.ELASTIC_SEARCH_INDEX_JOB_POLL_INTERVAL,
    ),
    enableDataRouter: boolEnv('ENABLE_DATA_ROUTER',
        process.env.ENABLE_DATA_ROUTER, 
        { defaultValue: false },
    ),
    apollo: {
        devTools: {
            enabled: boolEnv(
                'APOLLO_DEVTOOLS_ENABLED',
                process.env.APOLLO_DEVTOOLS_ENABLED, 
                { defaultValue: false },
            ),
        },
    },
    realtime: {
        ably: {
            apiKey: stringEnv('ABLY_API_KEY', process.env.ABLY_API_KEY),
        },
    },
}

// log.debug('Config loaded', config)

export default config
