import Logger from '@utils/log'
import * as store from '@store'
import ParseAPI, { baseUrl } from '@api/ParseAPI'
import { createQueryString } from '@utils'

const log = new Logger('actions/checks')

const storeKey = 'checks'

export const createCheck = async (
    name,
    file,
    organizationId,
    onUploadMax = value => value,
    onUploadValue = value => value,
) => {
    
    if (!name?.length)
        throw new Error('Param "name" required')
    
    if (!organizationId?.length)
        throw new Error('Param "organizationId" required')
    
    try {
        
        const formData = new FormData()
        
        formData.append('file', file)
        formData.append('name', name)
        formData.append('organizationId', organizationId)
        
        const config = {
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            onUploadProgress: progressEvent => {
                if (onUploadMax && onUploadValue) {
                    onUploadMax(progressEvent.total || 0)
                    onUploadValue(progressEvent.loaded || 0)
                }
            },
        }
        
        const res = await ParseAPI.post('/checks', formData, config)
        
        return res.data.id
        
    } catch (error) {
        
        log.e(error)
        
        return null
        
    }
    
}

export const fetchChecks = async ({
    offset = 0,
    limit = 100,
    orderField,
    orderDirection,
    withAnnotations = false,
    withAnnotationLabels = false,
    checkIds,
    q,
} = {}, setOnStore = true) => {
    
    const paramsObj = {
        offset,
        limit,
        withCount: true,
        orderField,
        orderDirection,
        withAnnotations,
        withAnnotationLabels,
        checkIds,
        name: q,
    }
    
    const params = createQueryString(paramsObj)
    
    const res = await ParseAPI.get(`${storeKey}${params}`)
    
    if (setOnStore)
        store[storeKey].setValue(res.data)
    
    return res.data
    
}

export const fetchCheckById = async (checkId, {
    withAnnotations = false,
    withAnnotationLabels = false,
} = {}) => {
    
    const params = createQueryString({
        withAnnotations,
        withAnnotationLabels,
    })
    
    const res = await ParseAPI.get(`${storeKey}/${checkId}${params}`)
    
    if (res.data)
        store.arrayUpsertByIdWithCount(storeKey, res.data, (prev, next) => ({
            ...prev,
            ...next,
        }))
    
    return res.data
    
}

export const fetchCheckDetailsByPageId = async checkPageId => {
    
    const res = await ParseAPI.get(`${storeKey}/details/${checkPageId}`)
    
    return res.data
    
}

export const uploadFileToExistingCheck = async (checkId, file) => {
    
    try {
        
        const formData = new FormData()
        
        formData.append('file', file)
        formData.append('checkId', checkId)
        
        const config = {
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
        }
        
        const res = await ParseAPI.post(`/checks/upload/${checkId}`, formData, config)
        
        store.arrayUpsertByIdWithCount(storeKey, res.data, (prev, next) => ({
            ...prev,
            ...next,
        }))
        
    } catch (error) {
        
        log.e(error)
        
        throw error
        
    }
}

export const getFileUrl = fileName => {
    
    return `${baseUrl}/${storeKey}/files/${fileName}`
    
}

export const streamFileUrl = async fileName => {
    
    const res = await ParseAPI.get(`${storeKey}/files/${fileName}`, {
        responseType: 'blob',
    })
    
    return res.data
    
}

export const updateCheck = async (checkId, data) => {
    
    const res = await ParseAPI.put(`${storeKey}/${checkId}`, data)
    
    store.objectUpdateById(storeKey, res.data)
    
    return res.data
    
}

export const deleteCheck = async checkId => {
    
    return await ParseAPI.delete(`${storeKey}/${checkId}`)
    
}

export const checkStatuses = async (checkIds, fetchUpdatedChecks) => {
    
    const res = await ParseAPI.post(`${storeKey}/statuses`, {
        ids: checkIds,
    })
    
    const checksWithStatus = res.data
    
    // fetches the checks that had their statuses updated
    if (fetchUpdatedChecks) {
        
        try {
            
            
            const updatedChecks = store[storeKey].getValue()?.rows
                .filter(c => {
                    const foundCheck = checksWithStatus.find(cs => cs.id === c.id)
                    
                    return foundCheck && foundCheck.status !== c.status
                })
            
            if (updatedChecks) {
                const checks = await fetchChecks({ checkIds: updatedChecks.map(c => c.id) }, false)
                
                checksWithStatus.forEach(cs => {
                    const check = checks.rows.find(c => cs.id === c.id)
                    
                    Object.assign(cs, check)
                })
            }
        } catch (err) {
            // the error can just be logged but no need to externally give any problem, so its seamless for the user
            log.e(err)
        }
    }
    
    // @todo this loop writes to store for every updated check,
    // can be made more efficient
    checksWithStatus.forEach(it => {
        store.arrayUpsertByIdWithCount(storeKey, it, (prev, next) => ({
            ...prev,
            ...next,
        }))
    })
    
    return res.data
    
}

export const processChecks = async checkIds => {
    
    const res = await ParseAPI.post(`${storeKey}/process`, {
        checkIds,
    })
    
    return res.data
    
}

export const exportCheck = async checkId => {
    
    return await ParseAPI.post(`${storeKey}/export/${checkId}`, {
        responseType: 'blob',
    })
    
}

export const fetchCheckOperators = async (checkId, {
    offset = 0,
    limit = 100,
} = {}) => {
    
    const paramsObj = {
        offset,
        limit,
    }
    
    const params = createQueryString(paramsObj)
    
    const res = await ParseAPI.get(`${storeKey}/${checkId}/operators${params}`)
    
    return res.data
    
}

export const fetchAllCheckOperators = async ({
    query = null,
    order,
    offset = 0,
    limit = 100,
} = {}) => {
    
    const paramsObj = {
        query,
        order,
        offset,
        limit,
    }
    
    const params = createQueryString(paramsObj)
    
    const res = await ParseAPI.get(`${storeKey}/operators${params}`)
    
    return res.data
    
}

export const fetchAllCheckStateCounty = async () => {
    
    const res = await ParseAPI.get(`${storeKey}/state-county`)
    
    return res.data
    
}

export const fetchCheckDetailsMonthly = async (key, property, {
    offset = 0,
    limit = 100,
    startDate,
    endDate,
} = {}) => {
    
    const paramsObj = {
        [key]: property,
        offset,
        limit,
        startDate,
        endDate,
    }
    
    const params = createQueryString(paramsObj)
    
    const res = await ParseAPI.get(`${storeKey}/details/monthly${params}`)
    
    return res.data
    
}

export const fetchCheckDetailsRevenue = async (key, property, {
    startDate,
    endDate,
} = {}) => {
    
    const paramsObj = {
        [key]: property,
        startDate,
        endDate,
    }
    
    const params = createQueryString(paramsObj)
    
    const res = await ParseAPI.get(`${storeKey}/details/revenue${params}`)
    
    return res.data
    
}

export const fetchCheckDetailsProduction = async (key, property, {
    offset = 0,
    limit = 100,
    startDate,
    endDate,
} = {}) => {
    
    const paramsObj = {
        [key]: property,
        offset,
        limit,
        startDate,
        endDate,
    }
    
    const params = createQueryString(paramsObj)
    
    const res = await ParseAPI.get(`${storeKey}/details/production${params}`)
    
    return res.data
    
}

export const fetchOwners = async () => {
    const res = await ParseAPI.get(`${storeKey}/owners`)
    
    return res.data
}

export const fetchCheckWells = async (checkId, {
    offset = 0,
    limit = 100,
} = {}) => {
    
    const paramsObj = {
        offset,
        limit,
    }
    
    const params = createQueryString(paramsObj)
    
    const res = await ParseAPI.get(`${storeKey}/${checkId}/properties${params}`)
    
    return res.data
    
}

export const fetchAllCheckWells = async ({
    query = null,
    order,
    offset = 0,
    limit = 100,
} = {}) => {
    
    const paramsObj = {
        query,
        order,
        offset,
        limit,
    }
    
    const params = createQueryString(paramsObj)
    
    const res = await ParseAPI.get(`${storeKey}/properties${params}`)
    
    return res.data
    
}

export const fetchCheckWellById = async wellId => {
    
    const res = await ParseAPI.get(`${storeKey}/properties/${wellId}`)
    
    return res.data
    
}

export const fetchCheckRevenue = async checkId => {
    
    const res = await ParseAPI.get(`${storeKey}/${checkId}/revenue`)
    
    return res.data
    
}

export const fetchCheckProduction = async checkId => {
    
    const res = await ParseAPI.get(`${storeKey}/${checkId}/production`)
    
    return res.data
    
}

export const fetchCheckProductionByOrgId = async ({
    startDate,
    endDate,
} = {}) => {
    
    const paramsObj = {
        startDate,
        endDate,
    }
    
    const params = createQueryString(paramsObj)
    
    const res = await ParseAPI.get(`${storeKey}/production${params}`)
    
    return res.data
    
}

export const fetchCheckReports = async () => {
    
    const res = await ParseAPI.get(`${storeKey}/reports`)
    
    return res.data
    
}

export const createCheckReport = async data => {
    
    const res = await ParseAPI.post(`${storeKey}/reports`, data)
    
    return res.data
    
}

export const deleteCheckReports = async checkReportIds => {
    
    const res = await ParseAPI.delete(`${storeKey}/reports`, {
        data: {
            reportIds: Array.isArray(checkReportIds) ? checkReportIds : [checkReportIds],
        },
    })
    
    return res.data
    
}

export const checkReportStatuses = async reportIds => {
    
    const res = await ParseAPI.post(`${storeKey}/reports/statuses`, {
        ids: reportIds,
    })
    
    return res.data
    
}

export const downloadCheckReport = async reportId => {
    
    try {
        
        const res = await ParseAPI.get(`${storeKey}/reports/${reportId}/download`, {
            responseType: 'blob',
        })
        
        return res.data
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const resetCheckStatus = async checkId => {
    
    return await ParseAPI.put(`${storeKey}/reset/${checkId}`)
    
}

export const getUnmappedChecksByIds = async checkIds => {
    
    const res = await ParseAPI.post('/checkMappings/checks', { ids: checkIds })
    
    return res.data
}

export const getMappingsFiltered = async filters => {
    
    const res = await ParseAPI.post('/checkMappings/filtered', { filters })
    
    return res.data
    
}

export const getExistingMappings = async () => {
    
    const res = await ParseAPI.get('/checkMappings')
    
    return res.data
    
}

export const saveMappings = async mappings => {
    const res = await ParseAPI.post('/checkMappings', { mappings })
    
    return res.data
}

export const exportWells = async query => {
    
    const paramsObj = {
        query,
    }
    
    const params = createQueryString(paramsObj)
    
    try {
        
        return await ParseAPI.get(`/${storeKey}/export/wells${params}`, {
            responseType: 'blob',
        })
        
    } catch (error) {
        
        log.e(error)
        
    }
}
