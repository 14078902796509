import Logger from '@utils/log'
import { NS } from '@constants'
import { useEffect } from 'react'
import { useWireValue } from '@forminator/react-wire'
import * as store from '@store'
import * as actions from '@actions'
import * as constants from '@constants'
import * as dashboardStore from '$store'
import * as dashboardActions from '$actions'
import { invert } from '@utils'

const log = new Logger('hook/useDebug')

if (!Object.prototype.asJson)
    Object.defineProperty(Object.prototype, 'asJson', {
        value: function() {
            return JSON.stringify(this, null, 4)
        },
    })

if (!Array.prototype.asJson)
    Object.defineProperty(Array.prototype, 'asJson', {
        value: function() {
            return JSON.stringify(this, null, 4)
        },
    })

if (!Object.prototype.logJson)
    Object.defineProperty(Object.prototype, 'logJson', {
        value: function() {
            console.log(JSON.stringify(this, null, 4))
        },
    })

if (!Array.prototype.logJson)
    Object.defineProperty(Array.prototype, 'logJson', {
        value: function() {
            console.log(JSON.stringify(this, null, 4))
        },
    })

/**
 * Exposes some useful state items during development
 */
export const useDebug = () => {
    
    const user = useWireValue(store.user)
    
    useEffect(() => {
        
        try {
            
            if (/* config.mode !== 'development' || */ window.app) return
            
            console.log('Enabling debug on window.app')
            
            window.app = {}
            window.app.user = user
            window.app.store = store
            window.app.actions = actions
            window.app.constants = constants
            
            window.dashboard = {
                store: dashboardStore,
                actions: dashboardActions,
            }
            
            window.app.reset = () => {
                
                const ls = JSON.parse(JSON.stringify(localStorage))
                const savedKeys = [
                    'user',
                    'dashboard.user',
                    'currentOrganization',
                ].map(it => `${NS}.${it}`)
                
                Object.keys(ls).forEach(it => {
                    if (it.startsWith(NS) && !savedKeys.includes(it))
                        localStorage.removeItem(it)
                })
                
            }
            
            window.app.takeOwn = async token => {
                
                localStorage.setItem('jwt', token)
                localStorage.removeItem('userId')
                localStorage.removeItem('userEmail')
                localStorage.removeItem('parse.user')
                
                window.location.replace('/collections')
                
            }
            
            const t = setTimeout(() => {
                
                try {
                    window.invert = invert
                } catch (e) {
                    console.warn('@debug could not set invert on window', e)
                }
                
                invert(localStorage.getItem('debug.invert') === 'true')
                
            }, 500)
            
            return () => clearTimeout(t)
            
        } catch (e) {
            
            log.w('hook/useDebug', e)
            
        }
        
    }, [user])
    
}
