
export const COMPAT_ERRORS = {
    GRAMMARLY: 1,
}

export * from './keys'

export * from './routing'
export * from './events'
export * from './roles'
export * from './annotations'
export * from './documents'
export * from './runsheets'
export * from './exports'
export * from './checks'
export * from './parties'
export * from './purchases'
export * from './elasticSearch'
export * from './escrows'

export * from './shared'

// @todo legacy stuff below
export * from './legacy'


export const HTML_ENTITIES = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&apos;': '\'',
    '&nbsp;': ' ',
    '&ndash;': '–',
    '&mdash;': '—',
    '&copy;': '©',
    '&reg;': '®',
    '&trade;': '™',
    '&asymp;': '≈',
    '&ne;': '≠',
    '&pound;': '£',
    '&euro;': '€',
    '&deg;': '°',
}