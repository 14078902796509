import { useEffect } from 'react'
import { useWireValue } from '@forminator/react-wire'
import {
    remoteConfig as storeRemoteConfig,
    appCheckStatus as storeAppCheckStatus,
} from '@store'
import { appVersionCheckInterval } from '@constants'
import { checkRemoteAppVersion } from '@actions/shared'

const useAppVersionCheck = () => {
    
    const remoteConfig = useWireValue(storeRemoteConfig)
    const appCheckStatus = useWireValue(storeAppCheckStatus)
    
    useEffect(() => {
        
        // @todo @featureflag
        if (!remoteConfig || !remoteConfig.appVersionCheckEnabled)
            return
        
        // For debugging
        window.app.checkRemoteAppVersion = checkRemoteAppVersion
        
        const t = setInterval(checkRemoteAppVersion, appVersionCheckInterval)
        
        return () => clearInterval(t)
        
    }, [remoteConfig])
    
    return appCheckStatus
    
}

export default useAppVersionCheck
