import { useCallback, useState } from 'react'
import CustomModal from '@/components/shared/CustomModal/CustomModal'
import ModalHeader from './CustomModal/ModalHeader'
import ModalBody from './CustomModal/ModalBody'
import ModalFooter from './CustomModal/ModalFooter'

const ConfirmationModal = ({
    className,
    title,
    subtitle,
    message,
    showModal,
    showHideModal,
    onCancel,
    onConfirm,
    confirmButtonText = 'Yes',
    confirmButtonClass = 'btn-primary',
    ...props
}) => {
    
    const [confirmClicked, setConfirmClicked] = useState(false)
    
    const onCancelClick = useCallback(() => {
        
        showHideModal(false)
        
        if (onCancel)
            onCancel()
        
    }, [onCancel, showHideModal])
    
    const onConfirmClick = useCallback(async () => {
        
        setConfirmClicked(true)
        
        if (onConfirm)
            await onConfirm()
        
        showHideModal(false)
        setConfirmClicked(false)
        
    }, [onConfirm, showHideModal])
    
    return (
        
        <CustomModal
            className={className}
            open={showModal}
            modalHandler={() => showHideModal(!showModal)}
            center
            {...props}>
            
            <ModalHeader>
                {title}
                {subtitle && (
                    <div className="text-sm">
                        {subtitle}
                    </div>
                )}
            </ModalHeader>
            
            <ModalBody>
                {message}
            </ModalBody>
            
            <ModalFooter>
                {!onConfirm && (
                    <button
                        className="btn btn-primary btn-outline"
                        onClick={onCancelClick}
                        data-test-id="ConfirmationModalPositiveButton">
                        OK
                    </button>
                )}
                
                {onConfirm && (<>
                    <button
                        className="btn btn-primary btn-outline"
                        onClick={onCancelClick}
                        data-test-id="ConfirmationModalNegativeButton">
                        Cancel
                    </button>
                    <button
                        disabled={confirmClicked}
                        className={`btn ${confirmButtonClass}`}
                        onClick={onConfirmClick}
                        data-test-id="ConfirmationModalPositiveButton">
                        {confirmButtonText}
                    </button>
                </>)}
            
            </ModalFooter>
        
        </CustomModal>
    )
}

export default ConfirmationModal
