import { useWireState } from '@forminator/react-wire'
import { insufficientBalanceWarningDialogOpen as storeOpen } from '@store'
import cn from 'classnames'

import CustomModal from '@/components/shared/CustomModal/CustomModal'

const InsufficientBalanceWarningDialog = ({
    className,
}) => {
    
    const [open, setOpen] = useWireState(storeOpen)
    
    return (
        
        <CustomModal
            className={cn('InsufficientBalanceWarningDialog', className)}
            open={open}
            modalHandler={() => setOpen(false)}
            center>
            
            <div className="modal-header">
                <h3 className="text-2xl">Insufficient Balance</h3>
            </div>
            
            <div className="text-base modal-body">
                
                <div className="modal-body-inner mt-4 overflow-h-auto">
                    
                    <p>Your balance is too low to pay for this action.</p>
                    
                    <p>Contact your organization administrator to add funds to your account.</p>
                
                </div>
            
            </div>
            
            <div className="flex justify-end modal-footer mt-4">
                <button
                    className="mr-3 btn btn-primary btn-outline"
                    onClick={() => setOpen(false)}>
                    OK
                </button>
            </div>
        
        </CustomModal>
        
    )
    
}

export default InsufficientBalanceWarningDialog
