import { createWire, createSelector } from '@forminator/react-wire'
import { createPersistedWire } from 'react-wire-persisted'
// eslint-disable-next-line
import { keys, storeInitialValues } from '@constants'

export const documents = createWire(storeInitialValues.documents)

export const documentNewFile = createWire({ key: '', file: null })

export const viewedDocuments = createPersistedWire('viewedDocuments', {})

export const documentHeaders = createPersistedWire('documentHeaders', [])

export const documentSearchQuery = createWire(undefined)

// @todo this is the result of an ES (wildcard?) query
// could maybe re-use `documents` if the format is the same
// need to investigate
export const documentSearchResults = createWire([])

// Global state for `useRenderPdf` hook
export const pdfRendererLoadingDoc = createWire(false)
export const pdfRendererLoadingPage = createWire(false)
export const pdfRendererLoading = createSelector({
    get: ({ get }) => get(pdfRendererLoadingDoc) || get(pdfRendererLoadingPage),
})

export const pdfRendererPageCount = createWire(0)
