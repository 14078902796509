import Logger from '@utils/log'
import * as store from '@store'
import ParseAPI from '@api/ParseAPI'

const log = new Logger('store/projects/actions')

const storeKey = 'projects'

export const createProject = async project => {
    
    try {
        
        const res = await ParseAPI.post(storeKey, project)
        
        store.arrayAdd(storeKey, res.data)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const updateProject = async project => {
    
    try {
        
        const res = await ParseAPI.put('projects', { project })
        
        store.arrayUpdateById(storeKey, res.data)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const fetchProjects = async () => {
    
    try {
        
        const res = await ParseAPI.get('projects')
        
        store.projects.setValue(res.data)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const fetchProjectById = async id => {
    
    try {
        
        const res = await ParseAPI.get(`projects/${id}`)
        
        // store.currentProject.setValue(res.data)
        
        store.arrayUpsertById(storeKey, res.data, (prev, next) => ({
            ...prev,
            ...next,
        }))
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const deleteProject = async id => {
    
    try {
        
        return await ParseAPI.post(`projects/${id}`)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const createFavoriteProject = async favoriteProject => {
    
    try {
        
        return await ParseAPI.post('favoriteProject', favoriteProject)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const deleteFavoriteProject = async favoriteProject => {
    
    try {
        
        return await ParseAPI.post(
            `favoriteProject/${favoriteProject.projectId}/${favoriteProject.userId}`,
            favoriteProject,
        )
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const createRecentProject = async projectId => {
    
    try {
        
        await ParseAPI.post('recentProject', projectId)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const fetchRecentProjects = async userId => {
    
    try {
        
        const res = await ParseAPI.get(`recentProject/${userId}`)
        
        store.recentProjects.setValue(res.data)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const deleteRecentProject = async recentProject => {
    
    try {
        
        await ParseAPI.post(
            `recentProject/${recentProject.projectId}/${recentProject.userId}`,
            recentProject,
        )
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const createTractInProject = async (tractId, projectId) => {
    
    try {
        
        const res = await ParseAPI.post(`projects/addtract/${tractId}/${projectId}`)
        
        log.w('@todo createTractInProject', res.data)
        
        /* dispatch({
            type: ADD_PROJECT_TRACT,
            
            payload: res.data,
        }) */
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const deleteTractInProject = async (tractId, projectId) => {
    
    try {
        
        const res = await ParseAPI.post(`projects/deletetract/${tractId}/${projectId}`)
        
        log.w('@todo deleteTractInProject', res.data)
        
        /* dispatch({
            type: DELETE_PROJECT_TRACT,
            payload: res.data,
        }) */
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const updateRecentProject = project => {
    
    try {
        
        ParseAPI.put('recentProject', { project })
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}
