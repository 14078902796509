import PrivateRoute from '@routes/PrivateRoute'
import CenteredSuspense from '@components/shared/CenteredSuspense'

export const routeFor = (_, Component) => (
    <Component />
)

export const privateRouteFor = (props, Component) => (
    <PrivateRoute>
        <Component {...props} />
    </PrivateRoute>
)

export const dynamicRouteFor = (_, Component) => (
    <CenteredSuspense>
        <Component />
    </CenteredSuspense>
)

export const dynamicPrivateRouteFor = (props, Component) => (
    <PrivateRoute>
        <CenteredSuspense>
            <Component {...props} />
        </CenteredSuspense>
    </PrivateRoute>
)

export const decorateRoutes = (fn, items) => items.map(it => {
    
    const { component } = it
    const result = { ...it }
    
    result.component = props => fn(props, component)
    
    if (it.childRoutes)
        result.childRoutes = decorateRoutes(fn, it.childRoutes)
    
    return result
    
})

export const populateRoutes = (routes, map) => routes.map(it => {
    
    const result = {
        ...it,
        component: map[it.component],
    }
    
    if (it.childRoutes)
        result.childRoutes = populateRoutes(it.childRoutes, map)
    
    return result
    
})
