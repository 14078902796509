import api from '$lib/api'
import { createQueryString } from '@utils'
import { pgbossPrivateClient, queries, mutations/* , subscriptions */ } from '@graphql'
import { returnDataOrThrow } from '@graphql/utils'

export const fetchStats = async () => {
    
    return await api.get('/queue')
    
}

export const toggleQueueEnabled = async () => {
    
    return await api.put('/queue/toggle')
    
}

export const fetchQueueJobs = async (name = '') => {
    
    const res = returnDataOrThrow(await pgbossPrivateClient.query({
        query: queries.Jobs,
        variables: {
            after: null,
            before: null,
            condition: null,
            first: 20,
            last: null,
            offset: 0,
            orderBy: 'CREATEDON_DESC',
            name,
        },
    }))
    
    return res
    
}

export const cancelQueueJobs = async jobIds => {
    
    const res = await api.delete('queue/jobs', {
        data: {
            jobIds,
        },
    })
    
    return res.data
    
}

export const deleteQueueJob = async id => {
    
    const res = returnDataOrThrow(await pgbossPrivateClient.mutate({
        mutation: mutations.DeleteJob,
        variables: {
            id,
        },
    }))
    
    return res
    
}

export const deleteQueueJobs = async ids => {
    
    return await Promise.all(ids.map(it => deleteQueueJob(it)))
    
}

export const fetchQueueArchivedJobs = async (name = '', {
    offset = 0,
    limit = 20,
} = {}) => {
    
    const res = returnDataOrThrow(await pgbossPrivateClient.query({
        query: queries.ArchivedJobs,
        variables: {
            after: null,
            before: null,
            condition: null,
            first: limit,
            last: null,
            offset,
            orderBy: 'CREATEDON_DESC',
            name,
        },
    }))
    
    return res
    
}

export const fetchQueueAudits = async ({
    query = undefined,
    jobType = null,
    offset = 0,
    limit = 50,
} = {}) => {
    
    const params = createQueryString({
        query,
        jobType,
        offset,
        limit,
    })
    
    const res = await api.get(`queue/audits${params}`)
    
    return res.data
    
}

export const fetchQueueAuditJobTypes = async () => {
    
    const res = await api.get('queue/audit-job-types')
    
    return res.data
    
}

export const scheduleQueueHealthCheck = async () => {
    
    const res = await api.post('admin/healthcheck/deedparser')
    
    return res.data
    
}

export const fetchPruneQueueAuditsCount = async () => {
    
    const res = await api.get('admin/audits/prune/queue')
    
    return res.data
    
}

export const pruneQueueAudits = async () => {
    
    const res = await api.delete('admin/audits/prune/queue')
    
    return res.data
    
}
