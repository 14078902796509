import cn from 'classnames'

import './CircleSpinner.css'

const CircleSpinner = ({
    className,
    spinnerClassName,
    size = '20px',
    color = '#ccc',
    indicatorColor = '#fff',
    message,
}) => {
    
    return (
        
        <div className={cn('CircleSpinner', {
            [className]: className,
        })}>
            
            <div
                className={cn('CircleSpinner-spinner', {
                    [spinnerClassName]: spinnerClassName,
                })}
                style={{
                    '--size': size,
                    '--color': color,
                    '--indicatorColor': indicatorColor,
                }} />
            
            {/* Only for easily adding debug messages */}
            {message}
        
        </div>
        
    )
    
}

export default CircleSpinner
