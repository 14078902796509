import { useMemo } from 'react'
import { useWireValue } from '@forminator/react-wire'
import * as store from '$store'
import { getUserFullName } from '@utils'

import Avatar from '$components/Avatar'
import StatBox from '$components/StatBox'
import CopyToClipboardButton from '$components/CopyToClipboardButton'

import './Profile.css'

const Profile = () => {
    
    const user = useWireValue(store.user)
    
    const fullName = useMemo(() => (
        user
            ? getUserFullName(user, false)
            : null
    ), [user])
    
    const initials = useMemo(() => {
        
        const names = (fullName ?? user?.email ?? 'X').split(' ')
        
        if (names.length < 2)
            names.push('X')
        
        return names.map(it => it.substring(0, 1)).join('')
        
    }, [user, fullName])
    
    return (
        
        <div className="Profile">
            
            <header className="flex flex-col justify-center">
                
                <div className="flex justify-center text-dashboard-primary">
                    <Avatar
                        sizeClass="w-24 h-24"
                        textClass="text-3xl"
                        initials={initials} />
                </div>
                
                <div className="flex justify-center mt-4 text-dashboard-primary">
                    <div className="text-center">
                        <h4>{fullName ?? ''}</h4>
                        <p>{user?.email ?? ''}</p>
                    </div>
                </div>
                
                <div className="flex justify-center mt-4 text-dashboard-primary">
                    <StatBox
                        style={{ maxWidth: '55%' }}
                        content={(
                            <div className="flex flex-col justify-center">
                                <h5>Your Token</h5>
                                <div className="flex items-center content-center mt-2">
                                    <div>
                                        <pre className="break-all" style={{ whiteSpace: 'pre-line' }}>
                                            <code className="break-all">
                                                {localStorage.getItem('jwt') ?? 'UNKNOWN'}
                                            </code>
                                        </pre>
                                    </div>
                                    <CopyToClipboardButton
                                        className="ml-4"
                                        iconClassName="text-4xl"
                                        value={localStorage.getItem('jwt') ?? ''} />
                                </div>
                            </div>
                        )} />
                </div>
                
                <div className="flex justify-center mt-20 text-dashboard-primary">
                    <p>TODO: More helpful stuff here...</p>
                </div>
            
            </header>
        
        </div>
        
    )
    
}

export default Profile
