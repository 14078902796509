import Logger from '@utils/log'
import * as store from '@store'
import ParseAPI from '@api/ParseAPI'
import { createQueryString } from '@utils'

const log = new Logger('actions/collections')

const storeKey = 'collections'

const deleteCollectionsLocally = collectionIds => {
    
    const collections = store.collections.getValue()
    
    if (!collections) return
    
    const updatedRows = collections.rows.filter(d => !collectionIds.includes(d.id))
    
    const updatedCount = collections.count - collectionIds.length
    
    const newCollection = {
        count: updatedCount,
        rows: updatedRows,
    }
    
    store.collections.setValue(newCollection)
    
}

export const createCollection = async collection => {
    
    try {
        
        const res = await ParseAPI.post(storeKey, { collection })
        
        store.arrayAdd(storeKey, res.data, true)
        
        return res.data
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const updateCollection = async collection => {
    
    try {
        
        const res = await ParseAPI.put(storeKey, { collection })
        
        // Response only returns partial data, so make sure to merge
        store.arrayUpsertByIdWithCount(storeKey, res.data, true)
        
        return res.data
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const fetchCollections = async ({
    query = null,
    offset = 0,
    limit = 100,
    orderField = 'createdAt',
    orderDirection = 'DESC',
    withCounts = false,
} = {}) => {
    
    try {
        
        const paramsObj = {
            query,
            offset,
            limit,
            orderField,
            orderDirection,
            withCounts,
        }
        
        const params = createQueryString(paramsObj)
        
        const res = await ParseAPI.get(`${storeKey}${params}`)
        
        store.collections.setValue(res.data)
        
    } catch (error) {
        log.e(error)
    }
}

export const fetchCollectionById = async id => {
    
    try {
        
        const res = await ParseAPI.get(`collections/${id}`)
        
        const collection = res.data
        
        if (!collection?.id) {
            console.warn('fetchCollectionById: Failed to fetch collection', res)
            window.location.href = '/collections'
            return
        }
        
        if (collection.isParse) {
            const certDate = await getCertificationDate(collection.id)
            
            Object.assign(collection, certDate)
        }
        
        store.currentCollection.setValue(collection)
        
        store.arrayUpsertByIdWithCount(storeKey, collection, (prev, next) => ({
            ...prev,
            ...next,
        }))
        
        return res
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const deleteCollection = async id => {
    
    await ParseAPI.delete(`collections/${id}`)
    
    deleteCollectionsLocally([id])
    
}

export const deleteCollections = async collectionIds => {
    
    await ParseAPI.delete('collections', {
        data: {
            collectionIds,
        },
    })
    
    deleteCollectionsLocally(collectionIds)
    
}

export const assignToUser = async (selectedModelIds = [], selectedUsers = []) => {
    
    try {
        
        const res = await ParseAPI.post('collections/assign/users', {
            selectedUsers,
            selectedModelIds, 
        })
        
        store.collections.setValue(res.data)
        
    } catch (e) {
        
        log.e('assignToUser', e)
        
    }
    
}

export const postFile = async (
    file,
    id,
    data,
    runsheetId = '',
    onUploadMax = value => value,
    onUploadValue = value => value,
    autoProcessDocument,
    fundingSource,
    batchSize = 1,
) => {
    
    try {
        
        const formData = new FormData()
        
        if (file) {
            formData.append('file', file)
            formData.append('name', data.name)
            formData.append('description', data.description)
            formData.append('ocr', data.selectValue)
            formData.append('collectionId', id)
            formData.append('runsheetId', runsheetId)
            formData.append('autoProcessDocument', autoProcessDocument)
            formData.append('fundingSource', fundingSource)
            formData.append('batchSize', batchSize)
        }
        
        const config = {
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            onUploadProgress: progressEvent => {
                if (onUploadMax && onUploadValue) {
                    onUploadMax(progressEvent.total || 0)
                    onUploadValue(progressEvent.loaded || 0)
                }
            },
        }
        
        const response = await ParseAPI.post('/pdfs/upload', formData, config)
        
        log.d('@todo UPLOAD_DOCUMENT', response.data)
        
        // dispatch({ type: UPLOAD_DOCUMENT, payload: response.data })
        
        // @todo add this document to the local collection?
        // or not necessary, since the app will re-fetch all documents?
        
        return response.data.id
        
    } catch (error) {
        
        log.e(error)
        
        return null
        
    }
    
}

// @deprecated - use actions/runsheets#exportRunsheet instead
export const downloadDocuments = async (runsheetId, documentIds, type, templateId) => {
    
    try {
        return await ParseAPI.post('/pdfs', {
            runsheetId,
            idList: documentIds,
            type,
            templateId,
        }, {
            responseType: 'blob',
        })
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const downloadDocumentPdf = async documentId => {
    try {
        
        return await ParseAPI.get(`/pdfs/document/${documentId}`, {
            responseType: 'blob',
        })
        
    } catch (error) {
        
        log.e(error)
        
    }
}

export const getCertificationDate = async collectionId => {
    try {
        
        const res = await ParseAPI.get(`/collections/${collectionId}/certdate`)
        
        return res.data
        
    } catch (err) {
        
        log.e(err)
        
    }
}
