import cn from 'classnames'

import { FaToggleOn, FaToggleOff } from 'react-icons/fa'

const Toggle = ({
    className,
    iconClassName = 'text-2xl',
    label,
    checked,
    onChange,
}) => {
    
    const Icon = checked ? FaToggleOn : FaToggleOff
    
    return (
        
        <div
            className={cn(
                'Toggle flex justify-center items-center content-center',
                className, {
                    'opacity-60': !checked,
                    'text-green-600': checked,
                },
            )}
            onClick={() => onChange(!checked)}>
            
            {label && (
                <label>{label}</label>
            )}
            
            <Icon className={iconClassName} />
        
        </div>
        
    )
    
}

/* const Toggle = ({
    className,
    label,
    checked,
    onChange,
}) => {
    
    return (
        
        <label className={cn('relative flex justify-between items-center p-2 text-xl', className)}>
            
            {label}
            
            <input
                className="absolute w-full h-full -translate-x-1/2 rounded-md appearance-none left-1/2 peer"
                type="checkbox"
                checked={checked}
                onChange={onChange} />
            
            <span className={cn(
                'flex items-center flex-shrink-0 h-4 p-1 ml-4',
                'duration-300 ease-in-out bg-gray-300 rounded-full',
                'w-8 peer-checked:bg-green-400 after:w-4 after:h-4',
                'after:bg-white after:rounded-full after:shadow-md',
                'after:duration-300 peer-checked:after:translate-x-6',
            )} />
        
        </label>
        
    )
    
} */

export default Toggle
