import { useMemo } from 'react'
import { useWireValue } from '@forminator/react-wire'
import {
    user as storeUser,
    ably as storeAbly,
    currentOrganization as storeCurrentOrganization,
} from '@store'
import {
    REALTIME_CHANNEL_TYPES,
    REALTIME_CHANNEL_TYPE_USERS,
    REALTIME_CHANNEL_TYPE_ORGANIZATIONS,
} from '@constants/realtime'

/**
 * Initializes a real-time channel name for a given channel type
 * 
 * @param {RealtimeChannelType} channelType
 * @returns {string}
 */
const useRealtimeChannelName = channelType => {
    
    if (!REALTIME_CHANNEL_TYPES.includes(channelType))
        throw new Error(`Invalid channel type "${channelType}"; must be one of ${REALTIME_CHANNEL_TYPES}`)
    
    /** @type {User} */
    const user = useWireValue(storeUser)
    
    /** @type {Ably.Realtime} */
    const ably = useWireValue(storeAbly, null)
    
    /** @type {Organization} */
    const currentOrganization = useWireValue(storeCurrentOrganization)
    
    /** @type {string} channelName */
    return useMemo(() => {
        
        if (!user || !ably || !currentOrganization)
            return null
        
        switch (channelType) {
            case REALTIME_CHANNEL_TYPE_USERS: return `users:${user.id}`
            case REALTIME_CHANNEL_TYPE_ORGANIZATIONS: return `organizations:${currentOrganization.id}`
            default: throw new Error(`Invalid channel type "${channelType}"; must be one of ${REALTIME_CHANNEL_TYPES}`)
        }
        
    }, [user, ably, currentOrganization])
    
}

export default useRealtimeChannelName
