import { useState, useEffect, useMemo, useCallback } from 'react'
import * as actions from '@graphql/actions'
import useUserHasRoles from '@hook/useUserHasRoles'
import { ROLE_SYS_ADMIN } from '@constants/roles'
import { zeroPad } from '@utils'
import { toast } from 'react-toastify'
import cn from 'classnames'

import AuditsTable from '$components/Queue/AuditsTable'
import JobsTable from '$components/Queue/JobsTable'
import ConfirmationModal from '@components/shared/ConfirmationModal'
import {
    MdInfoOutline,
    MdOutlineNotStarted,
    MdOutlineStopCircle,
    MdSecurity,
    MdWork,
    MdArchive,
    MdCheckCircleOutline,
    MdRemoveShoppingCart,
} from 'react-icons/md'

import './Queue.css'

const TABS = {
    AUDITS: 1,
    JOBS: 2,
    ARCHIVED: 3,
}

const TAB_ICONS = {
    [TABS.AUDITS]: MdSecurity,
    [TABS.JOBS]: MdWork,
    [TABS.ARCHIVED]: MdArchive,
}

const jobsLimit = 20
const auditsLimit = 50

const limitsMap = {
    [TABS.AUDITS]: auditsLimit,
    [TABS.JOBS]: jobsLimit,
    [TABS.ARCHIVED]: jobsLimit,
}

const Queue = () => {
    
    const [tab, setTab] = useState(TABS.AUDITS)
    const [stats, setStats] = useState(null)
    const [totalAudits, setTotalAudits] = useState(0)
    const [oldAuditsCount, setOldAuditsCount] = useState(0)
    const [totalJobs, setTotalJobs] = useState(0)
    const [filterName, setFilterName] = useState('parse')
    const [monitorJobsEnabled, setMonitorJobsEnabled] = useState(true)
    const [hideHealthChecks, setHideHealthChecks] = useState(true)
    const [queueInfoModalOpen, setQueueInfoModalOpen] = useState(false)
    const [confirmPruneQueueAuditsModalOpen, setConfirmPruneQueueAuditsModalOpen] = useState(false)
    
    // Just for display purposes
    const visibleLimit = useMemo(() => {
        
        let value = 0
        const total = limitsMap[tab]
        
        switch (tab) {
            case TABS.AUDITS:
                value = totalAudits
                break
            case TABS.JOBS:
            case TABS.ARCHIVED:
                value = totalJobs
                break
        }
        
        return Math.min(value, total)
        
    }, [tab, totalAudits, totalJobs])
    
    const totalJobsLabel = useMemo(() => zeroPad(
        tab === TABS.AUDITS ? totalAudits : totalJobs,
    ), [tab, totalAudits, totalJobs])
    
    const isSysAdmin = useUserHasRoles(ROLE_SYS_ADMIN)
    
    const onToggleQueueClick = useCallback(async () => {
        
        try {
            
            const res = await actions.toggleQueueEnabled()
            
            setStats(res?.data ?? null)
            
        } catch (e) {
            
            // @todo show error
            console.log('onToggleQueueClick', e)
            toast.error(e?.message ?? 'Unknown error')
            
        }
        
    }, [])
    
    const onScheduleQueueHealthCheckClick = useCallback(async () => {
        
        try {
            
            await actions.scheduleQueueHealthCheck()
            
            toast.success('Manual queue health check scheduled')
            
        } catch (e) {
            
            console.error('onScheduleQueueHealthCheckClick', e)
            toast.error('Failed to schedule queue health check')
            
        }
        
    }, [])
    
    const onPruneQueueAuditsClick = useCallback(async () => {
        
        try {
            
            const res = await actions.fetchPruneQueueAuditsCount()
            
            setOldAuditsCount(res.count)
            setConfirmPruneQueueAuditsModalOpen(true)
            
        } catch (e) {
            
            console.error('onPruneQueueAuditsClick', e)
            toast.error('Failed to prune queue audits')
            
        }
        
    }, [])
    
    const pruneQueueAudits = useCallback(async () => {
        
        try {
            
            await actions.pruneQueueAudits()
            
            setOldAuditsCount(0)
            toast.success(`Pruned ${oldAuditsCount} stale queue audit logs`)
            
        } catch (e) {
            
            console.error('pruneQueueAudits', e)
            toast.error('Failed to prune queue audits')
            
        }
        
    }, [oldAuditsCount])
    
    const tabIconFor = tab => {
        
        const Icon = TAB_ICONS[tab]
        
        return <Icon />
        
    }
    
    useEffect(() => {
        actions.fetchStats()
            .then(it => setStats(it?.data ?? null))
            .catch(e => {
                console.error('@todo show fetchStats error', e)
                toast.error('Failed to fetch queue stats')
            })
    }, [])
    
    return (
        
        <div className="Queue">
            <header className="flex justify-between items-bottom content-bottom">
                
                <div className="flex items-bottom content-bottom">
                    <div className="flex w-fit tabs tabs-boxed" role="tablist">
                        {Object.keys(TABS).map(it => (
                            <button
                                key={`tab-${it}`}
                                className={cn('flex btn w-auto tooltip tooltip-primary tooltip-bottom', {
                                    'tab-active': tab === TABS[it],
                                })}
                                role="tab"
                                data-tip={it}
                                onClick={() => setTab(TABS[it])}>
                                {tabIconFor(TABS[it])}
                                <span className="hidden xl:block">{it}</span>
                            </button>
                        ))}
                    </div>
                    <div className="flex items-center content-center ml-4 text-sm font-bold text-base-content">
                        SHOWING {visibleLimit}/{totalJobsLabel} TOTAL ITEMS
                    </div>
                </div>
                
                <div className="flex justify-end gap-2 p-1 items-bottom content-bottom">
                    <button
                        className="p-0 btn btn-link tooltip tooltip-primary tooltip-bottom"
                        data-tip="Info"
                        onClick={() => setQueueInfoModalOpen(true)}>
                        <MdInfoOutline className="w-auto mx-auto text-4xl
                            cursor-pointer text-info-content/60 hover:text-info-content"/>
                    </button>
                    {isSysAdmin && (
                        <button
                            className="
                                flex items-center content-center justify-center
                                w-auto text-warning-content btn btn-warning
                                tooltip tooltip-primary tooltip-bottom"
                            data-tip="SCHEDULE HEALTH CHECK"
                            onClick={onScheduleQueueHealthCheckClick}>
                            <MdCheckCircleOutline />
                            <span className="hidden xl:block">SCHEDULE HEALTH CHECK</span>
                        </button>
                    )}
                    {isSysAdmin && (
                        <button
                            className="
                                flex items-center content-center justify-center
                                w-auto text-warning-content btn btn-warning
                                tooltip tooltip-primary tooltip-bottom"
                            data-tip="PRUNE QUEUE AUDITS"
                            onClick={onPruneQueueAuditsClick}>
                            <MdRemoveShoppingCart />
                            <span className="hidden xl:block">PRUNE QUEUE AUDITS</span>
                        </button>
                    )}
                    <button
                        className={cn('btn w-auto',
                            'flex justify-center items-center content-center ',
                            'tooltip tooltip-primary tooltip-bottom', {
                                'btn-error text-red-400': stats?.isRunning,
                                'btn-info text-info-content': !stats?.isRunning,
                            },
                        )}
                        data-tip={`${(stats?.isRunning ? 'STOP' : 'START')} QUEUE`}
                        onClick={onToggleQueueClick}>
                        {stats?.isRunning
                            ? <MdOutlineStopCircle className="text-2xl"/>
                            : <MdOutlineNotStarted className="text-2xl"/>}
                        <span className="hidden xl:block ml-3">
                            {stats?.isRunning ? 'STOP' : 'START'} QUEUE
                        </span>
                    </button>
                </div>
            
            </header>
            
            <header className="flex items-center content-center justify-between mt-4">
                
                <div className="flex items-center content-center justify-end">
                    
                    <label className={cn('flex items-center content-center justify-center', {
                        'opacity-40': tab === TABS.AUDITS,
                    })}>
                        <span>Only Parse Jobs</span>
                        <input
                            className="ml-3"
                            type="checkbox"
                            checked={filterName}
                            disabled={tab === TABS.AUDITS}
                            onChange={e => setFilterName(e.target.checked)}/>
                    </label>
                    
                    <label className="flex items-center content-center justify-center ml-4">
                        <span>Monitor Jobs</span>
                        <input
                            className="ml-3"
                            type="checkbox"
                            checked={monitorJobsEnabled}
                            onChange={e => setMonitorJobsEnabled(e.target.checked)}/>
                    </label>
                    
                    <label className="flex items-center content-center justify-center ml-4">
                        <span>Hide Health Checks</span>
                        <input
                            className="ml-3"
                            type="checkbox"
                            checked={hideHealthChecks}
                            onChange={e => setHideHealthChecks(e.target.checked)}/>
                    </label>
                
                </div>
            
            </header>
            
            {tab === TABS.AUDITS ? (
                <AuditsTable
                    auditsLimit={auditsLimit}
                    monitorJobsEnabled={monitorJobsEnabled}
                    setTotalAudits={setTotalAudits}
                    hideHealthChecks={hideHealthChecks}/>
            ) : (
                <JobsTable
                    TABS={TABS}
                    tab={tab}
                    stats={stats}
                    filterName={filterName}
                    jobsLimit={jobsLimit}
                    monitorJobsEnabled={monitorJobsEnabled}
                    setTotalJobs={setTotalJobs}/>
            )}
            
            <ConfirmationModal
                showModal={queueInfoModalOpen}
                showHideModal={setQueueInfoModalOpen}
                title="Parse Queue"
                message={(
                    <div className="flex flex-col gap-4">
                        <div className="text-base">
                            <h4 className="text-xl">Audits</h4>
                            <p>
                                Audits are a more simple &amp; clean way to view queue job statuses.
                                <br/>
                                Jobs are grouped by <code>objectId</code> &amp; <code>jobType</code>,
                                and each group has a unique color-coded border for easy identification.
                                An <code>objectId</code> is the ID of the object the queue is working on &mdash;
                                for example, a document ID or check ID.
                            </p>
                            <p>
                                Jobs that succeed are marked with a green background, and failed jobs with red.
                                If a job reports an error, that will be shown. Most jobs also have a data
                                payload, which will also be visible (collapsed by default).
                            </p>
                        </div>
                        <div className="mt-4 text-base">
                            <h4 className="text-xl">Jobs</h4>
                            <p>
                                Jobs are the raw feed from the queue.
                                <br/>
                                This list is often messy &amp; difficult to read, but can be helpful for debugging.
                            </p>
                        </div>
                        <div className="mt-4 text-base">
                            <h4 className="text-xl">Archived</h4>
                            <p>
                                Archived jobs are the raw feed of expired jobs.
                            </p>
                        </div>
                    </div>
                )}/>
            
            <ConfirmationModal
                showModal={confirmPruneQueueAuditsModalOpen}
                showHideModal={setConfirmPruneQueueAuditsModalOpen}
                title="Prune Queue Audits"
                onConfirm={pruneQueueAudits}
                message={(
                    <div>
                        <div className="text-base">
                            <h4 className="text-xl">Prune Queue Audits</h4>
                            <p>
                                Are you sure you want to prune {oldAuditsCount} queue audits?
                            </p>
                        </div>
                    </div>
                )}/>
        
        </div>
        
    )
    
}

export default Queue
