import CustomModal, { ModalBody, ModalFooter, ModalHeader } from '@/components/shared/CustomModal'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as actions from '$actions'


const CreatePresetModal = ({
    
    showCreatePreset,
    setShowCreatePreset,
    templateId,
    system,
    
}) => {
    
    const navigate = useNavigate()
    
    const [presetName, setPresetName] = useState('')
    
    return (
        <CustomModal
            open={showCreatePreset}
            modalHandler={() => setShowCreatePreset(false)}>
            <ModalHeader>
            Create Preset
            </ModalHeader>
            
            <ModalBody>
                <div className="flex flex-col p-4 gap-4">
                    
                    <div className="flex justify-between">
                        <label htmlFor="name">
                        Preset Name
                        </label>
                        <input
                            type="text"
                            id="name"
                            className="input input-bordered w-10/12"
                            value={presetName}
                            onChange={e => setPresetName(e.target.value)}>
                        </input>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button 
                    className="btn btn-secondary"
                    onClick={() => {
                        setPresetName('')
                        setShowCreatePreset(false)
                    }}>
                Cancel
                </button>
                <button 
                    className="btn btn-primary"
                    onClick={async () => {
                        
                        await actions.createPreset({
                            system,
                            presetName: presetName,
                            valueExtractorTemplateId: templateId,
                        })
                        
                        setPresetName('')
                        setShowCreatePreset(false)
                        navigate(`presets?system=${system}&templateId=${templateId}`)
                    }}>
                Create
                </button>
            </ModalFooter>
        </CustomModal>
    )
}

export default CreatePresetModal