import { useState, useMemo } from 'react'
import { snippet, formatJsonNewLines } from '@utils'
import cn from 'classnames'

import { Link } from 'react-router-dom'
import { FaPlus, FaMinus, FaExternalLinkAlt } from 'react-icons/fa'

// const isId = value => value?.includes('-') && value?.length === 36

const breakLines = value => {
    
    try {
        return value?.includes('\n') ? value?.split('\n') : value
    } catch (e) {
        return value
    }
    
}

const formatValue = (key, value) => {
    
    try {
        
        if (!value) return value
        
        // Shorten IDs
        /* if (isId(value))
            return snippet(value) */
        if (key === 'ownerId')
            return snippet(value)
        
        // Likely an error with new line chars
        if (key === 'stack' || key === 'message')
            return value?.replace(/\n/ig, '\n')
        
        if (key === 'documentId')
            return (
                <Link
                    className="flex items-center content-center"
                    to={`/edit/${value}`}
                    target="_blank">
                    <span>{value}</span>
                    <FaExternalLinkAlt className="ml-2" />
                </Link>
            )
        
        return JSON.stringify(breakLines(value ?? 'N/A'), null, 4)
        
    } catch (e) {
        
        console.warn(e)
        return value
        
    }
    
}

const calculateItemWidths = totalItems => 100 / totalItems

const JobsDataTable = ({
    className,
    data,
    initiallyExpanded = false,
}) => {
    
    const [expanded, setExpanded] = useState(initiallyExpanded)
    
    const obj = useMemo(() => {
        try {
            return (typeof data === 'object') ? data : JSON.parse(data)
        } catch (e) {
            return {}
        }
    }, [data])
    
    const keys = useMemo(() => {
        try {
            return Object.keys(obj)
        } catch (e) {
            return []
        }
    }, [obj])
    
    const maxCellWidth = useMemo(() => calculateItemWidths(keys.length), [keys])
    
    const values = useMemo(() => {
        try {
            return keys.reduce((acc, it) => ({
                ...acc,
                [`${it}`]: {
                    key: Math.round(Date.now() * Math.random()),
                    data: Array.isArray(obj[it])
                        ? obj[it].map(value => formatValue(it, value))
                        : formatValue(it, obj[it]),
                },
            }), {})
        } catch (e) {
            return []
        }
    }, [keys, obj])
    
    return (
        
        <div className="JobsDataTable">
            
            <button
                className="flex items-center content-center w-auto btn btn-link"
                onClick={() => setExpanded(!expanded)}>
                {expanded ? <FaMinus className="text-sm" /> : <FaPlus className="text-sm" />}
                <span className="ml-2 text-sm">{expanded ? 'COLLAPSE' : 'EXPAND'}</span>
            </button>
            
            {!expanded ? (
                <pre className="break-all">
                    <code className="break-all">
                        {snippet(JSON.stringify(data))}
                    </code>
                </pre>
            ) : (
                <table className={cn('JobsDataTable table-auto', className)}>
                    
                    <thead>
                        <tr>
                            {keys.map(it => (
                                <th key={`JobsDataTable-${it}`}>
                                    {it}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    
                    <tbody>
                        <tr>
                            {keys.map(it => (
                                <td
                                    key={`JobsDataTable-${values[it]?.key}-data`}
                                    className="break-all align-top"
                                    title={formatJsonNewLines(obj[it])}
                                    style={{ maxWidth: maxCellWidth }}>
                                    <pre className="break-all">
                                        <code className="break-all">
                                            {values[it]?.data ?? 'N/A'}
                                        </code>
                                    </pre>
                                </td>
                            ))}
                        </tr>
                    </tbody>
                
                </table>
                
            )}
        
        </div>
        
    )
    
}

export default JobsDataTable
