import cn from 'classnames'

const ModalFooter = ({ className, children }) => {
    return (
        <div className={cn('modal-footer flex justify-end gap-2 mt-4', className)}>
            {children}
        </div>
    )
}

export default ModalFooter