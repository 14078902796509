import { useMemo, useState } from 'react'
import cn from 'classnames'

import DocumentsTable from '$components/DocumentsTable'
import AssignDocumentsModal from '$components/Modals/AssignDocumentsModal'
import { MdAssignmentInd } from 'react-icons/md'
import Pagination from '@components/shared/Pagination'

const DocumentsList = ({
    className,
    title,
    documents,
    pageCount,
    setPageIndex,
}) => {
    
    const [query, setQuery] = useState('')
    const [checkedIds, setCheckedIds] = useState([])
    const [singleAssignId, setSingleAssignId] = useState(null)
    
    const [modalAssignDocumentsOpen, setModalAssignDocumentsOpen] = useState(false)
    
    const hasQuery = useMemo(() => query?.length > 0, [query])
    
    const filteredDocuments = useMemo(() => {
        
        if (!documents) return []
        if (!hasQuery) return documents
        
        return documents.filter(it => {
            
            const data = [
                it.name,
                it.fileName,
            ].join(' ').toLowerCase()
            
            return data.includes(query.toLowerCase())
            
        })
        
    }, [documents, hasQuery, query])
    
    const assignDocumentIds = useMemo(() => {
        
        if (singleAssignId) return [singleAssignId]
        if (checkedIds?.length > 0) return checkedIds
        
        return []
        
    }, [singleAssignId, checkedIds])
    
    return (
        
        <div className={cn('DocumentsList', className)}>
            
            <header>
                <h5>{title}</h5>
                <div className="flex justify-end items-center content-center">
                    <Pagination
                        count={pageCount}
                        itemsPerPage={100}
                        setPageIndex={setPageIndex} />
                </div>
                {checkedIds?.length > 0 && (
                    <span className="text-xs opacity-50">
                        {checkedIds.length} DOCUMENTS SELECTED
                    </span>
                )}
                <div className="selected-actions">
                    {checkedIds?.length > 0 && <>
                        <button
                            className="flex items-center content-center mr-4 btn"
                            onClick={() => setModalAssignDocumentsOpen(true)}>
                            <MdAssignmentInd />
                            <span className="ml-3 text-sm">
                                ASSIGN DOCUMENTS
                            </span>
                        </button>
                    </>}
                    <input
                        type="text"
                        autoComplete="off"
                        value={query}
                        placeholder="Filter documents..."
                        onKeyUp={e => e.key === 'Escape' && setQuery('')}
                        onChange={e => setQuery(e.target.value)} />
                </div>
            </header>
            
            {filteredDocuments?.length > 0 ? (
                <DocumentsTable
                    documents={filteredDocuments}
                    onCheckedChange={ids => setCheckedIds(ids)}
                    onAssignSingleClick={id => {
                        setSingleAssignId(id)
                        setModalAssignDocumentsOpen(true)
                    }} />
            ) : (
                <div className="p-3 flex justify-center text-center bg-[#ffffff05]">
                    <p><i>No documents in this category.</i></p>
                </div>
            )}
            
            <AssignDocumentsModal
                open={modalAssignDocumentsOpen && singleAssignId}
                setOpen={setModalAssignDocumentsOpen}
                documentIds={assignDocumentIds} />
        
        </div>
        
    )
    
}

export default DocumentsList
