/* import { useWireValue } from '@forminator/react-wire'
import * as store from '$store' */
import appLinks from './appLinks'
import hostingLinks from './hostingLinks'
import quickLinks from './quickLinks'
import repositoryLinks from './repositoryLinks'
import buildInfo from '@/build.json'
import dayjs from 'dayjs'
import cn from 'classnames'

import HealthInfo from '$components/HealthInfo'
import Stats from '$components/Stats'
import ExternalLink from '$components/ExternalLink'
import HomeEnvPanel from '$routes/Home/HomeEnvPanel'

import './Home.css'

const LinksColumn = ({ className, title, children }) => (
    <div className={`LinksColumn flex flex-col ${className}`}>
        <h5 className="column-header">{title}</h5>
        <div className="flex flex-col">
            {children}
        </div>
    </div>
)

const linksColumnFor = (title, linksList) => (
    <LinksColumn title={title}>
        {linksList.map(({ name, description, url, before, after }) => (
            <div key={url} className="flex flex-col mb-3">
                <DescLink
                    to={url}
                    title={name}
                    desc={description}
                    before={before}
                    after={after} />
            </div>
        ))}
    </LinksColumn>
)

const DescLink = ({ className, to, title, desc, before, after }) => (
    <ExternalLink className={({ withDefaults }) => withDefaults(cn('DescLink', className))} to={to}>
        {before}
        <span className="text-lg">{title}</span>
        <span className="text-sm text-base-content/60">{desc}</span>
        {after}
    </ExternalLink>
)

const Home = () => {
    
    // const user = useWireValue(store.user)
    
    return (
        
        <div className="Home">
            
            <div className="flex items-center content-center justify-between gap-4">
                <HealthInfo />
                <Stats />
            </div>
            
            <div className="LinkColumns">
                {linksColumnFor('PARSE APPS', appLinks)}
                {linksColumnFor('GIT REPOSITORIES', repositoryLinks)}
                {linksColumnFor('HOSTING', hostingLinks)}
                {linksColumnFor('QUICK LINKS', quickLinks)}
            </div>
            
            <HomeEnvPanel className="my-4" />
            
            <footer className="build-info">
                <div>Build {buildInfo?.buildNumber ?? 'UNKNOWN'}</div>
                <div>&bull;</div>
                <div title={dayjs(buildInfo?.buildDate)?.format('MMMM D, YYYY [at] h:m A')}>
                    {buildInfo?.buildDate ?? 'UNKNOWN'}
                </div>
                <div>&bull;</div>
                <div>{buildInfo?.phrase?.toUpperCase() ?? '-'}</div>
            </footer>
        
        </div>
        
    )
    
}

export default Home
