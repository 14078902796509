import * as rwp from 'react-wire-persisted'

const { key, getPrefixedKeys } = rwp.utils

export const NS = 'parse.dashboard'

//

// Kitchen Sink LabelStudio document ID
key('ksLsDocId')

//

const prefixedKeys = getPrefixedKeys(NS)

export { prefixedKeys as keys }
