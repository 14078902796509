import useCheckableItems from '@hook/useCheckableItems'

import cn from 'classnames'

import NewLabelRow from './NewLabelRow'
import LabelRow from './LabelRow'
import Checkbox from '$components/Forms/Checkbox'
import { MdBolt } from 'react-icons/md'

const AnnotationLabelsTable = ({
    className,
    labelsType,
    labels,
}) => {
    
    const {
        allChecked,
        someChecked,
        hasChecked,
        toggleChecked,
        toggleAllChecked,
    } = useCheckableItems(labels || [])
    
    if (!labels) return null
    
    return (
        
        <table className={cn('AnnotationLabels table table-auto table-pin-rows', className)}>
            <thead>
                <tr>
                    <th>
                        <Checkbox
                            checked={allChecked}
                            indeterminate={someChecked}
                            onChange={toggleAllChecked} />
                    </th>
                    <th>ID</th>
                    <th>Type</th>
                    <th>Label</th>
                    <th>Description</th>
                    <th>Color</th>
                    <th>
                        {/* Actions */}
                        <div className="flex !justify-center">
                            <MdBolt className="text-lg" />
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody className="list">
                <NewLabelRow labelsType={labelsType} />
                {labels.map(it => (
                    <LabelRow
                        key={`AnnotationLabels-job-${it.id}`}
                        labelsType={labelsType}
                        label={it}
                        hasChecked={hasChecked}
                        toggleChecked={toggleChecked} />
                ))}
            </tbody>
        </table>
        
    )
    
}

export default AnnotationLabelsTable
