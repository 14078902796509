import Logger from '@utils/log'
import * as store from '@store'
import ParseAPI from '@api/ParseAPI'
import {
    RT_EVENT_TYPE_MESSAGE_PLAIN,
    RT_EVENT_TYPE_DOCUMENT_PROCESS,
    RT_EVENT_TYPE_ESCROW_FUNDED,
} from '@constants/realtime'

const log = new Logger('actions/notifications')
const storeKey = 'notifications'

export const fetchNotifications = async () => {
    
    try {
        
        const res = await ParseAPI.get('notifications')
        
        store.notifications.setValue(res.data)
        
    } catch (err) {
        
        log.e(err)
        
    }
    
}

export const dismissMessagePlainNotification = async id => {
    
    console.log('dismissMessagePlainNotification', id, it => it.message.data.id)
    
    store.arrayDeleteById(storeKey, id, it => it.message.data.id)
    
    try {
        
        await ParseAPI.put(`notifications/dismiss/${id}`, {
            type: RT_EVENT_TYPE_MESSAGE_PLAIN,
        })
        
    } catch (e) {
        
        log.e('dismissMessagePlainNotification', e)
        
    }
    
}

export const dismissDocumentProcessNotification = async id => {
    
    console.log('dismissDocumentProcessNotification', id)
    
    store.arrayDeleteById(storeKey, id, it => it.message.data.id)
    
    try {
        
        await ParseAPI.put(`notifications/dismiss/${id}`, {
            type: RT_EVENT_TYPE_DOCUMENT_PROCESS,
        })
        
    } catch (e) {
        
        log.e('dismissDocumentProcessNotification', e)
        
    }
    
}

// @todo @deprecated eventually migrate to 1 notifications endpoint
export const dismissEscrowNotification = async id => {
    
    store.arrayDeleteById(storeKey, id, it => it.message.data.id)
    
    try {
        
        await ParseAPI.put(`escrows/${id}`, {
            userNotified: true,
        })
        
    } catch (e) {
        
        log.e('dismissEscrowNotification', e)
        
    }
    
}

// @todo enum for notification types
export const dismissNotification = async (type, id) => {
    
    if (!id?.length)
        throw new Error('Invalid notification ID')
    
    const notificationIds = store[storeKey].getValue().map(it => it.message.data.id)
    
    if (notificationIds?.length < 1)
        return log.w('dismissNotification: notification', id, 'not found in', notificationIds)
    
    switch (type) {
        
        case RT_EVENT_TYPE_MESSAGE_PLAIN:
            return dismissMessagePlainNotification(id)
        case RT_EVENT_TYPE_DOCUMENT_PROCESS:
            return dismissDocumentProcessNotification(id)
        case RT_EVENT_TYPE_ESCROW_FUNDED:
            return await dismissEscrowNotification(id)
        
        default: {
            throw new Error(`Invalid notification type "${type}"`)
        }
        
    }
    
}

export const dismissAllNotifications = async () => {
    
    throw new Error('Not implemented')
    
    /* try {
        
        await ParseAPI.post('escrows/dismiss')
        
        store.escrows.setValue([])
    } catch (err) {
        log.e(err)
    } */
    
}
