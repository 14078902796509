import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'

const useUserActionsViewModel = () => {
    const [searchParams, setSearchParams] = useSearchParams({})
    const [organizations, setOrganizations] = useState([])
    const [organization, setOrganization] = useState(null)
    const [orgStats, setOrgStats] = useState([])
    const [userData, setUserData] = useState([])
    const [locked, setLocked] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [payload, setPayload] = useState({})
    
    const periodActions = [
        { key: 'this_month', label: 'This Month', dateSetter: () => {
            const refDate = dayjs()
            
            setStartDate(refDate.startOf('month').toDate())
            setEndDate(refDate.endOf('month').toDate())
        } },
        { key: 'last_month', label: 'Last Month', dateSetter: () => {
            const refDate = dayjs().set('month', dayjs().month() - 1)
            
            setStartDate(refDate.startOf('month').toDate())
            setEndDate(refDate.endOf('month').toDate())
        } },
        { key: 'all_time', label: 'All Time', dateSetter: () => {
            setStartDate(null)
            setEndDate(null)
        } },
        { key: 'custom', label: 'Custom', dateSetter: () => {
            setStartDate(null)
            setEndDate(null)
        } },
    ]
    
    const clearTrackingDetails = () => {
        setOrganization(null)
        // eslint-disable-next-line no-restricted-syntax
        setSearchParams({})
        setOrgStats([])
        setUserData([])
    }
    
    const goToTrackingDetails = async org => {
        setOrganization(org)
        setSearchParams(prev => {
            prev.set('filterPeriod', 'all_time')
            return prev
        })
    }
    
    return {
        periodActions,
        searchParams, setSearchParams,
        organizations, setOrganizations,
        organization, setOrganization,
        orgStats, setOrgStats,
        userData, setUserData,
        locked, setLocked,
        startDate, setStartDate,
        endDate, setEndDate,
        payload, setPayload,
        clearTrackingDetails,
        goToTrackingDetails,
    }
}

export default useUserActionsViewModel
