import { useMemo } from 'react'
import ReactPaginate from 'react-paginate'
import cn from 'classnames'

import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

import './Pagination.css'

const Arrow = ({ label, Icon, reversed = false } = {}) => (
    <div className="flex items-center content-center gap-1">
        {!reversed && <span>{label}</span>}
        <Icon />
        {reversed && <span>{label}</span>}
    </div>
)

/**
 * Generic pagination component for most use-cases
 * If `itemsPerPage` is specified, the total page count will be calculated as:
 * `(count / itemsPerPage)`
 * You may omit this prop to use the `count` param as the literal page count value
 *
 * @param {Object} props
 * @param {String} props.className
 * @param {Number} props.count Total number of items available
 * @param {Number} [props.itemsPerPage] Number of items per page (omit to use `count` as a literal)
 * @param {Function} [props.setPageIndex] Function to set the page index
 * @param {String} [props.theme] Specify a theme
 * @param {Number} pageRangeDisplayed
 * @param {Number} marginPagesDisplayed
 * @returns
 */
const Pagination = ({
    className,
    count = 0,
    itemsPerPage = null,
    setPageIndex = () => {},
    theme = 'squared',
    pageRangeDisplayed = 5,
    marginPagesDisplayed = 3,
    ...props
} = {}) => {
    
    /**
     * @type {Number}
     */
    const pageCount = useMemo(() => {
        
        if (!itemsPerPage) return count || 0
        if (count < 1) return count
        
        return Math.ceil(count / itemsPerPage)
        
    }, [count, itemsPerPage])
    
    return (
        
        <ReactPaginate
            className={cn('Pagination join', theme, className)}
            pageLinkClassName="link"
            activeLinkClassName="active"
            nextClassName="next-class"
            previousClassName="prev-class"
            breakClassName="break-class"
            breakLabel={
                <button className="pagination-button join-item btn-disabled">
                    ...
                </button>
            }
            nextLabel={
                <button className="pagination-button join-item">
                    <Arrow label="NEXT" Icon={FaAngleRight} />
                </button>}
            onPageChange={e => setPageIndex(e.selected)}
            pageRangeDisplayed={pageRangeDisplayed || 10}
            marginPagesDisplayed={marginPagesDisplayed || 3}
            pageCount={pageCount}
            previousLabel={
                <button className="pagination-button join-item">
                    <Arrow label="PREV" Icon={FaAngleLeft} reversed />
                </button>
            }
            pageLabelBuilder={page => (
                <button className="pagination-button join-item">
                    {page}
                </button>
            )}
            renderOnZeroPageCount={null}
            {...props} />
        
    )
    
}

export default Pagination
