
export default [
    {
        name: 'Doppler',
        description: 'Secrets & password management',
        url: 'https://dashboard.doppler.com/workplace/ae49ecb0d1d54ed39366/projects/parse-web',
    },
    {
        name: 'Sendgrid',
        description: 'Email & SMS campaigns',
        url: 'https://app.sendgrid.com/',
    },
    {
        name: 'Sentry',
        description: 'Error tracking',
        url: 'https://parse-ai.sentry.io/issues/?project=6780211',
    },
    {
        name: 'Loggly',
        description: 'Remote logging',
        url: 'https://parseai.loggly.com/search?terms=tag:winston',
    },
]
