import useUsersViewModel from './UsersViewModel'

import Pagination from '@components/shared/Pagination'
import UsersTable from '$components/UsersTable'
import EditUserRolesModal from '$components/Modals/EditUserRolesModal'
import ManageUserCreditsModal from '$components/Modals/ManageUserCreditsModal'
import MasqueradeConfirmDialog from '$components/Modals/MasqueradeConfirmDialog'
import { MdDeleteSweep } from 'react-icons/md'
import DeleteUsersModal from '$components/Modals/DeleteUsersModal'
import OnboardNewUserModal from '$components/Modals/OnboardNewUserModal'
import { FaRedo, FaChalkboardTeacher } from 'react-icons/fa'
import './Users.css'

const Users = () => {
    
    const vm = useUsersViewModel()
    
    return (
        
        <div className="Users">
            
            <header className="flex items-baseline justify-between content-baseline gap-4">
                
                <div className="flex items-center gap-4 shrink-0">
                    <h5 className="text-xl text-dashboard-primary">
                        Users ({vm.count})
                        {' '}
                        {vm.searchParams.get('query')?.length ? `- "${vm.searchParams.get('query')}"` : ''}
                    </h5>
                    {vm.loading && <span className="loading loading-spinner loading-sm" />}
                </div>
                
                <div className="ml-4 py-2 w-full shrink">
                    <input
                        className="w-full input input-bordered input-sm"
                        type="text"
                        autoComplete="off"
                        value={vm.query}
                        placeholder="Filter users..."
                        onKeyUp={e => e.key === 'Escape' && vm.setQuery('')}
                        onChange={e => vm.setQuery(e.target.value)}/>
                </div>
                
                <div className="flex items-center shrink-0">
                    <button
                        className="btn btn-secondary btn-ghost"
                        onClick={vm.fetchUsers}>
                        <FaRedo className="text-base" />
                    </button>
                </div>
                
                <div className="flex items-center shrink-0">
                    <button
                        className="flex items-center gap-2 translate-y-1 btn btn-primary"
                        onClick={() => vm.modalOnboardNewUserOpen.setValue(true)}>
                        <FaChalkboardTeacher className="text-xl" />
                        Onboard New User
                    </button>
                </div>
                
                {vm.checkedItemsState.anyChecked && (
                    <div className="flex shrink-0">
                        <button
                            className="btn btn-error"
                            onClick={() => vm.modalDeleteUsersOpen.setValue(true)}>
                            <MdDeleteSweep classame="text-2xl" />
                            Delete {vm.checkedItemsState.checked?.length} Users
                        </button>
                    </div>
                )}
                
                {vm.count > 0 && (<Pagination
                    className="shrink-0"
                    count={vm.count}
                    itemsPerPage={vm.ITEMS_PER_PAGE_LIMIT}
                    onPageChange={e => vm.setPageIndex(e.selected)}
                    setPageIndex={vm.setPageIndex}
                    forcePage={vm.pageIndex} />)}
            
            </header>
            
            <UsersTable
                users={vm.filteredUsers}
                onUserUpdated={vm.onUserUpdated}
                headers={vm.headers}
                orderField={vm.orderField}
                orderDirection={vm.orderDirection}
                onHeaderClick={vm.onHeaderClick}
                paymentsEnabled={vm.remoteConfig?.payments?.enabled}
                checkedItemsState={vm.checkedItemsState}/>
            
            {/* <div><pre><code>{JSON.stringify(users, null, 4)}</code></pre></div> */}
            
            <EditUserRolesModal onUserUpdated={vm.onUserUpdated} />
            <DeleteUsersModal users={vm.checkedUsers} onUsersDeleted={vm.onUsersDeleted} />
            <OnboardNewUserModal />
            <ManageUserCreditsModal onUserUpdated={vm.onUserUpdated} />
            <MasqueradeConfirmDialog />
        
        </div>
        
    )
    
}

export default Users
