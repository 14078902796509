import { useState, useMemo, useCallback } from 'react'
import { useWireValue } from '@forminator/react-wire'
import * as store from '$store'
import cn from 'classnames'

import { FaCheckCircle } from 'react-icons/fa'

const AnnotatorSelector = ({
    className,
    selectedAnnotatorIds,
    setSelectedAnnotatorIds,
}) => {
    
    const [query, setQuery] = useState('')
    
    const annotators = useWireValue(store.annotators)
    
    const filteredAnnotators = useMemo(() => {
        
        if (!query?.length) return annotators
        
        return annotators.filter(it => {
            
            const data = [
                it.firstName,
                it.lastName,
                it.email,
            ].join(' ').toLowerCase()
            
            return data.includes(query.toLowerCase())
            
        })
        
    }, [query, annotators])
    
    const onAnnotatorClick = useCallback(id => {
        
        setSelectedAnnotatorIds(prev => {
            
            if (prev.includes(id))
                return prev.filter(it => it !== id)
            else
                return [...prev, id]
            
        })
        
    }, [selectedAnnotatorIds])
    
    return (
        
        <div className={cn('AnnotatorSelector', className)}>
            
            <div className="pr-2 mt-4">
                <input
                    className="w-full text-sm"
                    type="text"
                    autoComplete="off"
                    value={query}
                    placeholder="Search annotators..."
                    onKeyUp={e => e.key === 'Escape' && setQuery('')}
                    onChange={e => setQuery(e.target.value)} />
            </div>
            
            <div className="flex flex-col pr-2 mt-2 overflow-y-auto h-52">
                {filteredAnnotators?.map(it => (
                    <div
                        key={`assignable-annotator-${it.id}`}
                        className="
                            flex items-center content-center justify-between w-full
                            border border-gray-300 rounded cursor-pointer"
                        onClick={() => onAnnotatorClick(it.id)}>
                        <div
                            className="w-full px-3 py-2 mb-2 text-sm">
                            <span className="">{it.firstName} {it.lastName}</span>
                            <br />
                            <span className="text-xs">{it.email}</span>
                        </div>
                        <div className="px-3">
                            {selectedAnnotatorIds.includes(it.id)
                                ? <FaCheckCircle className="text-lg" />
                                : <span className="text-lg">&nbsp;</span>
                            }
                        </div>
                    </div>
                ))}
            </div>
        
        </div>
        
    )
    
}

export default AnnotatorSelector
