import usePagerDutyViewModel from './PagerDutyViewModel'

import CircleSpinner from '@components/shared/CircleSpinner'
import PagerDutyIncidentsTable from './PagerDutyIncidentsTable'

import './PagerDuty.css'

// @todo option to trigger test event from dashboard
const PagerDuty = () => {
    
    const {
        error,
        loading,
        incidents,
    } = usePagerDutyViewModel()
    
    if (error) return (
        <div className="PagerDuty">
            <p>Error: {error}</p>
        </div>
    )
    
    if (loading) return (
        <div className="PagerDuty">
            <CircleSpinner className="absolute-centered" />
        </div>
    )
    
    return (
        
        <div className="PagerDuty">
            
            <header className="flex items-baseline justify-between mb-3 content-baseline">
                <h4 className="!mb-0 text-xl text-dashboard-primary">
                    PagerDuty
                </h4>
            </header>
            
            {!incidents?.length && (
                <div className="">
                    <p><i>No incidents found</i></p>
                </div>
            )}
            
            <div className="w-full mx-auto max-w-screen-2xl">
                <PagerDutyIncidentsTable incidents={incidents} />
            </div>
        
        </div>
        
    )
    
}

export default PagerDuty
