import Logger from '@utils/log'
import { useState, useEffect } from 'react'
import * as api from '$lib/api'

import StatBox from '$components/StatBox'

import './Stats.css'

const log = new Logger('Stats')

const formatNumber = number =>
    number.toLocaleString('en-US')

const Stats = ({
    className,
}) => {
    
    const [stats, setStats] = useState()
    
    useEffect(() => {
        
        api.getStats()
            .then(res => setStats(res.data))
            .catch(e => log.e('fetchStats', e))
        
    }, [])
    
    return (
        
        <div className={`Stats ${className}`}>
            
            <h5 className="column-header">PLATFORM STATS</h5>
            
            <div className="stats-grid">
                <StatBox stat={formatNumber(stats?.users ?? 0)} info="USERS" />
                <StatBox stat={formatNumber(stats?.organizations ?? 0)} info="ORGS" />
                <StatBox stat={formatNumber(stats?.collections ?? 0)} info="COLLECTIONS" />
                <StatBox stat={formatNumber(stats?.documents ?? 0)} info="DOCUMENTS" />
                <StatBox stat={formatNumber(stats?.runsheets ?? 0)} info="RUNSHEETS" />
                <StatBox stat={formatNumber(stats?.tracts ?? 0)} info="TRACTS" />
                <StatBox stat={formatNumber(stats?.projects ?? 0)} info="PROJECTS" />
            </div>
        
        </div>
        
    )
    
}

export default Stats
