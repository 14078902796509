import { createWire } from '@forminator/react-wire'
import { NOTIFICATION_TARGET_ORGANIZATION, RT_EVENT_TYPE_DOCUMENT_PROCESS } from '@constants/realtime'
import { v4 as uuidV4 } from 'uuid'

// Helper for debugging
const debugUseSampleNotifications = false

const _createSampleNotifications = (count = 5) => {
    
    return Array(count).fill(null).map((_, i) => ({
        message: {
            id: `ably-${Math.random().toString()}`, // Ably provided ID
            data: {
                id: uuidV4(), // Parse notification ID
                target: NOTIFICATION_TARGET_ORGANIZATION,
                type: RT_EVENT_TYPE_DOCUMENT_PROCESS,
                data: { name: `Demo_${i + 1}.pdf` },
            },
        },
    }))
    
}

export const notifications = createWire(
    debugUseSampleNotifications ? _createSampleNotifications() : [])
