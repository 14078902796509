import config from '@/config'
import { useExternalScript } from '@hook/useExternalScript'

/**
 * All external scripts used by the app, organized here for simplicity
 */
const useAllExternalScripts = () => {
    
    // HubSpot tracking
    // useExternalScript('//js-na1.hs-scripts.com/22012049.js', {
    //     enabled: config.enableHubSpot,
    //     disabledMessage: 'HubSpot disabled. You can change this by editing your .env config',
    //     id: 'hs-script-loader',
    //     async: true,
    //     defer: true,
    // })
    
    // Iubenda preload #1
    useExternalScript('//cdn.iubenda.com/cs/gpp/stub.js', {
        enabled: config.enablePrivacyPolicyScript,
        warnDisabled: false,
        id: 'ibuenda-script-loader',
        async: true,
        defer: true,
    })
    
    // Iubenda preload #2
    useExternalScript('//cdn.iubenda.com/cs/iubenda_cs.js', {
        enabled: config.enablePrivacyPolicyScript,
        warnDisabled: false,
        id: 'ibuenda-script-loader',
        async: true,
        defer: true,
    })
    
    // Iubenda main
    useExternalScript('//cdn.iubenda.com/iubenda.js', {
        enabled: config.enablePrivacyPolicyScript,
        disabledMessage: 'Iubenda privacy policy / TOS disabled. You can change this by editing your .env config',
        id: 'ibuenda-script-loader',
        async: true,
        defer: true,
    })
    
    // LinkedIn conversion tracking
    useExternalScript('https://snap.licdn.com/li.lms-analytics/insight.min.js', {
        enabled: config.enableLinkedInTracking,
        disabledMessage: 'LinkedIn conversion tracking disabled. You can change this by editing your .env config',
        id: 'linkedin-script-loader',
        async: true,
        defer: true,
        logging: true,
        onLoad: () => {
            
            const _linkedin_partner_id = '2726434'
            
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []
            window._linkedin_data_partner_ids.push(_linkedin_partner_id)
            
            if (!window.lintrk) {
                
                window.lintrk = function (a, b) {
                    window.lintrk.q.push([a, b])
                }
                
                window.lintrk.q = []
                
            }
            
        },
    })
    
}

export default useAllExternalScripts
