
// Reserved for internal developers to show some helpful debug info
export const ROLE_SYS_ADMIN = 'Systems Admin'

export const ROLE_PARSE_ADMIN = 'Parse Admin'
export const ROLE_SUBSCRIPTION_ADMIN = 'Subscription Admin'
export const ROLE_SUBSCRIPTION_MANAGER = 'Subscription Manager'
export const ROLE_SUBSCRIPTION_USER = 'Subscription User'
export const ROLE_PAY_AS_YOU_GO_USER = 'Pay-As-You-Go (User)'
export const ROLE_ANNOTATOR_USER = 'Annotator (User)'
export const ROLE_CHECK_MANAGER = 'Check Manager'

export const ROLES = {
    SYS_ADMIN: ROLE_SYS_ADMIN,
    PARSE_ADMIN: ROLE_PARSE_ADMIN,
    SUBSCRIPTION_ADMIN: ROLE_SUBSCRIPTION_ADMIN,
    SUBSCRIPTION_MANAGER: ROLE_SUBSCRIPTION_MANAGER,
    SUBSCRIPTION_USER: ROLE_SUBSCRIPTION_USER,
    PAY_AS_YOU_GO_USER: ROLE_PAY_AS_YOU_GO_USER,
    ANNOTATOR_USER: ROLE_ANNOTATOR_USER,
    CHECK_MANAGER: ROLE_CHECK_MANAGER,
}

export const ORG_MEMBER_ROLE_OWNER = 'owner' // Can rename, delete
export const ORG_MEMBER_ROLE_ADMIN = 'admin' // Can manage members
export const ORG_MEMBER_ROLE_MEMBER = 'member' // Is just a member

export const ORG_MEMBER_ROLES = [
    ORG_MEMBER_ROLE_OWNER,
    ORG_MEMBER_ROLE_ADMIN,
    ORG_MEMBER_ROLE_MEMBER,
]

export const ORG_MEMBER_ROLES_CAN_CHANGE_MEMBERS = [
    ORG_MEMBER_ROLE_OWNER,
    ORG_MEMBER_ROLE_ADMIN,
]
