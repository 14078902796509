import { useRef, useState, useMemo, useCallback, useEffect } from 'react'
import * as actions from '@actions'
import { RECORD_TYPES } from '@constants/annotations'
import { toast } from 'react-toastify'
import cn from 'classnames'

const NewLabelRow = ({
    className,
    labelsType,
}) => {
    
    const refType = useRef()
    const refLabel = useRef()
    const refDescription = useRef()
    
    const [type, setType] = useState(labelsType ?? '')
    const [label, setLabel] = useState('')
    const [description, setDescription] = useState('')
    
    const isFormValid = useMemo(() => {
        if (!type?.length) return false
        if (!RECORD_TYPES.includes(type)) return false
        if (!label?.length) return false
        return true
    }, [type, label])
    
    const onCreateLabelClick = useCallback(async () => {
        
        if (!type?.length || !RECORD_TYPES.includes(type))
            return console.log('invalid type') || refType.current?.focus()
        
        if (!label?.length)
            return refLabel.current?.focus()
        
        try {
            
            await actions.createLabel(type, label, description)
            await actions.fetchAllLabels()
            
            setLabel('')
            setDescription('')
        } catch (e) {
            
            console.error('onCreateLabelClick', e)
            toast.error('Failed to create label')
            
        }
        
    }, [type, label, description])
    
    useEffect(() => { setType(labelsType ?? '') }, [labelsType])
    
    return (
        
        <tr className={cn('NewLabelRow', className)}>
            <td colSpan={8} className='w-11/12'>
                <form 
                    onSubmit={e => {
                        e.preventDefault()
                        onCreateLabelClick()
                    }} 
                    className="flex items-center content-center gap-4">
                    {labelsType?.length > 0 ? labelsType : (
                        <select
                            ref={refType}
                            className="select select-bordered"
                            onChange={e => setType(e.target.value)}>
                            <option value="">Type</option>
                            {RECORD_TYPES.map(it => (
                                <option
                                    key={`new-label-type-${it}`}
                                    value={it}>
                                    {it}
                                </option>
                            ))}
                        </select>
                    )}
                    
                    <input
                        ref={refLabel}
                        type="text"
                        autoComplete="off"
                        value={label}
                        placeholder="New label"
                        onChange={e => setLabel(e.target.value ?? '')} />
                    <input 
                        ref={refDescription}
                        type="text"
                        autoComplete="off"
                        className='w-full'
                        placeholder="Label's description"
                        value={description}
                        onChange={e => setDescription(e.target.value ?? '')}/>
                    <button
                        className="btn-create-label btn btn-outline-primary"
                        disabled={!isFormValid}>
                        CREATE LABEL
                    </button>
                </form>
            </td>
        </tr>
        
    )
    
}

export default NewLabelRow
