import { useState, useCallback } from 'react'
import { useWireState } from '@forminator/react-wire'
import * as store from '$store'
import * as actions from '$actions'
import { DOCUMENT_STATUSES, HUMAN_STATUSES } from '@constants'
import { toast } from 'react-toastify'
import cn from 'classnames'

import Modal from '$components/Modal'

const SetDocumentStatusesModal = ({
    className,
    collection,
    ...props
}) => {
    
    const [status, setStatus] = useState(DOCUMENT_STATUSES[0])
    
    const [open, setOpen] = useWireState(store.modalSetDocumentStatusesOpen)
    
    const onUpdateDocumentStatusesClick = useCallback(async () => {
        
        try {
            
            const res = await actions.updateCollectionDocumentStatuses(collection.id, status)
            
            console.log(res)
            
            toast.success('Updated document statuses')
            
        } catch (e) {
            
            console.error('onUpdateDocumentStatusesClick', e)
            toast.error('Failed to update document statuses')
            
        }
        
    }, [collection, status])
    
    if (!collection) return null
    
    return (
        
        <Modal
            className={cn(className, 'overflow-x-auto')}
            style={{ maxHeight: '80%' }}
            open={open}
            setOpen={setOpen}
            title="Set Document Statuses"
            actionLabel="UPDATE STATUSES"
            actionOnClick={onUpdateDocumentStatusesClick}
            {...props}>
            
            <div className="mb-4">
                Update all document statuses within collection <b>{collection.name}</b>.
                <br />
                <i>This cannot be undone.</i>
            </div>
            
            <div className="mb-4">
                <select
                    className="select"
                    value={status}
                    onChange={e => setStatus(e.target.value)}>
                    {DOCUMENT_STATUSES.map(it => (
                        <option key={`doc-status-${it}`} value={it}>
                            {HUMAN_STATUSES[it]}
                        </option>
                    ))}
                </select>
            </div>
        
        </Modal>
        
    )
    
}

export default SetDocumentStatusesModal
