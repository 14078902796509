import { useState, useEffect, useCallback } from 'react'
import * as actions from '$actions'

import Modal from '$components/Modal'
import AnnotatorSelector from '$components/AnnotatorSelector'

const AssignCollectionModal = ({
    className,
    open,
    setOpen,
    collection,
    ...props
}) => {
    
    const [documentsCount, setDocumentsCount] = useState(0)
    const [selectedAnnotatorIds, setSelectedAnnotatorIds] = useState([])
    
    const onAssignClick = useCallback(async () => {
        
        if (!selectedAnnotatorIds.length || !collection) return
        
        try {
            
            const res = await actions.assignCollectionToAnnotators(
                selectedAnnotatorIds,
                collection.id,
            )
            
            await Promise.all([
                actions.getAnnotators(),
                actions.getAllDocuments(),
            ])
            
            console.log('onAssignClick res', res)
            
        } catch (e) {
            
            // @todo show error
            console.error('onAssignClick', e)
            
        }
        
    }, [selectedAnnotatorIds, collection])
    
    useEffect(() => {
        
        if (!collection) return
        
        actions.getDocumentsCount(collection.id)
            .then(it => setDocumentsCount(it))
        
    }, [collection])
    
    if (!collection) return null
    
    return (
        
        <Modal
            className={className}
            open={open}
            setOpen={setOpen}
            title="Assign Collection"
            actionClass="bg-base-100 hover:bg-base-200"
            actionLabel="ASSIGN COLLECTION"
            actionOnClick={onAssignClick}
            {...props}>
            
            <div className="">
                Assign collection <b>{collection.name}</b> ({documentsCount} documents) to annotator:
            </div>
            
            <AnnotatorSelector
                selectedAnnotatorIds={selectedAnnotatorIds}
                setSelectedAnnotatorIds={setSelectedAnnotatorIds} />
        
        </Modal>
        
    )
    
}

export default AssignCollectionModal
