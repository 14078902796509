// eslint-disable-next-line
import { createWire, createSelector } from '@forminator/react-wire'
// eslint-disable-next-line
import { keys } from '@constants'

export const abstracts = createWire(null)

export const currentAbstract = createWire(null)

export const states = createWire([])
