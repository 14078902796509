import { useMemo } from 'react'
import { useWireValue } from '@forminator/react-wire'
import { user as storeUser } from '@store'

const useUserHasRoles = requiredRoles => {
    
    const user = useWireValue(storeUser)
    const roles = useMemo(() => (
        Array.isArray(requiredRoles) ? requiredRoles : [requiredRoles]
    ), [requiredRoles])
    
    return useMemo(() => {
        
        if (!user || !roles?.length)
            return false
        
        return user?.roles?.some(it => roles.includes(it.name))
        
    }, [user, roles])
    
}

export default useUserHasRoles
