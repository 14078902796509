
// Types of exports users can download
export const EXPORT_TYPE_PDF = 'pdf'
export const EXPORT_TYPE_EXCEL = 'excel'

export const EXPORT_TYPES = [
    EXPORT_TYPE_PDF,
    EXPORT_TYPE_EXCEL,
]
export const EXPORT_TYPE_NAMES = {
    [EXPORT_TYPE_PDF]: 'Documents (.pdf)',
    [EXPORT_TYPE_EXCEL]: 'Runsheet (.xlsx)',
}

// Export templates available
export const EXPORT_TEMPLATE_TYPE_EXCEL = 'excel'
export const EXPORT_TEMPLATE_TYPES = [
    EXPORT_TEMPLATE_TYPE_EXCEL,
]

export const EXPORT_DICT = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
export const EXPORT_CELLS = [
    ...EXPORT_DICT,
    ...EXPORT_DICT.flatMap(char => EXPORT_DICT.flatMap(it => `${char}${it}`)),
]
