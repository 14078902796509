import Logger from '@utils/log'
import * as store from '@store'
import ParseAPI from '@api/ParseAPI'

const log = new Logger('actions/roles')

export const getProjects = async () => {
    
    try {
        
        const res = await ParseAPI.get('role')
        
        store.roles.setValue(res.data)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

