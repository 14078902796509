import { useMemo, useCallback } from 'react'
import * as actions from '@graphql/actions'
import { snippet } from '@utils'
import { relativeTime } from '@/utils/date'
import { JSONTreeTheme } from '@constants/shared'
import { toast } from 'react-toastify'
import cn from 'classnames'

import CopyToClipboardButton from '$components/CopyToClipboardButton'
import { JSONTree } from 'react-json-tree'
import { MdCancel } from 'react-icons/md'

const canCancelStatuses = [
    'created',
    'started',
]

const AuditsTableRow = ({
    audit,
    groupColor,
    expandedPayloads,
    setExpandedPayloads,
    ToggleIcon,
}) => {
    
    const getCreatedByName = user => {
        
        let name = ''
        
        if (user?.firstName?.length && user?.lastName?.length)
            name = `${user?.firstName} ${user?.lastName}`
        else if (user?.firstName?.length)
            name = user.firstName
        else
            name = user?.email ?? '(N/A)'
        
        if (name === user?.email)
            return <span>{name}</span>
        
        return (
            <>
                <span>{name}</span>
                <br />
                <span className="text-xs">{user?.email}</span>
            </>
        )
        
    }
    
    const getObjectLink = audit => {
        
        // @todo link to document or check etc
        return snippet(audit.objectId)
        
    }
    
    const getJobPayload = audit => {
        
        try {
            return JSON.parse(audit.jobPayload).data
        } catch (e) {
            return {}
        }
        
    }
    
    const toggleJobPayload = useCallback(auditId => {
        
        setExpandedPayloads(prev => {
            
            const next = { ...prev }
            
            if (next[auditId])
                delete next[auditId]
            else
                next[auditId] = true
            
            return next
            
        })
        
    }, [])
    
    const onCancelClick = async queueJobId => {
        
        try {
            
            const res = await actions.cancelQueueJobs([queueJobId])
            
            console.log('onCancelClick', res)
            
        } catch (e) {
            
            console.error('onCancelClick', e)
            toast.error('Failed to delete queue job')
            
        }
        
    }
    
    return useMemo(() => {
        
        const canCancel = canCancelStatuses.includes(audit.jobStatus)
        
        return (
            
            <tr
                className={cn({
                    'failed-job': audit.jobStatus === 'failed',
                    'success-job': audit.jobStatus === 'finished',
                })}
                style={{ '--group-color': groupColor }}>
                
                <td title={`group: ${groupColor}`}>
                    {getCreatedByName(audit.createdBy)}
                </td>
                
                <td>{relativeTime(audit.createdAt)}</td>
                <td>{audit.jobType}</td>
                
                <td>
                    <div className="flex items-center content-center gap-3">
                        <span title={audit.objectId}>
                            {getObjectLink(audit)}
                        </span>
                        <CopyToClipboardButton value={audit.objectId} />
                    </div>
                </td>
                
                <td>{audit.jobStatus}</td>
                <td>{audit.triggerSource}</td>
                
                <td>
                    <div className="leading-tight">
                        {audit.jobError?.split('\n').map((it, i) => (
                            <div
                                key={`jobError-line-${i}`}
                                className={cn({ 'font-bold mb-3': i === 0 })}>
                                {it}
                            </div>
                        ))}
                    </div>
                </td>
                
                <td>
                    <div className="flex items-center content-center gap-3">
                        <ToggleIcon
                            className="cursor-pointer shrink-0"
                            onClick={() => toggleJobPayload(audit.id)} />
                        {expandedPayloads[audit.id] ? (
                            <JSONTree
                                data={getJobPayload(audit)}
                                hideRoot
                                theme={JSONTreeTheme}
                                invertTheme={false} />
                        ) : ''}
                    </div>
                </td>
                
                <td>
                    <div className="flex items-center content-center justify-center">
                        <button
                            className={cn('action-button danger', {
                                'cursor-not-allowed': !canCancel,
                            })}
                            title="Cancel queue job"
                            disabled={!canCancel}
                            onClick={() => onCancelClick(audit.id)}>
                            <MdCancel className={cn({
                                'text-red-200 hover:text-red-600': canCancel,
                            })} />
                        </button>
                    </div>
                </td>
            
            </tr>
            
        )
        
    }, [
        audit,
        groupColor,
        expandedPayloads,
        setExpandedPayloads,
        ToggleIcon,
    ])
    
}

export default AuditsTableRow
