import { useEffect } from 'react'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'

import { noop } from '@/utils'
import * as actions from '$actions'

import UserActionsDetails from './UserActionsDetails'
import useUserActionsViewModel from './useUserActionsViewModel'

const UserActions = () => {
    
    const vm = useUserActionsViewModel()
    
    useEffect(() => {
        const period = vm.searchParams.get('filterPeriod')
        
        const periodAction = vm.periodActions.find(pa => pa.key === period)
        
        if (!periodAction) return
        
        (periodAction.dateSetter || noop)()
        vm.setLocked(false)
        
    }, [vm.searchParams])
    
    useEffect(() => {
        if (vm.locked || !vm.organization) return
        
        const period = vm.searchParams.get('filterPeriod')
        
        if (period === 'all_time' && !vm.startDate && !vm.endDate)
            vm.setPayload({ organizationId: vm.organization.id, period })
        else if (['this_month', 'last_month'].includes(period) && vm.startDate !== null)
            vm.setPayload({ organizationId: vm.organization.id, period, startDate: vm.startDate, endDate: vm.endDate })
        else if (period === 'custom' && (vm.startDate !== null || vm.endDate !== null))
            vm.setPayload({ organizationId: vm.organization.id, period, startDate: vm.startDate, endDate: vm.endDate })
    }, [vm.locked, vm.searchParams, vm.startDate, vm.endDate, vm.organization])
    
    useEffect(() => {
        const search = async () => {
            if (vm.payload.organizationId) {
                vm.setOrgStats(await actions.getActionsByOrganizationId(vm.payload))
                vm.setUserData(await actions.getActionCountGroupedByUser(vm.payload))
            }
        }
        
        search()
    }, [vm.payload])
    
    useEffect(() => {
        actions.getActionCountGroupedByOrg().then(res => vm.setOrganizations(res))
    }, [])
    
    return (
        <div className="CheckImports p-4 w-[100%]">
            
            <header className="flex mb-4">
                
                <div className="flex flex-col">
                    <h4 className="text-xl text-dashboard-primary flex items-center gap-2 h-8">
                        {vm.organization && (
                            <MdChevronLeft
                                className="text-3xl cursor-pointer"
                                onClick={() => vm.clearTrackingDetails()} />
                        )}
                        {' '}
                        User Action Tracking
                        {vm.organization && ` - ${vm.organization.name}`}
                    </h4>
                </div>
            
            </header>
            {!vm.organization && <div className="grid grid-cols-1 gap-3">
                <div className="card">
                    <div className="card-header grow-0">Actions by Organization</div>
                    <div className="card-body pt-2 pb-3">
                        <ul role="list" className="divide-y divide-gray-500">
                            {vm.organizations.map(o => (
                                <li
                                    key={o.id}
                                    className="flex items-center py-2 cursor-pointer"
                                    onClick={() => vm.goToTrackingDetails(o)}>
                                    <div className="flex-1">
                                        <p className="text-sm font-semibold leading-6">
                                            {o.name}
                                        </p>
                                        <p className="mt-1 truncate text-xs leading-5 text-gray-400">
                                            {o.count} actions
                                        </p>
                                    </div>
                                    <span>
                                        <MdChevronRight size={24} />
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>}
            {vm.organization && <UserActionsDetails vm={vm} />}
        </div>
    )
}

export default UserActions
