import api from '$lib/api'

export const sendConfirmationEmail = async email => {
    
    await api.post('emails', {
        email,
        to: email,
    })
    
}

export const resendSignupEmail = async userIds => {
    
    await api.post('admin/users/sendemail/signup', {
        userIds,
    })
    
}
