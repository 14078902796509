import { useMemo } from 'react'

export const useGrantorHelpers = runsheet => {
    
    // Grantors from all documents in this runsheet
    const allGrantors = useMemo(() => runsheet?.rdj?.reduce((acc, it) => (
        (it.value)
            ? [...acc, it.value.fullGrantor]
            : acc
    ) ?? [], []), [runsheet])
    
    // Grantees from all documents in this runsheet
    const allGrantees = useMemo(() => runsheet?.rdk?.reduce((acc, it) => (
        (it.value)
            ? [...acc, it.value.fullGrantee]
            : acc
    ) ?? [], []), [runsheet])
    
    // All parties (grantors/grantees) combined, for autocomplete
    const allParties = useMemo(() => [
        ...(allGrantors ?? []),
        ...(allGrantees ?? []),
    ], [allGrantors, allGrantees])
    
    // All parties formatted for chips input
    const grantorGranteeSuggestionList = useMemo(() => (
        allParties.map((gg, idx) => ({ id: idx, name: gg }))
    ), [allParties])
    
    return {
        allGrantors,
        allGrantees,
        allParties,
        grantorGranteeSuggestionList,
    }
    
}

export const buildPartyInterestData = party => {
    if (!party) return {}
    party.surface = party.surface || party.RunsheetDocumentPartiesJoin?.surface
    party.executive = party.executive || party.RunsheetDocumentPartiesJoin?.executive
    party.bonus = party.bonus || party.RunsheetDocumentPartiesJoin?.bonus
    party.delay = party.delay || party.RunsheetDocumentPartiesJoin?.delay
    party.royalty = party.royalty || party.RunsheetDocumentPartiesJoin?.royalty
    return party
}

const removeEmptyNameParties = state => state.filter(s => s?.name?.trim().length > 0)

export const handlePartyChanges = (parties, originalParties) => {
    const filtered = removeEmptyNameParties(parties)
    
    originalParties.forEach(op => {
        const foundParty = filtered.find(f => f.id === op.id)
        
        if (!foundParty) {
            filtered.push({ id: op.id, name: op.name, action: 'remove' })
        } else {
            foundParty.action = foundParty.name !== op.name ? 'update' : 'no-action'
        }
    })
    
    filtered.forEach(f => {
        if (!f.id)
            f.action = 'new'
    })
    
    return filtered
}

export const partiesFromText = (partiesText, originalParties = []) => {
    const filtered = []
    const parties = partiesText.split(';').map(p => p.trim()).filter(p => !!p)
    
    parties.forEach(party => {
        const foundParty = originalParties.find(op => op.name.toLowerCase() === party.toLowerCase())
        
        if (!foundParty) {
            filtered.push({ name: party, action: 'new' })
        } else {
            filtered.push({
                id: foundParty.id,
                name: party,
                action: foundParty.name !== party ? 'update' : 'no-action',
            })
        }
    })
    
    originalParties.forEach(op => {
        const foundParty = filtered.find(f => f.id === op.id)
        
        if (!foundParty) {
            filtered.push({ id: op.id, name: op.name, action: 'remove' })
        }
    })
    
    return filtered
}