import { useMemo } from 'react'
import { useWireValue } from '@forminator/react-wire'
import * as store from '@store'
import { MdNotifications, MdNotificationsPaused, MdNotificationsOff, MdNotificationsActive } from 'react-icons/md'

const NotificationCenterIcon = () => {
    
    const ably = useWireValue(store.ably, null)
    const connectionState = useWireValue(store.realtimeConnectionState)
    const notifications = useWireValue(store.notifications)
    
    // @todo
    // const escrowNotifications = useWireValue(store.escrows)
    
    const iconStateMap = useMemo(() => {
        
        if (!ably) return {}
        
        const states = Object
            .keys(ably.connection.connectionManager.states)
            .reduce((acc, it) => ({
                ...acc,
                [it]: ably.connection.connectionManager.states[it].state,
            }), {})
        
        return {
            [states.initialized]: MdNotifications,
            [states.connecting]: MdNotificationsPaused,
            [states.connected]: MdNotifications,
            [states.disconnected]: MdNotificationsOff,
            [states.suspended]: MdNotificationsPaused,
            [states.closing]: MdNotificationsOff,
            [states.closed]: MdNotificationsOff,
            [states.failed]: MdNotificationsOff,
        }
        
    }, [ably])
    
    const stateIcon = useMemo(() => {
        
        let Component = iconStateMap?.[connectionState?.state] || MdNotifications
        
        // Show a more active notification icon if there are any notifications
        if (notifications?.length > 0)
            Component = MdNotificationsActive
        
        return <Component className="text-2xl" />
        
    }, [connectionState, notifications])
    
    return (
        
        <div tabIndex={0} role="button" className="btn btn-ghost btn-square m-1">
            <div className="indicator">
                {stateIcon}
                {notifications.length > 0 && (
                    <span className="indicator-item badge translate-x-5 text-xs bg-transparent border-none">
                        {notifications.length}
                    </span>
                )}
            </div>
        </div>
        
    )
    
}

export default NotificationCenterIcon
