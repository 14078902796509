import { createWire, createSelector } from '@forminator/react-wire'
import { createPersistedWire } from 'react-wire-persisted'
import { keys, DEFAULT_REMOTE_CONFIG } from '@constants'

export const apiConfig = createPersistedWire(keys.apiConfig, DEFAULT_REMOTE_CONFIG)

export const remoteConfig = createSelector({
    get: ({ get }) => get(apiConfig)?.remoteConfig ?? {},
})

export const settings = createWire({})

export const billingInfo = createWire([])

export const addPaymentCardDialogOpen = createWire(false)
