import { useWire } from '@forminator/react-wire'
import * as store from '@store'
import { COMPAT_ERRORS } from '@constants'

import './CompatibilityErrorsWarning.css'

const CompatibilityErrorsWarning = ({
    errors,
}) => {
    
    const ignoreCompatErrors = useWire(store.ignoreCompatErrors)
    
    const onDismissClick = () => {
        
        ignoreCompatErrors.setValue(true)
        
    }
    
    return (
        
        <div className="CompatibilityErrorsWarning">
            
            <div className="absolute-centered" style={{ marginTop: '-4rem' }}>
                
                <h3 className="display-4">
                    Compatibility Check
                </h3>
                
                <p className="mt-4">It looks like your browser might have some compatibility issues running Parse:</p>
                
                <ul className="mt-4">
                    {errors?.includes(COMPAT_ERRORS.GRAMMARLY) && (
                        <li>Grammarly extension may cause the website to freeze</li>
                    )}
                </ul>
                
                <p className="mt-5">
                    I understand the risks, and want to:
                </p>
                
                <button
                    className="mt-2 btn btn-warning"
                    onClick={onDismissClick}>
                    Proceed Anyway
                </button>
            
            </div>
        
        </div>
        
    )
    
}

export default CompatibilityErrorsWarning
