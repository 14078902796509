import { useMemo } from 'react'

import { WiDaySunny, WiMoonAltWaxingCrescent1, WiMoonWaningCrescent2, WiMoonAltWaningCrescent5 } from 'react-icons/wi'

const themeIcons = {
    light: WiDaySunny,
    dim: WiMoonAltWaningCrescent5,
    deep: WiMoonAltWaxingCrescent1,
    dark: WiMoonWaningCrescent2,
}

const ThemeIcon = ({
    className = 'text-xl',
    theme,
}) => {
    
    const Icon = useMemo(() => {
        
        if (!theme) return null
        
        return themeIcons[theme.key]
        
    }, [theme])
    
    if (!Icon) return null
    
    return (
        
        <Icon className={className} />
        
    )
    
}

export default ThemeIcon
