import { useMemo } from 'react'
import { useWireValue } from '@forminator/react-wire'
import * as store from '@store'
import * as utils from '@utils'

export const useAccessUser = ({ accessType, accessName }) => {
    
    const user = useWireValue(store.user)
    
    return useMemo(() => {
        
        if (!user || !accessName || !accessType) return
        
        // console.log(accessType, '-', accessName, 'in', user.roles)
        
        return user?.roles?.some(it => (
            utils.get(it.rule, `${accessType}.${accessName}`)
        ))
        
    }, [user, accessName, accessType])
    
    /* return useMemo(() => {
        
        let isEdit = false
        
        user?.roles?.forEach(rule => {
            try {
                if (rule.rule[accessType][accessName])
                    isEdit = true
            } catch (e) {
                console.warn('Invalid access type or name', e)
            }
        })
        
        // if (userType === "Parse Admin") {
        // } else {
        //     return isEdit && Paid
        // }
        
        return isEdit
        
    }, [user, accessName, accessType]) */
    
}
