import { 
    PURCHASE_TYPE_COSTS,
    PURCHASE_TYPE_MIN_COSTS,
    PURCHASE_TYPE_AUTO_CHAIN,
} from '@constants'

/**
 * Calculates the cost of chaining.
 *
 * @param {number} numDocuments - The number of documents involved in the purchase.
 * @returns {number} - The calculated cost of the purchase.
 */
export const calculateChainingCost = numDocuments => {
    const totalCost = numDocuments * PURCHASE_TYPE_COSTS[PURCHASE_TYPE_AUTO_CHAIN]
    const minCost = PURCHASE_TYPE_MIN_COSTS[PURCHASE_TYPE_AUTO_CHAIN]
    
    return Math.max(minCost, Math.ceil(totalCost))
}
