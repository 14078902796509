import { useState, useCallback, useEffect } from 'react'
import useCheckableItems from '@hook/useCheckableItems'
import * as actions from '@graphql/actions'
import { snippet } from '@utils'
import { relativeTime } from '@/utils/date'
import { toast } from 'react-toastify'
import cn from 'classnames'

import JobsDataTable from './JobsDataTable'
import Checkbox from '$components/Forms/Checkbox'
import { FaTrash } from 'react-icons/fa'

const JobsTable = ({
    className,
    TABS,
    tab,
    stats,
    filterName,
    jobsLimit,
    monitorJobsEnabled,
    setTotalJobs,
}) => {
    
    const [jobs, setJobs] = useState([])
    
    const fetchJobs = useCallback(() => {
        
        const fn = tab === TABS.JOBS
            ? actions.fetchQueueJobs
            : actions.fetchQueueArchivedJobs
        
        fn(filterName ? 'parse' : '', { limit: jobsLimit })
            .then(it => {
                setJobs(it?.nodes ?? [])
                setTotalJobs(it?.totalCount ?? 0)
            }).catch(e => {
                console.error('@todo show fetchQueueJobs error', e)
                toast.error('Failed to fetch queue jobs')
            })
        
    }, [tab, filterName])
    
    const {
        checked,
        allChecked,
        someChecked,
        hasChecked,
        toggleChecked,
        toggleAllChecked,
    } = useCheckableItems(jobs)
    
    const onDeleteClick = async id => {
        
        if (!confirm('Are you sure you want to delete this job?\n\n@todo replace this with a real dialog'))
            return
        
        try {
            
            const res = await actions.deleteQueueJob(id)
            
            console.log('onDeleteClick res', res)
            
        } catch (e) {
            
            // @todo show error
            console.error('onDeleteClick', e)
            
        }
        
    }
    
    const onDeleteAllCheckedClick = useCallback(async () => {
        
        if (!confirm('Are you sure you want to delete all checked jobs?\n\n@todo replace this with a real dialog'))
            return
        
        try {
            
            const res = await actions.deleteQueueJobs(checked)
            
            console.log('onDeleteAllCheckedClick res', res)
            
        } catch (e) {
            
            // @todo show error
            console.error('onDeleteAllCheckedClick', e)
            
        }
        
    }, [checked])
    
    useEffect(() => { fetchJobs() }, [tab, stats, filterName])
    
    useEffect(() => {
        
        let t
        
        if (monitorJobsEnabled)
            t = setInterval(fetchJobs, 5000)
        else
            clearInterval(t)
        
        return () => clearInterval(t)
        
    }, [tab, monitorJobsEnabled])
    
    return (
        
        <table className={cn('JobsTable table table-auto', className)}>
            
            <thead>
                <tr>
                    <th>
                        <Checkbox
                            checked={allChecked}
                            indeterminate={someChecked}
                            onChange={toggleAllChecked} />
                    </th>
                    <th>ID</th>
                    <th>Created</th>
                    <th>Completed</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>State</th>
                    <th>Data</th>
                    <th>Output</th>
                    <th>
                        <button
                            className={cn('text-red-400 btn btn-link hover:text-red-600', {
                                'disabled opacity-0': !allChecked && !someChecked,
                            })}
                            disabled={!allChecked && !someChecked}
                            onClick={onDeleteAllCheckedClick}>
                            <FaTrash />
                        </button>
                    </th>
                </tr>
            </thead>
            
            <tbody>
                {!jobs?.length && (
                    <tr>
                        <td className="text-center" colSpan="8">
                            <p>No jobs currently in queue.</p>
                        </td>
                    </tr>
                )}
                {jobs?.map(it => (
                    <tr key={`JobsTable-job-${it.id}`}>
                        <td>
                            <Checkbox
                                checked={hasChecked(it.id)}
                                onChange={() => toggleChecked(it.id)} />
                        </td>
                        <td title={it.id}>{snippet(it.id)}</td>
                        <td>{relativeTime(it.createdon)}</td>
                        <td>{relativeTime(it.completedon)}</td>
                        <td>{it.name}</td>
                        <td>{it.data?.type ?? 'N/A'}</td>
                        <td>{it.state}</td>
                        <td>
                            {it.data.data ? <JobsDataTable data={it.data.data} initiallyExpanded={true} /> : 'N/A'}
                        </td>
                        <td>
                            {it.output ? <JobsDataTable data={it.output} /> : 'N/A'}
                        </td>
                        <td>
                            <div className="flex items-center content-center justify-around">
                                <button
                                    className="text-red-400 btn btn-link hover:text-red-600"
                                    onClick={() => onDeleteClick(it.id)}>
                                    <FaTrash />
                                </button>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        
        </table>
        
    )
    
}

export default JobsTable
