import * as store from '@store'

export const hasPromptedToProcessDocumentId = documentId => {
    const value = store.checkedProcessDocument.getValue()
    
    return Object.hasOwn(value, documentId)
}

export const rememberHasPromptedToProcessDocumentId = documentId => {
    const value = { ...store.checkedProcessDocument.getValue() }
    
    if (!hasPromptedToProcessDocumentId(documentId)) {
        value[documentId] = true
        store.checkedProcessDocument.setValue(value)
    }
}


export const forgetHasPromptedToProcessDocumentId = documentId => {
    const value = { ...store.checkedProcessDocument.getValue() }
    
    if (hasPromptedToProcessDocumentId(documentId)) {
        delete value[documentId]
        store.checkedProcessDocument.setValue(value)
    }
}
