import Logger from '@utils/log'
import * as store from '@store'
import ParseAPI from '@api/ParseAPI'

const log = new Logger('actions/subscriptions')

export const fetchSubscriptions = async () => {
    
    try {
        
        const res = await ParseAPI.get('subscription')
        
        store.subscriptions.setValue(res.data)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}
