import api from '$lib/api'
import * as store from '$store'

export const changeExampleType = async (exampleId, exampleType) => {
    
    const res = await api.put(`exampleManager/changeExampleType/${exampleId}`, { exampleType })
    
    const oldExamples = store.examples.getValue()
    const idx = oldExamples.findIndex(ex => ex.id === exampleId)
    
    oldExamples[idx].exampleType = exampleType
    
    const examplesToSet = [...oldExamples]
    
    store.examples.setValue(examplesToSet)
    
    return res.data
    
}

export const deployToLiveTable = async presetId => {
    
    const res = await api.post(`exampleManager/deployToLiveTable/${presetId}`)
    
    return res.data
}

export const loadFromLiveTable = async (activeTab, activeSubTab) => {
    
    const res = await api.post('exampleManager/loadFromLiveTable', { activeTab, activeSubTab })
    
    const oldExamples = store.examples.getValue()
    const examples = oldExamples.filter(ex => !(ex.system === activeTab 
            && ex.valueExtractorTemplateId === activeSubTab))
    
    store.examples.setValue([...examples, ...res.data])
    
}

export const deleteDefinition = async definitionId => {
    
    await api.delete(`exampleManager/definition/${definitionId}`)
    
}

export const deletePresetDefinition = async presetDefinitionId => {
    
    await api.delete(`exampleManager/presetDefinition/${presetDefinitionId}`)
    
}

export const saveInstructionAndDefinitions = async (instruction, definitions, activeTab, activeSubTab) => {
    
    const res = await api.post('exampleManager/saveInstructionAndDefinitions', 
        { instruction, definitions, activeTab, activeSubTab })
    
    return res.data
    
}

export const savePresetInstructionAndDefinitions = async (instruction, definitions, presetId) => {
    
    const res = await api.post(`exampleManager/savePresetInstructionAndDefinitions/${presetId}`, 
        { instruction, definitions })
    
    return res.data
    
    
}

export const findPresetInstructionAndDefinitions = async presetId => {
    
    const res = await api.get(`exampleManager/findPresetInstructionAndDefinitions/${presetId}`)
    
    return res.data
    
}

export const findInstructionAndDefinitions = async activeSubTab => {
    
    const res = await api.post('exampleManager/findInstructionAndDefinitions', { activeSubTab })
    
    return res.data
}

export const copyToWorkingTable = async presetId => {
    
    const res = await api.post(`exampleManager/copyToWorkingTable/${presetId}`)
    
    return res.data
    
}

export const getAllPresetDocuments = async presetToFetch => {
    
    const res = await api.get(`exampleManager/getAllPresetDocuments/${presetToFetch}`, presetToFetch)
    
    store.presetDocuments.setValue(res.data)
    
    return res.data
}

export const getAllPresets = async () => {
    
    const res = await api.get('exampleManager/getAllPresets')
    
    store.presets.setValue(res.data)
    
    return res.data
}

export const createPreset = async payload => {
    
    const res = await api.post('exampleManager/createPreset', payload)
    
    return res.data
}

export const getExamples = async () => {
    
    const res = await api.get('exampleManager/')
    
    store.examples.setValue(res.data)
    
    return res.data
}

export const getPromptConfig = async (id, orgOrCollection) => {
    
    const res = await api.get(`${orgOrCollection}/${id}`)
    
    return res.data
}

export const savePromptConfig = async (promptConfig, orgOrCollection) => {
    
    const res = await api.put(`${orgOrCollection}/${promptConfig.id}`, { ...promptConfig })
    
    return res.data
    
}

export const getTemplates = async () => {
    
    const res = await api.get('exampleManager/templates')
    
    store.systemTemplates.setValue(res.data)
    
    return res.data
}

export const deleteExamples = async ids => {
    
    await api.post('exampleManager/delete', { ids })
    
    const examples = store.examples.getValue()
    
    store.examples.setValue(examples.filter(ex => !ids.includes(ex.id)))
    
}

export const deletePresets = async ids => {
    
    await api.post('exampleManager/presets/delete', { ids })
    
    const presets = store.presets.getValue()
    
    store.presets.setValue(presets.filter(preset => !ids.includes(preset.id)))
    
}

export const addNewTemplate = async (name, system) => {
    
    const res = await api.post('exampleManager/template', { name, system })
    
    const oldTemplates = store.systemTemplates.getValue()
    
    const newTemplates = [...oldTemplates, res.data]
    
    store.systemTemplates.setValue(newTemplates)
    
}

export const editTemplate = async (id, template) => {
    
    // setting quickly before api call to not get stuck on the UI
    const data = [...store.systemTemplates.getValue()]
    const tmplIdx = data.findIndex(tmpl => tmpl.id === id)
    
    data[tmplIdx] = { ...data[tmplIdx], ...template }
    store.systemTemplates.setValue(data)
    
    const res = await api.patch(`exampleManager/template/${id}`, template)
    
    const copy = [...store.systemTemplates.getValue()]
    
    const idx = copy.findIndex(tmpl => tmpl.id === id)
    
    copy[idx] = res.data
    
    store.systemTemplates.setValue(copy)
    
}

export const createEval = async (presetId, collectionId, runsheetId) => {
    const res = await api.post('exampleManager/createEval', { presetId, collectionId, runsheetId })
    
    return res.data
}   

export const clonePreset = async (selectedPresetId, clonedPresetName) => {
    
    const res = await api.post(`exampleManager/clonePreset/${selectedPresetId}`, { clonedPresetName })
    
    const oldPresets = store.presets.getValue()
    const newPresets = [ res.data, ...oldPresets ]
    
    store.presets.setValue(newPresets)
    
    return res.data
}

export const changeEnabledValue = async (exampleId, enabledValue) => {
    
    const res = await api.put(`exampleManager/changeEnabledValue/${exampleId}`, { enabledValue })
    
    const oldExamples = store.examples.getValue()
    const idx = oldExamples.findIndex(ex => ex.id === exampleId)
    
    oldExamples[idx] = { ...oldExamples[idx], enabled: enabledValue }
    
    const examplesToSet = [...oldExamples]
    
    store.examples.setValue(examplesToSet)
    
    return res.data
}