import Logger from '@utils/log'
import * as store from '@store'
import ParseAPI from '@api/ParseAPI'
import { ROLE_SYS_ADMIN } from '@constants'
import * as crypto from '@utils/crypto'
import { saveMergedUserLocal } from '@store'

const log = new Logger('actions/auth')

export const checkUserExists = async email => {
    
    try {
        
        const res = await ParseAPI.get(`auth/checkuserexists/${email}`)
        
        // @todo prob not necessary?
        store.userExists.setValue(res.data.exists)
        
        return res.data.exists
        
    } catch (error) {
        
        log.e(error)
        return false
        
    }
    
}

export const getUserStage = async userId => {
    
    try {
        
        const res = await ParseAPI.get(`auth/user/${userId}`)
        
        store.userStage.setValue(res.data)
        
        return res.data
        
    } catch (e) {
        
        console.error('getUserStage', e)
        
        store.userStage.setValue(null)
        
        return null
        
        
    }
    
}

export const signIn = async (email, password) => {
    
    try {
        
        const res = await ParseAPI.post('auth/signin', { email, password })
        
        localStorage.setItem('jwt', res.headers['x-api-key'])
        localStorage.setItem('userId', res.data.id)
        localStorage.setItem('userEmail', res.data.email)
        
        ParseAPI.defaults.headers = {
            'Content-Type': 'application/json',
            'x-api-key': localStorage.getItem('jwt'),
        }
        
        // Explicitly not using `saveMergedUserLocal` here,
        // since auth should override any saved data
        store.user.setValue(res.data)
        
        // Only for development & admins, save logins for quick access
        if (process.env.NODE_ENV === 'development' &&
            res.data.isAdmin &&
            res.data.roles.some(it => it.name === ROLE_SYS_ADMIN)) {
            
            const enc = await crypto.subtleEncrypt(password)
            
            store.successfulLogins.setValue({
                ...(store.successfulLogins.getValue() || {}),
                [email]: crypto.serializeEncryptedData(enc),
            })
            
        }
        
        return {
            isLoggedIn: true,
            user: res.data,
        }
        
    } catch (error) {
        
        log.e('signIn', error)
        
        return {
            errorStatus: error?.response?.status || 400,
            isLoggedIn: false,
            user: null,
        }
        
    }
    
}

export const signInAdminDebugSavedLogin = async (email, encryptedSavedLogin) => {
    
    try {
        
        const enc = crypto.deserializeEncryptedData(encryptedSavedLogin)
        const password = await crypto.subtleDecrypt(enc.iv, enc.data)
        
        return await signIn(email, password)
        
    } catch (e) {
        
        console.error('signInAdminDebugSavedLogin', e)
        
    }
    
}

export const signUp = async user => {
    
    try {
        
        const res = await ParseAPI.post('auth/signup', { user })
        
        // Explicitly not using `saveMergedUserLocal` here,
        // since auth should override any saved data
        store.user.setValue(res.data)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const signOut = async () => {
    
    try {
        
        await ParseAPI.post('auth/signout')
            .then(() => { })
            .catch(error => log.e(error))
        
        localStorage.removeItem('jwt')
        localStorage.removeItem('userId')
        localStorage.removeItem('userEmail')
        
        // Explicitly not using `saveMergedUserLocal` here,
        // since auth should override any saved data
        store.user.setValue(null)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const verifyEmailAddress = async token => {
    
    try {
        
        const res = await ParseAPI.post(`auth/verify/${token}`)
        
        log.w('@todo not sure what this should do - verifyEmailAddress', res.data)
        
        /* dispatch({
            type: VERIFY_EMAIL_ADDRESS,
            payload: response.data,
        }) */
        
        // @todo pretty sure this returns user, so let's set it here
        saveMergedUserLocal(res.data)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const addUserBilling = async (token, card) => {
    
    try {
        
        const res = await ParseAPI.patch('auth/users/billing/add', {
            card,
            token,
        })
        
        log.w('@todo addUserBilling', res.data)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const setUserPassword = async (id, verificationToken, password) => {
    
    try {
        
        const res = await ParseAPI.post(`auth/setpassword/${id}`, {
            verificationToken,
            password,
        })
        
        saveMergedUserLocal(res.data)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const updateUserEmail = async (email, newEmail) => {
    
    try {
        
        const res = await ParseAPI.post('auth/updateuseremail', {
            email: email,
            newEmail: newEmail,
        })
        
        log.w('@todo updateUserEmail', res.data)
        
        /* dispatch({
            type: UPDATE_USER_EMAIL,
            payload: res.data,
        }) */
        
        // @todo pretty sure this returns user, so let's set it here
        saveMergedUserLocal(res.data)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}
