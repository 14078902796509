import { useCallback } from 'react'
import { useWireValue } from '@forminator/react-wire'
import { orgsPage as storeOrgsPage } from '$store'
import * as actions from '$actions'
import { RECORD_TYPE_CHECK } from '@constants/annotations'
import { toast } from 'react-toastify'

import ManageRecordLabelsBaseModal from './ManageRecordLabelsBaseModal'

const ManageOrgLabelsModal = ({
    organization,
    ...props
}) => {
    
    const orgsPage = useWireValue(storeOrgsPage)
    
    const fetchOrganizations = useCallback(async () => {
        
        try {
            // @todo move 100 limit to constant
            actions.getOrganizations(undefined, orgsPage * 100, 100)
        } catch (e) {
            console.error('fetchOrganizations', e)
            toast.error('Failed to fetch organizations')
        }
        
    }, [orgsPage])
    
    return (
        
        <ManageRecordLabelsBaseModal
            recordType={RECORD_TYPE_CHECK}
            record={organization}
            recordLabel="Organization"
            recordNameKey="name"
            storeKey="modalManageOrgLabelsOpen"
            setLabelsToRecordFn={actions.setLabelsToOrganization}
            fetchRecordsFn={fetchOrganizations}
            {...props} />
        
    )
    
}

export default ManageOrgLabelsModal
