import useElasticSearchViewModel from './ElasticSearchViewModel'
import cn from 'classnames'

import ESEnginesTable from './ESEnginesTable'
import ESJobsTable from './ESJobsTable'
import ESCollectionsTable from './ESCollectionsTable'
import ESDemoSearch from './ESDemoSearch'
import ESCollectionsCountsTable from './ESCollectionsCountsTable'
import SidePanel from '@components/shared/SidePanel'
import Checkbox from '$components/Forms/Checkbox'
import ConfirmationModal from '@components/shared/ConfirmationModal'
import ReIndexCollectionConfirmModal from '$components/Modals/ReIndexCollectionConfirmModal'
import { MdOutlineNotStarted, MdOutlineStopCircle } from 'react-icons/md'
import { BiStats } from 'react-icons/bi'

import './ElasticSearch.css'

const ElasticSearch = () => {
    
    const viewModel = useElasticSearchViewModel()
    
    return (
        
        <div className="ElasticSearch">
            
            <header className="flex items-baseline justify-between mb-3 content-baseline">
                
                <h4 className="!mb-0 text-xl text-dashboard-primary">
                    Elastic Search
                </h4>
                
                <div className="flex items-center content-center justify-end gap-2">
                    
                    <button
                        className={cn('btn w-auto flex justify-center items-center content-center', {
                            'btn-danger text-red-400': viewModel.stats?.enabled,
                            'btn-info text-blue-400': !viewModel.stats?.enabled,
                        })}
                        disabled={viewModel.loading}
                        onClick={() => viewModel.setConfirmToggleEnabled(true)}>
                        {viewModel.stats?.enabled
                            ? <MdOutlineStopCircle className="text-2xl" />
                            : <MdOutlineNotStarted className="text-2xl" />}
                        <span className="ml-3">
                            {viewModel.stats?.enabled ? 'STOP' : 'START'} ES
                        </span>
                    </button>
                    
                    <button
                        className={cn('btn w-auto flex justify-center items-center content-center', {
                            'btn-danger text-orange-400': viewModel.stats?.autoIndexRecords,
                            'btn-info text-blue-400': !viewModel.stats?.autoIndexRecords,
                        })}
                        disabled={viewModel.loading}
                        onClick={() => viewModel.setConfirmToggleAutoIndexRecords(true)}>
                        {viewModel.stats?.autoIndexRecords
                            ? <MdOutlineStopCircle className="text-2xl" />
                            : <MdOutlineNotStarted className="text-2xl" />}
                        <span className="ml-3">
                            AUTO INDEX RECORDS
                        </span>
                    </button>
                    
                    <button
                        className="flex items-center content-center justify-center w-auto text-blue-400 btn btn-info"
                        title="Toggle Sidebar Panel"
                        onClick={viewModel.toggleStatsPanel}>
                        <BiStats className="text-2xl" />
                    </button>
                
                </div>
            
            </header>
            
            <div className="grid grid-cols-12 gap-4">
                
                <ESEnginesTable
                    wrapClassName="col-span-4"
                    viewModel={viewModel} />
                
                <ESJobsTable
                    wrapClassName="col-span-8"
                    jobs={viewModel.jobs}
                    fetchJobs={viewModel.fetchJobs} />
                
                <ESCollectionsTable wrapClassName="col-span-4" viewModel={viewModel} />
                <ESCollectionsCountsTable wrapClassName="col-span-8" viewModel={viewModel} />
                
                <ESDemoSearch wrapClassName="col-span-6" />
            
            </div>
            
            <SidePanel
                className="bg-gray-900"
                contentClassName="flex flex-col p-2"
                open={viewModel.statsPanelOpen}
                width="50%"
                onClose={viewModel.toggleStatsPanel}>
                
                <div className="flex flex-col p-3 bg-gray-800 rounded">
                    
                    <header className="text-sm font-bold text-slate-400">
                        OPTIONS
                    </header>
                    
                    <div className="grid grid-cols-4 gap-12 mt-2">
                        <Checkbox
                            label="Wildcard Search"
                            checked={viewModel.stats.wildcardSearchEnabled}
                            onChange={() => viewModel.toggleWildcardSearchEnabled} />
                        <Checkbox
                            label="Advanced Field Search"
                            checked={viewModel.stats.advancedFieldSearchEnabled}
                            onChange={() => viewModel.toggleAdvancedFieldSearchEnabled} />
                    </div>
                
                </div>
                
                <input
                    className="mt-3"
                    type="text"
                    autoComplete="off"
                    value={viewModel.statsPanelQuery}
                    placeholder="Filter stats..."
                    onChange={e => viewModel.setStatsPanelQuery(e.target.value)} />
                
                <pre className="mt-3">
                    <code className="text-xs text-white">
                        {viewModel.statsFiltered}
                    </code>
                </pre>
            
            </SidePanel>
            
            <ConfirmationModal
                showModal={viewModel.confirmToggleEnabled}
                showHideModal={viewModel.setConfirmToggleEnabled}
                title="Toggle Elastic Search"
                onConfirm={viewModel.toggleEnabled}
                message={(
                    <div>
                        <div className="text-base">
                            <p>
                                Are you sure you want to toggle Elastic Search?
                            </p>
                            <p className="mt-3 text-red-400">
                                <b>This could incur additional GCP costs!</b>
                            </p>
                        </div>
                    </div>
                )} />
            
            <ConfirmationModal
                showModal={viewModel.confirmToggleAutoIndexRecords}
                showHideModal={viewModel.setConfirmToggleAutoIndexRecords}
                title="Toggle Elastic Search - Auto Index Records"
                onConfirm={viewModel.toggleAutoIndexRecords}
                message={(
                    <div>
                        <div className="text-base">
                            <p>
                                Are you sure you want to toggle Elastic Search
                                auto indexing for create/update/delete records?
                            </p>
                            <p className="mt-3 text-red-400">
                                <b>This could break the API if all record schemas are not fully configured!</b>
                            </p>
                            <p className="mt-3 text-red-400">
                                <b>This could incur additional GCP costs!</b>
                            </p>
                        </div>
                    </div>
                )} />
            
            <ReIndexCollectionConfirmModal viewModel={viewModel} />
        
        </div>
        
    )
    
}

export default ElasticSearch
