import { useState } from 'react'

const useSortableTableHeaders = (
    fields = [],
    defaultOrderField = 'createdAt',
    defaultOrderDirection = 'DESC',
) => {
    
    if (!fields?.length)
        throw new Error('Invalid "fields" param')
    
    const [orderField, setOrderField] = useState(defaultOrderField)
    const [orderDirection, setOrderDirection] = useState(defaultOrderDirection)
    
    const onHeaderClick = slug => {
        
        // Clicked on the same field, so switch direction
        if (orderField === slug) {
            setOrderDirection(prev => prev === 'DESC' ? 'ASC' : 'DESC')
        } else {
            setOrderField(slug)
            setOrderDirection('DESC')
        }
        
    }
    
    return {
        orderField,
        setOrderField,
        orderDirection,
        setOrderDirection,
        onHeaderClick,
    }
    
}

export default useSortableTableHeaders
