import { useMemo, useEffect } from 'react'
import { useWireValue } from '@forminator/react-wire'
import * as store from '$store'
import * as actions from '$actions'

import AddAnnotatorForm from './AddAnnotatorForm'
import AnnotatorsTable from '$components/AnnotatorsTable'
import { Link } from 'react-router-dom'

import './Annotators.css'

const Annotators = () => {
    
    const collections = useWireValue(store.collections)
    const documents = useWireValue(store.documents)
    const annotators = useWireValue(store.annotators)
    
    useEffect(() => { actions.getAnnotators() }, [])
    useEffect(() => { actions.getAllDocuments() }, [])
    
    const pending = useMemo(() => documents.filter(it => it.reviewStatus !== 'reviewed'), [documents])
    const complete = useMemo(() => documents.filter(it => it.reviewStatus === 'reviewed'), [documents])
    
    return (
        
        <div className="Annotators">
            
            <header>
                <h4 className="text-xl text-dashboard-primary">
                    Add New Annotator
                </h4>
                <AddAnnotatorForm className="mt-3" />
            </header>
            
            <header className="flex items-center content-center justify-around mt-3">
                <h4 className="text-xl text-dashboard-primary">
                    Document Stats
                </h4>
                <p>{complete?.length}/{pending?.length} completed annotations in {collections?.count} collections</p>
                <p className="mt-2">
                    <Link to="/dashboard/documents">
                        <button className="btn-link">
                            View All &rarr;
                        </button>
                    </Link>
                </p>
            </header>
            
            {/* <div><pre><code>{JSON.stringify({ documents, collections }, null, 4)}</code></pre></div> */}
            
            <div className="annotators-list">
                <AnnotatorsTable annotators={annotators} />
            </div>
        
        </div>
        
    )
    
}

export default Annotators
