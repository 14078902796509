
const badgeFor = (repo, branch) => (
    <div className="flex items-center content-center">
        <span>
            <code className="text-sm">{branch}</code>
        </span>
        <img
            className="ml-2 repo-badge"
            src={`https://gitlab.com/parse-ai/${repo}/badges/${branch}/pipeline.svg`} />
    </div>
)

// eslint-disable-next-line no-unused-vars
const badgesFor = repo => (
    <>
        {badgeFor(repo, 'master')}
        {badgeFor(repo, 'develop')}
    </>
)

export default [
    {
        name: 'Parse API',
        description: 'Backend, API codebase',
        url: 'https://gitlab.com/parse-ai/api',
        
        // after: badgesFor('api'),
    },
    {
        name: 'Parse Web',
        description: 'Frontend, website codebase',
        url: 'https://gitlab.com/parse-ai/web',
        
        // after: badgesFor('web'),
    },
    {
        name: 'Parse LabelStudio',
        description: 'Fork of the official LabelStudio app',
        url: 'https://gitlab.com/parse-ai/labelstudio',
        
        // after: badgesFor('labelstudio'),
    },
    {
        name: 'Parse Check Parser',
        description: 'TODO',
        url: 'https://gitlab.com/parse-ai/check_parser',
        
        // after: badgesFor('check_parser'),
    },
    {
        name: 'Parse GitLab Runner',
        description: 'Custom GitLab runner for private CI/CD',
        url: 'https://gitlab.com/parse-ai/gitlab-runner',
    },
    {
        name: 'Parse App (Deprecated)',
        description: 'Legacy Cognaize Parse codebase',
        url: 'https://gitlab.com/parse-ai/app',
    },
]
