// eslint-disable-next-line
import { createWire, createSelector } from '@forminator/react-wire'
import { createPersistedWire } from 'react-wire-persisted'
import { keys, storeInitialValues } from '@constants'

export const projects = createWire(storeInitialValues.projects)

export const currentProject = createPersistedWire(keys.currentProject, null)

export const recentProjects = createWire([])
