import { memo, useEffect } from 'react'
import useRealtimeChannelName from '@hook/useRealtimeChannelName'
import useRealtimeChannel from '@hook/useRealtimeChannel'
import { ChannelProvider } from 'ably/react'

/**
 * Dummy component that subscribes to a real-time channel
 * 
 * @param {string} channelName
 * @param {Function} [onMessage]
 * @param {Function} [onPresence]
 * @returns {null}
 * @constructor
 */
const RealtimeChannelSubscription = ({
    channelName,
    onMessage,
    onPresence,
}) => {
    
    const { updateStatus, presenceData } = useRealtimeChannel(channelName, onMessage)
    
    useEffect(() => {
        
        // Set the user's status to "online" when they
        // connect so other users can see the roster
        updateStatus('online')
            .catch(e => console.error('updateStatus: failed to set status', e))
        
    }, [])
    
    useEffect(() => {
        
        // Notify any listeners that the roster has changed
        onPresence?.(presenceData)
        
    }, [presenceData])
    
    return null
    
}

/**
 * Container for a real-time subscription to a channel
 * 
 * @param {RealtimeChannelType} channelType
 * @param {Function} [onMessage]
 * @param {Function} [onPresence]
 * @returns {JSX.Element|null}
 * @constructor
 */
const RealtimeSubscription = ({
    channelType,
    onMessage,
    onPresence,
}) => {
    
    // Get the channel name derived from the channel type
    const channelName = useRealtimeChannelName(channelType)
    
    if (!channelName) return null
    
    return (
        
        <ChannelProvider channelName={channelName}>
            
            <RealtimeChannelSubscription
                channelName={channelName}
                onMessage={onMessage}
                onPresence={onPresence} />
        
        </ChannelProvider>
        
    )
    
}

export default memo(RealtimeSubscription)
