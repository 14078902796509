import { useState, useEffect, useCallback } from 'react'
import { ColorPicker as ReactColorPicker, useColor } from 'react-color-palette'
import cn from 'classnames'

import 'react-color-palette/dist/css/rcp.css'
import './ColorPicker.css'

const ColorPicker = ({
    className,
    enabled = true,
    width = 200,
    height = 200,
    initialColor,
    onChange,
}) => {
    
    const [open, setOpen] = useState(false)
    const [color, setColor] = useColor('hex', initialColor)
    
    const clickOutsideHandler = e => {
        
        if (!e?.target?.className?.includes('rcp'))
            setOpen(false)
        
    }
    
    const toggleOpen = useCallback(e => {
        
        if (!enabled) return
        
        e.preventDefault()
        e.stopPropagation()
        
        if (open) {
            setOpen(false)
            // eslint-disable-next-line no-restricted-globals
            document.body.removeEventListener('click', clickOutsideHandler)
        } else {
            setOpen(true)
            // eslint-disable-next-line no-restricted-globals
            document.body.addEventListener('click', clickOutsideHandler)
        }
        
        return true
        
    }, [enabled, open])
    
    useEffect(() => {
        
        if (!open)
            // eslint-disable-next-line no-restricted-globals
            document.body.removeEventListener('click', clickOutsideHandler)
        
    }, [open])
    
    return (
        
        <div className={cn('ColorPicker', className, { open })}>
            
            <button
                style={{
                    backgroundColor: color?.hex ?? '#000000',
                }}
                onClick={toggleOpen} />
            
            <ReactColorPicker
                width={width}
                height={height}
                color={color}
                onChange={color => {
                    setColor(color)
                    onChange && onChange(color)
                }}
                hideHSV
                dark/>
        
        </div>
        
    )
    
}

export default ColorPicker
