import { useEffect, useState } from 'react'
import * as actions from '$actions'

import CircleSpinner from '@/components/shared/CircleSpinner'
import { toast } from 'react-toastify'
import { getFileSizeFormatted } from '@/utils'

const CheckImports = () => {
    
    const [uploadingIdx, setUploadingIdx] = useState([])
    const [checkImports, setCheckImports] = useState([])
    const [checkImportsUpload, setCheckImportsUpload] = useState([])
    
    const fetchImportsInfo = async () => {
        const data = await actions.getImportsInfo()
        
        setCheckImports(data)
    }
    
    const fetchImportsUploads = async () => {
        const data = await actions.getImportsUpload()
        
        setCheckImportsUpload(data)
    }
    
    useEffect(() => {
        fetchImportsInfo()
        fetchImportsUploads()
    }, [])
    
    const processUploadedFile = async (fileName, idx) => {
        try {
            if (uploadingIdx[idx]) return
            
            setUploadingIdx(prev => {
                const idxCopy = [...prev]
                
                idxCopy[idx] = true
                return idxCopy
            })
            await actions.processUploadedFile(fileName)
            await fetchImportsUploads()
            await fetchImportsInfo()
            toast.success('File have been queued for processing and import. See more on Queue page.')
        } catch (err) {
            toast.error(err.response?.data.error || err.message || 'Failed to queue file processing')
        }
        
        setUploadingIdx(prev => {
            const idxCopy = [...prev]
            
            idxCopy[idx] = false
            return idxCopy
        })
    }
    
    return (
        <div className="CheckImports p-4 w-[100%]">
            
            <header className="flex mb-4">
                
                <div className="flex flex-col">
                    <h4 className="text-xl text-dashboard-primary">
                        Check Imports
                    </h4>
                </div>
            
            </header>
            
            {!!checkImportsUpload?.length && <div className="mb-4 w-[50%]">
                <label>GCS Uploaded Files</label>
                <table className="table table-sm table-nowrap card-table">
                    <thead>
                        <tr>
                            <th>File Name</th>
                            <th>Size</th>
                            <th style={{ width: '100px' }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {checkImportsUpload.map((ciu, idx) => <tr key={idx}>
                            <td>{ciu.fileName}</td>
                            <td>{getFileSizeFormatted(ciu.size)}</td>
                            <td>
                                <button
                                    className="btn btn-sm btn-primary"
                                    onClick={() => processUploadedFile(ciu.fileName, idx)}>
                                    {!uploadingIdx[idx] && <span>Process</span>}
                                    {uploadingIdx[idx] && <CircleSpinner size="14px" className="mt-[3px] mb-[-3px]" />}
                                </button>
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>}
            
            {!!checkImports?.length && <div>
                <label>Processed Files</label>
                <table className="table table-sm table-nowrap card-table">
                    <thead>
                        <tr>
                            <th>File Name</th>
                            <th>Organization</th>
                            <th>Row Count</th>
                            <th>Starting Income Date</th>
                            <th>Ending Income Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {checkImports.map((ci, i) => <tr key={i}>
                            <td>{ci.fileName}</td>
                            <td>{ci.organizationName}</td>
                            <td>{ci.rowCount}</td>
                            <td>{ci.minIncomeDate}</td>
                            <td>{ci.maxIncomeDate}</td>
                        </tr>)}
                    </tbody>
                </table>
            </div>}
        </div>
    )
}

export default CheckImports
