import { useEffect } from 'react'
import { useChannel, usePresence, usePresenceListener } from 'ably/react'

/**
 * Initializes a real-time channel for a given channel type,
 * including presence updates & a channel subscription
 * 
 * Note: use `useRealtimeChannelName` to get a valid channel name
 *
 * @param {string} channelName
 * @param {Function} [onMessage]
 * @returns {Object}
 */
const useRealtimeChannel = (channelName, onMessage = null) => {
    
    // @todo not sure if this is needed
    const { updateStatus } = usePresence(channelName)
    
    const { presenceData } = usePresenceListener(channelName)
    
    const { channel } = useChannel(channelName, message => {
        console.log(`channel(${channelName}): message:`, message)
        onMessage?.(channelName, message)
    })
    
    useEffect(() => {
        console.log(`channel(${channelName}): subscribed`)
    }, [])
    
    useEffect(() => {
        console.log(`channel(${channelName}): total users connected: ${presenceData.length}`)
    }, [presenceData])
    
    return {
        updateStatus,
        presenceData,
        channel,
    }
    
}

export default useRealtimeChannel
