import api from '$lib/api'

// import { createQueryString } from '@utils'

export const getFirebaseRemoteConfig = async () => {
    
    const res = await api.get('/admin/fbrc')
    
    return res.data
    
}

export const syncFirebaseRemoteConfig = async () => {
    
    const res = await api.put('/admin/fbrc/sync')
    
    return res.data
    
}
