import { useState, useEffect } from 'react'
import ParseAPI from '@api/ParseAPI'
import cn from 'classnames'

import Collapse from '@components/shared/Collapse'
import CircleSpinner from '@components/shared/CircleSpinner'

const HomeEnvPanel = ({
    className,
}) => {
    
    const [loading, setLoading] = useState(true)
    
    /**
     * @type {[Object, Function]}
     */
    const [data, setData] = useState(null)
    
    useEffect(() => {
        
        setLoading(true)
        
        ParseAPI.get('/admin/env')
            .then(it => setData(it?.data))
            .catch(e => {
                console.error('fetchRemoteEnv', e)
                setData(null)
            })
            .finally(() => setLoading(false))
        
    }, [])
    
    if (loading) return (
        <CircleSpinner className="absolute-centered" />
    )
    
    if (!data) return null
    
    return (
        
        <Collapse
            className={cn('HomeEnvPanel', className)}
            titleClassName="opacity-50"
            title="Remote Environment"
            sticky>
            
            {/* <div>
                <pre>
                    <code className="text-left">
                        {JSON.stringify(data, null, 4)}
                    </code>
                </pre>
            </div> */}
            
            <table className="table-auto">
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(data).map(it => (
                        <tr key={`remote-env-${it}`}>
                            <th>{it}</th>
                            <td>{data[it]?.toString() ?? '-'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        
        </Collapse>
        
    )
    
}

export default HomeEnvPanel
