
export const REALTIME_CHANNEL_TYPE_USERS = 'users'
export const REALTIME_CHANNEL_TYPE_ORGANIZATIONS = 'organizations'

export const REALTIME_CHANNEL_TYPES = [
    REALTIME_CHANNEL_TYPE_USERS,
    REALTIME_CHANNEL_TYPE_ORGANIZATIONS,
]

export const NOTIFICATION_TARGET_USER = 'user'
export const NOTIFICATION_TARGET_ORGANIZATION = 'organization'

export const NOTIFICATION_TARGETS = [
    NOTIFICATION_TARGET_USER,
    NOTIFICATION_TARGET_ORGANIZATION,
]

export const RT_EVENT_TYPE_UPDATE_NOTIFICATION = 'update:notification'
export const RT_EVENT_TYPE_DISMISS_NOTIFICATION = 'dismiss:notification'
export const RT_EVENT_TYPE_MESSAGE_PLAIN = 'message:plain'
export const RT_EVENT_TYPE_DOCUMENT_PROCESS = 'doc:process'
export const RT_EVENT_TYPE_ESCROW_FUNDED = 'escrow:funded'

export const RT_EVENT_TYPES = [
    RT_EVENT_TYPE_UPDATE_NOTIFICATION,
    RT_EVENT_TYPE_DISMISS_NOTIFICATION,
    RT_EVENT_TYPE_MESSAGE_PLAIN,
    RT_EVENT_TYPE_DOCUMENT_PROCESS,
    RT_EVENT_TYPE_ESCROW_FUNDED,
]
