import { useMemo, useState, useEffect, useCallback } from 'react'
import { useWireValue } from '@forminator/react-wire'
import * as store from '$store'
import * as actions from '$actions'
import {
    DOC_NOT_REVIEWED_STATUSES,
    DOC_REVIEW_IN_PROGRESS_STATUSES,
    DOC_REVIEWED_STATUSES,
} from '@constants'
import { toast } from 'react-toastify'

import DocumentsList from './DocumentsList'
import AssignCollectionModal from '$components/Modals/AssignCollectionModal'
import ProgressBar from '@components/shared/ProgressBar'

import './Documents.css'

const allCollectionsItem = {
    id: 0,
    name: 'All Collections',
}

// @todo may introduce this, but hidden for now
const showDocsMissingPagesCount = false

const Documents = () => {
    
    const [count, setCount] = useState(0)
    const [pageIndex, setPageIndex] = useState(0)
    const [progress, setProgress] = useState(0)
    const [progressMax, setProgressMax] = useState(0)
    const [progressCollectionName, setProgressCollectionName] = useState('')
    
    // @todo we never set selected col?
    // eslint-disable-next-line no-unused-vars
    const [selectedCollection, setSelectedCollection] = useState(allCollectionsItem)
    const [docsMissingPagesCount, setDocsMissingPagesCount] = useState(0)
    const [modalAssignCollectionOpen, setModalAssignCollectionOpen] = useState(false)
    
    const documents = useWireValue(store.documents)
    
    const pageCount = useMemo(() => {
        if (!count) return 0
        return Math.round(count / 100)
    }, [count])
    
    const onRequeueDocsWopClick = useCallback(async () => {
        
        try {
            
            const res = await actions.requeueDocumentsMissingPages()
            
            console.log('onRequeueDocsWopClick', res)
            toast(`${docsMissingPagesCount} documents requeued`)
            
        } catch (e) {
            
            console.error('onRequeueDocsWopClick', e)
            
        }
        
    }, [docsMissingPagesCount])
    
    useEffect(() => { actions.getAnnotators() }, [])
    useEffect(() => { actions.getCollectionsWithStats() }, [])
    
    useEffect(() => {
        actions.getDocumentsCount()
            .then(value => setCount(value))
            .catch(e => {
                console.warn('Documents/getDocumentsCount', e)
                setCount(0)
            })
    }, [])
    
    useEffect(() => {
        
        if (!count) return
        
        if (selectedCollection.id)
            actions.getDocuments(selectedCollection.id)
        else
            actions.getAllDocuments(pageIndex, (value, total, collectionName) => {
                if (value < total) {
                    setProgress(value)
                    setProgressMax(total)
                    setProgressCollectionName(collectionName)
                } else {
                    setProgress(0)
                    setProgressMax(0)
                    setProgressCollectionName('')
                }
            })
        
    }, [selectedCollection, count, pageIndex])
    
    useEffect(() => {
        actions.getDocumentsWithoutPages()
            .then(setDocsMissingPagesCount)
    }, [])
    
    const pending = useMemo(() => documents.filter(it => (
        DOC_NOT_REVIEWED_STATUSES.includes(it.reviewStatus) ||
        DOC_REVIEW_IN_PROGRESS_STATUSES.includes(it.reviewStatus)
    )), [documents])
    
    const complete = useMemo(() => documents.filter(it => (
        DOC_REVIEWED_STATUSES.includes(it.reviewStatus)
    )), [documents])
    
    return (
        
        <div className="Documents">
            
            {showDocsMissingPagesCount && docsMissingPagesCount > 0 && (
                <header className="flex justify-center mx-auto">
                    <div className="p-3 px-6 text-center bg-orange-500 bg-opacity-50 rounded">
                        <h4 className="text-xl">Notice</h4>
                        <p>{docsMissingPagesCount} documents are missing pages.</p>
                        <div className="mt-3">
                            <button
                                className="btn btn-secondary"
                                onClick={onRequeueDocsWopClick}>
                                REQUEUE {docsMissingPagesCount} DOCUMENTS
                            </button>
                        </div>
                    </div>
                </header>
            )}
            
            <header className="flex justify-between">
                
                <div className="flex flex-col">
                    <h4 className="text-xl text-dashboard-primary">
                        Documents
                    </h4>
                    <p className="opacity-50">
                        {complete?.length}/{pending?.length} completed annotations
                    </p>
                </div>
            
            </header>
            
            {progress > 0 && progressMax > 0 && (
                <div className="w-full absolute-centered">
                    <div className="w-1/2 mx-auto -mt-20 text-center">
                        <p className="text-lg">
                            LOADING DOCUMENTS,
                            {' '}
                            {progress}/{progressMax}
                            {' '}
                            {Math.round((progress / progressMax) * 100)}%
                        </p>
                        <p className="text-sm">{progressCollectionName}</p>
                        <ProgressBar
                            className="mt-4"
                            max={progressMax}
                            value={progress} />
                    </div>
                </div>
            )}
            
            {progress === 0 && progressMax === 0 && (
                <>
                    <DocumentsList
                        title="Documents"
                        documents={documents}
                        pageCount={pageCount}
                        setPageIndex={setPageIndex} />
                    {/* <DocumentsList
                        title="Completed Documents"
                        documents={complete}
                        page={page}
                        setPage={setPage} /> */}
                </>
            )}
            
            <AssignCollectionModal
                open={modalAssignCollectionOpen}
                setOpen={setModalAssignCollectionOpen}
                collectionId={selectedCollection?.id}
                collectionName={selectedCollection?.name} />
        
        </div>
        
    )
    
}

export default Documents
