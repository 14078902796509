/*
import Logger from '@utils/log'
const log = new Logger('')
*/

const isProduction = process.env.NODE_ENV === 'production'

const verbs = [
    'trace',
    'debug',
    'error',
    'info',
    'log',
    'warn',
]

const verbsToConsoleVerbsMap = {
    trace: 'log',
    debug: 'log',
    error: 'error',
    info: 'info',
    log: 'log',
    warn: 'warn',
}

const defaultOptions = {
    enabled: null,
    setTag: tag => `[${tag}]`,
    pretty: null,
    colorizeTag: null,
    tagColors: {
        trace: '',
        debug: 'green',
        error: 'red',
        info: 'cyan',
        log: null,
        warn: 'orange',
    },
}

/**
 * @typedef Logger
 * Custom logger that passes all arguments to `window.console`,
 * decorated with some options like pretty printing
 *
 * @property {Function} t Short alias for `trace`
 * @property {Function} d Short alias for `debug`
 * @property {Function} e Short alias for `error`
 * @property {Function} i Short alias for `info`
 * @property {Function} l Short alias for `log`
 * @property {Function} w Short alias for `warn`
 * 
 * @example
 * 
 * // Create new loggers
 * const log = new Logger('MyComponent', { pretty: true })
 * 
 * // Derive a new logger with different options
 * const uglyLogger = log.withPretty(false)
 * 
 * uglyLogger.info('this is not pretty', { foo: 'bar' })
 */
export class Logger {
    
    /**
     * 
     * @param {String} tag Tag for each log message (typically the logical name for the file, class, etc.)
     * @param {Object} [options] Optional settings
     * @param {Boolean} [options.enabled]
     * @param {Function} [options.setTag] Override the default tag wrapper (e.g. tag => `[${tag}]`)
     * @param {Boolean} [options.pretty] Pretty-print indented JSON output for objects (default: false)
     * @param {Boolean} [options.colorizeTag] Enable console colors for tags
     * @param {Object} [options.tagColors] Override tag colors, (only applies if `options.colorizeTag` is `true`)
     */
    constructor(tag, options = defaultOptions) {
        
        this.tag = tag
        
        this.options = { ...defaultOptions, ...options }
        this.options.enabled = options.enabled === null ? !isProduction : options.enabled
        this.options.pretty = options.pretty === null ? false : options.pretty
        this.options.colorizeTag = options.colorizeTag === null ? !isProduction : options.colorizeTag
        
        if (isProduction && this.options.enabled)
            console.warn('WARNING: LOGGING ENABLED IN PRODUCTION')
        
    }
    
    /**
     * Chain method for creating a new logger instance with all
     * of this instance's properties, but setting the `enabled` option
     * 
     * @param {Boolean} value Enable all logging
     * @returns {Logger} New logger instance
     */
    withEnabled(value) {
        
        return new Logger(this.tag, {
            ...this.options,
            enabled: value,
        })
        
    }
    
    /**
     * Chain method for creating a new logger instance with all
     * of this instance's properties, but setting the `setTag` option
     * 
     * @param {Function} value Use custom `setTag` function
     * @returns {Logger} New logger instance
     */
    withCreateTag(value) {
        
        return new Logger(this.tag, {
            ...this.options,
            setTag: value,
        })
        
    }
    
    /**
     * Chain method for creating a new logger instance with all
     * of this instance's properties, but setting the `pretty` option
     * 
     * @param {Boolean} value Enable pretty printing
     * @returns {Logger} New logger instance
     */
    withPretty(value) {
        
        return new Logger(this.tag, {
            ...this.options,
            pretty: value,
        })
        
    }
    
    /**
     * Chain method for creating a new logger instance with all
     * of this instance's properties, but setting the `colorizeTag` option
     * 
     * @param {Boolean} value Enable colorized tags
     * @returns {Logger} New logger instance
     */
    withColorizeTag(value) {
        
        return new Logger(this.tag, {
            ...this.options,
            colorizeTag: value,
        })
        
    }
    
    /**
     * Chain method for creating a new logger instance with all
     * of this instance's properties, but setting the `tagColors` option
     * 
     * @param {Object} value Enable pretty printing (schema: { verb: 'color' })
     * @returns {Logger} New logger instance
     */
    withTagColors(value) {
        
        return new Logger(this.tag, {
            ...this.options,
            tagColors: value,
        })
        
    }
    
    /**
     * Calls `JSON.stringify` on object type arguments
     * 
     * @param {Array} args Arguments to format
     * @returns {Array} Indented JSON arguments
     */
    formatPretty(args) {
        
        return args.map(it => {
            
            if (typeof it === 'string') return it
            if (it instanceof Error) return it
            if (it === null) return it
            if (Object.prototype.toString.call(it) === '[object Number]') return it
            
            return JSON.stringify(it, null, 4)
            
        })
        
    }
    
    /**
     * Sends arguments to `window.console`
     * 
     * @param {String} verb Which verb to use for `window.console` (log, debug, info, etc.)
     * @param {...any} args Arguments to pass to `window.console`
     */
    send(verb, ...args) {
        
        if (!this.options.enabled) return
        
        const tag = this.options.setTag(this.tag)
        const tagColor = this.options.tagColors[verb]
        const consoleVerb = verbsToConsoleVerbsMap[verb]
        const messages = this.options.pretty
            ? this.formatPretty(args)
            : args
        
        if (this.options.colorizeTag)
            if (tagColor)
                console[consoleVerb](`%c${tag}\x1b[0m`, `color: ${tagColor}; font-weight: bold;`, ...messages)
            else
                console[consoleVerb](`%c${tag}\x1b[0m`, 'font-weight: bold;', ...messages)
        else
            console[consoleVerb](tag, ...messages)
        
    }
    
}

// Map all `window.console` methods to use custom logger,
// or for unknown verbs, pass directly to `window.console`
Object.keys(console).forEach(verb => {
    
    Logger.prototype[verb] = function(...args) {
        
        if (verbs.includes(verb))
            Logger.prototype.send.apply(this, [verb, ...args])
        else
            console[verb](...args)
        
    }
    
})

// Also map all verbs' shorthand, for convenience
verbs.forEach(verb => {
    
    const shortVerb = verb.substring(0, 1)
    
    Logger.prototype[shortVerb] = function(...args) {
        Logger.prototype.send.apply(this, [verb, ...args])
    }
    
})

// Additional verbs
Logger.prototype.v = Logger.prototype.verbose = function(...args) {
    Logger.prototype.send.apply(this, ['info', ...args])
}

window.Logger = Logger

export default Logger
