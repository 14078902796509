import { gql } from '@apollo/client'

export const JobsConnectionFragment = gql`
    
    fragment JobsConnectionFragment on JobsConnection {
        nodes {
            data
            createdon
            completedon
            id
            name
            state
            output
        }
        pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
        }
        totalCount
    }

`

export const ArchivesConnectionFragment = gql`
    
    fragment ArchivesConnectionFragment on ArchivesConnection {
        nodes {
            data
            createdon
            completedon
            id
            name
            state
            output
        }
        pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
        }
        totalCount
    }

`
