import { createWire } from '@forminator/react-wire'
import { createPersistedWire } from 'react-wire-persisted'
import { keys } from '@constants'

export const subtleCryptoKey = createPersistedWire(keys.subtleCryptoKey, null)
export const successfulLogins = createPersistedWire(keys.successfulLogins, null)

export const isFetchingUser = createWire(false)

export const user = createPersistedWire(keys.user, null)

export const users = createWire([])

export const credits = createWire({ user: 0, organization: 0 })

export const userExists = createWire(false)

export const userStage = createWire(null)

export const afterSignInRedirectUrl = createPersistedWire(keys.afterSignInRedirectUrl, null)

export const hasIdentifiedWithLogRocket = createWire(false)

export const blinkAvatarIndicator = createWire(false)
