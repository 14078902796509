import * as actions from '$actions'
import { snippet } from '@utils'
import cn from 'classnames'

import CopyToClipboardButton from '$components/CopyToClipboardButton'
import { Link } from 'react-router-dom'
import { MdRefresh } from 'react-icons/md'
import { formatDate } from '@/utils/date'

const CollectionLink = ({ document }) => document.collection?.id ? (
    <Link
        to={`/collection/${document.collection.id}`}
        target="_blank">
        {document.collection.name}
    </Link>
) : (
    <span>None</span>
)

const AnnotatorDocumentReviewsTable = ({
    className,
    documentReviews,
}) => {
    
    return (
        
        <table className={cn('AnnotatorDocumentReviewsTable', className)}>
            <thead>
                <tr>
                    <th>Document ID</th>
                    <th>Name</th>
                    <th>File Name</th>
                    <th>Created Date</th>
                    <th>Completed Date</th>
                    <th>Review Status</th>
                    <th>Collection</th>
                    <th>OCR Type</th>
                    <th>
                        <div className="flex items-center content-center justify-center">
                            <button
                                className="action-button"
                                onClick={actions.getAnnotators}>
                                <MdRefresh className="text-xl" />
                            </button>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {documentReviews.map(it => (
                    <tr key={it.id}>
                        <td>
                            <div className="flex justify-start">
                                <Link to={`/edit/${it.document?.id}`} target="_blank">
                                    {snippet(it.document?.id ?? '', 4)}
                                </Link>
                                <CopyToClipboardButton
                                    className="ml-3"
                                    value={it.document?.id} />
                            </div>
                        </td>
                        <td>{it.document.name}</td>
                        <td>{it.document.fileName}</td>
                        <td>{formatDate(it.createdAt)}</td>
                        <td>{formatDate(it.completedAt) ?? 'Not completed'}</td>
                        <td>{it.document.reviewStatus}</td>
                        <td>
                            <CollectionLink document={it.document} />
                        </td>
                        <td>{it.document.ocr}</td>
                        <td>&nbsp;</td>
                    </tr>
                ))}
            </tbody>
        </table>
        
    )
    
}

export default AnnotatorDocumentReviewsTable
