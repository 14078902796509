import Logger from '@utils/log'
import ParseAPI from '@api/ParseAPI'

const log = new Logger('actions/halfile')

export const getFileList = async () => {
    
    try {
        
        const res = await ParseAPI.get('atr')
        
        return res.data
        
    } catch (error) {
        log.e(error)
        throw error
    }
}

export const processAtrFile = async directory => {
    
    try {
        
        await ParseAPI.post('atr', directory)
        
    } catch (error) {
        log.e(error)
        throw error
    }
}
