import Logger from '@utils/log'
import { useEffect, useMemo, useCallback } from 'react'
import { useWireState } from '@forminator/react-wire'
import { externalScriptsCache as storeExternalScriptsCache } from '@store'
import { omit } from '@utils'

const log = new Logger('hook/useExternalScript')

export const defaultProps = {
    enabled: true,
    warnDisabled: true,
    disabledMessage: null,
    logging: false,
    parentSelector: 'body',
    delayMillis: 500,
    onLoad: () => {},
}

export const useExternalScript = (src, properties = {}) => {
    
    const [externalScriptsCache, setExternalScriptsCache] = useWireState(storeExternalScriptsCache)
    
    const props = useMemo(() => ({
        ...defaultProps,
        ...properties,
    }), [properties])
    
    const removeScript = useCallback(() => {
        
        if (!src) return
        
        // eslint-disable-next-line no-restricted-globals
        const parent = document.querySelector(props.parentSelector)
        
        if (!parent) return
        
        Array.from(parent.querySelector('script')).forEach(el => {
            
            if (el.src === src) {
                
                if (props.logging)
                    log.d('Removing script', el)
                
                parent.removeChild(el)
                
            }
            
        })
        
    }, [src, props.parentSelector, props.logging])
    
    const onLoadCallback = useCallback(() => {
        
        if (props.onLoad)
            try {
                props.onLoad()
            } catch (e) {
                console.error('onLoad failed for script', src, e)
            }
        
    }, [props, src])
    
    const loadScript = useCallback(() => {
        
        if (!src?.length) return
        
        // Don't try to load/unload this script if we've already seen it
        if (externalScriptsCache[src]) return
        
        if (!props.enabled) {
            
            if (props.warnDisabled && !process.env.MUTE_EXTERNAL_SCRIPTS_WARNING)
                log.w('useExternalScript', props.disabledMessage ?? `Script disabled (${src})`)
            
            // Also set this so we don't get warned 1000x
            setExternalScriptsCache({
                ...externalScriptsCache,
                [src]: true,
            })
            
            return
            
        }
        
        // eslint-disable-next-line no-restricted-globals
        const parent = document.querySelector(props.parentSelector)
        const extraProps = omit(props, ['enabled', 'disabledMessage', 'logging', 'parentSelector'])
        
        if (!parent)
            throw new Error(`useExternalScript: parent element not found (${props.parentSelector})`)
        
        removeScript()
        
        // eslint-disable-next-line no-restricted-globals
        const script = document.createElement('script')
        
        Object.keys(extraProps).forEach(it => {
            script[it] = extraProps[it]
        })
        
        script.src = src
        script.onload = onLoadCallback
        parent.appendChild(script)
        
        if (props.logging)
            log.d('Added script', script)
        
        setExternalScriptsCache({
            ...externalScriptsCache,
            [src]: true,
        })
        
    }, [
        src,
        externalScriptsCache,
        props,
        removeScript,
        onLoadCallback,
        setExternalScriptsCache,
    ])
    
    useEffect(() => {
        
        const t = setTimeout(loadScript, props.delayMillis)
        
        return () => {
            clearTimeout(t)
            removeScript()
        }
        
    }, [src, props.delayMillis, loadScript, removeScript])
    
}
