import api from '$lib/api'
import * as store from '$store'

export const getAnnotators = async () => {
    
    const res = await api.get('/admin/annotators')
    
    if (res.data) {
        console.info('Fetched', res.data?.length, 'annotators')
        store.annotators.setValue(res.data)
    }
    
    return res
    
}

export const getAnnotatorById = async id => {
    
    const res = await api.get(`/admin/annotators/${id}`)
    
    if (res.data) {
        
        console.info('Fetched annotator with ID', id, res.data)
        
        let values = [...store.annotators.getValue()]
        let userExists = false
        
        // Replace the user if they're already cached
        values = values.map(it => {
            
            if (it.id === id) {
                userExists = true
                return res.data
            }
            
            return it
            
        })
        
        // Add the new user if not
        if (!userExists)
            values.push(res.data)
        
        store.annotators.setValue(values)
        
    }
    
    return res
    
}

export const createAnnotator = async (firstName, lastName, email) => {
    
    return api.post('/admin/annotators', {
        firstName,
        lastName,
        email,
    })
    
}

export const deleteAnnotator = async id => {
    
    return api.delete(`/admin/annotators/${id}`)
    
}
