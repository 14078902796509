// eslint-disable-next-line
import { createWire, createSelector } from '@forminator/react-wire'
// eslint-disable-next-line
import { keys, storeInitialValues } from '@constants'

export const collections = createWire(storeInitialValues.collections)

export const currentCollection = createWire(null)

export const newCollection = createWire(null)

export const renameFileStatus = createWire(false)
