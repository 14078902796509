import cn from 'classnames'

const ModalBody = ({ children, className }) => {
    return (
        <div className={cn('modal-body', className)}>
            {children}
        </div>
    )
}

export default ModalBody