import { useMemo, useState } from 'react'
import { useWireValue } from '@forminator/react-wire'
import { user as storeUser } from '@store'
import { getUserInitials, loadImageAsync } from '@utils'
import useAbortableFetch from '@hook/useAbortableFetch'
import md5 from 'md5'
import cn from 'classnames'

import './Gravatar.css'

const Gravatar = ({
    className,
    imageClassName,
    email = null,
    size = 100,
    children,
}) => {
    
    const [loading, setLoading] = useState(false)
    const [imageUrl, setImageUrl] = useState(null)
    const [userInitials, setUserInitials] = useState('')
    
    const user = useWireValue(storeUser)
    
    const url = useMemo(() => {
        
        // Allow passing an email directly, otherwise default to the user's email
        const value = email ?? user?.email ?? null
        const hash = value ? md5(value.toLowerCase()) : null
        
        return hash ? `https://www.gravatar.com/avatar/${hash}?s=${size}&d=404` : null
        
    }, [size])
    
    useAbortableFetch(() => {
        
        setLoading(true)
        
        loadImageAsync(url)
            .then(it => setImageUrl(it.dataUrl))
            .catch(() => setUserInitials(getUserInitials(user) ?? '??'))
            .finally(() => setLoading(false))
        
    }, [url], 500)
    
    return (
        
        <div
            className={cn('Gravatar rounded-full transition-all ease-in-out duration-500', className, {
                'bg-base-200': !loading && !imageUrl,
            })}
            style={{ '--size': `${size}px` }}>
            
            {imageUrl ? (
                <img className={imageClassName} src={imageUrl} alt={userInitials} />
            ) : (
                <span className="text-xs">{userInitials}</span>
            )}
            
            {children}
        
        </div>
        
    )
    
}

export default Gravatar
