import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useWireState } from '@forminator/react-wire'
import * as store from '$store'
import * as actions from '$actions'
import { toast } from 'react-toastify'

import Modal from '$components/Modal'
import AutocompleteInput from '@components/shared/AutocompleteInput/AutocompleteInput'

const OnboardNewUserModal = ({
    className,
    ...props
}) => {
    
    const [loading, setLoading] = useState(false)
    const [orgs, setOrgs] = useState([])
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [selectedOrganization, setSelectedOrganization] = useState({})
    const [newOrganizationName, setNewOrganizationName] = useState('')
    
    const [modalOnboardNewUserOpen, setModalOnboardNewUserOpen] = useWireState(store.modalOnboardNewUserOpen)
    
    const onOnboardNewUserClick = useCallback(async () => {
        
        setLoading(true)
        
        try {
            
            const res = await actions.onboardNewUser(
                email, firstName, lastName, selectedOrganization.id, newOrganizationName)
            
            await actions.getUsers()
            
            console.log('onOnboardNewUserClick status', res.status)
            toast.success('New user onboarded')
            
            setEmail('')
            setFirstName('')
            setLastName('')
            setSelectedOrganization({})
            setNewOrganizationName('')
            setModalOnboardNewUserOpen(false)
            
        } catch (e) {
            
            console.error('onOnboardNewUserClick', e)
            toast.error('Failed to onboard new user')
            
        }
        
        setLoading(false)
        
    }, [email, firstName, lastName, selectedOrganization, newOrganizationName])
    
    const canSubmitOnboarding = useMemo(() => (
        email?.length > 0 && firstName?.length > 0 && lastName?.length > 0 &&
        (selectedOrganization?.id?.length > 0 || newOrganizationName?.length > 0)
    ), [email, firstName, lastName, selectedOrganization, newOrganizationName])
    
    useEffect(() => {
        
        if (!modalOnboardNewUserOpen) return
        if (orgs?.length) return
        
        // @todo if we have a ton of orgs, need to switch this to actual remote search
        actions.getOrganizations(undefined, 0, 9999)
            .then(res => {
                if (res?.data)
                    setOrgs(res?.data ?? [])
            })
            .catch(e => {
                console.error('useOrganizationsViewModel', e)
                setOrgs([])
                toast.error('Failed to fetch organizations')
            })
        
    }, [orgs, modalOnboardNewUserOpen])
    
    return (
        
        <Modal
            className={className}
            contentClassName="!pt-0"
            open={modalOnboardNewUserOpen}
            setOpen={setModalOnboardNewUserOpen}
            title="Onboard New User"
            actionClass="btn-success"
            actionLabel={(<>
                {loading && <span className="loading loading-spinner"></span>}
                ONBOARD NEW USER
            </>)}
            actionDisabled={!canSubmitOnboarding || loading}
            actionOnClick={onOnboardNewUserClick}
            {...props}>
            
            <div className="mb-4 flex">
                Onboarding a new user will sign them up, send them the email to create a password,
                and either add them to an existing organization or create a new one.
            </div>
            
            <form className="flex flex-col gap-4">
                
                <div className="grid grid-cols-1 md:grid-cols-3 items-center gap-4">
                    
                    <input
                        className="input input-bordered"
                        type="text"
                        value={firstName}
                        placeholder="First Name"
                        autoComplete="off"
                        onChange={e => setFirstName(e.target.value)} />
                    
                    <input
                        className="input input-bordered"
                        type="text"
                        value={lastName}
                        placeholder="Last Name"
                        autoComplete="off"
                        onChange={e => setLastName(e.target.value)} />
                    
                    <input
                        className="input input-bordered grow"
                        type="text"
                        value={email}
                        placeholder="newuser@gmail.com"
                        autoComplete="off"
                        onChange={e => setEmail(e.target.value)} />
                
                </div>
                
                <div className="py-2">
                    <p><i>Choose one of the following options:</i></p>
                </div>
                
                <div className="flex items-center gap-4 w-full">
                    
                    <div className="card px-3 py-4 bg-base-200/60 rounded-box grid flex-grow place-items-center">
                        <h5>Existing Organization</h5>
                        {orgs?.length > 0
                            ? <AutocompleteInput items={orgs} onChange={setSelectedOrganization} />
                            : <p><i>Fetching organizations...</i></p>
                        }
                        {/* <OrganizationTypeahead setOrgName={console.log} /> */}
                    </div>
                    
                    <div className="divider divider-horizontal">
                        OR
                    </div>
                    
                    <div className="card px-3 py-4 bg-base-200/60 rounded-box grid flex-grow place-items-center">
                        <h5>New Organization</h5>
                        <input
                            className="input input-bordered"
                            type="text"
                            value={newOrganizationName}
                            onKeyUp={e => e.key === 'Escape' && setNewOrganizationName('')}
                            onChange={e => setNewOrganizationName(e.target.value)}
                            placeholder="Organization Name"/>
                    </div>
                
                </div>
            
            </form>
            
            {canSubmitOnboarding && (
                <p className="mt-6">
                    User <code className="bg-accent">{firstName} {lastName}</code>
                    {' '}
                    <code className="bg-accent"></code> with email
                    {' '}
                    <code className="bg-accent">{email}</code> will be onboarded to
                    {' '}
                    <code className="bg-accent">{newOrganizationName?.length
                        ? newOrganizationName
                        : selectedOrganization?.name}</code>.
                </p>
            )}
        
        </Modal>
        
    )
    
}

export default memo(OnboardNewUserModal)
