import Logger from '@utils/log'

const log = new Logger('actions/escrows')

// @todo @deprecated will use Ably history instead
export const getUnseenEscrowNotifications = async () => {
    
    return log.d('@todo escrows/unseen')
    
    /* try {
        const res = await ParseAPI.get('escrows/unseen')
        
        store.escrows.setValue(res.data)
    } catch (err) {
        log.e(err)
    } */
    
}
