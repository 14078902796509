
// For fetching the remote API config
export const fetchApiConfigTimeout = 1000 * 30
export const fetchApiConfigInterval = 1000 * 60 * 1 // 5 minutes, as ms

// For checking if the app is up to date
export const appVersionCheckTimeout = 1000 * 30
export const appVersionCheckInterval = 1000 * 60 * 1 // 5 minutes, as ms

// For checking if the app is up to date
export const AppCheckStatus = {
    Loading: 0,
    UpToDate: 1,
    OutOfDate: 2,
}

import {
    DOCUMENT_STATUS_UPLOADED,
    DOCUMENT_STATUS_SAVED,
    DOCUMENT_STATUS_PROCESSING,
    DOCUMENT_STATUS_OCR_APPLIED,
    DOCUMENT_STATUS_MODEL_APPLIED,
    DOCUMENT_STATUS_READY_FOR_REVIEW,
    DOCUMENT_STATUS_REVIEW_IN_PROGRESS,
    DOCUMENT_STATUS_REVIEWED,
    DOCUMENT_STATUS_FAILED,
    DOCUMENT_STATUS_FAILED_PREPROCESS,
} from './documents'
import {
    CHECK_STATUSES_PROCESSING,
    CHECK_STATUS_PROCESSED,
    CHECK_REPORT_STATUS_PROCESSING,
    CHECK_REPORT_STATUS_CREATED,
    CHECK_REPORT_VISIBILITY_OWNER,
    CHECK_REPORT_VISIBILITY_ORGANIZATION,
    CHECK_STATUS_OUT_OF_NETWORK,
} from './checks'

// These correspond to themes in `tailwind.config.js`, and must be spelled
// the same. When switching themes, the slug will be `parse-${name.toLowerCase()}`
export const themes = [
    { key: 'light', name: 'Light', isDark: false },
    { key: 'dim', name: 'Dim', isDark: false },
    { key: 'deep', name: 'Deep', isDark: true },
    { key: 'dark', name: 'Dark', isDark: true },
]

/**
 * Initial values for store objects
 * Mostly used to set initial value, and reset to defaults when reloading the whole UI
 * @type {StoreInitialValues}
 */
export const storeInitialValues = {
    organizations: [],
    projects: [],
    collections: {},
    runsheets: [],
    documents: {},
}

export const DEFAULT_REMOTE_CONFIG = {
    remoteConfig: {
        elasticSearch: {
            wildcardSearchEnabled: true,
            advancedFieldSearchEnabled: true,
        },
        payments: {
            enabled: true,
        },
    },
}

export const GCS_BUCKET_CHECKS = 'checks/files'
export const GCS_BUCKET_DOCUMENTS = 'pdfs/annotations'
export const GCS_BUCKET_INDEX_DOCUMENTS = 'pdfs/indexfiles'

export const GCS_BUCKETS = {
    [GCS_BUCKET_CHECKS]: GCS_BUCKET_CHECKS,
    [GCS_BUCKET_DOCUMENTS]: GCS_BUCKET_DOCUMENTS,
    [GCS_BUCKET_INDEX_DOCUMENTS]: GCS_BUCKET_INDEX_DOCUMENTS,
}

export const GCS_BUCKETS_ALL = [
    GCS_BUCKET_CHECKS,
    GCS_BUCKET_DOCUMENTS,
    GCS_BUCKET_INDEX_DOCUMENTS,
]

// More human readable versions of document/check/etc. statuses
export const HUMAN_STATUSES = {
    
    // Note: the `uploaded` status just means the server received the PDF,
    //       while `saved` means we've split the PDF into PNG pages,
    //       but this is likely confusing to the end user, so we rename things a bit here
    [DOCUMENT_STATUS_UPLOADED]: 'Preprocessing',
    [DOCUMENT_STATUS_SAVED]: 'Uploaded',
    [DOCUMENT_STATUS_PROCESSING]: 'Processing',
    [DOCUMENT_STATUS_OCR_APPLIED]: 'Processing',
    [DOCUMENT_STATUS_MODEL_APPLIED]: 'Model Applied',
    [DOCUMENT_STATUS_READY_FOR_REVIEW]: 'Ready for Review',
    [DOCUMENT_STATUS_REVIEW_IN_PROGRESS]: 'Review In Progress',
    [DOCUMENT_STATUS_REVIEWED]: 'Reviewed',
    [DOCUMENT_STATUS_FAILED]: 'Processing Failed',
    [DOCUMENT_STATUS_FAILED_PREPROCESS]: 'Page Generation Failed',
    
    ...CHECK_STATUSES_PROCESSING.reduce((acc, it) => ({
        ...acc,
        [it]: 'Processing',
    }), {}),
    [CHECK_STATUS_PROCESSED]: 'Processed',
    
    [CHECK_STATUS_OUT_OF_NETWORK]: 'Out of Network',
    
    [CHECK_REPORT_STATUS_PROCESSING]: 'Processing',
    [CHECK_REPORT_STATUS_CREATED]: 'Ready for Download',
    
    [CHECK_REPORT_VISIBILITY_OWNER]: 'Owner Only',
    [CHECK_REPORT_VISIBILITY_ORGANIZATION]: 'Organization Members',
    
}

// More human readable versions of document/check/etc. statuses but for examples
export const HUMAN_STATUSES_EXAMPLES = {
    
    ...HUMAN_STATUSES,
    
    [DOCUMENT_STATUS_OCR_APPLIED]: 'OCR Applied',
    
}

export const JSONTreeTheme = {
    scheme: 'parse',
    author: 'parse',
    base00: '#102e4a',
    base01: '#eeeeee',
    base02: '#eeeeee',
    base03: '#eeeeee',
    base04: '#eeeeee',
    base05: '#eeeeee',
    base06: '#eeeeee',
    base07: '#eeeeee',
    base08: '#eeeeee',
    base09: '#eeeeee', // primitive values
    base0A: '#eeeeee',
    base0B: '#eeeeee', // string values
    base0C: '#eeeeee',
    base0D: '#8fc9ff', // keys
    base0E: '#eeeeee',
    base0F: '#eeeeee',
}
