import { forwardRef } from 'react'
import { Modal } from 'react-responsive-modal'
import { FaTimes } from 'react-icons/fa'
import cn from 'classnames'

import 'react-responsive-modal/styles.css'
import '../../../assets/styles/modal.css'

const CustomModal = forwardRef(({
    className,
    open,
    modalHandler,
    children,
    modalClass,
    ...props
}, ref) => {
    
    return (
        
        <Modal
            ref={ref}
            open={open}
            onClose={modalHandler}
            center
            classNames={{
                modal: cn(className, 'custom-modal'),
                overlay: 'overlay',
                closeButton: 'close z-[20]',
                modalAnimationIn: 'custom-enter-modal-animation',
                modalAnimationOut: 'custom-leave-modal-animation',
            }}
            animationDuration={400}
            closeIcon={<FaTimes className="" />}
            {...props}>
            
            <div className={`${modalClass} modal-content p-5 bg-base-200 rounded-md`}>
                {children}
            </div>
        
        </Modal>
    )
})

CustomModal.displayName = 'CustomModal'

export default CustomModal
