import { useEffect } from 'react'

const useBuildInfo = () => {
    
    useEffect(() => {
        
        import('@/build.json')
            .then(it => {
                window.build = it.default
            })
            .catch(e => {
                console.warn('useBuildInfo', e)
                window.build = { version: 'unknown' }
            })
        
    }, [])
    
}

export default useBuildInfo
