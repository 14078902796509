import { useMemo, useCallback } from 'react'
import dayjs from 'dayjs'

import { Link } from 'react-router-dom'
import { FaTrash } from 'react-icons/fa'

const AnnotatorsTableRow = ({
    className,
    id,
    firstName,
    lastName,
    email,
    createdAt,
    documentReviews,
    setPendingDeleteAnnotator,
}) => {
    
    const onDeleteClick = useCallback(() => setPendingDeleteAnnotator({
        id,
        firstName,
        lastName,
    }), [id, firstName, lastName])
    
    const createdAtFormatted = useMemo(() => {
        if (!createdAt) return ''
        return dayjs(createdAt).format('YYYY-MM-DD')
    }, [createdAt])
    
    return (
        
        <tr className={className}>
            <td>{firstName} {lastName}</td>
            <td>{email}</td>
            <td>{createdAtFormatted}</td>
            <td className="col-reviews">
                <div>
                    <span>{documentReviews.length}</span>
                    <Link
                        className="ml-4 text-xs"
                        to={`/dashboard/annotators/${id}`}>
                        VIEW &rarr;
                    </Link>
                </div>
            </td>
            <td className="col-actions">
                <div className="flex items-center content-center justify-center">
                    <button
                        className="action-button danger"
                        onClick={onDeleteClick}>
                        <FaTrash />
                    </button>
                </div>
            </td>
        </tr>
        
    )
    
}

export default AnnotatorsTableRow
