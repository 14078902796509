import api from '$lib/api'

// import { CHECK_NOT_READY_STATUSES, CHECK_READY_STATUSES } from '@constants/checks'
import { createQueryString } from '@utils'

export const getChecksCount = async (organizationId = null) => {
    
    const query = createQueryString({
        organizationId,
    })
    
    const res = await api.get(`/checks/count${query}`)
    
    return res.data.count
    
}

export const assignOrganizationChecksToAnnotators = async (annotatorIds, organizationId) => {
    
    const res = await api.post(`/orgs/${organizationId}/assign/annotator`, {
        annotatorIds,
    })
    
    return res
    
}

export const importCheckCsv = async (
    file,
    organizationId,
    onUploadMax = value => value,
    onUploadValue = value => value,
) => {
    const formData = new FormData()
    
    if (file) {
        formData.append('file', file)
        formData.append('organizationId', organizationId)
    }
    
    const config = {
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        onUploadProgress: progressEvent => {
            if (onUploadMax && onUploadValue) {
                onUploadMax(progressEvent.total || 0)
                onUploadValue(progressEvent.loaded || 0)
            }
        },
    }
    
    const response = await api.post('/checks/import', formData, config)
    
    // log.d('@todo UPLOAD_DOCUMENT', response.data)
    
    return response.data
    
}

export const getImportsInfo = async () => {
    const response = await api.get('/checks/import')
    
    return response.data
}

export const getImportsUpload = async () => {
    const response = await api.get('/checks/import/uploaded')
    
    return response.data
}

export const processUploadedFile = async fileName => {
    const response = await api.post('/checks/import/process', { fileName })
    
    return response.data
}
