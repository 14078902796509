import cn from 'classnames'

import Checkbox from '$components/Forms/Checkbox'
import { formatDate } from '@/utils/date'

const DocumentsTableRow = ({
    className,
    id,
    name,
    fileName,
    createdAt,
    updatedAt,
    reviewStatus,
    checked,
    onCheckChange,
    onAssignSingleClick,
}) => {
    
    return (
        
        <tr className={cn('DocumentsTableRow', className)}>
            <td>
                <Checkbox
                    checked={checked || false}
                    onChange={onCheckChange} />
            </td>
            <td onClick={() => console.log('Document ID', id)}>{name}</td>
            <td>{fileName}</td>
            <td>{formatDate(createdAt)}</td>
            <td>{formatDate(updatedAt)}</td>
            <td>{reviewStatus}</td>
            <td>
                <div className="flex">
                    <button className="text-xs btn btn-link" onClick={() => onAssignSingleClick(id)}>
                        ASSIGN
                    </button>
                </div>
            </td>
        </tr>
        
    )
    
}

export default DocumentsTableRow
