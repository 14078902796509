import { useMemo } from 'react'
import { snippet } from '@utils'
import { relativeTime } from '@/utils/date'
import cn from 'classnames'

import './CollectionDocumentReviewsTable.css'

const CollectionDocumentReviewsTable = ({
    className,
    documentReviews,
}) => {
    
    const items = useMemo(() => {
        
        const result = []
        
        documentReviews?.rows?.forEach(row => {
            row.reviews.forEach(review => {
                result.push({
                    document: row.document,
                    ...review,
                })
            })
        })
        
        return result
        
    }, [documentReviews])
    
    if (!documentReviews?.count)
        return null
    
    return (
        
        <table className={cn('CollectionDocumentReviewsTable table table-auto table-pin-rows', className)}>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Reviewer</th>
                    <th>Created</th>
                    <th>Completed</th>
                </tr>
            </thead>
            <tbody className="list">
                {items.map(it => (
                    <tr key={`CollectionDocumentReviewsTable-${it.id}`}>
                        <td>{snippet(it.document.id)}</td>
                        <td>{it.document.name}</td>
                        <td>
                            <div className="flex flex-col">
                                <span>{it.reviewer.firstName} {it.reviewer.lastName}</span>
                                <span className="text-sm">{it.reviewer.email}</span>
                            </div>
                        </td>
                        <td>{relativeTime(it.createdAt)}</td>
                        <td>{relativeTime(it.completedAt)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        
    )
    
}

export default CollectionDocumentReviewsTable
