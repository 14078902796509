import { useState, useMemo } from 'react'
import * as actions from '$actions'
import cn from 'classnames'

import { Link } from 'react-router-dom'
import Checkbox from '$components/Forms/Checkbox'
import { MdRefresh, MdBolt } from 'react-icons/md'
import { SiElasticsearch } from 'react-icons/si'

const ESCollectionsCountsTable = ({
    className,
    wrapClassName,
    viewModel,
}) => {
    
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState('')
    const [collections, setCollections] = useState([])
    const [onlyParseCollections, setOnlyParseCollections] = useState(true)
    
    const viewModelCollectionsById = useMemo(() => (
        (viewModel?.collections ?? []).reduce((acc, it) => ({
            ...acc,
            [it.id]: it,
        }), {})
    ), [viewModel?.collections])
    
    const getSimpleEngineName = name =>
        name.startsWith('search-') ? name.substring(7) : name
    
    const filteredCollections = useMemo(() => (
        onlyParseCollections
            ? collections?.filter(it => it.isParse)
            : collections
    ), [onlyParseCollections, collections])
    
    const enginesCounted = useMemo(() => Object.keys(
        (collections ?? []).reduce((acc, it) => ({
            ...acc,
            ...Object.keys(it.elasticIndexCounts).reduce((acc2, it2) => ({
                ...acc2,
                [getSimpleEngineName(it2)]: 1,
            }), {}),
        }), {}),
    ), [collections])
    
    const fetchCollectionsWithElasticCounts = async (query, onlyParseCollections) => {
        
        const params = {
            offset: 0,
            limit: 20,
            query,
            onlyParseCollections,
        }
        
        setLoading(true)
        
        try {
            
            const res = await actions.getCollectionsWithElasticCounts(params)
            
            setCollections(res.data)
            
        } catch (e) {
            
            console.error('fetchCollectionsWithElasticCounts', e)
            setError('Error fetching collections with Elastic counts')
            
        }
        
        setLoading(false)
        
    }
    
    return (
        
        <div className={cn('ESCollectionsCountsTable-wrap', wrapClassName)}>
            
            <header>
                <div className="">Collections With Index Counts</div>
                <div className="flex items-center content-center gap-3">
                    <input
                        className="w-full shrink"
                        type="text"
                        autoComplete="off"
                        value={query}
                        placeholder={`Search ${onlyParseCollections ? 'index ' : ''}collections...`}
                        onKeyUp={e => e.key === 'Escape' && setQuery('')}
                        onChange={e => setQuery(e.target.value?.toLowerCase() ?? '')} />
                    <div className="shrink-0 grow">
                        <Checkbox
                            label="Only Parse"
                            checked={onlyParseCollections}
                            onChange={() => setOnlyParseCollections(!onlyParseCollections)} />
                    </div>
                    <button
                        className="action-button"
                        onClick={() => fetchCollectionsWithElasticCounts(query, onlyParseCollections)}>
                        <MdRefresh className="text-xl" />
                    </button>
                </div>
            </header>
            
            {error && (
                <div className="">
                    {error}
                </div>
            )}
            
            {loading && (
                <div className="">
                    <i>Fetching collections...</i>
                </div>
            )}
            
            {!error && !loading && filteredCollections?.length < 1 && (
                <div className="">
                    <p>No collections found</p>
                    <p><i>Note: this panel does not auto load. Click the refresh button to see data.</i></p>
                </div>
            )}
            
            {!error && !loading && filteredCollections?.length > 0 && (
                <table className={cn('ESCollectionsCountsTable table-pin-rows', className)}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Document Count</th>
                            {enginesCounted.map(indexName => (
                                <th key={indexName}>
                                    {indexName}
                                </th>
                            ))}
                            <th>
                                {/* Actions */}
                                <div className="flex !justify-center">
                                    <MdBolt className="text-lg" />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredCollections.map(it => {
                            
                            const vmCol = viewModelCollectionsById[it.id] || {}
                            const docsCount = vmCol.stats?.documentsCount ?? 0
                            
                            return (
                                <tr key={it.id}>
                                    <td>
                                        <Link
                                            to={`/collection/${it.id}/browse`}
                                            target="_blank">
                                            {it.name}
                                        </Link>
                                    </td>
                                    <td>
                                        {docsCount.toLocaleString('en-US')}
                                    </td>
                                    {enginesCounted.map(indexName => {
                                        
                                        const colIndexCount = it.elasticIndexCounts[`search-${indexName}`]
                                        const isFullyIndexed = docsCount === colIndexCount
                                        const missingCount = docsCount - colIndexCount
                                        
                                        return (
                                            <td key={`${it.id}-${indexName}`}>
                                                <span className={isFullyIndexed ? 'text-green-500' : ''}>
                                                    {colIndexCount.toLocaleString('en-US')}
                                                    {' '}
                                                    of
                                                    {' '}
                                                    {docsCount.toLocaleString('en-US')}
                                                    {!isFullyIndexed && (
                                                        <span className="ml-3 badge bg-red-500/60 gap-2">
                                                            &ndash; {missingCount.toLocaleString('en-US')}
                                                        </span>
                                                    )}
                                                </span>
                                            </td>
                                        )
                                        
                                    })}
                                    <td>
                                        <div className="flex items-center content-center justify-center">
                                            <button
                                                className="w-auto btn btn-link"
                                                title="Re-index Collection"
                                                onClick={() => viewModel.setConfirmReindexCollection(it)}>
                                                <SiElasticsearch />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                            
                        })}
                    </tbody>
                </table>
            )}
        
        </div>
    )
    
}

export default ESCollectionsCountsTable
