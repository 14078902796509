import { useState, useCallback } from 'react'
import * as actions from '$actions'
import cn from 'classnames'

import CircleSpinner from '@components/shared/CircleSpinner'

const AddAnnotatorForm = ({
    className,
}) => {
    
    const [loading, setLoading] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    
    const onAddAnnotatorClick = useCallback(async () => {
        
        if ([firstName, lastName, email].some(it => !it.length))
            return
        
        try {
            
            setLoading(true)
            
            const res = await actions.createAnnotator(firstName, lastName, email)
            
            await actions.sendConfirmationEmail(email)
            
            console.log(res.data)
            
            await actions.getAnnotators()
            
        } catch (e) {
            
            // @todo show error
            console.error('onAddAnnotatorClick', e)
            
        }
        
        setLoading(false)
        
    }, [firstName, lastName, email])
    
    return (
        
        <div className={cn('AddAnnotatorForm', className)}>
            
            <input
                type="text"
                autoComplete="off"
                value={firstName}
                placeholder="First Name"
                disabled={loading}
                onChange={e => setFirstName(e.target.value)} />
            
            <input
                type="text"
                autoComplete="off"
                value={lastName}
                placeholder="Last Name"
                disabled={loading}
                onChange={e => setLastName(e.target.value)} />
            
            <input
                type="email"
                autoComplete="off"
                value={email}
                placeholder="partner@gmail.com"
                disabled={loading}
                onChange={e => setEmail(e.target.value)} />
            
            <button
                className="flex items-center content-center btn"
                disabled={loading}
                onClick={onAddAnnotatorClick}>
                <span className="p-1 text-sm">ADD ANNOTATOR</span>
                {loading && <CircleSpinner className="ml-4" />}
            </button>
        
        </div>
        
    )
    
}

export default AddAnnotatorForm
