import cn from 'classnames'

import { Menu } from '@headlessui/react'
import { Link } from 'react-router-dom'

const AccountMenuItem = ({
    className = 'text-primary-content',
    labelClassName,
    label,
    to,
    target,
    Icon,
    iconProps = {},
    children,
    ...props
}) => {
    
    return (
        
        <Menu.Item>
            {({ active }) => (
                <Link
                    className={cn(
                        'AccountMenuItem menu-item-button group gap-4',
                        className,
                        { active },
                    )}
                    to={to}
                    target={target}
                    {...props}>
                    
                    <span className={labelClassName}>
                        {label}
                    </span>
                    
                    {children || <Icon className="text-2xl" {...iconProps} />}
                
                </Link>
            )}
        </Menu.Item>
        
    )
    
}

export default AccountMenuItem
