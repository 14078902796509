import Logger from '@utils/log'
import * as store from '@store'
import { updateLocalCredits } from '@actions/user'
import { forgetHasPromptedToProcessDocumentId } from '@actions/processingDocuments'
import ParseAPI from '@api/ParseAPI'
import { createQueryString } from '@utils'
import { omit } from '@/utils'
import deepmerge from 'deepmerge'
import { toast } from 'react-toastify'
import { parseElasticResponseToCountRows } from '@/utils/elasticsearch'
import { PENDO_EVENTS, pendoTrack } from '@/utils/pendo'
import { buildPartyInterestData } from '@/components/tract/Runsheet/Runsheet.lib'

const log = new Logger('actions/documents')

const storeKey = 'documents'

export const fetchDocuments = async ({
    collectionId,
    orderField,
    orderDirection,
    offset,
    limit,
    queryValue,
    doneStatus,
    currentStatus,
}) => {
    
    try {
        
        const res = await ParseAPI.get('document', {
            params: {
                collectionId,
                orderField,
                orderDirection,
                offset,
                limit,
                queryValue,
                doneStatus,
                currentStatus,
            },
        })
        
        store.documents.setValue(res.data)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const fetchDocumentById = async documentId => {
    
    // log.v('fetchDocumentById', { documentId })
    
    try {
        
        const res = await ParseAPI.get(`document/${documentId}`)
        
        if (res.data)
            store.arrayUpsertByIdWithCount(storeKey, res.data, (prev, next) => ({
                ...prev,
                ...next,
            }))
        
        // log.v('fetchDocumentById', res.data)
        
        return res.data
        
    } catch (e) {
        
        log.e('fetchDocumentById', e)
        
    }
    
}

/**
 * Only updates properties on a document (no relations or values)
 * 
 * @param {UUID} documentId Document ID
 * @param {Object} data Fields to update
 */
export const updateDocumentProperties = async (documentId, data) => {
    
    const res = await ParseAPI.patch(`document/${documentId}`, data)
    
    try {
        
        const prev = store.currentCollection.getValue()
        
        store.currentCollection.setValue({
            ...prev,
            documents: prev.documents?.map(it => {
                if (it.id === res.data.id)
                    return res.data
                return it
            }) ?? [],
        })
        
    } catch (e) {
        
        console.error('updateDocumentProperties: Failed to update document in collections local store', e)
        
    }
    
    try {
        store.arrayUpsertByIdWithCount(storeKey, res.data, (prev, next) => ({
            ...prev,
            ...next,
        }))
    } catch (e) {
        console.error('updateDocumentProperties: Failed to update document in documents local store', e)
    }
    
    return res.data
    
}

export const updateDocument = async (documentId, data) => {
    const fieldsToOmit = ['createdAt', 'createdById', 'documentId', 'id', 'updatedAt', 'updatedById']
    
    try {
        
        const res = await ParseAPI.put(`document/${documentId}`, omit(data, fieldsToOmit))
        
        const documentValue = res.data
        
        const value = documentValue?.rdj?.[0]?.value
        
        if (value) {
            value.grantors = value.grantors.map(gtor => buildPartyInterestData(gtor))
            value.grantees = value.grantees.map(gtee => buildPartyInterestData(gtee))
        }
        
        const currentRunsheet = store.currentRunsheet.getValue()
        
        if (currentRunsheet) {
            const replaceIdx = currentRunsheet.rdj.findIndex(r => r.document.id === documentId)
            
            currentRunsheet.rdj = [...currentRunsheet.rdj]
            const currValue = currentRunsheet.rdj[replaceIdx].value
            
            value.legalDescriptions = currValue.legalDescriptions
            
            currentRunsheet.rdj[replaceIdx] = {
                ...currentRunsheet.rdj[replaceIdx],
                value,
            }
            
            store.currentRunsheet.setValue({ ...currentRunsheet })
        }
        
    } catch (e) {
        
        log.e('updateDocument', e)
        
    }
    
}

export const deleteDocuments = async documentIds => {
    
    try {
        
        await ParseAPI.delete('document', {
            data: {
                documentIds,
            },
        })
        
        documentIds.forEach(id => store.objectRowsDeleteById('documents', id))
        
    } catch (e) {
        
        console.error('deleteDocuments', e)
        toast.error(`Failed to delete ${documentIds?.length > 1 ? 'documents' : 'document'}`)
        throw e
        
    }
    
}

export const checkDocumentStatuses = async (documentIds, docsWithStatus) => {
    
    try {
        
        // @todo pass a time limit that would return only the docs that have
        //  been updated in the last X seconds (use the POLL INTERVAL config)
        const res = await ParseAPI.post('document/ids', {
            documentIds,
            docsWithStatus,
        })
        
        // Nothing has changed in the documents, so ignore
        if (res.status === 304 || !res.data?.length)
            return
        
        const map = res.data.reduce((acc, it) => ({
            ...acc,
            [it.id]: it,
        }), {})
        
        const prev = store.documents.getValue()
        
        if (!prev.rows?.length)
            prev.rows = []
        
        const filtered = res.data.filter(d => !prev.rows.some(doc => d.id === doc.id))
        
        prev.rows.push(...filtered)
        
        const checkAndMerge = (it, map, keyGroups) => {
            let shouldMerge = false
            
            for (const group of keyGroups) {
                const currStatus = group.map(key => it[key]).find(status => !!status)
                const newStatus = group.map(key => map[it.id][key]).find(status => !!status)
                
                if (currStatus !== newStatus) {
                    shouldMerge = true
                    break
                }
            }
            
            return shouldMerge ? deepmerge(it, map[it.id]) : it
        }
        
        const keyGroupsToCheck = [['orgStatus', 'reviewStatus'], ['purchased']]
        
        const rows = prev.rows.map(it => {
            
            if (Object.hasOwn(map, it.id)) {
                return checkAndMerge(it, map, keyGroupsToCheck)
            }
            
            return it
            
        })
        
        store.documents.setValue({
            ...prev,
            rows,
        })
        
        // Only return the requested docs (updated from API)
        return rows.filter(it => documentIds.includes(it.id))
        
    } catch (e) {
        
        log.e(e)
        
    }
    
}

export const queueDocumentForOcr = async documentId => {
    
    
    
    try {
        
        const res = await ParseAPI.put(`document/ocr/${documentId}`)
        
        const rows = store.documents.getValue()?.rows ?? []
        
        store.documents.setValue({
            ...(store.documents.getValue() ?? {}),
            rows: rows.map(it => it.id === documentId ? res.data : it),
        })
        
        return res
        
    } catch (e) {
        
        log.e(e)
        throw e
    }
    
}

export const queueDocumentForProcessing = async (documentId, runsheetId, batchSize, fundingSource) => {
    
    try {
        
        forgetHasPromptedToProcessDocumentId(documentId)
        
        const params = createQueryString({ runsheetId, batchSize, fundingSource })
        
        const res = await ParseAPI.put(`document/process/${documentId}${params}`)
        
        log.d('queueDocumentForProcessing', res)
        
        pendoTrack(PENDO_EVENTS.DOC_PROCESS, { documentId })
        
        const rows = store.documents.getValue()?.rows ?? []
        
        store.documents.setValue({
            ...(store.documents.getValue() ?? {}),
            rows: rows.map(it => it.id === documentId ? res.data : it),
        })
        
        return res
        
    } catch (e) {
        
        log.e(e)
        throw e
    }
    
}

export const queueDocumentForProcessingBulk = async (documentIds, fundingSource) => {
    
    try {
        
        const res = await ParseAPI.post('document/process/bulk', { documentIds, fundingSource })
        
        const rows = store.documents.getValue()?.rows ?? []
        
        store.documents.setValue({
            ...(store.documents.getValue() ?? {}),
            rows: rows.map(it => res.data.find(d => d.id === it.id) || it),
        })
        
        log.d('queueDocumentForProcessingBulk', res)
        
        return res.data.reduce((acc, curr) => {
            acc.success += !!curr.id
            acc.failure += !curr.id
            return acc
        }, { success: 0, failure: 0 })
        
    } catch (e) {
        
        log.e(e)
        
    }
    
}

export const resetDocumentStatus = async documentId => {
    
    return await ParseAPI.put(`document/reset/${documentId}`)
    
}

export const addDocumentToHistory = document => {
    
    const viewedDocs = { ...store.viewedDocuments.getValue() }
    const collectionName = document.collection?.name
            ?? document.collectionName
            ?? store.currentCollection.getValue()?.name
    
    if (!collectionName?.length)
        console.warn('addDocumentToHistory: could not determine collection name', document)
    
    viewedDocs[document.id] = {
        id: document.id,
        name: document.name,
        collectionName,
        viewedAt: new Date(),
    }
    
    store.viewedDocuments.setValue(viewedDocs)
    
}

export const searchElasticWildcard = async (engine, opts) => {
    
    const paramsObj = {
        engine,
        q: opts.query,
        ...opts,
    }
    
    const params = createQueryString(paramsObj)
    
    const res = await ParseAPI.get(`document/elastic${params}`)
    
    return parseElasticResponseToCountRows(res.data)
    
}

export const advancedSearchElastic = async ({
    engine,
    collectionId,
    query,
    sort,
    search_after,
    offset = 0,
    limit = 20,
}) => {
    
    const res = await ParseAPI.post(`document/elastic/${collectionId}`, {
        engine,
        query,
        sort,
        offset,
        limit,
        search_after,
    })
    
    return parseElasticResponseToCountRows(res.data)
    
}

export const getDocumentComment = async documentId => {
    const res = await ParseAPI.get(`document/comment/${documentId}`)
    
    return res.data
}

export const saveDocumentComment = async (documentId, comment) => {
    const res = await ParseAPI.post('document/comment', { documentId, comment })
    
    return res.data
}

/**
 * 
 * @param {string} documentId
 * @param {PurchaseType} purchaseType - One of {PURCHASE_TYPES}
 * @returns {Promise<AxiosResponse<any>>}
 */
export const purchaseDocument = async (documentId, purchaseType, fundingSource) => {
    
    try {
        
        const res = await ParseAPI.post(`document/purchase/${documentId}`, {
            purchaseType,
            fundingSource,
        })
        
        await updateLocalCredits()
        
        return res.data
        
    } catch (err) {
        if (err.response.status !== 302)
            throw err
    }
    
}

export const toggleAnnotationCompleted = async documentId => {
    try {
        
        const res = await ParseAPI.patch(`document/${documentId}/toggle-completed`)
        
        const docs = store.documents.getValue()
        
        if (docs?.rows?.length) {
            const idx = docs.rows.findIndex(r => r.id === documentId)
            
            if (idx > -1) {
                const newRows = [...docs.rows]
                
                newRows.splice(idx, 1, { ...newRows[idx], annotationCompleted: res.data.annotationCompleted })
                
                store.documents.setValue({
                    ...docs,
                    rows: newRows,
                })
            }
        }
        
        return res.data
        
    } catch (e) {
        
        log.e(e)
        
    }
}

export const saveDocumentType = async (documentId, documentType) => {
    await ParseAPI.patch(`document/${documentId}`, { documentType })
    
}

export const captureTextsFromBBox = async (documentPageId, bbox) => {
    try {
        const response = await ParseAPI.post(`document/capture-page/${documentPageId}`, { bbox })
        
        return response.data
    } catch (e) {
        log.e(e)
    }
}

export const saveLegalDescriptions = async (runsheetValueId, legals) => {
    try {
        
        const res = await ParseAPI.post(`legalDescriptions/${runsheetValueId}`, { legals })
        
        return res.data
        
    } catch (error) {
        
        log.e(error)
        
    }
}

export const verifyIfExistsBySha1 = async (sha1List, collectionId) => {
    
    const paramsObj = {
        sha1List,
        collectionId,
    }
    
    const params = createQueryString(paramsObj)
    
    const res = await ParseAPI.get(`/document/exists/sha1${params}`)
    
    return res.data
}

export const hasUserAnnotations = async documentsIds => {
    
    const paramsObj = {
        documentsIds,
    }
    
    const params = createQueryString(paramsObj)
    
    const res = await ParseAPI.get(`/document/hasUserAnnotations${params}`)
    
    return res.data
    
}
