import Logger from '@utils/log'
import ParseAPI from '@api/ParseAPI'
import { createQueryString } from '@/utils'

const log = new Logger('actions/states')

export const fetchCostAndRevenue = async (fromDate, toDate) => {
    const dateParams = {
        fromDate, 
        toDate,
    }
    const queryParams = createQueryString(dateParams)
    
    try {
        
        const res = await ParseAPI.get(`admin/costrevenue/${queryParams}`, {
            responseType: 'blob',
        })
        
        return res
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}