import { snippet } from '@utils'
import cn from 'classnames'

import CopyToClipboardButton from '$components/CopyToClipboardButton'
import { Link } from 'react-router-dom'
import { formatDate } from '@/utils/date'

const AnnotatorAssignedDocsTable = ({
    className,
    documents,
}) => {
    
    if (!documents) return (
        <p>No assigned documents found.</p>
    )
    
    return (
        
        <table className={cn('AnnotatorAssignedDocsTable', className)}>
            
            <thead>
                <tr>
                    <th>Document ID</th>
                    <th>File Name</th>
                    <th>Created Date</th>
                    <th>Completed Date</th>
                    <th>Review Status</th>
                    <th>Collection</th>
                </tr>
            </thead>
            
            <tbody>
                {documents.map(it => (
                    <tr key={`AnnotatorAssignedDocsTable-${it.id}`}>
                        <td>
                            <div className="flex justify-start">
                                <Link to={`/edit/${it.id}`} target="_blank">
                                    {snippet(it.id, 4)}
                                </Link>
                                <CopyToClipboardButton
                                    className="ml-3"
                                    value={it.id} />
                            </div>
                        </td>
                        <td>{it.fileName}</td>
                        <td>{formatDate(it.createdAt)}</td>
                        <td>{it.completedAt ? formatDate(it.completedAt) : '-'}</td>
                        <td>{it.reviewStatus}</td>
                        <td>
                            <Link to={`/collection/${it.collection?.id}`} target="_blank">
                                {it.collection?.name ?? 'Unknown'}
                            </Link>
                        </td>
                    </tr>
                ))}
            </tbody>
        
        </table>
        
    )
    
}

export default AnnotatorAssignedDocsTable
