import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWireState, useWireValue } from '@forminator/react-wire'
import * as store from '$store'
import * as actions from '$actions'
import { toast } from 'react-toastify'

import Modal from '$components/Modal'

const MasqueradeConfirmDialog = ({
    className,
    ...props
}) => {
    
    const navigate = useNavigate()
    
    const [open, setOpen] = useWireState(store.modalMasqueradeConfirmOpen)
    const user = useWireValue(store.modalMasqueradeConfirmUser)
    
    const onConfirmMasqueradeClick = useCallback(async () => {
        
        try {
            
            const res = await actions.masqueradeAsUser(user.email)
            
            console.log('masq', user.email, res)
            
            navigate('/collections', { replace: true })
            
        } catch (e) {
            
            console.error('onConfirmMasqueradeClick', e)
            
            toast.error(`Failed to masquerade as ${user.email}`)
            
        }
        
    }, [user])
    
    if (!user) return null
    
    return (
        
        <Modal
            className={className}
            open={open}
            setOpen={setOpen}
            title="Masquerade As User"
            actionLabel="MASQUERADE"
            actionOnClick={onConfirmMasqueradeClick}
            {...props}>
            
            <div className="flex flex-col justify-center text-center">
                <p>Masquerade lets you impersonate another user by assuming their authentication token.</p>
                <p className="mt-4">If the user has not logged in yet, a token will be created for them.</p>
                <p className="mt-4 font-bold text-orange-400">
                    If either you or the other user signs in or out while the other is signed in,
                    one or both of you may get signed out & need to sign back in.
                </p>
                <p className="mt-4">This can cause an unexpected user experience, so use with caution!</p>
                <p className="pb-2 mt-4 font-bold border-b border-gray-300">Masquerade as user:</p>
                <p className="mt-2">{user.firstName} {user.lastName}</p>
                <p className="">{user.email}</p>
            </div>
        
        </Modal>
        
    )
    
}

export default MasqueradeConfirmDialog
