import { v4 as uuidV4 } from 'uuid'
import { switchPartyNames } from '@/components/shared/utils'

const mapFullPartyFromValue = partyName => iv => iv[partyName].map(switchPartyNames).join('; ')

const ignoredKeys = ['documentId', 'documentPageId']

const groupAnnotations = doc => (doc.pages || []).reduce((acc, curr) => {
    Object.keys(curr.annotationValue || {}).forEach(key => {
        if (ignoredKeys.includes(key)) return
        const value = curr.annotationValue[key]
        
        acc[key] = `${acc[key] || ''} ${Array.isArray(value) ? value.join('; ') : value}`.trim()
    })
    return acc
}, {})

export const parseDocumentFromElastic = docs => {
    for (const doc of docs) {
        if (doc.indexValue) {
            doc.indexValue.fullGrantor = mapFullPartyFromValue('grantors')(doc.indexValue)
            doc.indexValue.fullGrantee = mapFullPartyFromValue('grantees')(doc.indexValue)
        }
        
        doc.values = [{ ...doc.indexValue, ...groupAnnotations(doc) }]
        
        if (!doc.values[0].fullGrantor && doc.values[0].grantors)
            doc.values[0].fullGrantor = doc.values[0].grantors
        if (!doc.values[0].fullGrantee && doc.values[0].grantees)
            doc.values[0].fullGrantee = doc.values[0].grantees
    }
    
    return docs
}

/**
 * Converts an [OcrWord] to an [AnnotationResult]
 * 
 * @param {width: number, height: number} rawOcrSize
 * @param {OcrWord} ocrWord
 * @return {AnnotationResult}
 */
export const ocrDataToAnnotationResult = (rawOcrSize, ocrWord) => {
    
    if (!ocrWord)
        throw new Error('ocrWord is required')
    
    // Check if id, bbox, and text properties exist
    if (!ocrWord.bbox || !ocrWord.text)
        throw new Error('bbox, and text properties are required ' +
            JSON.stringify(ocrWord, null, 4))
    
    const numericProps = ['x1', 'y1', 'width', 'height']
    
    numericProps.forEach(it => {
        try {
            ocrWord.bbox[it] = parseInt(ocrWord.bbox[it], 10)
        } catch (e) {
            throw new Error(`Invalid number on bbox.${it}: ${ocrWord.bbox[it]}`)
        }
    })
    
    // Check if left, top, width, and height properties exist and are numbers
    if (typeof ocrWord.bbox.x1 !== 'number' ||
        typeof ocrWord.bbox.y1 !== 'number' ||
        typeof ocrWord.bbox.width !== 'number' ||
        typeof ocrWord.bbox.height !== 'number')
        throw new Error('left, top, width, and height must be numbers ' +
            JSON.stringify(ocrWord, null, 4))
    
    return {
        id: uuidV4(),
        original_width: rawOcrSize.width,
        original_height: rawOcrSize.height,
        value: {
            x: ocrWord.bbox.x1,
            y: ocrWord.bbox.y1,
            width: ocrWord.bbox.width,
            height: ocrWord.bbox.height,
            text: [ocrWord.text],
        },
    }
    
}

/**
 * Simplifies an ElasticSearch query response into a flatter object
 * 
 * @param {Object} responseData Raw ES API response
 * @returns {{count, rows: *}}
 */
export const parseElasticResponseToCountRows = responseData => {
    
    const hits = responseData?.hits?.hits || []
    
    return {
        count: responseData?.hits?.total?.value || 0,
        rows: hits?.map(h => ({
            ...h._source,
            highlight: h.highlight,
        })) || [],
        // this is used to allow pagination over 10k results with `search_after`
        search_after: hits?.[hits.length - 1]?.sort,
    }
    
}

/**
 *
 * @param {GDocumentPage} page
 * @param {String[]} highlightTerms
 * @returns {AnnotationResult[]}
 */
export const getSearchIndexOcrDataForPage = (page, highlightTerms) => {
    // console.log('@debug getSearchIndexOcrDataForPage words', page.rawOcrWords || [])
    const words = page.rawOcrWords || []
    
    /* const temp = words.filter(word => highlightTerms.some(ht => (
        word.text?.toLowerCase()?.includes(ht)
    ))) */
    const temp = words.filter(word => highlightTerms.some(ht => {
        return word.text?.toLowerCase()?.includes(ht)
    }))
    
    return temp.map(it => ocrDataToAnnotationResult(page.rawOcrSize, it))
    
}

/**
 * 
 * @param {GDocumentPage} page
 * @param {String[]} highlightTerms
 * @returns {AnnotationResult[]}
 */
export const getSearchAnnotationResultsForPage = (page, highlightTerms) => {
    
    const annotations = page.annotations || []
    
    /**
     * @param {object} acc
     * @param {AnnotationResult} result
     */
    const formatResultNumbers = (acc, result) => {
        
        if (result.type === 'relation') return
        
        // @todo not sure why these come back as strings
        result.x = parseFloat(result.x)
        result.y = parseFloat(result.y)
        result.width = parseFloat(result.width)
        result.height = parseFloat(result.height)
        
        acc[result.id] = result
        
    }
    
    /**
     * @type {AnnotationResult[]}
     */
    const results = Object.values(
        annotations.reduce((acc, anno) => {
            
            anno.results.forEach(it => formatResultNumbers(acc, it))
            
            return acc
            
        }, {}),
    )
    
    return results.filter(result => highlightTerms.some(ht => (
        result.text?.join(' ')?.toLowerCase()?.includes(ht)
    )))
    
}
