import api from '$lib/api'
import { createQueryString } from '@utils'

/* import * as store from '$store' */

export const fetchElasticSearchStats = async () => {
    
    const res = await api.get('/admin/elasticsearch/stats')
    
    return res.data
    
}

export const toggleElasticSearchProps = async props => {
    
    const res = await api.post('/admin/elasticsearch/toggle', {
        props,
    })
    
    return res.data
    
}

export const toggleElasticSearchEnabled = () =>
    toggleElasticSearchProps(['enabled'])

export const toggleElasticSearchAutoIndexRecords = () =>
    toggleElasticSearchProps(['autoIndexRecords'])

export const toggleElasticSearchWildcardSearchEnabled = () =>
    toggleElasticSearchProps(['wildcardSearchEnabled'])

export const toggleElasticSearchAdvancedFieldSearchEnabled = () =>
    toggleElasticSearchProps(['advancedFieldSearchEnabled'])


export const fetchElasticSearchEngines = async () => {
    
    const res = await api.get('/admin/elasticsearch/engines')
    
    return res.data
    
}

export const refreshElasticSearchIndex = async indexName => {
    
    const res = await api.post('/admin/elasticsearch/refresh-indices', {
        indices: [indexName],
    })
    
    return res.data
    
}

export const reindexElasticSearchEngine = async (modelName, strategyName, {
    collectionId = null,
    initialOffset = null,
    maximumOffset = null,
    onlyMissingRecords = false,
} = {}) => {
    
    const params = {
        modelName,
        strategyName,
    }
    
    if (collectionId)
        params.collectionId = collectionId
    
    if (initialOffset)
        params.initialOffset = initialOffset
    
    if (maximumOffset)
        params.maximumOffset = maximumOffset
    
    if (onlyMissingRecords)
        params.onlyMissingRecords = onlyMissingRecords
    
    const res = await api.post('/admin/elasticsearch/indexjobs', params)
    
    return res.data
    
}

export const reindexElasticSearchSkippedDocuments = async jobId => {
    
    const res = await api.put(`/admin/elasticsearch/indexjobs/${jobId}`)
    
    return res.data
    
}

export const truncateElasticSearchIndex = async indexName => {
    
    if (!indexName?.length)
        throw new Error('Invalid param "indexName"')
    
    const res = await api.delete(`/admin/elasticsearch/indices/${indexName}`)
    
    return res.data
    
}

export const fetchElasticSearchIndexJobs = async statuses => {
    
    const params = statuses ? createQueryString({ statuses }) : ''
    
    const res = await api.get(`/admin/elasticsearch/indexjobs${params}`)
    
    return res.data
    
}

export const fetchElasticSearchIndexJobsByIds = async jobIds => {
    
    const res = await api.post('/admin/elasticsearch/indexjobs/poll', {
        jobIds,
    })
    
    return res.data
    
}

export const deleteElasticSearchIndexJob = async jobId => {
    
    const res = await api.delete(`/admin/elasticsearch/indexjobs/${jobId}`)
    
    return res.data
    
}

export const fetchElasticSearchSample = async (engine, query) => {
    
    const paramsObj = {
        engine,
        query,
    }
    
    const params = createQueryString(paramsObj)
    
    const res = await api.get(`document/elastic${params}`)
    
    return res.data
    
}
