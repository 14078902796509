import { useEffect } from 'react'
import { useWire, useWireValue } from '@forminator/react-wire'
import { ably as storeAbly, realtimeConnectionState as storeRealtimeConnectionState } from '@store/realtime'
import { useConnectionStateListener } from 'ably/react'

const useRealtime = () => {
    
    const ably = useWireValue(storeAbly, null)
    const connectionState = useWire(storeRealtimeConnectionState)
    
    const updateConnectionState = newState => {
        
        /** @type {RealtimeConnectionState} */
        const data = {
            state: newState,
            isConnected: newState === ably.connection.connectionManager.states.connected.state,
        }
        
        if (process.env.NODE_ENV !== 'production')
            console.log('useRealtime: write connectionState', data)
        
        connectionState.setValue(data)
        
    }
    
    // Note: this can't be called until Ably is initialized,
    // and since we don't initialize until we get the user's token,
    // this needs to be wrapped in a component that waits for that
    // https://ably.com/docs/getting-started/react#useConnectionStateListener
    useConnectionStateListener(stateChange => {
        
        /**
         * @type ConnectionState
         * @see https://ably.com/docs/connect/states#connection-states
         * @description One of the following states:
         * initialized, connecting, connected, disconnected, suspended, closing, closed, failed
         */
        const currentState = stateChange.current
        
        // Note: you may not see state changes until you unfocus the browser window
        // for a few seconds, since the intitial connection doesn't always log
        if (process.env.NODE_ENV !== 'production')
            console.log('useRealtime: state changed from', stateChange.previous,
                'to', currentState, 'because', stateChange.reason)
        
        // Example:
        // if (currentState === ably.connection.connectionManager.states.connected)
        
        updateConnectionState(currentState)
        
    })
    
    useEffect(() => {
        
        if (!ably) return
        
        if (process.env.NODE_ENV !== 'production')
            console.log('useRealtime: subscribing to connection state', ably.connection.connectionManager.state.state)
        
        // Make sure to set the initial connection state, since we don't get an event callback initially
        updateConnectionState(ably.connection.connectionManager.state.state)
        
        // Examples of other ways to do this without the provided hook:
        
        // Monitor a single state change
        // ably.connection.on('connected', stateChange => {
        //     console.log('Ably is connected')
        // })
        
        // Monitor all state changes
        /* ably.connection.on(stateChange => {
            console.log('///New connection state is ' + stateChange.current)
            connectionState.setValue(stateChange.current)
        })
        
        return () => ably.connection.off() */
        
    }, [ably])
    
}

export default useRealtime
