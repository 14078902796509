import { useRef, useState, useEffect, useCallback } from 'react'
import { snippet } from '@utils'
import * as actions from '@actions'
import { toast } from 'react-toastify'
import cn from 'classnames'

import Checkbox from '$components/Forms/Checkbox'
import ColorPicker from '$components/ColorPicker'
import { FaEdit, FaRegEye, FaRegEyeSlash, FaTrash, FaTimesCircle } from 'react-icons/fa'

const LabelRow = ({
    className,
    labelsType,
    label,
    hasChecked,
    toggleChecked,
}) => {
    
    const refLabel = useRef()
    
    const [value, setValue] = useState(label.label)
    const [color, setColor] = useState(label.color)
    const [isEditMode, setIsEditMode] = useState(false)
    
    const onLabelColorChange = value => setColor(
        value?.hex ?? label?.color ?? '#000000',
    )
    
    const fetchLabels = useCallback(async () => (
        labelsType
            ? actions.fetchLabels(labelsType)
            : actions.fetchAllLabels()
    ), [labelsType])
    
    const onUpdateClick = useCallback(async () => {
        
        try {
            
            await actions.updateLabel(label.id, {
                label: value,
                color: color ?? label.color,
            })
            
            setIsEditMode(false)
            
            await fetchLabels()
            
        } catch (e) {
            
            console.error('onUpdateClick', e)
            toast.error('Failed to update label')
            
        }
        
    }, [label, value, color, fetchLabels])
    
    const onTogglePublicClick = useCallback(async label => {
        
        try {
            
            await actions.updateLabelAdmin(label.id, {
                public: !label.public,
            })
            
            await fetchLabels()
            
        } catch (e) {
            
            console.error('onUpdateClick', e)
            toast.error('Failed to toggle label visibility')
            
        }
        
    }, [fetchLabels])
    
    const onDeleteClick = useCallback(async id => {
        
        if (!confirm('Are you sure you want to delete this label?\n\n@todo replace this with a real dialog'))
            return
        
        try {
            
            const res = await actions.deleteLabel(id)
            
            console.log('onDeleteClick res', res)
            
            await fetchLabels()
            
        } catch (e) {
            
            // @todo show error
            console.error('onDeleteClick', e)
            
        }
        
    }, [fetchLabels])
    
    useEffect(() => {
        if (!label) return
        setValue(label.label)
    }, [label])
    
    return (
        
        <tr className={cn('LabelRow', className)}>
            
            <td>
                <Checkbox
                    checked={hasChecked(label.id)}
                    onChange={() => toggleChecked(label.id)} />
            </td>
            
            <td title={label.id}>
                {snippet(label.id)}
            </td>
            
            <td>
                {label.type}
            </td>
            
            <td onDoubleClick={() => !isEditMode && setIsEditMode(true)}>
                {!isEditMode && label.label}
                {isEditMode && (
                    <input
                        ref={refLabel}
                        type="text"
                        autoComplete="off"
                        value={value}
                        placeholder="Label"
                        onKeyUp={e => {
                            if (e.key === 'Escape') setValue('')
                            if (e.key === 'Enter') onUpdateClick()
                        }}
                        onChange={e => setValue(e.target.value ?? '')} />
                )}
            </td>
            
            <td>{label.description}</td>
            
            <td>
                <ColorPicker
                    enabled={isEditMode}
                    initialColor={label.color ?? '#0000ff'}
                    onChange={onLabelColorChange} />
            </td>
            
            <td>
                <div className="flex items-center content-center justify-center">
                    {!isEditMode && (
                        <>
                            <button
                                className="w-auto btn btn-link"
                                onClick={() => setIsEditMode(true)}>
                                <FaEdit />
                            </button>
                            <button
                                className="w-auto btn btn-link"
                                title="Toggle public visibility"
                                onClick={() => onTogglePublicClick(label)}>
                                {label.public
                                    ? <FaRegEye />
                                    : <FaRegEyeSlash className="text-red-500" />}
                            </button>
                            <button
                                className="w-auto text-red-800 btn btn-link hover:text-red-500"
                                onClick={() => onDeleteClick(label.id)}>
                                <FaTrash />
                            </button>
                        </>
                    )}
                    {isEditMode && (
                        <>
                            <button
                                className="btn btn-primary"
                                onClick={onUpdateClick}>
                                UPDATE LABEL
                            </button>
                            <button
                                className="w-auto text-2xl text-orange-800 btn btn-link hover:text-orange-500"
                                onClick={() => setIsEditMode(false)}>
                                <FaTimesCircle />
                            </button>
                        </>
                    )}
                </div>
            </td>
        
        </tr>
        
    )
    
}

export default LabelRow
