import { gql } from '@apollo/client'
import {
    JobsConnectionFragment,
    ArchivesConnectionFragment,
} from '@graphql/fragments'

export const Jobs = gql`
    
    ${JobsConnectionFragment}
    
    query Jobs(
        $after: Cursor,
        $before: Cursor,
        $condition: JobCondition,
        $first: Int,
        $last: Int,
        $offset: Int,
        $orderBy: [JobsOrderBy!] = [STARTEDON_DESC],
        $name: String
    ) {
        
        jobs(
            after: $after,
            before: $before,
            condition: $condition,
            first: $first,
            last: $last,
            offset: $offset,
            orderBy: $orderBy,
            filter: {
                name: {
                    includes: $name
                }
            }
        ) {
            ...JobsConnectionFragment
        }
        
    }

`

export const ArchivedJobs = gql`
    
    ${ArchivesConnectionFragment}
    
    query ArchivedJobs(
        $after: Cursor,
        $before: Cursor,
        $condition: ArchiveCondition,
        $first: Int,
        $last: Int,
        $offset: Int,
        $orderBy: [ArchivesOrderBy!] = [CREATEDON_DESC],
        $name: String
    ) {
        
        archives(
            after: $after,
            before: $before,
            condition: $condition,
            first: $first,
            last: $last,
            offset: $offset,
            orderBy: $orderBy,
            filter: {
                name: {
                    includes: $name
                }
            }
        ) {
            ...ArchivesConnectionFragment
        }
        
    }

`
