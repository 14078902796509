import { useState, useEffect, useCallback } from 'react'
import { snippet } from '@utils'
import { DOC_REVIEWED_STATUSES } from '@constants'
import { createQueryString } from '@utils'
import { parseDate, relativeTime } from '@/utils/date'
import ParseAPI from '@api/ParseAPI'
import dayjs from 'dayjs'
import cn from 'classnames'

import Flatpickr from 'react-flatpickr'
import Pagination from '@components/shared/Pagination'

import 'flatpickr/dist/themes/dark.css'
import './CollectionDocumentsCompletedTable.css'

const documentsPerPage = 10

const recentStartDate = parseDate(dayjs().subtract(1, 'd').format())
const recentEndDate = parseDate(dayjs().add(1, 'd').format())

const CollectionDocumentsCompletedTable = ({
    className,
}) => {
    
    const [documents, setDocuments] = useState([])
    const [pageIndex, setPageIndex] = useState(0)
    const [startDate, setStartDate] = useState(recentStartDate)
    const [endDate, setEndDate] = useState(recentEndDate)
    
    const fetchCompletedDocuments = useCallback(async () => {
        
        const query = createQueryString(
            DOC_REVIEWED_STATUSES.map(it => ['reviewStatuses[]', it]),
            {
                betweenStartDate: dayjs(startDate).format(),
                betweenEndDate: dayjs(endDate).format(),
                offset: Math.round(pageIndex * documentsPerPage),
                limit: documentsPerPage,
            },
        )
        
        try {
            
            const res = await ParseAPI.get(`/document${query}`)
            
            setDocuments(res.data)
            
        } catch (e) {
            
            // @todo show error
            console.error('get docs', e)
            
        }
        
    }, [startDate, endDate, pageIndex])
    
    // @todo can prob re-use the @utils download helper here
    const downloadCompletedDocuments = useCallback(async () => {
        
        const query = createQueryString(
            DOC_REVIEWED_STATUSES.map(it => ['reviewStatuses[]', it]),
            {
                betweenStartDate: startDate,
                betweenEndDate: endDate,
                offset: Math.round(pageIndex * documentsPerPage),
                limit: documentsPerPage,
            },
        )
        
        try {
            
            const res = await ParseAPI.get(`/document/download/completed${query}`, {
                responseType: 'blob',
            })
            
            const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv' }))
            
            // eslint-disable-next-line no-restricted-globals
            const link = document.createElement('a')
            const fileName = `${dayjs().format('YYYY-MM-DD')}_completed_documents.csv`
            
            link.href = url
            link.setAttribute('download', fileName)
            
            // eslint-disable-next-line no-restricted-globals
            document.body.appendChild(link)
            link.click()
            
            link.parentNode.removeChild(link)
            
        } catch (e) {
            
            // @todo show error
            console.error('get docs', e)
            
        }
        
    }, [startDate, endDate, pageIndex])
    
    useEffect(() => {
        fetchCompletedDocuments()
    }, [startDate, endDate, pageIndex])
    
    // @todo loading spinner
    if (!documents) return null
    
    return (<>
        
        <header className="flex items-baseline justify-between content-baseline">
            
            <h4 className="text-xl">
                Recently Completed Documents ({documents?.count ?? '0'})
            </h4>
            
            <div className="flex items-center content-center justify-center">
                <Flatpickr
                    className="w-40 text-center max-w-min"
                    value={startDate}
                    placeholder="Start"
                    options={{
                        dateFormat: 'Y-M-d',
                        allowInput: true,
                        parseDate: date => parseDate(date),
                    }}
                    onChange={value => setStartDate(value)} />
                <span className="mx-3">to</span>
                <Flatpickr
                    className="w-40 text-center max-w-min"
                    value={endDate}
                    placeholder="End"
                    options={{
                        dateFormat: 'Y-M-d',
                        allowInput: true,
                        parseDate: date => parseDate(date),
                    }}
                    onChange={value => setEndDate(value)} />
            </div>
            
            <div className="flex items-center content-center justify-end">
                <Pagination
                    count={documents?.count}
                    itemsPerPage={documentsPerPage}
                    setPageIndex={setPageIndex} />
            </div>
            <button
                className="w-auto btn"
                onClick={downloadCompletedDocuments}>
                DOWNLOAD CSV
            </button>
        </header>
        
        <table className={cn('CollectionDocumentsCompletedTable table table-auto table-pin-rows mt-3', className)}>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Collection</th>
                    <th>Annotator(s)</th>
                    <th>Started</th>
                    <th>Duration</th>
                </tr>
            </thead>
            <tbody className="list">
                {documents?.rows?.map(it => (
                    <tr key={`CollectionDocumentsCompletedTable-${it.id}`}>
                        <td>{snippet(it.id)}</td>
                        <td>{it.name}</td>
                        <td>{it.collection.name}</td>
                        <td>
                            <div className="flex flex-col">
                                {it.assignedAnnotators.map(user => (
                                    <div
                                        key={`CollectionDocumentsCompletedTable-annotator-${user.id}`}
                                        title={user.email}>
                                        {user.firstName} {user.lastName}
                                    </div>
                                ))}
                            </div>
                        </td>
                        <td>{relativeTime(it.annotationStartedAt)}</td>
                        <td>{it.annotationWorkDurationSec} sec</td>
                    </tr>
                ))}
            </tbody>
        </table>
    
    </>)
    
}

export default CollectionDocumentsCompletedTable
