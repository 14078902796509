import { useCallback } from 'react'
import { useWireState } from '@forminator/react-wire'
import * as store from '$store'
import * as actions from '$actions'

import Modal from '$components/Modal'

const DeleteUsersModal = ({
    className,
    users,
    onUsersDeleted,
    ...props
}) => {
    
    const [modalDeleteUsersOpen, setModalDeleteUsersOpen] = useWireState(store.modalDeleteUsersOpen)
    
    const onDeleteClick = useCallback(async () => {
        
        try {
            
            const res = await actions.deleteUsers(users.map(u => u.id))
            
            await actions.getUsers()
            
            console.log('onDeleteClick status', res.status)
            
            onUsersDeleted?.()
            
        } catch (e) {
            
            // @todo show error
            console.error('onDeleteClick', e)
            
        }
        
    }, [users])
    
    if (!users?.length) return null
    
    return (
        
        <Modal
            className={className}
            open={modalDeleteUsersOpen}
            setOpen={setModalDeleteUsersOpen}
            title="Delete Users"
            actionClass="bg-red-700/25 hover:bg-red-700/50"
            actionLabel="PERMANENTLY DELETE"
            actionOnClick={onDeleteClick}
            {...props}>
            
            <p>Are you sure you want to delete these users?</p>
            <p>This cannot be undone!</p>
            
            <p className="mt-4">
                <b>Note:</b> This is experimental, and will fail if the user owns any collections, documents, etc..
            </p>
            
            <ul className="list-disc mt-4 ml-5">
                {users.map(user => (
                    <li key={`user-${user.id}`}>
                        {user.email}
                    </li>
                ))}
            </ul>
        
        </Modal>
        
    )
    
}

export default DeleteUsersModal
