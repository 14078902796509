import { useEffect } from 'react'
import { useWireValue } from '@forminator/react-wire'
import LogRocket from 'logrocket'
import config from '@/config'
import buildInfo from '@/build.json'
import { user as storeUser, hasIdentifiedWithLogRocket } from '@store'
import { getUserFullName } from '@utils'

const useLogRocket = (
    config.logRocket.enabled &&
    config.logRocket.id?.length > 0
)

if (useLogRocket) {
    console.info('Initializing LogRocket')
    LogRocket.init(config.logRocket.id, {
        release: buildInfo.hash,
    })
}

/**
 * Subscribes to the user store and logs the user in to LogRocket
 * Automatically unsubscribes when the component unmounts
 */
export const useSubscribeIdentifyUser = () => {
    
    /** @type User */
    const user = useWireValue(storeUser)
    
    useEffect(() => {
        
        if (!config.logRocket.enabled)
            return
        
        // Don't identify if we've already identified
        if (hasIdentifiedWithLogRocket.getValue())
            return
        
        if (!user?.id?.length)
            return
        
        LogRocket.identify(user.id, {
            name: getUserFullName(user, false) ?? 'Unknown User',
            email: user.email,
            
            // Add your own custom user variables here, ie:
            // subscriptionType: 'pro'
        })
        
        console.log('LogRocket identified user', user.email)
        hasIdentifiedWithLogRocket.setValue(true)
        
    }, [user])
    
}
