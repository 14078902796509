import { useState } from 'react'
import cn from 'classnames'

const Collapse = ({
    className,
    titleClassName,
    contentClassName,
    title,
    sticky,
    children,
}) => {
    
    const [open, setOpen] = useState(false)
    
    return (
        
        <div
            tabIndex={0}
            className={cn('collapse collapse-arrow', className, {
                'collapse-open': sticky && open,
            })}>
            
            <div
                className={cn('collapse-title', titleClassName)}
                onClick={() => setOpen(!open)}>
                {title}
            </div>
            
            <div className={cn('collapse-content', contentClassName)}>
                {children}
            </div>
        
        </div>
        
    )
    
}

export default Collapse
