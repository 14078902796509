import { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useWireValue } from '@forminator/react-wire'
import * as store from '$store'
import * as actions from '$actions'

import CircleSpinner from '@components/shared/CircleSpinner'
import CollectionDocumentsCompletedTable from '$components/CollectionDocumentsCompletedTable'
import CollectionDocumentReviewsTable from '$components/CollectionDocumentReviewsTable'

const CollectionDetail = () => {
    
    const params = useParams()
    
    const [documentReviews, setDocumentReviews] = useState([])
    
    const collections = useWireValue(store.collections)
    
    const collection = useMemo(() => {
        return collections?.rows?.find(it => it.id === params.id)
    }, [collections])
    
    useEffect(() => {
        
        if (!params?.id) return
        if (collection) return
        
        // Either way, fetch a fresh copy from the server
        actions.getCollectionsWithStats({ id: params.id })
        
    }, [params, collections])
    
    useEffect(() => {
        
        if (!collection) return
        
        actions.getDocumentReviewsByCollectionId(collection.id)
            .then(it => setDocumentReviews(it?.data))
            .catch(e => console.error(e))
        
    }, [collection])
    
    if (!collection) return (
        <div className="CollectionDetail">
            <CircleSpinner className="absolute-centered" />
        </div>
    )
    
    return (
        
        <div className="CollectionDetail">
            
            <header className="flex items-baseline justify-between mb-3 content-baseline">
                
                <div className="flex flex-col">
                    <h4 className="!mb-0 text-xl text-dashboard-primary">
                        Collection
                    </h4>
                    <h4 className="text-lg">{collection.name}</h4>
                </div>
            
            </header>
            
            <CollectionDocumentsCompletedTable />
            
            <h4 className="text-xl">Document Reviews</h4>
            <CollectionDocumentReviewsTable documentReviews={documentReviews} />
        
        </div>
        
    )
    
}

export default CollectionDetail
