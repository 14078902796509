import { useState, useEffect, useMemo, useCallback } from 'react'
import cn from 'classnames'

import CircleSpinner from '@components/shared/CircleSpinner'
import DocumentsTableRow from './DocumentsTableRow'
import Checkbox from '$components/Forms/Checkbox'
import { MdBolt } from 'react-icons/md'

import './DocumentsTable.css'

const DocumentsTable = ({
    className,
    documents,
    onCheckedChange,
    onAssignSingleClick,
}) => {
    
    const [checked, setChecked] = useState({})
    
    const allChecked = useMemo(() => Object.keys(checked).length === documents.length, [checked, documents])
    const someChecked = useMemo(() => !allChecked && Object.keys(checked).length > 0, [checked, allChecked])
    
    const onCheckAllChange = useCallback(() => {
        
        setChecked(allChecked
            ? {}
            : documents.reduce((acc, it) => ({
                ...acc,
                [it.id]: true,
            }), {}),
        )
        
    }, [allChecked, documents])
    
    const onCheckChange = documentId => {
        
        if (checked[documentId]) {
            
            const value = { ...checked }
            
            delete value[documentId]
            setChecked(value)
            
        } else {
            
            setChecked({
                ...checked,
                [documentId]: true,
            })
            
        }
        
    }
    
    useEffect(() => {
        
        // Update the parent whenever checked selection
        // changes, so we can show counts/etc.
        onCheckedChange && onCheckedChange(Object.keys(checked))
        
    }, [checked, onCheckedChange])
    
    useEffect(() => {
        
        // Clear all checked rows when filtering, so as
        // not to confuse which documents an action applies to
        setChecked({})
        
    }, [documents])
    
    if (!documents?.length) return (
        <div className="DocumentsTable">
            <CircleSpinner className="absolute-centered" />
        </div>
    )
    
    return (
        
        <table className={cn('DocumentsTable table table-sm table-nowrap table-pin-rows card-table', className)}>
            <thead>
                <tr>
                    <th>
                        <Checkbox
                            checked={allChecked}
                            indeterminate={someChecked}
                            onChange={onCheckAllChange} />
                    </th>
                    <th>Name</th>
                    <th>File Name</th>
                    <th>Created Date</th>
                    <th>Updated Date</th>
                    <th>Status</th>
                    {/* <th>ACount</th>
                    <th>RCount</th>
                    <th>Locked</th> */}
                    <th>
                        {/* Actions */}
                        <div className="flex justify-center">
                            <MdBolt className="text-lg" />
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody className="list">
                {documents.map(it => (
                    <DocumentsTableRow
                        key={`document-${it.id}`}
                        {...it}
                        checked={checked[it.id]}
                        onCheckChange={() => onCheckChange(it.id)}
                        onAssignSingleClick={onAssignSingleClick} />
                ))}
            </tbody>
        </table>
        
    )
    
}

export default DocumentsTable
