import { createWire } from '@forminator/react-wire'

// Edit user roles
export const modalEditUserRolesUser = createWire(null)
export const modalEditUserRolesOpen = createWire(false)

// Onboard a new user more conveniently (for admins)
export const modalOnboardNewUserOpen = createWire(false)

// Delete users (admin)
export const modalDeleteUsersOpen = createWire(false)

// Delete an annotator user account
export const modalDeleteAnnotatorOpen = createWire(false)

// Update a user's credits
export const modalManageUserCreditsUser = createWire(null)
export const modalManageUserCreditsOpen = createWire(false)

// Update an organization's credits
export const modalManageOrgCreditsOrg = createWire(null)
export const modalManageOrgCreditsOpen = createWire(false)

// Manage annotation labels associated with an organization
export const modalManageOrgMembersOpen = createWire(false)

// Manage annotation labels associated with an organization
export const modalManageOrgLabelsOpen = createWire(false)

// Manage annotation labels associated with a collection
export const modalManageCollectionLabelsOpen = createWire(false)

// Set status for all documents in a collection
export const modalSetDocumentStatusesOpen = createWire(false)

// Manage prompt configuration for LLM per collection
export const modalManageLLMPromptOpen = createWire(false)

// Masquerade confirmation
export const modalMasqueradeConfirmUser = createWire(null)
export const modalMasqueradeConfirmOpen = createWire(false)
