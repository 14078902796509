// import Logger from '@utils/log'
import ParseAPI from '@api/ParseAPI'

// const log = new Logger('actions/billing')

export const deleteCreditCard = async cardId => {
    
    return await ParseAPI.delete(`/stripe/cards/${cardId}`)
    
}

export const cancelInFlightPaymentIntents = async sessionId => {
    
    return await ParseAPI.delete('/stripe/in-flight-payment-intents', {
        sessionId,
    })
    
}
