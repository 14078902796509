import { useState, useMemo, useEffect } from 'react'
import config from '@/config'
import cn from 'classnames'

import ConfirmationModal from '@components/shared/ConfirmationModal'
import { MdBolt, MdRefresh, MdOutlineDeleteSweep } from 'react-icons/md'

// import { SiElasticsearch } from 'react-icons/si'

const ESEnginesTable = ({
    className,
    wrapClassName,
    viewModel,
}) => {
    
    const {
        
        stats,
        engines,
        
        confirmRefreshIndexName,
        setConfirmRefreshIndexName,
        
        confirmReindexEngineName,
        setConfirmReindexEngineName,
        
        confirmTruncateIndexName,
        setConfirmTruncateIndexName,
        
        fetchEngines,
        fetchJobs,
        refreshIndex,
        reindexEngine,
        truncateIndex,
        
        /* getLastJob,
        getRunningJob, */
        
    } = viewModel
    
    const [query, setQuery] = useState('')
    
    const filteredEngines = useMemo(() => (
        query?.length > 0
            ? engines?.filter(it => it.name.toLowerCase().includes(query)) ?? []
            : engines
    ), [engines, query])
    
    useEffect(() => {
        
        if (stats?.enabled)
            fetchEngines()
        
        fetchJobs()
        
        const t = setInterval(fetchJobs, config.elasticSearchIndexJobPollInterval)
        
        return () => clearInterval(t)
        
    }, [stats])
    
    return (
        
        <div className={cn('ESEnginesTable-wrap', wrapClassName)}>
            
            <header>
                <div className="">
                    <input
                        className="w-full"
                        type="text"
                        autoComplete="off"
                        value={query}
                        placeholder="Filter engines..."
                        onKeyUp={e => e.key === 'Escape' && setQuery('')}
                        onChange={e => setQuery(e.target.value?.toLowerCase() ?? '')} />
                </div>
            </header>
            
            <table className={cn('ESEnginesTable table-pin-rows', className)}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Health</th>
                        <th>Total</th>
                        <th>
                            {/* Actions */}
                            <div className="flex !justify-center">
                                <MdBolt className="text-lg" />
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {filteredEngines?.length < 1 && (
                        <tr>
                            <td colSpan={3}>
                                <i>No engines found</i>
                            </td>
                        </tr>
                    )}
                    {filteredEngines?.map(it => {
                        
                        /* const lastJob = getLastJob(it.name)
                        const runningJob = getRunningJob(it.name)
                        
                        console.log('@todo ESEnginesTable', {
                            lastJob,
                            runningJob,
                        }) */
                        
                        return (
                            <tr key={`engine-${it.name}`}>
                                <td title={it.name}>
                                    {it.name.startsWith('search-') ? it.name.substring(7) : it.name}
                                </td>
                                <td>
                                    <span style={{ color: it.color }}>
                                        {it.status}
                                    </span>
                                </td>
                                <td>
                                    {(it.count ?? '0').toLocaleString('en-US')}
                                </td>
                                <td>
                                    <div className="flex items-center content-center justify-center">
                                        <button
                                            className="w-auto btn btn-link"
                                            title="Refresh Index"
                                            onClick={() => setConfirmRefreshIndexName(it.name)}>
                                            <MdRefresh />
                                        </button>
                                        {/* @todo since we switched from App Search
                                            to normal indices, this needs to be reworked */}
                                        {/* <button
                                            className="w-auto btn btn-link"
                                            title="Re-index Engine"
                                            onClick={() => setConfirmReindexEngineName(it.name)}>
                                            <SiElasticsearch />
                                        </button> */}
                                        <button
                                            className="w-auto btn btn-link"
                                            title="Truncate Index"
                                            onClick={() => setConfirmTruncateIndexName(it.name)}>
                                            <MdOutlineDeleteSweep className="text-red-500" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )
                        
                    })}
                </tbody>
            </table>
            
            <ConfirmationModal
                showModal={confirmRefreshIndexName}
                showHideModal={setConfirmRefreshIndexName}
                title="Refresh Index"
                onConfirm={refreshIndex}
                message={(
                    <div>
                        <div className="text-base">
                            <p>
                                Are you sure you want to refresh the
                                {' '}
                                <b>{confirmRefreshIndexName}</b>
                                {' '}
                                index in ElasticSearch?
                            </p>
                            <p className="mt-3">
                                This could take a long time.
                            </p>
                            <p className="mt-3 text-red-400">
                                <b>This could incur additional GCP costs!</b>
                            </p>
                        </div>
                    </div>
                )} />
            
            <ConfirmationModal
                showModal={confirmReindexEngineName}
                showHideModal={setConfirmReindexEngineName}
                title="Re-index Engine"
                onConfirm={reindexEngine}
                message={(
                    <div>
                        <div className="text-base">
                            <p>
                                Are you sure you want to re-index the
                                {' '}
                                <b>{confirmReindexEngineName}</b>
                                {' '}
                                engine in ElasticSearch?
                            </p>
                            <p className="mt-3">
                                This could take a long time.
                            </p>
                            <p className="mt-3 text-red-400">
                                <b>This could incur additional GCP costs!</b>
                            </p>
                        </div>
                    </div>
                )} />
            
            <ConfirmationModal
                showModal={confirmTruncateIndexName}
                showHideModal={setConfirmTruncateIndexName}
                title="Truncate Index"
                onConfirm={truncateIndex}
                message={(
                    <div>
                        <div className="text-base">
                            <p>
                                Are you sure you want to truncate the
                                {' '}
                                <b>{confirmTruncateIndexName}</b>
                                {' '}
                                index in ElasticSearch?
                            </p>
                            <p className="mt-3">
                                This could take a long time.
                            </p>
                            <p className="mt-3 text-red-400">
                                <b>This will permanently delete indexed data!</b>
                            </p>
                            <p className="mt-3 text-red-400">
                                <b>This could incur additional GCP costs!</b>
                            </p>
                        </div>
                    </div>
                )} />
        
        </div>
        
    )
    
}

export default ESEnginesTable
