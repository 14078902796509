import * as actions from '$actions'

// eslint-disable-next-line no-unused-vars
import { Chart as ChartJS } from 'chart.js/auto' // this is necessary to bootstrap the chart 
import { useEffect, useMemo, useState } from 'react'
import { Line } from 'react-chartjs-2'

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            labels: {
                color: '#f4f4f4',
            },
        },
        title: {
            display: false,
        },
    },
    scales: {
        y: {
            ticks: {
                color: '#a8a8a8',
            },
        },
        x: {
            ticks: {
                color: '#a8a8a8',
            },
        },
    },
    layout: {
        padding: 16,
    },
}

const labels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

const UserActionsChart = ({ trackingActions, organization }) => {
    
    const [monthlyActions, setMonthlyActions] = useState([])
    
    useEffect(() => {
        if (organization)
            actions.getActionsCountPerMonthByOrgId(organization.id)
                .then(data => setMonthlyActions(data))
    }, [organization])
    
    // dedupePrimitive(monthlyActions.map(ma => ma.createdMonth)).sort().map(month => labels[parseInt(month, 10)])
    const filteredLabels = useMemo(() => monthlyActions?.length
        ? labels.slice(0, new Date().getMonth() + 1)
        : labels,
    [monthlyActions])
    
    const filterData = action => {
        const labelsIdx = filteredLabels.map(fl => labels.indexOf(fl) + 1)
        const actions = monthlyActions?.filter(ma => ma.action === action) || []
        
        return labelsIdx.map(monthIdx => actions.find(a => parseInt(a.createdMonth, 10) === monthIdx)?.count || 0)
    }
    
    const data = {
        labels: filteredLabels,
        datasets: trackingActions.map(ta => ({
            label: ta.label,
            data: filterData(ta.key),
        })),
    }
    
    
    return (
        <Line options={options} data={data} />
    )
}

export default UserActionsChart
