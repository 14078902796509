
/**
 * Converts a compiled GraphQL query to a subscription
 * 
 * @param {Object} query GraphQL query (compiled by `graphql-tag`)
 * @returns {Object} Query converted to Subscription
 */
export const asSubscription = query => {
    
    const obj = JSON.parse(JSON.stringify(query))
    
    obj.definitions = obj.definitions.map(it => {
        if (it.kind === 'OperationDefinition')
            it.operation = 'subscription'
        return it
    })
    
    return obj
    
}

export const returnDataOrThrow = (result, autoReturnFirstKey = true) => {
    
    if (result?.errors?.length) {
        
        console.error(result.errors)
        
        const status = result.errors[0]
            ?.extensions
            ?.internal
            ?.response
            ?.status ?? null
        
        if (status > 399 && status < 500) {
            console.error('Unauthenticated')
            localStorage.removeItem('jwt')
            localStorage.removeItem('userId')
            localStorage.removeItem('userEmail')
            return window.location.replace('/')
        }
        
        throw new Error(
            result.errors[0]
                ?.extensions
                ?.internal
                ?.response
                ?.body
                ?.error ?? 'Unknown error',
        )
        
    }
    
    if (!autoReturnFirstKey) {
        console.log('returnOrThrow', { autoReturnFirstKey, data: result.data })
        return result.data
    }
    
    const keys = Object.keys(result.data)
    const key = keys[0]
    
    console.log('returnOrThrow', { key, keys, data: result.data[key] })
    
    return result.data[Object.keys(result.data)[0]]
    
}
