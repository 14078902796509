import * as reactRouter from 'react-router-dom'
import { ROUTE_PATHS } from '@constants'

/**
 * Typesafe version of React Router's `useNavigate`
 * 
 * @example
 * // Normal functionality:
 * navigate('/arbitrary/url')
 * 
 * // Typesafe helpers
 * navigate.toCollectionDetail(collectionId)
 */
const useNavigate = () => {
    
    const navigate = reactRouter.useNavigate()
    
    const _navigate = (url, tokens = [], ...args) => {
        
        if (tokens?.length)
            tokens.forEach(([token, value]) => {
                url = url.replace(token, value)
            })
        
        return navigate(url, ...args)
        
    }
    
    // Default navigate function pass-through to the real one
    const fn = (...args) => navigate(...args)
    
    // User & Auth
    fn.toSignin = (...args) => _navigate(
        ROUTE_PATHS.SIGNIN,
        null,
        ...args,
    )
    
    fn.toSignup = (...args) => _navigate(
        ROUTE_PATHS.SIGNUP,
        null,
        ...args,
    )
    
    fn.toEmailSent = (...args) => _navigate(
        ROUTE_PATHS.EMAIL_SENT,
        null,
        ...args,
    )
    
    fn.toResendEmail = (...args) => _navigate(
        ROUTE_PATHS.RESEND_EMAIL,
        null,
        ...args,
    )
    
    fn.toForgotPassword = (...args) => _navigate(
        ROUTE_PATHS.FORGOT_PASSWORD,
        null,
        ...args,
    )
    
    fn.toResetPassword = (...args) => _navigate(
        ROUTE_PATHS.RESET_PASSWORD,
        null,
        ...args,
    )
    
    fn.toResetPasswordEmailSent = (...args) => _navigate(
        ROUTE_PATHS.RESET_PASSWORD_EMAIL_SENT,
        null,
        ...args,
    )
    
    fn.toSetPassword = (...args) => _navigate(
        ROUTE_PATHS.SET_PASSWORD,
        null,
        ...args,
    )
    
    fn.toSetBilling = (...args) => _navigate(
        ROUTE_PATHS.SET_BILLING,
        null,
        ...args,
    )
    
    // Collections
    fn.toCollections = (...args) => _navigate(
        ROUTE_PATHS.COLLECTIONS,
        null,
        ...args,
    )
    
    fn.toCollectionDetail = (collectionId, ...args) => _navigate(
        ROUTE_PATHS.COLLECTION_DETAIL,
        [[':collectionId', collectionId]],
        ...args,
    )
    
    fn.toCollectionDetailActiveTab = (collectionId, routeActiveTab, ...args) => _navigate(
        ROUTE_PATHS.COLLECTION_DETAIL_ACTIVE_TAB,
        [
            [':collectionId', collectionId],
            [':routeActiveTab', routeActiveTab],
        ],
        ...args,
    )
    
    fn.toViewDocumentId = (documentId, ...args) => _navigate(
        ROUTE_PATHS.VIEW_DOCUMENT_ID,
        [[':documentId', documentId]],
        ...args,
    )
    
    fn.toEditDocumentId = (documentId, ...args) => _navigate(
        `${ROUTE_PATHS.VIEW_DOCUMENT_ID}?annotate=`,
        [[':documentId', documentId]],
        ...args,
    )
    
    // Tracts
    fn.toTracts = (...args) => _navigate(
        ROUTE_PATHS.TRACTS,
        null,
        ...args,
    )
    
    fn.toTractDetail = (tractId, ...args) => _navigate(
        ROUTE_PATHS.TRACT_DETAIL,
        [[':id', tractId]],
        ...args,
    )
    
    // Runsheets
    fn.toRunsheets = (...args) => _navigate(
        ROUTE_PATHS.RUNSHEETS,
        null,
        ...args,
    )
    
    fn.toRunsheetDetail = (runsheetId, ...args) => _navigate(
        ROUTE_PATHS.RUNSHEET_DETAIL,
        [[':id', runsheetId]],
        ...args,
    )
    
    fn.toRunsheetTitleBreakdown = runsheetId => _navigate(
        ROUTE_PATHS.RUNSHEET_TITLE_BREAKDOWN,
        [[':id', runsheetId]],
    )
    
    fn.toRunsheetOwnership = runsheetId => _navigate(
        ROUTE_PATHS.TRACT_OWNERSHIP,
        [[':id', runsheetId]],
    )
    
    fn.toRunsheetDetailTractId = (runsheetId, tractId, ...args) => _navigate(
        ROUTE_PATHS.RUNSHEET_DETAIL_TRACT_ID,
        [
            [':id', runsheetId],
            [':tractId', tractId],
        ],
        ...args,
    )
    
    // Projects
    fn.toProjects = (...args) => _navigate(
        ROUTE_PATHS.PROJECTS,
        null,
        ...args,
    )
    
    fn.toProjectId = (projectId, ...args) => _navigate(
        ROUTE_PATHS.PROJECT_DETAIL,
        [[':id', projectId]],
        ...args,
    )
    
    // Checks
    fn.toChecks = (...args) => _navigate(
        ROUTE_PATHS.CHECKS,
        null,
        ...args,
    )
    
    fn.toCheckPreview = (checkId, pageIndex = 0, ...args) => _navigate(
        ROUTE_PATHS.CHECK_PREVIEW,
        [
            [':checkId', checkId],
            [':pageIndex', pageIndex],
        ],
        ...args,
    )
    
    fn.toCheckDetail = (checkId, ...args) => _navigate(
        ROUTE_PATHS.CHECK_DETAIL,
        [[':checkId', checkId]],
        ...args,
    )
    
    fn.toCheckDetailEdit = (checkId, ...args) => _navigate(
        ROUTE_PATHS.CHECK_DETAIL_EDIT,
        [[':checkId', checkId]],
        ...args,
    )
    
    // User & org settings
    fn.toIam = (...args) => _navigate(
        ROUTE_PATHS.IAM,
        null,
        ...args,
    )
    
    fn.toSettings = (...args) => _navigate(
        ROUTE_PATHS.SETTINGS,
        null,
        ...args,
    )
    
    fn.toEmail = (...args) => _navigate(
        ROUTE_PATHS.EMAIL,
        null,
        ...args,
    )
    
    fn.toOrgs = (...args) => _navigate(
        ROUTE_PATHS.ORGS,
        null,
        ...args,
    )
    
    fn.toOrgDetail = (orgId, ...args) => _navigate(
        ROUTE_PATHS.ORG_DETAIL,
        [[':orgId', orgId]],
        ...args,
    )
    
    fn.toTemplates = (...args) => _navigate(
        ROUTE_PATHS.TEMPLATES,
        null,
        ...args,
    )
    
    fn.toTemplateDetail = (templateId, ...args) => _navigate(
        ROUTE_PATHS.TEMPLATE_DETAIL,
        [[':templateId', templateId]],
        ...args,
    )
    
    fn.toBilling = (...args) => _navigate(
        ROUTE_PATHS.BILLING,
        null,
        ...args,
    )
    
    fn.toPurchases = (...args) => _navigate(
        ROUTE_PATHS.PURCHASES,
        null,
        ...args,
    )
    
    fn.toPersonalinfoEmail = (email, ...args) => _navigate(
        ROUTE_PATHS.PERSONALINFO_EMAIL,
        [[':email', email]],
        ...args,
    )
    
    // Annotations
    fn.toPartners = (...args) => _navigate(
        ROUTE_PATHS.PARTNERS,
        null,
        ...args,
    )
    
    fn.toPartnersAnnotateDocument = (documentId, ...args) => _navigate(
        ROUTE_PATHS.PARTNERS_ANNOTATE_DOCUMENT_ID,
        [[':documentId', documentId]],
        ...args,
    )
    
    fn.toPartnersAnnotateCheck = (checkId, ...args) => _navigate(
        ROUTE_PATHS.PARTNERS_ANNOTATE_CHECK_ID,
        [[':checkId', checkId]],
        ...args,
    )
    
    return fn
    
}

export default useNavigate
