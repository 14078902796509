import Logger from '@utils/log'
import * as store from '@store'
import ParseAPI from '@api/ParseAPI'
import { RECORD_TYPES } from '@constants/annotations'
import { createQueryString } from '@utils'

// eslint-disable-next-line no-unused-vars
const log = new Logger('actions/annotations')

export const createLabel = async (labelsType, label, description) => {
    
    return await ParseAPI.post('annotations/labels', {
        type: labelsType,
        label,
        description,
    })
    
}

export const fetchLabels = async (recordType, {
    organizationId = null,
    collectionId = null,
    documentId = null,
    storeResults = true,
} = {}) => {
    
    const paramsObj = {
        recordType,
        organizationId,
        collectionId,
        documentId,
    }
    
    const params = createQueryString(paramsObj)
    
    const res = await ParseAPI.get(`annotations/labels${params}`)
    
    if (storeResults)
        store.labels.setValue(res.data ?? [])
    
    return res
    
}

export const fetchAllLabels = async () => {
    
    const res = await Promise.all(RECORD_TYPES.map(it => {
        return fetchLabels(it, { storeResults: false })
    }))
    
    const newLabels = res.flatMap(it => it.data ?? [])
    
    store.labels.setValue(newLabels)
    
    return newLabels
    
}

export const updateLabel = async (id, data) => {
    
    return await ParseAPI.put(`annotations/labels/${id}`, data)
    
}

export const updateLabelAdmin = async (id, data) => {
    
    return await ParseAPI.put(`admin/labels/${id}`, data)
    
}

export const deleteLabel = async id => {
    
    return await ParseAPI.delete(`annotations/labels/${id}`)
    
}
