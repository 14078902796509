import { useMemo } from 'react'
import { useWireState } from '@forminator/react-wire'
import * as store from '@store'
import { PURCHASE_TYPES } from '@constants/purchases'

import PaymentRequiredDialog from './PaymentRequiredDialog'

/**
 * Global `PaymentRequiredDialog` that automatically
 * gets its config from the global store
 */
const GlobalPaymentRequiredDialog = () => {
    
    const [config, setConfig] = useWireState(store.paymentRequiredDialogConfig)
    
    const onCloseFn = () => setConfig({
        open: false,
        purchaseType: undefined,
        itemCount: 0,
        onConfirmPayment: undefined,
    })
    
    // We could pass the whole config directly to the dialog,
    // but here we have an opportunity to error check a few things
    const {
        open,
        purchaseType,
        itemCount,
        onClose,
        onConfirmPayment,
    } = useMemo(() => {
        
        if (!config || !config.open) return {}
        
        if (!PURCHASE_TYPES.includes(config.purchaseType))
            throw new Error(`Invalid param "purchaseType" (${config.purchaseType});
                should be one of ${PURCHASE_TYPES}`)
        
        if (config.onClose && typeof config.onClose !== 'function')
            console.warn('GlobalPaymentRequiredDialog onClose should be a function')
        
        if (!config.onConfirmPayment || typeof config.onConfirmPayment !== 'function')
            throw new Error('Invalid param "onConfirmPayment"; must be a function')
        
        if (config.itemCount < 0)
            throw new Error('Invalid param "itemCount"; should be gte 0')
        
        return {
            open: config.open,
            purchaseType: config.purchaseType,
            itemCount: config.itemCount,
            onClose: onCloseFn,
            onConfirmPayment: config.onConfirmPayment,
        }
        
    }, [config])
    
    if (!config || !open) return null
    
    return (
        
        <PaymentRequiredDialog
            open={open}
            purchaseType={purchaseType}
            itemCount={itemCount}
            onClose={onClose}
            onConfirmPayment={onConfirmPayment} />
        
    )
    
}

export default GlobalPaymentRequiredDialog
