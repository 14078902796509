import { useWireState, useWireValue } from '@forminator/react-wire'
import * as store from '@store'
import cn from 'classnames'

/**
 * Organization list dropdown
 * Alternative to [OrganizationSelect] for global menu, but may also replace it in the future
 * @param className
 * @constructor
 */
const CurrentOrgProjectSelectorNavItem = ({
    className,
}) => {
    
    const currentOrganization = useWireValue(store.currentOrganization)
    // const currentProject = useWireValue(store.currentProject)
    const [open, setOpen] = useWireState(store.currentOrgProjectSelectorDialogOpen)
    
    return (
        
        <li
            className="mr-4 pr-5 border-r border-base-200 tooltip tooltip-primary tooltip-bottom"
            data-tip="Switch your currently selected organization">
            
            <a
                className={cn('CurrentOrgProjectSelectorNavItem',
                    'nav-link h-full flex items-center bg-base-200/20', className)}
                onClick={() => setOpen(!open)}>
                
                <span className="flex items-center content-center gap-2 max-w-[8rem] truncate">
                    <div className="w-4 h-4 p-1 bg-base-300 rounded todo-org-icon"/>
                    <span className="max-w-[150px] hidden md:inline text-start leading-none truncate">
                        {currentOrganization?.name ?? ''}
                    </span>
                </span>
                
                {/* <div className="flex items-center gap-2 p-2 rounded">
                    <div className="w-4 h-4 p-1 bg-green-600/50 rounded todo-project-icon"/>
                    <span className="max-w-[150px] hidden md:inline text-start leading-none truncate">
                        {currentProject?.name ?? 'Super Long Project Name'}
                    </span>
                </div> */}
            
            </a>
        
        </li>
        
    )
    
}

export default CurrentOrgProjectSelectorNavItem
