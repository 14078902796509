import { useCallback, useMemo, useState } from 'react'
import { useWireState, useWireValue } from '@forminator/react-wire'
import * as store from '@store'
import { ACCOUNT_TYPES, PURCHASE_TYPE_COSTS } from '@constants'

/**
 * @type {function}
 * @returns {PaymentsHook}
 */
const usePayments = () => {
    
    const [fundingSource, setFundingSource] = useState(ACCOUNT_TYPES.Organization)
    
    /** @type User */
    const user = useWireValue(store.user)
    /** @type Organization */
    const currentOrganization = useWireValue(store.currentOrganization)
    const credits = useWireValue(store.credits)
    const remoteConfig = useWireValue(store.remoteConfig)
    const [, setPaymentRequiredDialogConfig] = useWireState(store.paymentRequiredDialogConfig)
    const paymentRequiredDialogShouldWarn = useWireValue(store.paymentRequiredDialogShouldWarn)
    
    const orgCreditsBalance = useMemo(() => credits.organization ?? 0, [credits])
    const orgCreditsBalanceFormatted = useMemo(() => orgCreditsBalance?.toFixed(2), [orgCreditsBalance])
    const hasOrgCreditsBalance = useMemo(() => orgCreditsBalance > 0, [orgCreditsBalance])
    
    const userCreditsBalance = useMemo(() => credits.user ?? 0, [credits])
    const userCreditsBalanceFormatted = useMemo(() => userCreditsBalance?.toFixed(2), [userCreditsBalance])
    const hasUserCreditsBalance = useMemo(() => userCreditsBalance > 0, [userCreditsBalance])
    
    const balance = useMemo(() => {
        switch (fundingSource) {
            case ACCOUNT_TYPES.User: return userCreditsBalance
            case ACCOUNT_TYPES.Organization: return orgCreditsBalance
            
            default: {
                throw new Error(`Invalid funding source: ${fundingSource}; expected one of ${ACCOUNT_TYPES}`)
            }
        }
    }, [fundingSource, userCreditsBalance, orgCreditsBalance])
    const balanceFormatted = useMemo(() => balance.toFixed(2), [balance])
    const hasCreditsBalance = useMemo(() => balance > 0, [balance])
    
    const userCanAffordPurchase = useCallback((quantity, purchaseType) => {
        
        const cost = Math.ceil(quantity * PURCHASE_TYPE_COSTS[purchaseType])
        
        return userCreditsBalance >= cost
        
    }, [userCreditsBalance])
    
    const orgCanAffordPurchase = useCallback((quantity, purchaseType) => {
        
        const cost = Math.ceil(quantity * PURCHASE_TYPE_COSTS[purchaseType])
        
        return orgCreditsBalance >= cost
        
    }, [orgCreditsBalance])
    
    /**
     * Determines if the purchase modal should be shown
     *
     * @function
     * @param {PurchaseType} purchaseType - Type of purchase
     * @param {number} [itemCount=1] - Number of items
     * @returns {boolean} - True if the dialog should be shown
     */
    const shouldShowPurchaseDialog = useCallback((purchaseType, itemCount) => {
        
        const basePrice = PURCHASE_TYPE_COSTS[purchaseType] ?? 0
        const price = basePrice * (itemCount || 0)
        
        // Show payment prompt if:
        // 1. Payments are enabled (@todo can remove this once payments are live)
        // 2. The user has not checked the "don't remind me" option OR the price is >= $20
        return remoteConfig?.payments?.enabled &&
            (paymentRequiredDialogShouldWarn || price >= 20)
        
    }, [remoteConfig, paymentRequiredDialogShouldWarn])
    
    /**
     * Determines if the purchase modal should be shown
     *
     * @function
     * @param {Document} record - Record to be purchased
     * @param {PurchaseType} purchaseType - Type of purchase
     * @param {number} [itemCount=1] - Number of items
     * @returns {boolean} - True if the dialog should be shown
     */
    const shouldShowPurchaseRecordDialog = useCallback((record, purchaseType, itemCount) => {
        
        if (!Object.hasOwn(record?.collection || {}, 'isParse'))
            throw new Error(`Collection field "isParse" is required; ${JSON.stringify(record, null, 4)}`)
        
        return (
            record.collection?.isParse === true &&
            !record.purchased &&
            shouldShowPurchaseDialog(purchaseType, itemCount)
        )
        
    }, [shouldShowPurchaseDialog])
    
    /**
     * Determines if the purchase modal should be shown, and
     * automatically shows the dialog if true.
     *
     * @function
     * @param {object} options
     * @param {PurchaseType} options.purchaseType - Type of purchase
     * @param {number} [options.itemCount=1] - Number of items
     * @param {*} [options.otherOption] - Other arbitrary options that can be passed to `paymentRequiredDialogConfig`
     * @returns {boolean} - True if the dialog was shown
     */
    const showPurchaseDialog = useCallback((options = {}) => {
        
        if (!options.purchaseType)
            throw new Error('Param "purchaseType" required')
        
        if (options.itemCount !== 0 && !options.itemCount)
            throw new Error('Param "itemCount" required')
        
        // Show payment prompt if:
        // 1. Payments are enabled (@todo can remove this once payments are live)
        // 2. The user has not checked the "don't remind me" option OR the price is >= $20
        if (shouldShowPurchaseDialog(options.purchaseType, options.itemCount)) {
            
            setPaymentRequiredDialogConfig({
                open: true,
                purchaseType: options.purchaseType,
                itemCount: options.itemCount,
                ...options,
            })
            
            return true
            
        }
        
        return false
        
    }, [shouldShowPurchaseDialog])
    
    return {
        
        // State
        fundingSource,
        setFundingSource,
        
        // Global State
        user,
        currentOrganization,
        remoteConfig,
        paymentRequiredDialogShouldWarn,
        
        // Memos
        orgCreditsBalance,
        hasOrgCreditsBalance,
        orgCreditsBalanceFormatted,
        userCreditsBalance,
        userCreditsBalanceFormatted,
        hasUserCreditsBalance,
        balance,
        balanceFormatted,
        hasCreditsBalance,
        
        // Methods
        userCanAffordPurchase,
        orgCanAffordPurchase,
        shouldShowPurchaseDialog,
        shouldShowPurchaseRecordDialog,
        showPurchaseDialog,
        
    }
    
}

export default usePayments
