import { useRef, useState, useMemo, useCallback, useEffect } from 'react'
import * as actions from '@graphql/actions'
import { arrayGroupBy, sortBy } from '@utils'
import useAbortableFetch from '@hook/useAbortableFetch'
import randomColor from 'randomcolor'
import { toast } from 'react-toastify'
import cn from 'classnames'

import AuditsTableRow from './AuditsTableRow'
import Dropdown from '$components/Dropdown'
import { MdBolt } from 'react-icons/md'
import { BiExpand, BiCollapse } from 'react-icons/bi'
import { FaAngleDown } from 'react-icons/fa'

import './AuditsTable.css'

const AuditsTable = ({
    className,
    auditsLimit,
    setTotalAudits,
    monitorJobsEnabled,
    hideHealthChecks,
}) => {
    
    const refGroupedAuditColors = useRef({})
    
    const [query, setQuery] = useState('')
    const [audits, setAudits] = useState([])
    const [auditJobTypes, setAuditJobTypes] = useState([])
    const [selectedAuditJobType, setSelectedAuditJobType] = useState('All Job Types')
    const [expandedPayloads, setExpandedPayloads] = useState({})
    
    const filteredAudits = useMemo(() => (
        hideHealthChecks
            ? audits?.rows?.filter(it => !it.jobType.startsWith('HealthCheck'))
            : audits?.rows
    ), [audits, hideHealthChecks])
    
    const groupedAudits = useMemo(() => (
        arrayGroupBy(filteredAudits || [], ['objectId', 'jobType'])
    ), [filteredAudits])
    
    const fetchAuditJobTypes = useCallback(async () => {
        
        await actions.fetchQueueAuditJobTypes()
            .then(res => setAuditJobTypes(['All Job Types', ...res]))
            .catch(e => console.error('fetchAuditJobTypes', e))
        
    }, [])
    
    const fetchAudits = useCallback(async (query, jobType = null) => {
        
        try {
            
            const res = await actions.fetchQueueAudits({
                query,
                jobType: jobType === 'All Job Types' ? null : jobType,
                offset: 0,
                limit: auditsLimit,
            })
            
            setAudits(res)
            setTotalAudits(res?.count ?? 0)
            
        } catch (e) {
            
            console.error('fetchAudits', e)
            toast.error('Failed to fetch queue audit log')
            
        }
        
    }, [])
    
    useEffect(() => { fetchAuditJobTypes() }, [])
    
    useAbortableFetch(() => (
        query?.length > 0
            ? fetchAudits(query, selectedAuditJobType)
            : Promise.resolve()
    ), [query, selectedAuditJobType])
    
    useEffect(() => {
        
        let t
        
        if (monitorJobsEnabled) {
            fetchAudits(query, selectedAuditJobType)
            t = setInterval(() => fetchAudits(query, selectedAuditJobType), 5000)
        } else {
            clearInterval(t)
        }
        
        return () => clearInterval(t)
        
    }, [monitorJobsEnabled, query, selectedAuditJobType])
    
    useEffect(() => {
        
        Object.keys(groupedAudits).forEach(groupKey => {
            if (!Object.prototype.hasOwnProperty.call(refGroupedAuditColors.current, groupKey))
                refGroupedAuditColors.current[groupKey] = randomColor({
                    // hue: 'random',
                    // luminosity: 'bright', // dark
                    // alpha: 0.5,
                })
        })
        
    }, [groupedAudits, refGroupedAuditColors])
    
    return (<>
        
        <header className="flex items-baseline justify-end mb-3 AuditsTableHeader content-baseline">
            
            <div className="flex">
                <Dropdown
                    menuButtonClassName="btn btn-link"
                    menuClassName="mt-12 right-3"
                    menuItemClassName="text-left p-2 pr-4"
                    label={
                        <div className="flex items-center content-center px-2">
                            <div>{selectedAuditJobType}</div>
                            <div className="pl-3 ml-2 border-l border-base-300">
                                <FaAngleDown className="text-lg" />
                            </div>
                        </div>
                    }
                    items={auditJobTypes}
                    withCheck={false}
                    renderItem={it => (
                        <div className="flex items-center content-center !justify-start menu-item-inner">
                            <div className="ml-3 menu-item-label">
                                {it}
                            </div>
                        </div>
                    )}
                    onChange={item => setSelectedAuditJobType(item)} />
            </div>
            
            <div className="">
                <input
                    type="text"
                    autoComplete="off"
                    value={query}
                    placeholder="Search audits..."
                    onKeyUp={e => e.key === 'Escape' && setQuery('')}
                    onChange={e => setQuery(e.target.value?.toLowerCase() ?? '')} />
            </div>
        
        </header>
        
        <table className={cn('AuditsTable table-auto table-sticky-headers', className)}>
            
            <thead>
                <tr>
                    <th>Created By</th>
                    <th>Created On</th>
                    <th>Job Type</th>
                    <th>Object ID</th>
                    <th>Job Status</th>
                    <th>Trigger Source</th>
                    <th>Job Error</th>
                    <th>Job Payload</th>
                    <th>
                        {/* Actions */}
                        <div className="flex !justify-center">
                            <MdBolt className="text-lg" />
                        </div>
                    </th>
                </tr>
            </thead>
            
            <tbody>
                
                {filteredAudits?.length < 1 && (
                    <tr>
                        <td className="text-center" colSpan="8">
                            <p>No audits currently available.</p>
                        </td>
                    </tr>
                )}
                
                {Object.keys(groupedAudits)?.map(groupKey => (
                    
                    sortBy(groupedAudits[groupKey], 'createdAt').map(it => {
                        
                        const groupColor = refGroupedAuditColors.current?.[groupKey] ?? 'transparent'
                        const ToggleIcon = expandedPayloads[it.id] ? BiCollapse : BiExpand
                        
                        return (
                            
                            <AuditsTableRow
                                key={`AuditsTable-job-${it.id}`}
                                audit={it}
                                groupColor={groupColor}
                                expandedPayloads={expandedPayloads}
                                setExpandedPayloads={setExpandedPayloads}
                                ToggleIcon={ToggleIcon} />
                            
                        )
                        
                    })
                    
                ))}
            
            </tbody>
        
        </table>
    
    </>)
    
}

export default AuditsTable
