import { useEffect } from 'react'
import useAnnotationLabelsViewModel from './AnnotationLabelsViewModel'

import AnnotationLabelsNav from './AnnotationLabelsNav'
import AnnotationLabelsTable from './AnnotationLabelsTable'

import './AnnotationLabels.css'

const AnnotationLabels = () => {
    
    const viewModel = useAnnotationLabelsViewModel()
    
    useEffect(() => {
        
        if (viewModel.currentTab)
            viewModel.fetchLabels(viewModel.currentTab)
        else
            viewModel.fetchAllLabels()
        
    }, [viewModel.currentTab, viewModel.labels])
    
    if (viewModel.error) return (
        <div className="Labels">
            <div className="text-center absolute-centered">
                Error: {viewModel.error}
            </div>
        </div>
    )
    
    return (
        
        <div className="AnnotationLabels">
            
            <div className="AnnotationLabels-content">
                
                {viewModel.loading && (
                    <div className="Labels">
                        <div className="text-center absolute-centered">
                            Loading labels...
                        </div>
                    </div>
                )}
                
                {!viewModel.loading && (<>
                    
                    <header className="flex items-baseline content-baseline justify-between">
                        
                        <div className="flex flex-col">
                            <h4 className="text-xl text-dashboard-primary my-0">
                                Annotation Labels ({viewModel.filteredLabels?.length ?? 0})
                            </h4>
                        </div>
                        
                        <AnnotationLabelsNav
                            currentTab={viewModel.currentTab}
                            setCurrentTab={viewModel.setCurrentTab} />
                        
                        <div className="">
                            <input
                                type="text"
                                autoComplete="off"
                                value={viewModel.query}
                                placeholder="Filter labels..."
                                onKeyUp={e => e.key === 'Escape' && viewModel.setQuery('')}
                                onChange={e => viewModel.setQuery(e.target.value?.toLowerCase() ?? '')} />
                        </div>
                    
                    </header>
                    
                    <div className="mt-2 labels-list">
                        <AnnotationLabelsTable
                            labelsType={viewModel.currentTab}
                            labels={viewModel.filteredLabels} />
                    </div>
                
                </>)}
            
            </div>
        
        </div>
        
    )
    
}

export default AnnotationLabels
