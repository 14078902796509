import { useState, useEffect } from 'react'
import * as actions from '$actions'
import { snippet } from '@utils'
import useAbortableFetch from '@hook/useAbortableFetch'
import {
    ES_ENGINE_DOCUMENTS,
    ES_ENGINE_INDEX_DOCUMENTS,
} from '@constants/elasticSearch'
import deepmerge from 'deepmerge'
import { toast } from 'react-toastify'
import cn from 'classnames'

const indexesToSearch = [
    ES_ENGINE_DOCUMENTS,
    ES_ENGINE_INDEX_DOCUMENTS,
]

const ESDemoSearch = ({
    className,
    wrapClassName,
}) => {
    
    const [query, setQuery] = useState('')
    const [sampleQuery, setSampleQuery] = useState('')
    const [autoSearch, setAutoSearch] = useState(false)
    const [results, setResults] = useState([])
    
    useAbortableFetch(() => {
        
        if (!query?.length) {
            setResults([])
            return
        }
        
        Promise.all(indexesToSearch.map(it => actions.fetchElasticSearchSample(it, query)))
            .then(it => deepmerge(...it))
            .then(it => console.log('es sample results', it) || setResults(it))
            .catch(e => {
                console.error(e)
                toast.error('Failed to sample search ES docs')
            })
        
    }, [query])
    
    useEffect(() => {
        
        // Cache the last query value
        if (query?.length > 0)
            setSampleQuery(query)
        
    }, [query])
    
    useEffect(() => {
        
        let t = null
        
        if (!autoSearch) {
            clearInterval(t)
            return
        }
        
        t = setInterval(() => {
            setQuery(prev => prev === sampleQuery ? '' : sampleQuery)
        }, 2000)
        
        return () => clearInterval(t)
        
    }, [sampleQuery, autoSearch])
    
    return (
        
        <div className={cn('ESDemoSearch-wrap', wrapClassName)}>
            
            <header>
                <div className="flex items-center content-center gap-3">
                    <input
                        className="w-full shrink"
                        type="text"
                        autoComplete="off"
                        value={query}
                        placeholder="Sample document query..."
                        onKeyUp={e => e.key === 'Escape' && setQuery('')}
                        onChange={e => setQuery(e.target.value?.toLowerCase() ?? '')} />
                    <label className="flex items-center content-center gap-2 m-0 shrink-0">
                        <input
                            className=""
                            type="checkbox"
                            value={autoSearch}
                            onChange={e => setAutoSearch(e.target.checked)} />
                        <span>Auto-search</span>
                    </label>
                </div>
            </header>
            
            <table className={cn('ESDemoSearch table-pin-rows', className)}>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {results?.hits?.hits?.map(it => (
                        <tr key={it._source.id}>
                            <td>{snippet(it._source.id)}</td>
                            <td>{it._source.name}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            
            {/* <div><pre><code className="text-xs text-white">{JSON.stringify(results, null, 4)}</code></pre></div> */}
        
        </div>
        
    )
    
}

export default ESDemoSearch
