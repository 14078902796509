import Logger from '@utils/log'
import ParseAPI from '@api/ParseAPI'

const log = new Logger('actions/email')

export const sendConfirmationEmail = async (email, to) => {
    
    try {
        
        await ParseAPI.post('emails/', {
            email: email,
            to: to,
        })
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const sendResetPasswordEmail = async email => {
    
    return await ParseAPI.post('emails/resetpassword', {
        email: email,
    })
    
}
