import { createWire, createSelector } from '@forminator/react-wire'
import { baseUrl } from '@api/ParseAPI'
import * as Ably from 'ably'
import * as userStore from '@store/user'

const instance = {
    jwt: null,
    token: null,
    ably: null,
}

/** @type {ReadOnlyWire<Ably.Realtime>} */
export const ably = createSelector({
    get: ({ get }) => {
        
        const user = get(userStore.user)
        
        // User should have an Ably prop with a token
        if (!user?.ably) return null
        
        const jwt = localStorage.getItem('jwt')
        const tokenDetails = user.ably.token
        
        if (!tokenDetails?.token) return null
        
        // Ably should already be initialized
        if (instance.jwt === jwt && instance.ably &&
            instance.token === tokenDetails.token)
            return instance.ably
        
        /** @type {ClientOptions} */
        const options = {
            token: tokenDetails.token,
            clientId: tokenDetails.clientId,
            authUrl: `${baseUrl}/realtime/auth`,
            authMethod: 'POST',
            authHeaders: {
                // Authorization: `Bearer ${user.token}`,
                'x-api-key': jwt,
                'x-ably-token-only': true,
            },
            // If true, the library will query the Ably servers for the current
            // time when issuing TokenRequests instead of relying on a locally-available time of day.
            // Since our local development servers often can have varying time, this should be enabled locally
            queryTime: process.env.NODE_ENV !== 'production',
        }
        
        instance.ably = new Ably.Realtime(options)
        
        if (process.env.NODE_ENV === 'development')
            console.log('store/realtime: ably', instance.ably)
        
        return instance.ably
        
    },
})

/** @type {Wire<RealtimeConnectionState>} */
export const realtimeConnectionState = createWire({
    state: null,
    isConnected: false,
})

export const realtimeUserPresenceCount = createWire(0)
export const realtimeOrganizationPresenceCount = createWire(0)
