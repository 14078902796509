import api from '$lib/api'

export const getExistingMappings = async () => {
    
    const res = await api.get('/checkMappings')
    
    return res.data
    
}

export const saveMappings = async mappings => {
    const res = await api.post('/checkMappings', { mappings })
    
    return res.data
}

