import { memo } from 'react'
import { useWireValue } from '@forminator/react-wire'
import { remoteConfig as storeRemoteConfig } from '@store'
import { FaExclamationTriangle } from 'react-icons/fa'
import './AppMaintenanceNotification.css'

const AppMaintenanceNotification = () => {
    
    const remoteConfig = useWireValue(storeRemoteConfig, {})
    
    // @todo @featureflag
    if (!remoteConfig?.appMaintenanceNotificationEnabled)
        return null
    
    if (remoteConfig?.isDeployingApi !== true)
        return null
    
    return (
        
        <div
            id="AppMaintenanceNotification"
            className="alert alert-warning"
            role="alert">
            
            <div className="flex items-center gap-3">
                <FaExclamationTriangle className="text-base"/>
                <p>
                    App is currently under maintenance.
                    {' '}
                    <i>You may want to postpone any sensitive work you have in progress.</i>
                </p>
            </div>
        
        </div>
        
    )
    
}

export default memo(AppMaintenanceNotification)
