import { useCallback } from 'react'
import { useWireState } from '@forminator/react-wire'
import * as store from '$store'
import * as actions from '$actions'

import Modal from '$components/Modal'

const DeleteAnnotatorModal = ({
    className,
    annotator,
    ...props
}) => {
    
    const [modalDeleteAnnotatorOpen, setModalDeleteAnnotatorOpen] = useWireState(store.modalDeleteAnnotatorOpen)
    
    const onDeleteClick = useCallback(async () => {
        
        try {
            
            const res = await actions.deleteAnnotator(annotator.id)
            
            await actions.getAnnotators()
            
            console.log('onDeleteClick status', res.status)
            
        } catch (e) {
            
            // @todo show error
            console.error('onDeleteClick', e)
            
        }
        
    }, [annotator])
    
    if (!annotator) return null
    
    return (
        
        <Modal
            className={className}
            open={modalDeleteAnnotatorOpen}
            setOpen={setModalDeleteAnnotatorOpen}
            title="Delete Annotator"
            actionClass="bg-red-700/25 hover:bg-red-700/50"
            actionLabel="DELETE"
            actionOnClick={onDeleteClick}
            {...props}>
            
            <p>Are you sure you want to delete the annotator {annotator.firstName} {annotator.lastName}?</p>
        
        </Modal>
        
    )
    
}

export default DeleteAnnotatorModal
