import config from '@/config'
import {
    ApolloClient,
    HttpLink,
    ApolloLink,
    from,
    split,
} from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { onError } from '@apollo/client/link/error'
import { Hermes } from 'apollo-cache-hermes'
import * as store from '@store'

const defaultHeaders = {
    'Content-Type': 'application/json',
}

const privateHeaders = {
    ...defaultHeaders,
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.error(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
        )
    if (networkError) console.log(`[Network error]: ${networkError}`)
})

const logoutLink = onError(({ networkError }) => {
    if (networkError?.statusCode === 401) {
        localStorage.removeItem('jwt')
        localStorage.removeItem('userId')
        localStorage.removeItem('userEmail')
    }
})

const authLink = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem('jwt')
    
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            
            // authorization: token ? `Bearer ${token}` : null,
            'x-api-key': token ?? null,
            'X-Organization-Id': store.currentOrganization?.getValue()?.id,
        },
    }))
    return forward(operation)
})

const createHttpLink = (uri, headers) => new HttpLink({
    uri,
    headers,
})

const createWsLink = (url, headers) => new GraphQLWsLink(
    createClient({
        url: url.replace('http', 'ws'),
        shouldRetry: errOrCloseEvent => {
            console.warn('GraphQLWsLink shouldRetry', { errOrCloseEvent })
            return true
        },
        connectionParams: () => {
            const token = localStorage.getItem('jwt')
            
            return {
                headers: {
                    ...headers,
                    authorization: token ? `Bearer ${token}` : null,
                },
            }
        },
    }),
)

// https://www.apollographql.com/docs/react/api/core/ApolloClient/#connecttodevtools
const createInstance = (uri, headers, withAuth = false) => {
    
    const cache = new Hermes({
        addTypename: true,
        freeze: false,
    })
    
    const wsLink = createWsLink(uri, headers)
    const httpLink = createHttpLink(uri, headers)
    
    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query)
            
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            )
        },
        wsLink,
        httpLink,
    )
    
    const link = from([
        errorLink,
        withAuth ? authLink : null,
        logoutLink,
        splitLink,
    ].filter(Boolean))
    
    return new ApolloClient({
        name: 'parse-web',
        uri,
        cache,
        link,
        connectToDevTools: (
            config.apollo.devTools.enabled &&
            process.env.NODE_ENV !== 'production'
        ),
        queryDeduplication: true,
        assumeImmutableResults: true,
        defaultOptions: {
            query: {
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            },
            watchQuery: {
                fetchPolicy: 'cache-and-network',
                errorPolicy: 'ignore',
            },
            mutate: {
                errorPolicy: 'all',
            },
        },
    })
    
}

const createGqlUrl = endpoint =>
    `${process.env.PARSE_API_BASE_URL}/${endpoint}`

// export const publicClient = createInstance(publicHeaders)
// export const privateClient = createInstance(privateHeaders, true)

export const parsePrivateClient = createInstance(
    createGqlUrl(process.env.PARSE_GQL_ENDPOINT),
    privateHeaders,
    true,
)

export const pgbossPrivateClient = createInstance(
    createGqlUrl(process.env.PARSE_PGBOSS_GQL_ENDPOINT),
    privateHeaders,
    true,
)
