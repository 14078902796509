
export default [
    {
        name: 'app.parseai.co',
        description: 'Production website',
        url: 'https://app.parseai.co',
        before: <span className="opacity-40">PRODUCTION</span>,
    },
    {
        name: 'staging.parseai.co',
        description: 'Stable, QA-ready',
        url: 'https://staging.parseai.co',
        before: <span className="opacity-40 ">STAGING</span>,
    },
    {
        name: 'develop.parseai.co',
        description: 'Latest, bleeding-edge',
        url: 'https://develop.parseai.co',
        before: <span className="opacity-40 ">DEVELOPMENT</span>,
    },
]
