import { ANNOTATION_LABEL_TYPES_NAV_MAP } from '@constants/annotations'
import cn from 'classnames'

import { Link } from 'react-router-dom'

const AnnotationLabelsNav = ({
    className,
    currentTab,
    setCurrentTab,
}) => {
    
    const onLinkClick = (e, annotationLabelType) => {
        e.preventDefault()
        setCurrentTab(annotationLabelType)
    }
    
    return (
        
        <nav className={cn('AnnotationLabelsNav', className)}>
            
            {Object.keys(ANNOTATION_LABEL_TYPES_NAV_MAP).map(it => (
                <Link
                    key={ANNOTATION_LABEL_TYPES_NAV_MAP[it] || 'annotation-label-type-all'}
                    className={cn('link', { active: currentTab === ANNOTATION_LABEL_TYPES_NAV_MAP[it] })}
                    to=""
                    onClick={e => onLinkClick(e, ANNOTATION_LABEL_TYPES_NAV_MAP[it])}>
                    {it}
                </Link>
            ))}
        
        </nav>
        
    )
    
}

export default AnnotationLabelsNav
