import { useState, useMemo, useCallback } from 'react'
import { useWireValue } from '@forminator/react-wire'
import * as store from '@store'
import * as actions from '@actions'
import { ANNOTATION_LABEL_TYPES_NAV_MAP } from '@constants/annotations'

const useAnnotationLabelsViewModel = () => {
    
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const [currentTab, setCurrentTab] = useState(Object.values(ANNOTATION_LABEL_TYPES_NAV_MAP)[0])
    const [query, setQuery] = useState('')
    
    const labels = useWireValue(store.labels)
    
    // Labels grouped by type (all, documents, checks, etc.)
    const groupedLabels = useMemo(() => (
        !currentTab
            ? labels
            : labels.filter(it => it.type === currentTab)
    ), [currentTab, labels])
    
    // Labels filtered (if a search query was entered)
    const filteredLabels = useMemo(() => {
        
        if (!query?.length) return groupedLabels
        
        return groupedLabels.filter(it => {
            
            const fields = [
                it.id,
                it.label,
            ]
            
            return fields.some(field => field.toLowerCase().includes(query))
            
        })
        
    }, [query, groupedLabels])
    
    const fetchLabels = useCallback(async (recordType, opts = {}) => {
        
        try {
            setLoading(true)
            await actions.fetchLabels(recordType, opts)
        } catch (e) {
            setError(e?.message ?? 'Unknown error')
        }
        
        setLoading(false)
        
    }, [])
    
    const fetchAllLabels = async () => {
        
        try {
            setLoading(true)
            await actions.fetchAllLabels()
        } catch (e) {
            setError(e?.message ?? 'Unknown error')
        }
        
        setLoading(false)
        
    }
    
    return {
        
        error,
        setError,
        loading,
        setLoading,
        currentTab,
        setCurrentTab,
        query,
        setQuery,
        
        filteredLabels,
        
        fetchLabels,
        fetchAllLabels,
        
    }
    
}

export default useAnnotationLabelsViewModel
