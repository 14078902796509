
export const ES_ENGINE_USERS = 'users'
export const ES_ENGINE_COLLECTIONS = 'collections'
export const ES_ENGINE_DOCUMENTS = 'documents'
export const ES_ENGINE_INDEX_DOCUMENTS = 'index-documents'

export const ES_ENGINES = [
    ES_ENGINE_USERS,
    ES_ENGINE_COLLECTIONS,
    ES_ENGINE_DOCUMENTS,
    ES_ENGINE_INDEX_DOCUMENTS,
]

export const ES_INDEX_STRATEGY_INNER = 'es-index-strategy-inner'
export const ES_INDEX_STRATEGY_NESTED = 'es-index-strategy-nested'
export const ES_INDEX_STRATEGY_PARENT_CHILD = 'es-index-strategy-parent-child'
export const ES_INDEX_STRATEGY_DENORMALIZED = 'es-index-strategy-denormalized'

export const ES_INDEX_STRATEGIES = [
    ES_INDEX_STRATEGY_INNER,
    ES_INDEX_STRATEGY_NESTED,
    ES_INDEX_STRATEGY_PARENT_CHILD,
    ES_INDEX_STRATEGY_DENORMALIZED,
]

export const ES_INDEX_SUFFIXES_BY_STRATEGY = {
    [ES_INDEX_STRATEGY_INNER]: '-inner',
    [ES_INDEX_STRATEGY_NESTED]: '-nested',
    [ES_INDEX_STRATEGY_PARENT_CHILD]: '-parent-child',
    [ES_INDEX_STRATEGY_DENORMALIZED]: '',
}

export const ES_INDEX_STATUS_RUNNING = 'running'    // ES index job is running
export const ES_INDEX_STATUS_FINISHED = 'finished'  // ES index job finished successfully

export const ES_INDEX_STATUS_FAILED = 'failed'      // ES index job failed

export const ES_INDEX_STATUSES = [
    ES_INDEX_STATUS_RUNNING,
    ES_INDEX_STATUS_FINISHED,
    ES_INDEX_STATUS_FAILED,
]
