import ParseAPI from '@api/ParseAPI'
import { createQueryString } from '@utils'

export const createStripeSetupIntent = async () => {
    
    const res = await ParseAPI.post('/stripe/create-setup-intent')
    
    return res.data
    
}

/**
 *
 * @param {StripeBalancePurchaseAccountType} accountType
 * @param {number} amount
 * @param {string} currency
 * @returns {Promise<any>}
 */
export const createStripePaymentIntent = async (accountType, amount, currency) => {
    
    const res = await ParseAPI.post('/stripe/create-payment-intent', {
        accountType,
        amount,
        currency,
    })
    
    return res.data
    
}

// @todo docs, filterMode = mine | current-org | my-orgs (default)
export const fetchOrgPurchases = async (optionalOrgId = null, filterMode) => {
    
    const query = createQueryString({
        filterMode,    
    })
    
    const endpoint = optionalOrgId ? `orgs/${optionalOrgId}/activity` : 'orgs/activity'
    
    const res = await ParseAPI.get(`${endpoint}${query}`)
    
    return res.data
    
}
