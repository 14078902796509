
export const PROD_TYPES = [
    'Combination of Products',
    'Oil of Undetermined Origin',
    'Oil Well Oil',
    'Condensate',
    'Plant Condensate',
    'Gas of Undetermined Origin',
    'Oil Well Gas',
    'Gas Well Gas',
    'Irrigation Gas',
    'LPG With No Determined Breakdown',
    'Methane',
    'Ethane',
    'Propane',
    'Butane',
    'Isobutane',
    'Gasoline',
    'Misc. Non-Hydrocarbon Products',
    'Sulphur',
    'Salt Water',
    'Carbon Dioxide',
    'Helium',
    'Old Ocean Settlement',
    'Undefined Product',
]

export const INTEREST_TYPES_PROD = [
    'Blanchard',
    'Miscellaneous',
    'Net Profit Payment',
    'Overriding',
    'Production Purchases',
    'Royalty',
    'Special Overriding',
    'Tax',
    'Working',
    'Buy/Purchase',
    'Interest Payment',
]

export const INTEREST_TYPES_DEDUCT = [
    'Accounting Adjustments',
    'Ad Valorem Taxes -Oil & Gas',
    'Backup Withholding Tax',
    'Compression',
    'Conservation Tax',
    'Deficiency and Pre-Paid Gas',
    'Dehydration Reimbursements for Gas Purchases',
    'Dehydration',
    'Federal Income Taxes -Oil & Gas',
    'Federal NRA Tax',
    'Gas Royalty Deduction for Compression, Sweetening, Dehydration and/or Transportation',
    'Gathering',
    'General Adjustments',
    'Interest Payment',
    'Joint Venture Charges',
    'Manual Accounting Entry to Prevent Over/Under Payment',
    'Marketing',
    'Mining Rights Taxes -Oil & Gas',
    'Net Proceeds',
    'New Mexico SB 0621',
    'Normal Sale of Product',
    'Oklahoma SB 1048',
    'Override',
    'Overriding Royalty',
    'Petroleum and Gas Fund -Oil & Gas',
    'Purchased Gas Resold',
    'Reimbursement to Working Interest for Gas Royalty Deduction',
    'Royalty % and Value',
    'Royalty Paid for Co-Owners -Oil & Gas',
    'Royalty',
    'School Tax',
    'Settled MMBTU Volume',
    'Severance Tax',
    'State Income Taxes -Oil & Gas',
    'State NRA (Non-Resident Alien) Tax',
    'State Withholding Tax',
    'Subsidence Abatement Fund -Oil & Gas',
    'Supplemental',
    'Tax Credit allowed on Payment of Production Taxes -Gas',
    'Tax Reimbursement -Oil & Gas',
    'Taxes Paid for Co-Owners -Oil & Gas',
    'Transportation',
    'Unspecified Deduction or Reimbursement',
    'Processing and/or Sweetening',
]

export const VALUES_BY_TYPE = {
    productionType: PROD_TYPES,
    interestTypeProd: INTEREST_TYPES_PROD,
    interestTypeDeduct: INTEREST_TYPES_DEDUCT,
}
