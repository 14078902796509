import { useState, useEffect, useCallback } from 'react'
import * as actions from '$actions'
import { toast } from 'react-toastify'

import Modal from '$components/Modal'
import AnnotatorSelector from '$components/AnnotatorSelector'

const AssignOrganizationChecksModal = ({
    className,
    open,
    setOpen,
    organization,
    ...props
}) => {
    
    const [checksCount, setChecksCount] = useState(0)
    const [selectedAnnotatorIds, setSelectedAnnotatorIds] = useState([])
    
    const onAssignClick = useCallback(async () => {
        
        if (!selectedAnnotatorIds.length || !organization) return
        
        try {
            
            await actions.assignOrganizationChecksToAnnotators(
                selectedAnnotatorIds,
                organization.id,
            )
            
            await actions.getAnnotators()
            
            toast.success(`Assigned all checks in ${organization.name}`)
            
        } catch (e) {
            
            // @todo show error
            console.error('onAssignClick', e)
            toast.error(`Failed to assign checks from ${organization.name}`)
            
        }
        
    }, [selectedAnnotatorIds, organization])
    
    useEffect(() => {
        
        if (!organization) return
        
        actions.getChecksCount(organization.id)
            .then(it => setChecksCount(it))
            .catch(e => {
                console.error('AssignOrganizationChecksModal', e)
                toast.error('Failed to fetch checks count for organization')
            })
        
    }, [organization])
    
    if (!organization) return null
    
    return (
        
        <Modal
            className={className}
            open={open}
            setOpen={setOpen}
            title="Assign Organization Checks"
            actionLabel="ASSIGN CHECKS"
            actionOnClick={onAssignClick}
            {...props}>
            
            <div className="">
                Assign organization <b>{organization.name}</b> ({checksCount} checks) to annotator:
            </div>
            
            <AnnotatorSelector
                selectedAnnotatorIds={selectedAnnotatorIds}
                setSelectedAnnotatorIds={setSelectedAnnotatorIds} />
        
        </Modal>
        
    )
    
}

export default AssignOrganizationChecksModal
