import Logger from '@utils/log'
import * as store from '@store'
import ParseAPI from '@api/ParseAPI'
import { omit, createQueryString } from '@utils'
import { listUniqueParties } from './uniqueParties'
import {
    definePartyFraction,
    insertNewPartyConveyance,
    updatePartyData,
} from '@/components/tract/TitleBreakdown/ConveyanceUtils'
import { TITLE_BREAKDOWN_PROCESSING } from '@/constants'

const log = new Logger('actions/runsheets')

export const fetchTitleBreakdown = async (runsheetId, runsheetTabId) => {
    const paramsObj = {
        runsheetTabId,
    }
    
    const params = createQueryString(paramsObj)
    const res = await ParseAPI.get(`titleBreakdown/${runsheetId}${params}`)
    
    const titleBreakdown = res.data || {}
    
    await definePartyFraction(titleBreakdown?.documents || [])
    return titleBreakdown
}

export const upsertConveyance = async ({ documentId, party, type, conveyance }) => {
    const titleBreakdown = store.titleBreakdown.getValue()
    const documents = titleBreakdown.documents
    const partyId = party.id
    const { partyType, newConveyance } = conveyance
    
    delete conveyance.partyType
    delete conveyance.newConveyance
    
    const docIdx = documents.findIndex(tb => tb.documentId === documentId)
    const doc = { ...documents[docIdx] }
    
    documents.splice(docIdx, 1, doc)
    
    if (newConveyance) {
        insertNewPartyConveyance(doc, partyType, omit(party, 'conveyances'), type, conveyance)
    } else {
        updatePartyData(doc.value, 'grantors', partyId, type, conveyance)
        updatePartyData(doc.value, 'grantees', partyId, type, conveyance)
        updatePartyData(doc.value, 'grantorGrantee', partyId, type, conveyance)
    }
    
    const documentsCopy = [...documents]
    
    await Promise.all([
        ParseAPI.post(`titleBreakdown/${titleBreakdown.id}/document/${documentId}`, {
            partyId,
            type,
            partyType,
            conveyance,
        }),
        definePartyFraction(documentsCopy),
    ])
    
    store.titleBreakdown.setValue({ ...titleBreakdown, documents: documentsCopy })
}

export const processTitleBreakdown = async (runsheetId, numDocuments, runsheetTabId, fundingSource) => {
    const paramsObj = {
        numDocuments,
        runsheetTabId,
        fundingSource,
    }
    
    const params = createQueryString(paramsObj)
    
    const res = await ParseAPI.post(`titleBreakdown/process/${runsheetId}${params}`)
    
    store.titleBreakdown.setValue(res.data)
}

export const retryTitleChain = async (runsheetId, runsheetTabId) => {
    const paramsObj = {
        runsheetTabId,
    }
    
    const params = createQueryString(paramsObj)
    const res = await ParseAPI.post(`titleBreakdown/retry/${runsheetId}${params}`)
    
    store.titleBreakdown.setValue(res.data)
}

export const checkTitleBreakdownStatus = async (runsheetId, runsheetTabId, setHasRecentFailure) => {
    const paramsObj = {
        runsheetTabId,
    }
    
    const params = createQueryString(paramsObj)
    const res = await ParseAPI.get(`titleBreakdown/status/${runsheetId}${params}`)
    
    const titleBreakdown = res.data
    
    if (titleBreakdown && (!store.titleBreakdown.getValue()
        || !TITLE_BREAKDOWN_PROCESSING.includes(titleBreakdown?.status))) {
        const title = await fetchTitleBreakdown(runsheetId, runsheetTabId)
        
        store.titleBreakdown.setValue(title)
        setHasRecentFailure?.(title.hasRecentFailure)
        await listUniqueParties(runsheetId)
    }
}

export const exportTitleChain = async (runsheetId, runsheetTabId) => {
    const paramsObj = {
        runsheetTabId,
    }
    
    const params = createQueryString(paramsObj)
    
    try {
        
        return await ParseAPI.get(`titleBreakdown/export/${runsheetId}${params}`, {
            responseType: 'blob',
        })
        
    } catch (error) {
        
        log.e(error)
        
    }
}