import { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { MdOutlineAddBox } from 'react-icons/md'
import { useWireValue } from '@forminator/react-wire'
import cn from 'classnames'
import * as store from '$store'
import * as actions from '$actions'
import { ST_VAL_EXT_KEY, SYSTEM_TYPES } from '@/constants/exampleManager'
import { DOCUMENT_TYPES } from '@/constants'

const ExampleManagerTabs = ({ setIncludeSubTabModal }) => {
    
    const [searchParams, setSearchParams] = useSearchParams({ system: ST_VAL_EXT_KEY })
    const systemTemplates = useWireValue(store.systemTemplates)
    
    const system = useMemo(() => searchParams.get('system') || ST_VAL_EXT_KEY, [searchParams.get('system')])
    const templateId = useMemo(() => searchParams.get('templateId'), [searchParams.get('templateId')])
    
    const currentTemplates = useMemo(() =>
        systemTemplates.filter(st => st.system === system).sort((a, b) => a.name.localeCompare(b.name)),
    [systemTemplates, system])
    
    const selectedTemplate = useMemo(() =>
        currentTemplates.find(ct => ct.id === templateId)
    , [currentTemplates, templateId])
    
    const saveTemplateDocumentType = documentType =>
        actions.editTemplate(templateId, { documentType })
    
    useEffect(() => {
        
        if (!currentTemplates?.[0]) return
        
        setSearchParams(prev => {
            // if its already a valid template, then i dont need to reset it
            if (currentTemplates.some(ct => ct.id === prev.get('templateId')))
                return prev
            
            prev.set('templateId', currentTemplates[0].id)
            return prev
        })
        
    }, [system, currentTemplates])
    
    return (
        <div className="flex flex-col">
            <div role="tablist" className="tabs tabs-boxed mt-4 shadow-md w-fit justify-start">
                {SYSTEM_TYPES.map(tab =>
                    <a
                        key={tab.key}
                        role="tab"
                        className={cn('tab whitespace-nowrap', { 'tab-active': system === tab.key })}
                        onClick={() => {
                            if (tab.key === system) return
                            
                            setSearchParams(prev => {
                                prev.set('system', tab.key)
                                prev.delete('templateId')
                                return prev
                            })
                        }}>
                        {tab.value}
                    </a>,
                )}
            </div>
            <div className="flex flex-row gap-2 mt-4">
                <div className="join">
                    {setIncludeSubTabModal && <a className="btn btn-secondary join-item cursor-pointer flex items-center
                                    px-2 tooltip tooltip-bottom-right"
                    data-tip="Add a new Template"
                    onClick={() => setIncludeSubTabModal(true)}>
                        <MdOutlineAddBox className="text-xl" />
                    </a>}
                    <select
                        className="select select-sm select-bordered join-item"
                        value={templateId || ''}
                        onChange={ev =>
                            setSearchParams(prev => {
                                prev.set('templateId', ev.target.value)
                                return prev
                            })}>
                        {currentTemplates.map(tmpl =>
                            <option
                                key={tmpl.id}
                                value={tmpl.id}>
                                {tmpl.name}
                            </option>,
                        )}
                    </select>
                    {selectedTemplate &&
                    <select
                        value={selectedTemplate.documentType || ''}
                        onChange={ev => saveTemplateDocumentType(ev.target.value)}
                        className="select select-sm select-bordered join-item">
                        <option value=""></option>
                        {DOCUMENT_TYPES.map(dt =>
                            <option
                                value={dt.id}
                                key={dt.id}>
                                {dt.label}
                            </option>,
                        )}
                    </select>}
                </div>
            </div>
        </div>
    )
}

export default ExampleManagerTabs