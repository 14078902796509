import Logger from '@utils/log'
import { useState, useCallback, useEffect, useRef } from 'react'
import { useWire } from '@forminator/react-wire'
import * as store from '@store'

const log = new Logger('useRenderPdfScrollable', { enabled: false })

/**
 * Custom React hook for rendering a PDF onto a canvas.
 *
 * Warning: if this causes an infinite loop, make sure the `onError`
 * method is a memoized function (`useCallback`, etc. should work)
 * 
 * The general flow for this hook is:
 * 1. Initialize the PDF rendering library (global worker, etc.)
 * 2. Load the PDF file (async, using the PDF library)
 * 3. Render the PDF in the custom viewer for scrolling
 *
 * #gpt
 *
 * @function
 * @param {Object} options - The options for rendering the PDF
 * @param {string} options.containerEl - The container element to load the pdf viewer
 * @param {string} options.url - The URL of the PDF to render
 * @returns {Object} pdf - The pdf object
 * @returns {Object} viewer - The PDFViewer instance
 * @returns {Object} eventBus - The event emitter
 */
export const useRenderPdfScrollable = ({
    containerEl,
    url,
} = {}) => {
    
    //region State
    
    const [isInit, setIsInit] = useState(false)
    const [pdf, setPdf] = useState(null)
    const viewer = useRef()
    
    const loadingDoc = useWire(store.pdfRendererLoadingDoc)
    
    //endregion State
    
    //region Methods
    
    /**
     * Loads a PDF document by URL
     * @param {String} url
     * @returns {Promise<void>}
     */
    const loadDocument = useCallback(async url => {
        
        log.d('loadDocument')
        loadingDoc.setValue(true)
        
        try {
            
            const { pdfjsLib } = globalThis
            
            // @todo not sure how to import the types for pdfJsLib
            // noinspection JSCheckFunctionSignatures
            const loadingTask = pdfjsLib.getDocument({ url })
            const loadedPdf = await loadingTask.promise
            
            log.d('loaded pdf,', loadedPdf.numPages, 'pages')
            setPdf(loadedPdf)
            
        } catch (e) {
            
            log.e('failed', e)
            
        }
        
        loadingDoc.setValue(false)
        
    }, [loadingDoc])
    
    //endregion Methods
    
    /**
     * Initialize PDF library hook
     */
    useEffect(() => {
        
        // const { pdfjsLib } = globalThis
        // pdfjsLib.GlobalWorkerOptions.workerSrc = 'pdfjs-dist/build/pdf.worker.js'
        
        setIsInit(true)
        
        return () => {
            log.d('hook initialize cleanup')
            setPdf(null)
        }
        
    }, [setIsInit])
    
    /**
     * Load PDF document hook
     */
    useEffect(() => {
        
        if (!isInit) return
        log.d('hook load pdf')
        
        // noinspection JSIgnoredPromiseFromCall
        loadDocument(url)
        
    }, [isInit, loadDocument, url])
    
    /**
     * Render PDF hook
     */
    useEffect(() => {
        
        if (!pdf) return
        log.d('hook render pdf')
        
        viewer.current.setDocument(pdf)
        
    }, [pdf])
    
    useEffect(() => {
        if (!containerEl || !!viewer.current) return
        
        const { pdfjsViewer } = globalThis
        
        viewer.current = new pdfjsViewer.PDFViewer({
            annotationMode: 0,
            textLayerMode: 0,
            container: containerEl,
            viewer: containerEl.querySelector('.viewer'),
            eventBus: new pdfjsViewer.EventBus(),
        })
        
    }, [containerEl])
    
    return {
        pdf,
        viewer: viewer.current,
        eventBus: viewer.current?.eventBus,
    }
    
}
