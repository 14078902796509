// eslint-disable-next-line
import { createWire, createSelector } from '@forminator/react-wire'
// eslint-disable-next-line
import { createPersistedWire } from 'react-wire-persisted'
// eslint-disable-next-line
import { keys } from '$constants'

export const examples = createWire([])

export const systemTemplates = createWire([])

export const presets = createWire([])

export const presetDocuments = createWire([])