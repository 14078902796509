
export const CHECK_STATUS_PROCESSING_IMAGES = 'processing_images'   // Check processing (document to images)
export const CHECK_STATUS_SAVED = 'saved'                           // Check images created, ready to process
export const CHECK_STATUS_PROCESSING_OCR = 'processing_ocr'         // Check processing (images to OCR text)
export const CHECK_STATUS_PROCESSED = 'processed'                   // Check processed (PDF split into images)
export const CHECK_STATUS_READY_FOR_REVIEW = 'ready_for_review'     // Check may have been reviewed previously
export const CHECK_STATUS_REVIEW_IN_PROGRESS = 'review_in_progress' // A human is reviewing this check
export const CHECK_STATUS_REVIEWED = 'reviewed'                     // Annotations reviewed by a human

export const CHECK_STATUS_OUT_OF_NETWORK = 'out_of_network'         // Checks that can't be processed by ML
export const CHECK_STATUS_FAILED = 'failed'                       // General failure (may have more specific ones later)

export const CHECK_STATUSES = [
    
    CHECK_STATUS_PROCESSING_IMAGES,
    CHECK_STATUS_SAVED,
    CHECK_STATUS_PROCESSING_OCR,
    CHECK_STATUS_PROCESSED,
    
    CHECK_STATUS_READY_FOR_REVIEW,
    CHECK_STATUS_REVIEW_IN_PROGRESS,
    CHECK_STATUS_REVIEWED,
    
    CHECK_STATUS_OUT_OF_NETWORK,
    CHECK_STATUS_FAILED,
    
]

export const CHECK_STATUSES_PROCESSING = [
    CHECK_STATUS_PROCESSING_IMAGES,
    CHECK_STATUS_PROCESSING_OCR,
]

// Checks that can have their status "reset"
export const CHECK_CAN_BE_RESET_STATUSES = [
    CHECK_STATUS_PROCESSING_IMAGES,
    CHECK_STATUS_PROCESSING_OCR,
    CHECK_STATUS_PROCESSED,
    CHECK_STATUS_FAILED,
]

// Checks that have not been reviewed/annotated yet
export const CHECK_NOT_REVIEWED_STATUSES = [
    CHECK_STATUS_SAVED,
    CHECK_STATUS_PROCESSING_OCR,
    CHECK_STATUS_PROCESSED,
    CHECK_STATUS_READY_FOR_REVIEW,
]

// Checks with in-progress review(s)
export const CHECK_REVIEW_IN_PROGRESS_STATUSES = [
    CHECK_STATUS_REVIEW_IN_PROGRESS,
]

// Checks that have finished reviews/annotations
export const CHECK_REVIEWED_STATUSES = [
    CHECK_STATUS_REVIEWED,
]

export const CHECK_FIELDS = {
    'Owner': { key: 'owner', sortable: false },
    'Operator': { key: 'operator', sortable: false },
    'Check Number': { key: 'checkNumber', sortable: false },
    'Check Amount': { key: 'checkAmount', sortable: false },
    'Check Date': { key: 'incomeDate', sortable: true },
}

export const CHECK_DETAIL_FIELDS = {
    'Property': 'property',
    'Production Type': 'productionType',
    'Sales Date': 'salesDate',
    'Interest Type': 'interestType',
    'Tax Code': 'taxCode',
    'Owner Interest': 'ownerInterest',
    'Distribution Interest': 'distributionInterest',
    'BTU': 'btu',
    'Property Volume': 'propertyVolume',
    'Property Price': 'propertyPrice',
    'Property Value': 'propertyValue',
    'Property Taxes': 'propertyTaxes',
    'Property Amount': 'propertyAmount',
    'Owner Volume': 'ownerVolume',
    'Owner Value': 'ownerValue',
    'Owner Taxes': 'ownerTaxes',
    'Owner Amount': 'ownerAmount',
}

// Check report status
export const CHECK_REPORT_STATUS_PROCESSING = 'processing'
export const CHECK_REPORT_STATUS_CREATED = 'created'
export const CHECK_REPORT_STATUS_FAILED = 'failed'

// Check report visibility
export const CHECK_REPORT_VISIBILITY_OWNER = 'owner'
export const CHECK_REPORT_VISIBILITY_ORGANIZATION = 'organization'

export const CHECK_REPORT_STATUSES = [
    CHECK_REPORT_STATUS_PROCESSING,
    CHECK_REPORT_STATUS_CREATED,
    CHECK_REPORT_STATUS_FAILED,
]
