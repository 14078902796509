import { useState } from 'react'
import { useWire } from '@forminator/react-wire'
import * as store from '$store'
import * as actions from '$actions'

import cn from 'classnames'

import AnnotatorsTableRow from './AnnotatorsTableRow'
import DeleteAnnotatorModal from '$components/Modals/DeleteAnnotatorModal'
import { MdRefresh } from 'react-icons/md'

import './AnnotatorsTable.css'

const AnnotatorsTable = ({
    className,
    annotators,
}) => {
    
    const [pendingDeleteAnnotator, setPendingDeleteAnnotator] = useState(null)
    
    const modalDeleteAnnotatorOpen = useWire(store.modalDeleteAnnotatorOpen)
    
    return (
        
        <>
            
            <table className={cn('AnnotatorsTable', className)}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email Address</th>
                        <th>Created Date</th>
                        <th>Document Reviews</th>
                        <th>
                            <div className="flex items-center content-center justify-center">
                                <button
                                    className="action-button"
                                    onClick={actions.getAnnotators}>
                                    <MdRefresh className="text-xl" />
                                </button>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {annotators.map(it => (
                        <AnnotatorsTableRow
                            key={it.email}
                            setPendingDeleteAnnotator={annotator => {
                                setPendingDeleteAnnotator(annotator)
                                modalDeleteAnnotatorOpen.setValue(true)
                            }}
                            {...it} />
                    ))}
                </tbody>
            </table>
            
            <DeleteAnnotatorModal
                annotator={pendingDeleteAnnotator}
                onClose={() => setPendingDeleteAnnotator(null)} />
        
        </>
        
    )
    
}

export default AnnotatorsTable
