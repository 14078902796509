import Logger from '@utils/log'
import * as store from '@store'
import ParseAPI from '@api/ParseAPI'

const log = new Logger('actions/states')

export const fetchStates = async (populateMode = true) => {
    
    try {
        
        const res = await ParseAPI.get(`abstracts/states/${populateMode ? 'true' : ''}`)
        
        store.states.setValue(res.data)
        
        return res?.data
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}

export const fetchAbstractById = async id => {
    
    try {
        
        const res = await ParseAPI.get(`abstracts/${id}/abstract`)
        
        store.arrayUpdateById('abstracts', res.data)
        
    } catch (error) {
        
        log.e(error)
        
    }
    
}
