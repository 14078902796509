import api from '$lib/api'

// import { createQueryString } from '@utils'

export const fetchPagerDutyIncidents = async () => {
    
    const res = await api.get('/admin/pagerduty/incidents')
    
    return res.data
    
}

