
export const typesList = ['visa', 'amex', 'mastercard']

export const COLLECTION_INITIAL_VALUES = {
    id: '',
    userId: '',
    creatorId: '',
    name: '',
    state: '',
    county: '',
    description: '',
}

export const TRACT_INITIAL_VALUES = {
    id: '',
    name: '',
    grossAcreage: 0,
    assignedTo: [],
    status: '',
    statusDate: '',
    certDate: '',
    block: '',
    lot: '',
    projects: [],
    section1: '',
    section2: '',
    section3: '',
    section4: '',
    subdivision: '',
    platBook: '',
    platVolumn: '',
    platPage: '',
    bookType: '',
    book: '',
    page: '',
    documentNumber: '',
    documentType: '',
    instrumentDate: '',
    fileDate: '',
    grantor: '',
    grantee: '',
    description: '',
}

const currentDate = new Date().toISOString()

export const RUNSHEET_INITIAL_VALUES = {
    id: '',
    tracts: [],
    name: '',
    grossAcreage: 0,
    assignedTo: [],
    status: '',
    statusDate: currentDate,
    certDate: currentDate,
    documents: [],
}
export const DOCUMENT_INITIAL_VALUES = {
    id: '',
    name: '',
    CollectionId: '',
    RunsheetId: '',
    IqDocumentId: '',
    values: [
        {
            id: '',
            state: '',
            county: '',
            grantor: '',
            grantee: '',
            page: '',
            instrumentNumber: 0,
            instrumentType: '',
            bookType: '',
            path: '',
            section: '',
            block: '',
            township: '',
            survey: '',
            abstract: '',
            subdivision: '',
            subdivisionLot: '',
            subdivisionBlock: '',
            minAcreage: '',
            maxAcreage: '',
            volume: '',
            createdAt: '',
            updatedAt: '',
            DocumentId: '',
            isFinished: false,
        },
    ],
}
export const FAVORITE_TRACT_INITIAL_VALUES = {
    tractId: '',
    userId: '',
}
export const RESENT_TRACT_INITIAL_VALUES = {
    tractId: '',
    userId: '',
    tractName: '',
    grossAcreage: 0,
    assignedTo: '',
    status: '',
}
