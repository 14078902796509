import cn from 'classnames'

const PagerDutyIncidentsTable = ({
    className,
    incidents,
}) => {
    
    return (
        
        <table className={cn('PagerDutyIncidentsTable table-auto', className)}>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Created</th>
                    <th>Type</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th>Urgency</th>
                    <th>Summary</th>
                </tr>
            </thead>
            <tbody>
                {incidents?.map(it => (
                    <tr key={it.id}>
                        <td>{it.id}</td>
                        <td>{it.created_at}</td>
                        <td>{it.type}</td>
                        <td>
                            <a href={it.html_url} target="_blank" rel="noreferrer">
                                {it.title}
                            </a>
                        </td>
                        <td>{it.description}</td>
                        <td>{it.status}</td>
                        <td>{it.urgency}</td>
                        <td>{it.summary}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        
    )
    
}

export default PagerDutyIncidentsTable
