import { CONVEYANCE_TYPES, CONVEYANCE_REGEX } from '@/constants'

export const evalFractionCalculation = v => {
    if (!v) return null
    
    if (v && CONVEYANCE_REGEX.test(v)) {
        try {
            if (v.startsWith('='))
                v = v.substring(1)
            return eval(v)
        } catch (err) {
            return NaN
        }
    }
    
    return NaN
}

export const getConveyanceValue = (conveyance = {}) => {
    const dataConveyance = evalFractionCalculation(conveyance.data)
    
    if (conveyance.fraction)
        return conveyance.fraction * dataConveyance
    return dataConveyance
}

export const getFixedConveyanceValue = (conveyance, partyType) => {
    
    const value = getConveyanceValue(conveyance)?.toFixed(6) || ''
    
    if (value && partyType === 'grantor_grantee')
        return '±' + value
    
    return value
    
}

export const getConveyanceByDocId = (party, docId) =>
    party.conveyances.find(c => c.documentId === docId)

// promisified the function so i can parallelize it with other calls
export const definePartyFraction = async docs => {
    for (let i = 0; i < docs.length; i++) {
        const doc = docs[i].value
        const dynamicGrantorConv = doc.grantors.filter(g => g.conveyance.dynamic)
        
        
        // this runs back for every grantor to get their interest in the previous documents, where they were grantees
        dynamicGrantorConv.forEach(grantor => {
            for (let j = i - 1; j >= 0; j--) {
                const grantee = docs[j].value.grantees.find(g => g.id === grantor.id)
                
                if (grantee)
                    CONVEYANCE_TYPES.forEach(key => {
                        
                        const conveyanceValue = getConveyanceValue(grantee.conveyance[key])
                        
                        grantor.conveyance[key].fraction = Math.abs(conveyanceValue)
                        
                    })
            }
        })
        const dynamicGranteeConv = doc.grantees.filter(g => g.conveyance.dynamic)
        
        
        // this runs on the current grantor list to define how much is
        // the interest of each person that got a fraction of the total
        dynamicGranteeConv.forEach(grantee => {
            const conveyances = doc.grantors.reduce((acc, grantor) => {
                CONVEYANCE_TYPES.forEach(key => {
                    acc[key] = (acc[key] || 0) + getConveyanceValue(grantor.conveyance[key])
                })
                return acc
            }, {})
            
            CONVEYANCE_TYPES.forEach(key => grantee.conveyance[key].fraction = Math.abs(conveyances[key]))
        })
    }
}

export const updatePartyData = (docValue, key, partyId, type, conveyance) => {
    const partyIdx = docValue[key].findIndex(g => g.id === partyId)
    
    if (partyIdx > -1) {
        docValue[key] = [...docValue[key]]
        const party = { ...docValue[key][partyIdx] }
        
        party.conveyance = { ...party.conveyance }
        party.conveyance[type] = { ...party.conveyance[type], ...conveyance }
        docValue[key].splice(partyIdx, 1, party)
    }
}

export const insertNewPartyConveyance = (doc, key, party, type, conveyance) => {
    const docValue = doc.value
    const namedKey = `${key}s`
    
    docValue[namedKey] = [...docValue[namedKey]]
    party.conveyance = {
        documentId: doc.documentId,
        [type]: conveyance,
        dynamic: false, // for now it will always be false on dynamic
    }
    
    CONVEYANCE_TYPES.forEach(ct => {
        party.conveyance[ct] = party.conveyance[ct] || { numerator: null, denominator: null, float: null, data: null }
    })
    docValue[namedKey].push(party)
}

// to be used on Array.reduce
export const sumConveyance = field => (sum, conv) => {
    
    if (conv.partyType === 'grantor_grantee')
        return sum
    
    return sum + (getConveyanceValue(conv[field]) || 0)
    
}