import { useMemo } from 'react'
import cn from 'classnames'

import './ProgressBar.css'

const ProgressBar = ({
    className = '',
    barClassName = 'bg-info',
    max = 0,
    value = 0,
}) => {
    
    const progress = useMemo(() => {
        
        if (max <= 0) return 0
        if (value <= 0) return 0
        
        try {
            return (value / max) * 100
        } catch (e) {
            return 0
        }
        
    }, [max, value])
    
    return (
        
        <div
            className={cn('ProgressBar', className)}
            style={{
                width: '100%',
                height: '8px',
            }}>
            
            <div
                className={cn('ProgressBar-bar', barClassName)}
                style={{
                    width: `${progress}%`,
                }}
                role="progressbar"
                aria-valuemin={0}
                aria-valuemax={max ?? 0}
                aria-valuenow={value ?? 0} />
        
        </div>
        
    )
    
}

export default ProgressBar
