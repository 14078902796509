
const ModalHeader = ({ children }) => {
    
    return (
        
        <div className="modal-header">
            <h4 className="modal-title">{children}</h4>
        </div>
        
    )
    
}

export default ModalHeader
