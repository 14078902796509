import { useState, useMemo, useCallback } from 'react'
import * as actions from '$actions'
import { ES_INDEX_STATUS_FAILED } from '@constants/elasticSearch'
import useCheckableItems from '@hook/useCheckableItems'
import { snippet, getUserFullName } from '@utils'
import { toast } from 'react-toastify'
import cn from 'classnames'

import Dropdown from '$components/Dropdown'
import ProgressBar from '@components/shared/ProgressBar'
import { MdDelete, MdDeleteSweep } from 'react-icons/md'
import { FaAngleDown } from 'react-icons/fa'
import Checkbox from '$components/Forms/Checkbox'

const roughFilterMatch = (job, query) => {
    
    // @todo just matches against all object fields rn
    const rawValue = Object.values(job)
        .map(it => it?.toString() ?? '')
        .join(' ')
        .toLowerCase()
    
    return rawValue.indexOf(query.toLowerCase())
    
}

const ESJobsTable = ({
    className,
    wrapClassName,
    jobs,
    fetchJobs,
}) => {
    
    const [query, setQuery] = useState('')
    
    const filteredJobs = useMemo(() => (
        query?.length
            ? jobs.filter(it => roughFilterMatch(it, query))
            : jobs
    ), [jobs, query])
    
    const {
        checked,
        allChecked,
        someChecked,
        anyChecked,
        hasChecked,
        toggleChecked,
        toggleAllChecked,
    } = useCheckableItems(filteredJobs ?? [])
    
    const onDeleteJobClick = async (jobId, skipConfirm = false, skipToast = false) => {
        
        if (!skipConfirm && !confirm('Are you sure you want to delete this FAILED index job?'))
            return
        
        try {
            
            await actions.deleteElasticSearchIndexJob(jobId)
            await fetchJobs()
            
            if (!skipToast)
                toast.success('Deleted index job')
            
        } catch (e) {
            
            console.error('onDeleteJobClick', e)
            toast.error('Failed to delete index job')
            
        }
        
    }
    
    // @todo need an actual 'delete multiple' endpoint; this is inefficient
    const onDeleteCheckedJobsClick = useCallback(async () => {
        
        if (!anyChecked) return
        
        if (!confirm(`Are you sure you want to delete ${checked.length} FAILED index jobs?`))
            return
        
        await Promise.all(checked.map(it => onDeleteJobClick(it, true, true)))
        
        toast.success(`Deleted ${checked.length} index jobs`)
        
    }, [checked, anyChecked])
    
    const onReIndexSkippedRecordsClick = async jobId => {
        
        try {
            
            await actions.reindexElasticSearchSkippedDocuments(jobId)
            toast.success('Skipped documents re-queued for indexing')
            
        } catch (e) {
            
            console.error('onReIndexSkippedRecordsClick', e)
            toast.error('Failed to re-queue skipped documents')
            
        }
        
    }
    
    const getProgressTitle = job => [
        job.progressValue.toLocaleString('en-US'),
        '/',
        job.progressMax.toLocaleString('en-US'),
    ].join(' ')
    
    return (
        
        <div className={cn('ESJobsTable-wrap', wrapClassName)}>
            
            <header>
                <div className="">
                    <input
                        className="w-full"
                        type="text"
                        autoComplete="off"
                        value={query}
                        placeholder="Filter jobs..."
                        onKeyUp={e => e.key === 'Escape' && setQuery('')}
                        onChange={e => setQuery(e.target.value?.toLowerCase() ?? '')} />
                </div>
            </header>
            
            <table className={cn('ESJobsTable table-pin-rows', className)}>
                <thead>
                    <tr>
                        <th>
                            <Checkbox
                                checked={allChecked}
                                indeterminate={someChecked}
                                onChange={toggleAllChecked} />
                        </th>
                        <th>ID</th>
                        <th>Started By</th>
                        <th>Trigger Source</th>
                        <th>Model Name</th>
                        <th>Strategy Name</th>
                        <th>Status</th>
                        <th>Progress</th>
                        <th>Errors</th>
                        <th>Skipped</th>
                        <th>
                            {/* Actions */}
                            <div className="ESJobsTable-actions flex items-center content-center justify-center gap-2">
                                <button
                                    data-testid="ESJobsTable-delete-checked-jobs-button"
                                    className={cn('btn-square btn-sm btn btn-link', {
                                        '!text-red-400 !hover:text-red-600': anyChecked,
                                    })}
                                    disabled={!anyChecked}
                                    onClick={onDeleteCheckedJobsClick}>
                                    <MdDeleteSweep />
                                </button>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    
                    {filteredJobs?.length < 1 && (
                        <tr>
                            <td colSpan={3}>
                                <i>No currently running jobs</i>
                            </td>
                        </tr>
                    )}
                    
                    {filteredJobs?.map(it => (
                        <tr key={`job-${it.id}`}>
                            <td>
                                <Checkbox
                                    checked={hasChecked(it.id)}
                                    onChange={() => toggleChecked(it.id)} />
                            </td>
                            <td
                                title={it.id}
                                onClick={() => console.info(it.id)}>
                                {snippet(it.id)}
                            </td>
                            <td>{getUserFullName(it.startedBy, true)}</td>
                            <td>{it.triggerSource}</td>
                            <td>{it.modelName}</td>
                            <td>{it.strategyName}</td>
                            <td className={cn({
                                'text-red-500': it.status === ES_INDEX_STATUS_FAILED,
                            })}>
                                {it.status}
                            </td>
                            <td className="text-center" title={getProgressTitle(it)}>
                                <span className="text-sm">
                                    {it.progressValue.toLocaleString('en-US')}
                                </span>
                                <ProgressBar
                                    className="mt-1"
                                    barClassName=""
                                    max={it.progressMax}
                                    value={it.progressValue} />
                            </td>
                            <td>
                                <div>
                                    <pre>
                                        <code className="text-white">{JSON.stringify(it.errors, null, 4)}</code>
                                    </pre>
                                </div>
                            </td>
                            <td>
                                <Dropdown
                                    menuButtonClassName=""
                                    menuClassName="mt-4"
                                    menuItemClassName="text-left"
                                    label={
                                        <div className="flex items-center content-center gap-1">
                                            <div>{it.skippedRecordsCount}</div>
                                            <div className="ml-2 border-l border-black">
                                                <FaAngleDown className="text-lg" />
                                            </div>
                                        </div>
                                    }
                                    items={[
                                        {
                                            id: 1,
                                            label: (
                                                <span style={{ textDecoration: 'line-through' }}>
                                                    View missing documents
                                                </span>
                                            ),
                                        },
                                        {
                                            id: 2,
                                            label: 'Re-index missing documents',
                                        },
                                    ]}
                                    withCheck={false}
                                    renderItem={(it, props) => (
                                        <div className="
                                            flex items-center content-center
                                            !justify-start menu-item-inner">
                                            <div className="ml-3 menu-item-label">
                                                {it[props.labelKey]}
                                            </div>
                                        </div>
                                    )}
                                    onChange={item => {
                                        switch (item.id) {
                                            case 1: return alert('Not yet implemented')
                                            case 2: return onReIndexSkippedRecordsClick(it.id)
                                        }
                                    }} />
                            </td>
                            <td>
                                <div className="flex items-center content-center justify-center gap-2 text-center">
                                    <button
                                        className="!text-red-400 btn btn-sm btn-link btn-square !hover:text-red-600"
                                        onClick={() => onDeleteJobClick(it.id)}>
                                        <MdDelete />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                
                </tbody>
            </table>
        
        </div>
        
    )
    
}

export default ESJobsTable
