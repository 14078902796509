import { useState, useMemo, useCallback, useEffect } from 'react'
import { useWireValue } from '@forminator/react-wire'
import * as store from '$store'
import * as actions from '$actions'

import Modal from '$components/Modal'
import { FaCheckCircle } from 'react-icons/fa'

const AssignDocumentsModal = ({
    className,
    open,
    setOpen,
    documentIds,
    ...props
}) => {
    
    const [query, setQuery] = useState('')
    const [selectedAnnotatorIds, setSelectedAnnotatorIds] = useState([])
    
    const annotators = useWireValue(store.annotators)
    const documents = useWireValue(store.documents)
    
    const selectedDocs = useMemo(() => (
        documents?.filter(it => documentIds.includes(it.id))
    ), [documents, documentIds])
    
    const filteredAnnotators = useMemo(() => {
        
        if (!query?.length) return annotators
        
        return annotators.filter(it => {
            
            const data = [
                it.firstName,
                it.lastName,
                it.email,
            ].join(' ').toLowerCase()
            
            return data.includes(query.toLowerCase())
            
        })
        
    }, [query, annotators])
    
    // @todo most of this is duplicated in the AnnotatorSelector component
    
    const onAnnotatorClick = useCallback(id => {
        
        setSelectedAnnotatorIds(prev => {
            
            if (prev.includes(id))
                return prev.filter(it => it !== id)
            else
                return [...prev, id]
            
        })
        
    }, [])
    
    const onAssignClick = useCallback(async () => {
        
        if (!selectedAnnotatorIds?.length || !documentIds.length) return
        
        try {
            
            await actions.assignDocumentsToAnnotators(
                selectedAnnotatorIds,
                documentIds,
            )
            
            await Promise.all([
                actions.getAnnotators(),
                actions.getAllDocuments(),
            ])
            
        } catch (e) {
            
            // @todo show error
            console.error('onAssignClick', e)
            
        }
        
    }, [selectedAnnotatorIds, documentIds])
    
    useEffect(() => {
        
        if (!selectedDocs?.length) return
        
        setSelectedAnnotatorIds(selectedDocs.flatMap(it => {
            return it.assignedAnnotators.flatMap(it => it.id)
        }))
        
    }, [selectedDocs])
    
    if (!documentIds) return null
    
    return (
        
        <Modal
            className={className}
            open={open}
            setOpen={setOpen}
            title="Assign Documents"
            actionLabel="ASSIGN DOCUMENTS"
            actionOnClick={onAssignClick}
            {...props}>
            
            <div className="">
                Assign {documentIds.length} documents to annotator:
            </div>
            
            <div className="pr-2 mt-4">
                <input
                    className="w-full text-sm"
                    type="text"
                    autoComplete="off"
                    value={query}
                    placeholder="Search annotators..."
                    onKeyUp={e => e.key === 'Escape' && setQuery('')}
                    onChange={e => setQuery(e.target.value)} />
            </div>
            
            <div className="flex flex-col pr-2 mt-2 overflow-y-auto h-52">
                {filteredAnnotators?.map(it => (
                    <div
                        key={`assignable-annotator-${it.id}`}
                        className="
                            flex items-center content-center justify-between w-full
                            border border-gray-300 rounded cursor-pointer"
                        onClick={() => onAnnotatorClick(it.id)}>
                        <div
                            className="w-full px-3 py-2 mb-2 text-sm">
                            <span className="">{it.firstName} {it.lastName}</span>
                            <br />
                            <span className="text-xs">{it.email}</span>
                        </div>
                        <div className="px-3">
                            {selectedAnnotatorIds.includes(it.id)
                                ? <FaCheckCircle className="text-lg" />
                                : <span className="text-lg">&nbsp;</span>
                            }
                        </div>
                    </div>
                ))}
            </div>
        
        </Modal>
        
    )
    
}

export default AssignDocumentsModal
