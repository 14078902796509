import { useState, useEffect, useCallback } from 'react'
import { useWireState } from '@forminator/react-wire'
import * as store from '$store'
import * as actions from '$actions'
import cn from 'classnames'

import Modal from '$components/Modal'
import { FaCheckCircle } from 'react-icons/fa'

const EditUserRolesModal = ({
    className,
    onUserUpdated,
    ...props
}) => {
    
    const [roles, setRoles] = useState([])
    const [userRoles, setUserRoles] = useState([])
    
    const [open, setOpen] = useWireState(store.modalEditUserRolesOpen)
    const [user, setUser] = useWireState(store.modalEditUserRolesUser)
    
    const hasRole = id => {
        
        return userRoles.includes(id)
        
    }
    
    const toggleRole = roleId => () => {
        
        if (hasRole(roleId))
            setUserRoles(userRoles.filter(it => it !== roleId))
        else
            setUserRoles([...userRoles, roleId])
        
    }
    
    const onUpdateRolesClick = useCallback(async () => {
        
        try {
            
            const res = await actions.updateUser(user.id, {
                roleIds: userRoles,
            })
            
            // console.log('onUpdateRolesClick', res)
            
            onUserUpdated(res.data)
            
        } catch (e) {
            
            // @todo show error
            console.error('onUpdateRolesClick', e)
            
        }
        
    }, [user, userRoles])
    
    useEffect(() => {
        
        if (!user) return
        
        setUserRoles(user.roles?.map(it => it.id) ?? [])
        
    }, [user])
    
    useEffect(() => {
        
        const fetchRoles = async () => {
            
            try {
                
                const res = await actions.getRoles()
                
                setRoles(res.data ?? [])
                
            } catch (e) {
                
                // @todo show error
                console.error('fetchRoles', e)
                
            }
            
        }
        
        if (open)
            fetchRoles()
        
    }, [open])
    
    if (!user || !roles?.length) return null
    
    return (
        
        <Modal
            className={className}
            open={open}
            setOpen={value => {
                if (!value) setUser(null)
                setOpen(value)
            }}
            title="Edit User Roles"
            actionLabel="UDPDATE ROLES"
            actionOnClick={onUpdateRolesClick}
            {...props}>
            
            <div className="flex flex-col">
                {roles?.map(it => (
                    <div
                        key={`role-${it.id}`}
                        className="flex items-center content-center justify-between">
                        <div>{it.name}</div>
                        <div className="mb-2">
                            <button
                                className="border-none outline-none btn !focus:border-none !focus:outline-none"
                                onClick={toggleRole(it.id)}>
                                <FaCheckCircle className={cn('text-lg', {
                                    'opacity-50': !hasRole(it.id),
                                    'text-green-500': hasRole(it.id),
                                })} />
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        
        </Modal>
        
    )
    
}

export default EditUserRolesModal
