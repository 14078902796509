import { Helmet } from 'react-helmet'

const Title = ({
    children,
}) => {
    
    return (
        
        <Helmet>
            <title>{children} - Parse</title>
        </Helmet>
        
    )
    
}

export default Title
