import { /* useCallback, */ } from 'react'
import { useWireValue } from '@forminator/react-wire'
import * as store from '@store'

/* import * as actions from '@actions'
import {
    ESCROW_STATUS_FAILED,
    ESCROW_STATUS_FULFILLED,
    ESCROW_STATUS_PENDING,
    
    // HUMAN_ESCROW_TYPES,
} from '@constants/escrows' */
import NotificationCenterIcon from '@components/shared/NotificationCenter/NotificationCenterIcon'
import NotificationCenterItem from '@components/shared/NotificationCenter/NotificationCenterItem'
import cn from 'classnames'
import './NotificationCenter.css'

// Set this to true to force the menu to open while developing
const debugForceMenuOpen = false

const NotificationCenter = () => {
    
    const notifications = useWireValue(store.notifications)
    
    // const escrowNotifications = useWireValue(store.escrows)
    
    // @todo
    /* const getEscrowStatusText = useCallback(status => {
        switch (status) {
            case ESCROW_STATUS_PENDING: return 'Still processing'
            case ESCROW_STATUS_FULFILLED: return 'Processing completed'
            case ESCROW_STATUS_FAILED: return 'Processing failed'
            default: return null
        }
    }, []) */
    
    // @todo
    /* const dismissAllNotifications = ev => {
        [...ev.target.parentElement.parentElement.querySelectorAll('li.notification')]
            .forEach(li => li.dataset.dismissing = true)
        actions.dismissAllNotifications()
    } */
    
    return (
        
        <div id="NotificationCenter" className={cn('dropdown dropdown-end', {
            'dropdown-open': debugForceMenuOpen,
        })}>
            
            <NotificationCenterIcon/>
            
            <div tabIndex={0} className="dropdown-content">
                
                <div className="wrapper">
                    
                    <header>
                        <b>Notification Center</b>
                    </header>
                    
                    <ul>
                        {(notifications?.length) < 1 && (
                            <div className="my-2 pl-1 italic">
                                No notifications yet.
                            </div>
                        )}
                        {notifications.map(it => (
                            <NotificationCenterItem
                                key={it.message.id}
                                event={it.message} />
                        ))}
                    </ul>
                
                </div>
            
            </div>
        
        </div>
        
    )
    
}

export default NotificationCenter
