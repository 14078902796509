import { useCallback, useMemo } from 'react'
import * as actions from '@actions'
import { MdClose, MdInfo } from 'react-icons/md'
import {
    RT_EVENT_TYPE_MESSAGE_PLAIN,
    RT_EVENT_TYPE_DOCUMENT_PROCESS,
    RT_EVENT_TYPE_ESCROW_FUNDED,
} from '@constants/realtime'

const severityMap = {
    info: { className: 'alert-info', Icon: MdInfo },
}

const NotificationCenterItem = ({
    event,
}) => {
    
    const data = useMemo(() => (
        severityMap[event.data.severity] ?? { className: 'alert-info', Icon: MdInfo }
    ), [event])
    
    const message = useMemo(() => {
        
        const { type, data } = event.data
        
        switch (type) {
            case RT_EVENT_TYPE_MESSAGE_PLAIN:
                return data.message
            case RT_EVENT_TYPE_DOCUMENT_PROCESS:
                return `Document ${data.name} processed`
            case RT_EVENT_TYPE_ESCROW_FUNDED:
                return `Escrow ${data.name} funded`
            default:
                return 'Unknown event'
        }
        
    }, [event])
    
    const dismissNotification = useCallback(() => {
        
        console.log('dismissNotification', event)
        
        actions.dismissNotification(event.data.type, event.data.id)
        
    }, [event])
    
    return (
        
        <li className="">
            
            <div role="alert" className={`alert ${data.className} flex justify-between items-center my-1 p-3`}>
                
                <div className="flex items-center gap-2">
                    <data.Icon className="text-2xl" />
                    <div>{message}</div>
                </div>
                
                <button
                    className="btn btn-ghost btn-circle btn-xs !px-1 w-auto shrink"
                    onClick={dismissNotification}>
                    <MdClose className="pointer-events-none text-lg" />
                </button>
            
            </div>
        
        </li>
        
    )
    
}

export default NotificationCenterItem
