import dayjs from 'dayjs'

// @fix for 863gjff19 - date object being passed instead of string
// splits the string date to get the correct date value without timezone
const __getValidDate = date => typeof date === 'string' ? date.split('T')[0] : date

export const formatDate = (date, format = 'YYYY-MM-DD') => date ? dayjs(__getValidDate(date)).format(format) : ''

export const stringToDate = date => date ? dayjs(__getValidDate(date)).toDate() : new Date()

export const formatDateTime = (date, format = 'DD.MM.YYYY hh:mm') => date ? dayjs(date).format(format) : ''

export const parseDate = (date, defaultValue = null, returnCurrentDate = true) => {
    let formatedDate = stringToDate(date)
    
    if (formatedDate.toString() !== 'Invalid Date') return formatedDate
    
    // case an invalid date is passed, it returns the default value case it exists
    // case it doesnt exist, will return, by default, the current date, otherwise, returns undefined
    return defaultValue || (returnCurrentDate && new Date()) || undefined
}

export const parseMoment = (
    date,
    
    // @todo this could've been passed as a parameter before,
    //  but the function was broken since always. remove in the future, if needed
    // eslint-disable-next-line no-unused-vars
    _format = 'DD/MM/YYYY',
    defaultValue = undefined,
) => {
    try {
        if (!date) return undefined
        const res = dayjs(__getValidDate(date))
        
        if (res.toString() === 'Invalid date') return defaultValue || undefined
        return res.toDate()
    } catch (e) {
        return defaultValue || undefined
    }
}

export const relativeTime = value => {
    const d = dayjs(value)
    
    return (isNaN(d)) ? 'N/A' : dayjs().to(d)
}
