import * as actions from '$actions'
import { RECORD_TYPE_DOCUMENT } from '@constants'

import ManageRecordLabelsBaseModal from './ManageRecordLabelsBaseModal'

const ManageCollectionLabelsModal = ({
    collection,
    ...props
}) => {
    
    return (
        
        <ManageRecordLabelsBaseModal
            recordType={RECORD_TYPE_DOCUMENT}
            record={collection}
            recordLabel="Collection"
            recordNameKey="name"
            storeKey="modalManageCollectionLabelsOpen"
            setLabelsToRecordFn={actions.setLabelsToCollection}
            fetchRecordsFn={actions.getCollectionsWithStats}
            {...props} />
        
    )
    
}

export default ManageCollectionLabelsModal
