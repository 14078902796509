import axios from 'axios'
import { PUBLIC_ROUTE_PATHS, SKIP_AUTH_REDIRECT_PATHS } from '@/constants'
import * as store from '@store'

/**
 * Creates a new Axios API instance
 * 
 * @param {String} baseURL Base API URL
 * @param {Object} options 
 * @param {Object} options.clearAuthOnAccessDenied Clear locally stored user auth on 401 responses
 * @param {String} options.redirectToOnAccessDenied Redirect to a URL (typically signup) on 401 responses
 * @returns {AxiosInstance} Client instance
 */
export const createApi = (baseURL, {
    clearAuthOnAccessDenied,
    redirectToOnAccessDenied,
} = {
    clearAuthOnAccessDenied: true,
    redirectToOnAccessDenied: '/signin',
}) => {
    
    const api = axios.create({ baseURL })
    
    api.interceptors.request.use(
        config => ({
            ...config,
            headers: {
                'x-api-key': localStorage.getItem('jwt'),
                'X-Organization-Id': store.currentOrganization.getValue()?.id,
            },
        }),
        error => {
            console.error('api.interceptors.request', error)
            return Promise.reject(error)
        },
    )
    
    api.interceptors.response.use(
        res => res,
        error => {
            
            if (error?.response?.status === 401) {
                
                // If the current URL is not blacklisted, save it for auto redirect after sign-in
                if (!SKIP_AUTH_REDIRECT_PATHS.includes(window.location.pathname))
                    store.afterSignInRedirectUrl.setValue(window.location.pathname ?? null)
                
                if (clearAuthOnAccessDenied)
                    localStorage.removeItem('jwt')
                
                if (redirectToOnAccessDenied !== false) {
                    
                    const redirectTarget = redirectToOnAccessDenied || '/signin'
                    const isPublicRoute = PUBLIC_ROUTE_PATHS.some(it => location.pathname === it)
                    
                    // Don't redirect public routes, or if we're already on the correct path,
                    // or things like signup error messages won't appear
                    if (!isPublicRoute && location.pathname !== redirectTarget) {
                        console.warn('Navigating away from', window.location.href, 'to', redirectTarget)
                        window.location.replace(redirectTarget)
                    }
                    
                }
                
            }
            
            // No need to error log on 300 codes
            if (error?.response?.status >= 400)
                console.error('api.interceptors.response',
                    error.response?.status, error.code, error.message,
                    (error.response?.data?.error ?? error.response?.data))
            
            return Promise.reject(error)
            
        },
    )
    
    return api
    
}
