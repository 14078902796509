import config from '@/config'
import { createApi } from '@utils/api'

export const baseUrl = `${config.parseApiBaseUrl}/api/v1`

export const publicApi = createApi(baseUrl, {
    clearAuthOnAccessDenied: false,
    redirectToOnAccessDenied: false,
})

export default createApi(baseUrl)
