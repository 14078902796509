import { useState } from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import cn from 'classnames'

const Accordion = ({ label, children, bodyClassName }) => {
    
    const [open, setOpen] = useState(false)
    
    return (
        
        <div className="flex flex-col bg-[var(--parse-background-lighter)] rounded-md">
            
            <div
                className={cn('px-4 py-3 flex justify-between cursor-pointer', {
                    'border-b-[1px] border-gray-500': open,
                })}
                onClick={() => setOpen(!open)}>
                {label}
                {!open && <MdKeyboardArrowDown className="text-xl" />}
                {open && <MdKeyboardArrowUp className="text-xl" />}
            </div>
            
            {open && <div className={cn('py-2', { bodyClassName })}>
                {children}
            </div>}
        
        </div>
        
    )
}

export default Accordion
