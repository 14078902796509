import * as actions from '@actions'
import primaryMenuItems from '$components/Navbar/primaryMenuItems'
import cn from 'classnames'

import { Link } from 'react-router-dom'
import { MdOutlineArrowBack } from 'react-icons/md'

const SidebarPrimaryNav = ({
    collapsed,
}) => {
    
    const onLinkClick = item => e => {
        
        if (item.label !== 'LOGOUT') return
        
        e.preventDefault()
        actions.signOut()
        
    }
    
    return (<>
        
        <Link className="back-link" to="/collections">
            <MdOutlineArrowBack className="text-lg" />
            {!collapsed && <span>Back to App</span>}
        </Link>
        
        {primaryMenuItems.map(it => it.divider ? (
            <div key={it.id} className="px-2 py-2">
                <div className="w-full h-px bg-accent" />
            </div>
        ) : (
            <Link
                key={it.id}
                className={cn('link', it.className, {
                    'active': location.pathname === it.to,
                })}
                to={it.to}
                title={it.label}
                onClick={onLinkClick(it.value || it)}>
                <div>{it.Icon && <it.Icon className="text-lg" />}</div>
                <div className="link-label text-sm">
                    {it.label}
                </div>
            </Link>
        ))}
    
    </>)
    
}

export default SidebarPrimaryNav
