import { createWire } from '@forminator/react-wire'
import { createPersistedWire } from 'react-wire-persisted'
import { AppCheckStatus, keys } from '@constants'
import { noop } from '@/utils'

export const appCheckStatus = createWire(AppCheckStatus.Loading)

export const theme = createPersistedWire(keys.theme, null)

// @todo idk what this is
export const shared = createWire(null)

// Compatibility errors to show user
export const compatErrors = createWire([])
export const ignoreCompatErrors = createPersistedWire(keys.ignoreCompatErrors, false)

// Don't attempt to reload external scripts on every page change
export const externalScriptsCache = createWire({})

export const isFetchingParseConfig = createWire(false)

// @section Modals/Dialogs
export const currentOrgProjectSelectorDialogOpen = createWire(false)
export const documentViewerModalDocument = createWire(null)
export const exportRunsheetDialogOpen = createWire(false)
export const uploadChecksDialogOpen = createWire(false)
export const paymentRequiredDialogConfig = createWire({
    open: false,
    purchaseType: undefined,
    itemCount: 0,
    onClose: null,
    onConfirmPayment: noop,
})
export const insufficientBalanceWarningDialogOpen = createWire(false)
export const addExistingOrgMemberDialogOpen = createWire(false)
export const inviteNewOrgMemberDialogOpen = createWire(false)
export const purchaseCreditsDialogOpen = createWire(false)
export const remainingBalance = createWire(null)

export const paymentRequiredDialogShouldWarn = createPersistedWire(keys.paymentRequiredDialogShouldWarn_disabled, true)
