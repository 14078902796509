import CustomModal, { ModalBody, ModalHeader } from '@/components/shared/CustomModal'
import { useState } from 'react'
import * as actions from '$actions'


const CreateTemplateModal = ({
    
    includeSubTabModal,
    setIncludeSubTabModal,
    activeTab,
    
}) => {
    
    const [newSubTabName, setNewSubTabName] = useState('')
    
    const createNewSubTab = async () => {
        
        try {
            await actions.addNewTemplate(newSubTabName, activeTab)
        } catch (err) {
            console.log(err)
        }
        
        setIncludeSubTabModal(false)
        setNewSubTabName('')
        
    }
    
    return (
        
        <CustomModal
            open={includeSubTabModal}
            modalHandler={() => setIncludeSubTabModal(!includeSubTabModal)}>
            
            <ModalHeader>
                New Template
            </ModalHeader>
            
            <ModalBody className="flex gap-4">
                <input
                    type="text"
                    className="w-10/12 input input-bordered"
                    placeholder="Template Name"
                    value={newSubTabName}
                    onChange={e => setNewSubTabName(e.target.value)} />
                <button
                    onClick={() => createNewSubTab()}
                    className="btn btn-primary">
                    Create
                </button>
            </ModalBody>
        </CustomModal>
    )
}

export default CreateTemplateModal