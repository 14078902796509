import { useRef, useMemo, useEffect } from 'react'
import cn from 'classnames'

import './Checkbox.css'

// #techdebt
// @todo move this into shared components, since some of the regular app use it
const Checkbox = ({
    className,
    labelClassName,
    label = null,
    checked,
    indeterminate = false,
    labelRtl = false,
    labelProps = {},
    ...props
}) => {
    
    const refCheckbox = useRef()
    
    const labelEl = useMemo(() => (
        <span className="label">
            {label}
        </span>
    ), [label])
    
    useEffect(() => {
        
        if (!refCheckbox.current) return
        
        refCheckbox.current.indeterminate = indeterminate
        
    }, [refCheckbox, indeterminate])
    
    return (
        
        <label
            className={cn('Checkbox-label', labelClassName)}
            {...labelProps}>
            
            {label && labelRtl && labelEl}
            
            <input
                ref={refCheckbox}
                className={cn('Checkbox', className)}
                type="checkbox"
                checked={checked || false}
                {...props} />
            
            {label && !labelRtl && labelEl}
        
        </label>
        
    )
    
}

export default Checkbox
