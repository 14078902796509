import { useState, useEffect } from 'react'
import * as actions from '$actions'
import { toast } from 'react-toastify'

import './CheckMappings.css'
import Checkbox from '$components/Forms/Checkbox'
import CircleSpinner from '@/components/shared/CircleSpinner'
import { VALUES_BY_TYPE } from '@/constants/checkMappings'

const INITIAL_FIELDS = ['productionType', 'interestTypeProd', 'interestTypeDeduct']

const MAP_FIELDNAMES = [{
    key: 'productionType', 
    label: 'Production Type',
}, {
    key: 'interestTypeProd',
    label: 'Interest Type',
}, {
    key: 'interestTypeDeduct',
    label: 'Adjustment Type',
}]

const CheckMappings = () => {
    
    const [checkMappings, setCheckMappings] = useState([])
    const [selectedFields, setSelectedFields] = useState(INITIAL_FIELDS)
    const [filteredMappings, setFilteredMappings] = useState([])
    const [editedFields, setEditedFields] = useState({})
    const [loading, setLoading] = useState(false)
    const [showEmptyOnly, setShowEmptyOnly] = useState(false)
    
    const fetchCheckMappings = async () => {
        
        try {
            const mappings = await actions.getExistingMappings()
            
            setCheckMappings(mappings)
            setFilteredMappings([...mappings])
            setEditedFields({})
        } catch (e) {
            console.error('fetchCheckMappings', e)
            toast.error('Failed to fetch check mappings')
        }
        
    }
    
    const saveMappings = async () => {
        
        try {
            setLoading(true)
            await actions.saveMappings(Object.values(editedFields))
            await fetchCheckMappings()
            toast.success('Mappings saved successfully')
        } catch (err) {
            toast.error(err.message)
        }
        
        setLoading(false)
    }
    
    const toggleSelectedFields = (field, checked) => {
        const fields = [...selectedFields]
        
        if (checked)
            setSelectedFields([...fields, field])
        else {
            fields.splice(selectedFields.indexOf(field), 1)
            setSelectedFields(fields)
        }
    }
    
    const filterMappings = () => {
        setFilteredMappings(checkMappings.filter(cm => {
            const isOnFilter = selectedFields.includes(cm.filter)
            const isEmpty = !cm.cleanField
            
            if (showEmptyOnly) return isOnFilter && isEmpty
            return isOnFilter
        }))
    }
    
    const addEditedField = (editedField, newCleanFieldValue) => {
        editedField.cleanField = newCleanFieldValue
        setEditedFields({ ...editedFields, [editedField.hash]: { ...editedField } })
    }
    
    useEffect(() => { fetchCheckMappings() }, [])
    
    useEffect(() => {
        filterMappings()
    }, [checkMappings, selectedFields, showEmptyOnly])
    
    return (
        
        <div className="CheckMappings">
            
            <header className="flex mb-4">
                
                <div className="flex flex-col">
                    <h4 className="text-xl text-dashboard-primary">
                        Check Mappings
                    </h4>
                </div>
            
            </header>
            
            {/* <pre>
                {JSON.stringify(editedFields, null, 2)}
            </pre> */}
            
            <div className="flex justify-between mb-4">
                <div className="flex gap-4">
                    {MAP_FIELDNAMES.map(fn => (
                        <Checkbox
                            key={`field-names-${fn.key}`}
                            className="mr-2"
                            checked={selectedFields.includes(fn.key)}
                            label={fn.label}
                            onChange={evt => toggleSelectedFields(fn.key, evt.target?.checked)} />
                    ))}
                    <Checkbox
                        className="mr-2"
                        checked={showEmptyOnly}
                        label="Unassigned"
                        onChange={evt => setShowEmptyOnly(evt.target?.checked)} />
                </div>
                <div style={{ width: '300px' }}>
                    <button
                        className="btn btn-primary"
                        disabled={loading || !Object.keys(editedFields).length}
                        onClick={saveMappings}>
                        {loading && <CircleSpinner size="22.5px" className="flex justify-center" />}
                        {!loading && `Save (${Object.keys(editedFields).length})`}
                    </button>
                </div>
            </div>
            
            <table className="table table-auto">
                
                <thead>
                    <tr>
                        <th style={{ width: '200px' }}>Field Name</th>
                        <th style={{ width: '100px' }}>Count</th>
                        <th>Check Name</th>
                        <th>Dirty Value</th>
                        <th style={{ width: '50%' }}>Clean Value</th>
                    </tr>
                </thead>
                
                <tbody>
                    {filteredMappings?.map(it => (
                        <tr key={it.hash}>
                            <td>{MAP_FIELDNAMES.find(fn => fn.key === it.filter).label}</td>
                            <td>{it.count}</td>
                            <td>{it.checkName}</td>
                            <td>{it.dirtyField}</td>
                            <td>
                                <select
                                    className="select border border-base-200"
                                    value={editedFields[it.hash]?.cleanField || it.cleanField}
                                    onChange={evt => addEditedField({ ...it }, evt.target.value)}>
                                    {!editedFields[it.hash]?.cleanField && !it.cleanField && (
                                        <option value=""></option>
                                    )}
                                    {VALUES_BY_TYPE[it.filter].map((vt, i) => (
                                        <option key={i} value={vt}>
                                            {vt}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                    ))}
                </tbody>
            
            </table>
        
        
        
        </div>
        
    )
    
}

export default CheckMappings
