import Logger from '@utils/log'
import config from '@/config'
import { useEffect } from 'react'
import { useWireValue } from '@forminator/react-wire'
import { user as storeUser, currentOrganization as storeCurrentOrganization } from '@store'

const log = new Logger('hook/usePendo')

const initializePendo = (user, organization) => {
    
    try {
        if (typeof process === 'undefined') return
        if (window.parsePendoInitializedUserId === user?.id) return
        if (!config.enablePendo)
            return log.w('Pendo disabled. You can change this by editing your .env config')
    } catch (e) {
        return
    }
    
    window.pendo.initialize({
        visitor: {
            // Required if user is logged in, default creates anonymous ID
            id: user.id,
            
            // Recommended if using Pendo Feedback, or NPS Email
            email: user.email,
            
            // Recommended if using Pendo Feedback
            full_name: `${user.firstName} ${user.lastName}`,
            
            // Optional
            role: JSON.stringify(user.roles?.map(it => it.name).sort() ?? []),
            
            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
        },
        account: {
            // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
            id: organization.id,
            
            // Optional
            name: organization.name,
            
            // TODO: These fields once billing is set up
            
            // Recommended if using Pendo Feedback
            is_paying: false,
            
            // Recommended if using Pendo Feedback
            // monthly_value: ,
            // Optional
            // planLevel: ,
            // Optional
            // planPrice: ,
            // Optional
            // creationDate: ,
            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
        },
    })
}

const usePendo = () => {
    
    /** @type WireState<User> */
    const user = useWireValue(storeUser)
    
    /** @type WireState<GOrganization> */
    const currentOrganization = useWireValue(storeCurrentOrganization)
    
    useEffect(() => {
        
        if (!user?.id) return
        if (!currentOrganization?.id) return
        
        let pendoRetries = 0
        const maxPendoRetries = 5
        
        // Waits for window.pendo to be loaded via their script,
        // then initializes Pendo with the logged in user's information
        const t = setInterval(() => {
            if (!user?.id || !Object.hasOwn(window, 'pendo')) {
                pendoRetries++
                
                if (pendoRetries >= maxPendoRetries) {
                    clearInterval(t)
                    log.w('Pendo initialization timed out')
                }
                
                return
            }
            
            clearInterval(t)
            initializePendo(user, currentOrganization)
            
            // Prevent Pendo from initializing more than once per session
            window.parsePendoInitializedUserId = user.id
            
        }, 3000)
        
        return () => clearInterval(t)
        
    }, [user])
    
}

export default usePendo
