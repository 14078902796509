
// Record types for showing documents/checks/etc. tables for partners
export const RECORD_TYPE_CHECK = 'check'
export const RECORD_TYPE_DOCUMENT = 'document'

export const RECORD_TYPES = [
    RECORD_TYPE_CHECK,
    RECORD_TYPE_DOCUMENT,
]

export const ANNOTATION_LABEL_TYPE_ALL = null
export const ANNOTATION_LABEL_TYPE_DOC = 'document'
export const ANNOTATION_LABEL_TYPE_CHECK = 'check'

export const ANNOTATION_LABEL_TYPES = [
    ANNOTATION_LABEL_TYPE_ALL,
    ANNOTATION_LABEL_TYPE_DOC,
    ANNOTATION_LABEL_TYPE_CHECK,
]

export const ANNOTATION_LABEL_TYPES_NAV_MAP = {
    'All': ANNOTATION_LABEL_TYPE_ALL,
    'Documents': ANNOTATION_LABEL_TYPE_DOC,
    'Checks': ANNOTATION_LABEL_TYPE_CHECK,
}
