import { useMemo } from 'react'
import cn from 'classnames'

import { FaTimes } from 'react-icons/fa'

import './SidePanel.css'

const SidePanel = ({
    className,
    contentClassName,
    open,
    width = '30%',
    title,
    onClose = () => console.warn('SidePanel: close button has no event handler'),
    children,
}) => {
    
    const rightOffset = useMemo(() => {
        
        const value = parseInt(width, 10)
        const type = width.replace(String(value), '').trim()
        
        return `${0 - value}${type}`
        
    }, [width])
    
    return (
        
        <div
            className={cn('SidePanel', className, { open })}
            style={{
                '--width': width,
                '--right-offset': rightOffset,
            }}>
            
            <header className="flex items-center content-center justify-between w-full p-3">
                <div>{title ?? ' '}</div>
                <FaTimes
                    className="text-2xl cursor-pointer"
                    onClick={onClose} />
            </header>
            
            <div className={contentClassName}>
                {children}
            </div>
        
        </div>
        
    )
    
}

export default SidePanel
