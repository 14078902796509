import cn from 'classnames'

const Avatar = ({
    className,
    sizeClass = 'w-12 h-12',
    textClass = 'text-base',
    initials,
}) => {
    
    
    
    return (
        
        <div className={cn(
            'Avatar',
            'relative border-2 border-gray-600 rounded-full',
            sizeClass,
            className,
        )}>
            
            <div className={cn('absolute-centered', textClass)}>
                {initials}
            </div>
        
        </div>
        
    )
    
}

export default Avatar
