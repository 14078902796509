import cn from 'classnames'

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'

const SimplePager = ({
    className,
    pages,
    page,
    setPage,
}) => {
    
    return (
        
        <div className={cn(
            'SimplePager',
            'flex justify-end items-center content-center',
            className,
        )}>
            
            <button
                className=""
                onClick={() => setPage(Math.max(1, page - 1))}>
                <MdKeyboardArrowLeft style={{ fontSize: '2rem' }} />
            </button>
            
            <span>Page {page} of {pages ?? 0}</span>
            
            <button
                className=""
                onClick={() => setPage(Math.min(pages, page + 1))}>
                <MdKeyboardArrowRight style={{ fontSize: '2rem' }} />
            </button>
        
        </div>
        
    )
    
}

export default SimplePager
