import './wydr'
import './utils/logrocket'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { NS } from '@constants'
import { setNamespace } from 'react-wire-persisted'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import Main from '@/app'
import config from './config'
import { BrowserRouter } from 'react-router-dom'
import '@assets/styles/tailwind.css'
/* import '@assets/styles/themes.g.css' */
import '@assets/styles/index.css'
import 'react-toastify/dist/ReactToastify.css'

setNamespace(NS)

dayjs.extend(utc)
dayjs.extend(duration)
dayjs.extend(relativeTime)

const useStrictMode = false

// eslint-disable-next-line no-restricted-globals
const container = document.getElementById('root')
const root = createRoot(container)

if (config.enableDataRouter) {
    
    if (useStrictMode)
        root.render(
            <StrictMode>
                <Main />
            </StrictMode>,
        )
    else
        root.render(<Main />)
    
} else {
    if (useStrictMode)
        root.render(
            <StrictMode>
                <BrowserRouter>
                    <Main />
                </BrowserRouter>
            </StrictMode>,
        )
    else
        root.render(
            <BrowserRouter>
                <Main />
            </BrowserRouter>,
        )
}
