import Logger from '@utils/log'
import * as store from '@store'
import ParseAPI from '@api/ParseAPI'

const log = new Logger('actions/runsheets')

export const listUniqueParties = async runsheetId => {
    try {
        
        const res = await ParseAPI.get(`uniqueParty/list/${runsheetId}`)
        
        store.uniqueParties.setValue(res.data)
        
    } catch (err) {
        log.e(err)
    }
}

export const mergeUniqueParties = async (runsheetId, data) => {
    try {
        
        const res = await ParseAPI.post(`uniqueParty/merge/${runsheetId}`, {
            ...data,
        })
        
        return res.data
        
    } catch (err) {
        log.e(err)
        throw err
    }
}