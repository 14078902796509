
const withDefaults = extraClasses => `flex flex-col ${extraClasses}`

const ExternalLink = ({
    className = () => withDefaults(),
    to,
    children,
}) => {
    
    return (
        
        <a
            className={className({ withDefaults })}
            href={to}
            target="_blank"
            rel="noreferrer">
            {children}
        </a>
        
    )
    
}

export default ExternalLink
