import { memo } from 'react'
import { ably as storeAbly } from '@store/realtime'
import { useWireValue } from '@forminator/react-wire'
import useRealtime from '@hook/useRealtime'

const RealtimeInternal = () => {
    
    useRealtime()
    
    console.log('RealtimeInternal rendered')
    
    return null
    
}

const Realtime = () => {
    
    const ably = useWireValue(storeAbly, null)
    
    return ably ? <RealtimeInternal /> : null
    
}

export default memo(Realtime)
