import { useState, useEffect, useCallback } from 'react'
import * as actions from '$actions'
import { toast } from 'react-toastify'

const usePagerDutyViewModel = () => {
    
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const [incidents, setIncidents] = useState([])
    
    // @todo eventually handle PD pagination here
    const fetchIncidents = useCallback(async () => {
        
        setError()
        setLoading(true)
        
        try {
            
            const res = await actions.fetchPagerDutyIncidents()
            
            setIncidents(res.incidents)
            
        } catch (e) {
            
            console.error('fetchIncidents', e)
            toast.error('Failed to fetch incidents')
            setError(e.message)
            
        }
        
        setLoading(false)
        
    }, [])
    
    useEffect(() => { fetchIncidents() }, [])
    
    return {
        
        error,
        setError,
        loading,
        setLoading,
        incidents,
        setIncidents,
        
        fetchIncidents,
        
    }
    
}

export default usePagerDutyViewModel
