import api from '$lib/api'
import * as store from '$store'
import { createQueryString } from '@utils'

const upsertLocalCollections = collections => {
    
    const existingCollections = store.collections.getValue() ?? {}
    const existingRows = existingCollections?.rows ?? []
    const rowsMap = existingRows.reduce((acc, it) => ({
        ...acc,
        [it.id]: it,
    }), {})
    
    collections?.rows.forEach(it => {
        rowsMap[it.id] = it
    })
    
    store.collections.setValue({
        count: collections?.count ?? 0,
        rows: Object.values(rowsMap),
    })
    
}

export const getCollectionsWithStats = async ({
    offset = 0,
    limit = 100,
    orderField = 'createdAt',
    orderDirection = 'DESC',
    query = null,
    onlyMyCollections = undefined,
    onlyParseCollections = undefined,
} = {}) => {
    
    const paramsObj = {
        offset,
        limit,
        orderField,
        orderDirection,
        onlyMyCollections,
        onlyParseCollections,
    }
    
    if (query?.length)
        paramsObj.query = query
    
    const params = createQueryString(paramsObj)
    
    const res = await api.get(`/admin/collections${params}`)
    
    if (res.data) {
        
        upsertLocalCollections(res.data)
    }
    
    return res
    
}

export const getCollectionById = async id => {
    
    const res = await api.get(`/collections/${id}`)
    
    if (res.data) {
        
        console.info('Fetched collection', id)
        
        const existingCollections = store.collections.getValue() ?? {}
        const hasCollectionLocally = existingCollections?.rows?.some(it => it.id === id)
        const existingRows = existingCollections?.rows ?? []
        
        const updatedRows = hasCollectionLocally
            ? existingRows.map(it => it.id === id ? res.data : it)
            : [...existingRows, res.data]
        
        store.collections.setValue({
            count: existingCollections?.count ?? 0,
            rows: updatedRows,
        })
        
    }
    
    return res
    
}

export const getCollectionsWithElasticCounts = async ({
    offset = 0,
    limit = undefined,
    query = undefined,
    onlyParseCollections = true,
} = {}) => {
    
    const paramsObj = {
        offset,
        limit,
        onlyParseCollections,
    }
    
    if (query?.length)
        paramsObj.query = query
    
    const params = createQueryString(paramsObj)
    
    const res = await api.get(`/admin/elasticsearch/collection-counts${params}`)
    
    return res
    
}

export const assignCollectionToAnnotators = async (annotatorIds, collectionId) => {
    
    const res = await api.post('/collections/assign/annotator', {
        annotatorIds,
        collectionId,
    })
    
    return res
    
}

export const updateCollectionDocumentStatuses = async (collectionId, reviewStatus) => {
    
    const res = await api.put(`/admin/collections/${collectionId}/documentstatuses`, {
        reviewStatus,
    })
    
    return res
    
}

export const getEvalCollections = async () => {
    
    const res = await api.get('/exampleManager/getEvalCollections')
    
    return res.data
}

export const deleteCollection = async collectionId => {
    
    const res = await api.delete(`/collections/${collectionId}`)
    
    return res
    
}

/**
 * Adds labels to a collection
 * 
 * @param {UUID} collectionId 
 * @param {String[]} labelIds 
 */
export const addLabelsToCollection = async (collectionId, labelIds) => {
    
    const res = await api.put('/collections/labels', {
        collectionId,
        labelIds,
    })
    
    return res
    
}

/**
 * Replaces all labels for a collection
 * 
 * @param {UUID} collectionId 
 * @param {String[]} labelIds 
 */
export const setLabelsToCollection = async (collectionId, labelIds) => {
    
    const res = await api.post('/collections/labels', {
        collectionId,
        labelIds,
    })
    
    return res
    
}

/**
 * Deletes labels from a collection
 * 
 * @param {UUID} collectionId 
 * @param {String[]} labelIds 
 */
export const removeLabelsFromCollection = async (collectionId, labelIds) => {
    
    const res = await api.post('/collections/labels', {
        collectionId,
        labelIds,
    })
    
    return res
    
}

/**
 * Toggles isParse to determine whether the collection belongs to ParseAI and should be visible to all users
 * 
 * @param {UUID} collectionId
 * @param {boolean} isParse
 */
export const toggleParseCollection = async (collectionId, isParse) =>
    await api.patch(`/admin/collections/${collectionId}/isparse`, { isParse })

/**
 * Toggles isExample to determine whether the collection is example or not.
 * 
 * @param {UUID} collectionId
 * @param {boolean} isExample
 */
export const toggleIsExample = async (collectionId, isExample) =>
    await api.put(`/admin/collections/${collectionId}/isexample`, { isExample })

export const toggleIsEval = async (collectionId, isEval) =>
    await api.put(`/admin/collections/${collectionId}/iseval`, { isEval })
