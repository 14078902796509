// eslint-disable-next-line
import { createWire, createSelector } from '@forminator/react-wire'
// eslint-disable-next-line
import { createPersistedWire } from 'react-wire-persisted'
// eslint-disable-next-line
import { keys } from '$constants'

export const isFetchingOrgs = createWire(false)

export const organizations = createWire([])

export const orgsPage = createWire(0)
