import { createWire } from '@forminator/react-wire'
import { createPersistedWire } from 'react-wire-persisted'
import { keys, storeInitialValues } from '@constants'

export const isFetchingOrgs = createWire(false)

/** @type StateWire<GOrganization[]> */
export const organizations = createWire(storeInitialValues.organizations)

/** @type StateWire<GOrganization> */
export const currentOrganization = createPersistedWire(keys.currentOrganization, null)

export const exportTemplates = createWire([])
export const currentExportTemplate = createWire(null)
