import { useRenderPdfScrollable } from '@/hook/useRenderPdfScrollable'
import { useEffect, useMemo, useRef } from 'react'
import * as store from '@store'
import './ScrollableDocumentViewer.css'
import { MdAdd, MdKeyboardArrowDown, MdKeyboardArrowUp, MdRemove } from 'react-icons/md'
import { useWireValue } from '@forminator/react-wire'
import CircleSpinner from '@/components/shared/CircleSpinner'

const MIN_SCALE = 0.1
const MAX_SCALE = 3

const ScrollableDocumentViewer = ({ url }) => {
    
    const toolbarRef = useRef(null)
    const containerRef = useRef(null)
    const thumbnailRef = useRef(null)
    const loadingDoc = useWireValue(store.pdfRendererLoadingDoc)
    
    const els = useMemo(() => {
        const toolbar = toolbarRef.current
        
        if (!toolbar) return {}
        const nextPageBtn = toolbar.querySelector('#next-page')
        const prevPageBtn = toolbar.querySelector('#prev-page')
        const zoomInBtn = toolbar.querySelector('#zoom-in')
        const zoomOutBtn = toolbar.querySelector('#zoom-out')
        const pageInput = toolbar.querySelector('#page-input')
        const scaleSelect = toolbar.querySelector('#scale-select')
        
        return {
            toolbar,
            prevPageBtn,
            nextPageBtn,
            pageInput,
            zoomInBtn,
            zoomOutBtn,
            scaleSelect,
        }
    }, [toolbarRef.current])
    
    const { pdf, viewer, eventBus } = useRenderPdfScrollable({
        url,
        containerEl: containerRef.current,
    })
    
    const updateUI = () => {
        if (!viewer || !pdf) return
        
        const {
            prevPageBtn,
            nextPageBtn,
            pageInput,
            zoomInBtn,
            zoomOutBtn,
            scaleSelect,
        } = els
        
        pageInput.value = viewer.currentPageNumber
        pageInput.max = pdf.numPages
        
        nextPageBtn.disabled = viewer.currentPageNumber === pdf.numPages
        prevPageBtn.disabled = viewer.currentPageNumber <= 1
        zoomOutBtn.disabled = viewer.currentScale <= MIN_SCALE
        zoomInBtn.disabled = viewer.currentScale >= MAX_SCALE
        
        if (!viewer.currentScaleValue || !isNaN(viewer.currentScaleValue)) {
            scaleSelect.value = 'custom'
            scaleSelect.options[scaleSelect.selectedIndex].innerText = Math.floor(viewer.currentScale * 100) + '%'
        }
    }
    
    const setValidPage = ev => {
        if (ev.key !== 'Enter') return
        const value = ev.target.value
        
        const pageNumber = parseInt(value, 10)
        
        if (isNaN(pageNumber)) return ev.target.value = viewer.currentPageNumber
        if (pageNumber < 1 || pageNumber > pdf.numPages) return ev.target.value = viewer.currentPageNumber
        
        viewer.scrollPageIntoView({ pageNumber })
        updateUI()
    }
    
    const prevPage = () => {
        viewer.previousPage()
        updateUI()
    }
    
    const nextPage = () => {
        viewer.nextPage()
        updateUI()
    }
    
    const zoomOut = () => {
        viewer.currentScale = viewer.currentScale || 1
        viewer.decreaseScale()
        updateUI()
    }
    
    const zoomIn = () => {
        viewer.currentScale = viewer.currentScale || 1
        viewer.increaseScale({ updateZoom: true })
        updateUI()
    }
    
    const setScale = ev => {
        viewer.currentScaleValue = ev.target.value
        updateUI()
    }
    
    useEffect(() => {
        eventBus?._on('pagechanging', () => updateUI())
        
        eventBus?._on('pagesloaded', () => {
            viewer.currentScaleValue = 'page-width'
            updateUI()
        })
        
        return () => {
            eventBus?._off('pagechanging')
            eventBus?._off('pagesloaded')
        }
    }, [eventBus, viewer, pdf, toolbarRef.current])
    
    return (
        <div className="scrollable-document-viewer">
            <div ref={toolbarRef} className="toolbar">
                <div className="page-change">
                    <div className="join">
                        <button type="button"
                            id="prev-page"
                            onClick={prevPage}
                            className="toolbar-btn join-item">
                            <MdKeyboardArrowUp />
                        </button>
                        <button type="button"
                            id="next-page"
                            onClick={nextPage}
                            className="toolbar-btn join-item">
                            <MdKeyboardArrowDown />
                        </button>
                    </div>
                    <input type="number"
                        id="page-input"
                        className="input input-xs input-bordered"
                        autoComplete="off"
                        defaultValue={1}
                        onKeyDown={setValidPage}/>
                    <span className="text-xs">of {pdf?.numPages}</span>
                </div>
                <div className="page-zoom">
                    <div className="join">
                        <button type="button"
                            id="zoom-out"
                            className="toolbar-btn join-item"
                            onClick={zoomOut}>
                            <MdRemove />
                        </button>
                        <button type="button"
                            id="zoom-in"
                            className="toolbar-btn join-item"
                            onClick={zoomIn}>
                            <MdAdd />
                        </button>
                    </div>
                    <select
                        id="scale-select"
                        className="select select-xs select-bordered !py-0 !h-auto"
                        defaultValue="page-width"
                        onChange={setScale}>
                        <option id="pageAutoOption" value="auto" >Automatic Zoom</option>
                        <option id="pageActualOption" value="page-actual" >Actual Size</option>
                        <option id="pageFitOption" value="page-fit" >Page Fit</option>
                        <option id="pageWidthOption" value="page-width" >Page Width</option>
                        <option id="customScaleOption" value="custom"
                            disabled="disabled" hidden={true}>{(viewer?.currentScale || 1) * 100}%</option>
                        <option value="0.5">50%</option>
                        <option value="0.75">75%</option>
                        <option value="1">100%</option>
                        <option value="1.25">125%</option>
                        <option value="1.5">150%</option>
                        <option value="2">200%</option>
                        <option value="3">300%</option>
                    </select>
                </div>
            </div>
            <div ref={thumbnailRef} className="thumbs-bar"></div>
            {loadingDoc && <CircleSpinner className="absolute-centered" />}
            <div className="pdf-container" ref={containerRef}>
                <div className="viewer"></div>
            </div>
        </div>
    )
}

export default ScrollableDocumentViewer
