import config from '@/config'

export const PENDO_EVENTS = {
    DOC_UPLOAD: 'document_upload',
    DOC_PROCESS: 'document_process',
    DOC_ANNOTATE: 'document_annotate',
    ADD_TO_RUNSHEET: 'add_to_runsheet',
    RUNSHEET_EXPORT: 'runsheet_exported',
    
    CHECK_UPLOAD: 'check_upload',
    CHECK_PROCESS: 'check_process', // might not be necessary
    CHECK_ANNOTATE: 'check_annotate',
    CHECK_REPORT: 'check_report',
}

export const pendoTrack = (event, data) => {
    if (!config.enablePendo) return
    if (!Object.hasOwn(window, 'pendo')) return
    
    window.pendo.track(event, data)
}