
export default [
    {
        name: 'Google Cloud Console (GCP)',
        description: 'Application hosting, cloud storage',
        url: 'https://console.cloud.google.com/welcome?project=parse-app',
    },
    {
        name: 'AWS Console (deprecated)',
        description: 'Application hosting, Cognaize deployments',
        url: 'https://us-east-1.console.aws.amazon.com/ec2globalview/home?region=us-east-1&skipRegion=true#',
    },
    {
        name: 'Google Search Console',
        description: 'Domain verification, usage stats, monitoring',
        url: 'https://search.google.com/u/2/search-console?resource_id=sc-domain:develop.parseai.co',
    },
    {
        name: 'Squarespace DNS',
        description: 'Manage DNS entries, subdomains, etc.',
        url: 'https://tan-triangle-heez.squarespace.com/config/settings/domains/parseai.co/dns-settings',
    },
]
