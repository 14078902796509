import { useState, useMemo, useCallback, useEffect } from 'react'
import * as actions from '$actions'
import {
    ES_INDEX_STATUS_RUNNING,
    ES_INDEX_STRATEGY_INNER,
} from '@constants/elasticSearch'
import { toast } from 'react-toastify'

const useElasticSearchViewModel = () => {
    
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    
    // Stats (enabled, etc.)
    const [stats, setStats] = useState({})
    
    // Elastic Search "engines" (different schemas to index & search against)
    const [engines, setEngines] = useState(null)
    
    // Currently running ES jobs
    const [jobs, setJobs] = useState(null)
    
    // Collections list (for indexing specific collections)
    const [collections, setCollections] = useState([])
    
    // Visibility of stats panel
    const [statsPanelOpen, setStatsPanelOpen] = useState(false)
    const [statsPanelQuery, setStatsPanelQuery] = useState('')
    
    // Confirmation modals
    const [confirmToggleEnabled, setConfirmToggleEnabled] = useState(false)
    const [confirmToggleAutoIndexRecords, setConfirmToggleAutoIndexRecords] = useState(false)
    const [confirmRefreshIndexName, setConfirmRefreshIndexName] = useState()
    const [confirmReindexEngineName, setConfirmReindexEngineName] = useState()
    const [confirmReindexStrategy, setConfirmReindexStrategy] = useState(ES_INDEX_STRATEGY_INNER)
    const [confirmReindexCollection, setConfirmReindexCollection] = useState()
    const [confirmTruncateIndexName, setConfirmTruncateIndexName] = useState()
    
    const fetchStats = async () => {
        
        try {
            
            const res = await actions.fetchElasticSearchStats()
            
            setStats(res)
            
        } catch (e) {
            
            console.error('fetchStats', e)
            setEngines(null)
            toast.error('Failed to fetch ES stats')
            
        }
        
    }
    
    const toggleBooleanProp = useCallback(async (fnName, fn) => {
        
        setLoading(true)
        
        try {
            
            await fn()
            await fetchStats()
            
        } catch (e) {
            
            console.log(fnName, e)
            toast.error(e?.message ?? 'Unknown error')
            
        }
        
        setLoading(false)
        
    }, [])
    
    const toggleEnabled = useCallback(() => toggleBooleanProp(
        'toggleEnabled',
        actions.toggleElasticSearchEnabled,
    ), [toggleBooleanProp])
    
    const toggleAutoIndexRecords = useCallback(() => toggleBooleanProp(
        'toggleAutoIndexRecords',
        actions.toggleElasticSearchAutoIndexRecords,
    ), [toggleBooleanProp])
    
    const toggleWildcardSearchEnabled = useCallback(() => toggleBooleanProp(
        'wildcardSearchEnabled',
        actions.toggleElasticSearchWildcardSearchEnabled,
    ), [toggleBooleanProp])
    
    const toggleAdvancedFieldSearchEnabled = useCallback(() => toggleBooleanProp(
        'advancedFieldSearchEnabled',
        actions.toggleElasticSearchAdvancedFieldSearchEnabled,
    ), [toggleBooleanProp])
    
    const fetchEngines = async () => {
        
        try {
            
            const res = await actions.fetchElasticSearchEngines()
            
            setEngines(res || null)
            
        } catch (e) {
            
            console.error('fetchEngines', e)
            setEngines(null)
            toast.error('Failed to fetch ES engines')
            
        }
        
    }
    
    const fetchJobs = async () => {
        
        try {
            
            const res = await actions.fetchElasticSearchIndexJobs()
            
            setJobs(res || null)
            
        } catch (e) {
            
            console.error('fetchJobs', e)
            setJobs(null)
            toast.error('Failed to fetch ES jobs')
            
        }
        
    }
    
    const fetchCollections = async (query, onlyParseCollections) => {
        
        const params = {
            offset: 0,
            limit: 20,
            query,
            onlyParseCollections,
        }
        
        setLoading(true)
        
        try {
            
            const res = await actions.getCollectionsWithStats(params)
            
            setCollections(res.data.rows)
            
        } catch (e) {
            
            console.error('fetchCollections', e)
            setError('Error fetching collections')
            
        }
        
        setLoading(false)
        
    }
    
    const toggleStatsPanel = () => setStatsPanelOpen(prev => !prev)
    
    const _reindexEngine = useCallback(async (modelName, strategyName, {
        collectionId = null,
        collectionName = null,
        initialOffset = null,
        maximumOffset = null,
        onlyMissingRecords = false,
    } = {}) => {
        
        try {
            
            await actions.reindexElasticSearchEngine(modelName, strategyName, {
                collectionId,
                initialOffset,
                maximumOffset,
                onlyMissingRecords,
            })
            await fetchJobs()
            
            if (collectionName)
                toast.success(`Collection "${collectionName}" queued for indexing`)
            else
                toast.success(`Records "${modelName}" with strategy "${strategyName}" queued for indexing`)
            
        } catch (e) {
            
            if (e.response.status === 503)
                return toast.error('Elastic Search is not currently enabled')
            
            console.error('_reindexEngine', e)
            toast.error('Failed to queue engine for indexing')
            
        }
        
    }, [])
    
    const refreshIndex = useCallback(async () => {
        try {
            await actions.refreshElasticSearchIndex(confirmRefreshIndexName)
            toast.success(`Refreshed index ${confirmRefreshIndexName}`)
        } catch (e) {
            console.error('refreshIndex', e)
            toast.error('Failed to refresh index')
        }
    }, [confirmRefreshIndexName])
    
    const reindexEngine = useCallback(async () => {
        return toast.warn('TODO not yet operational')
        
        // return await _reindexEngine(confirmReindexEngineName)
    }, [])
    
    const reindexCollection = useCallback(async (collection, initialOffset, maximumOffset, onlyMissingRecords) => {
        return await _reindexEngine('Document', confirmReindexStrategy, {
            collectionId: collection?.id,
            collectionName: collection?.name,
            initialOffset,
            maximumOffset,
            onlyMissingRecords,
        })
    }, [_reindexEngine, confirmReindexStrategy])
    
    const truncateIndex = useCallback(async () => {
        try {
            await actions.truncateElasticSearchIndex(confirmTruncateIndexName)
            toast.success('Truncated index')
        } catch (e) {
            console.error('truncateIndex', e)
            toast.error('Failed to truncate index')
        }
    }, [confirmTruncateIndexName])
    
    const getLastJob = engineName => jobs?.find(it => (
        `search-${it.engineName}` === engineName
        && it.status !== ES_INDEX_STATUS_RUNNING
    )) ?? { status: 'TBD' }
    
    const getRunningJob = engineName => jobs?.find(it => (
        `search-${it.engineName}` === engineName
        && it.status === ES_INDEX_STATUS_RUNNING
    ))
    
    const statsFiltered = useMemo(() => {
        
        const text = JSON.stringify(stats ?? {}, null, 4)
        
        if (statsPanelQuery?.length < 0) return text
        
        return text?.split('\n')?.filter(it => (
            it.toLowerCase().includes(statsPanelQuery.toLowerCase())
        )).join('\n') ?? ''
        
    }, [stats, statsPanelQuery])
    
    useEffect(() => { fetchStats() }, [])
    
    return {
        
        error,
        setError,
        loading,
        setLoading,
        stats,
        setStats,
        engines,
        setEngines,
        jobs,
        setJobs,
        collections,
        setCollections,
        statsPanelOpen,
        setStatsPanelOpen,
        statsPanelQuery,
        setStatsPanelQuery,
        
        statsFiltered,
        
        confirmRefreshIndexName,
        setConfirmRefreshIndexName,
        confirmReindexEngineName,
        setConfirmReindexEngineName,
        confirmReindexStrategy,
        setConfirmReindexStrategy,
        confirmTruncateIndexName,
        setConfirmTruncateIndexName,
        
        confirmToggleEnabled,
        setConfirmToggleEnabled,
        confirmToggleAutoIndexRecords,
        setConfirmToggleAutoIndexRecords,
        confirmReindexCollection,
        setConfirmReindexCollection,
        
        fetchStats,
        toggleEnabled,
        toggleAutoIndexRecords,
        toggleWildcardSearchEnabled,
        toggleAdvancedFieldSearchEnabled,
        fetchEngines,
        fetchJobs,
        fetchCollections,
        toggleStatsPanel,
        refreshIndex,
        reindexEngine,
        reindexCollection,
        truncateIndex,
        getLastJob,
        getRunningJob,
        
    }
    
}

export default useElasticSearchViewModel
