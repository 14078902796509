import { gql } from '@apollo/client'

export const DeleteJob = gql`
    
    mutation DeleteJob(
        $id: UUID!
    ) {
        
        deleteJob(
            input: {
                id: $id
            }
        ) {
            deletedJobNodeId
        }
        
    }

`
