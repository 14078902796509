import usePaymentRequiredFlowViewModel from './PaymentRequiredFlowViewModel'
import { ACCOUNT_TYPES } from '@constants'
import cn from 'classnames'

import { MdWarning } from 'react-icons/md'
import { Link } from 'react-router-dom'

import './PaymentRequiredFlow.css'

/**
 * Base payment flow
 * All params are passed directly, not read from the global store
 *
 * @param {Object} params
 * @param {String} params.className
 * @param {PaymentsHook} params.payments
 * @param {PurchaseType} params.purchaseType
 * @param {Number} [params.itemCount=0] Number of items
 * @param {Function} [params.onClose]
 * @param {Function} params.onConfirmPayment Callback (fundingSource, remainingBalance) => {}
 */
const PaymentRequiredFlow = ({
    className,
    payments,
    purchaseType,
    itemCount = 0,
    onClose,
    onConfirmPayment,
    onInsufficientBalanceChange,
    onRemainingBalanceChange,
}) => {
    
    const vm = usePaymentRequiredFlowViewModel({
        fundingSource: payments.fundingSource,
        balance: payments.balance,
        purchaseType,
        itemCount,
        onClose,
        onConfirmPayment,
        onInsufficientBalanceChange,
        onRemainingBalanceChange,
    })
    
    return (
        
        <div className={cn('PaymentRequiredFlow text-base', className)}>
            
            <div className="flex justify-between mb-6">
                
                <div>
                    
                    <p>
                        This is a paid feature.
                        {vm.price >= 20 && ' All purchases of $20 or greater require verification.'}
                        <br/>
                        Would you like to proceed?
                    </p>
                    
                    <div className="mt-4">
                        
                        <p className="py-2">Funding Source:</p>
                        
                        <div className="form-control">
                            <label className="label cursor-pointer justify-start gap-2">
                                <input
                                    className="radio"
                                    type="radio"
                                    value={ACCOUNT_TYPES.User}
                                    checked={payments.fundingSource === ACCOUNT_TYPES.User}
                                    onChange={e => payments.setFundingSource(e.target.value)}/>
                                <div className="label-text">
                                    <div className={cn({
                                        'font-bold': payments.fundingSource === ACCOUNT_TYPES.User,
                                    })}>
                                        Personal
                                    </div>
                                    <div className="text-sm">
                                        Balance: &nbsp;<code>${payments.userCreditsBalanceFormatted}</code>
                                    </div>
                                </div>
                            </label>
                        </div>
                        
                        <div className="form-control">
                            <label className="label cursor-pointer justify-start gap-2">
                                <input
                                    className="radio"
                                    type="radio"
                                    value={ACCOUNT_TYPES.Organization}
                                    checked={payments.fundingSource === ACCOUNT_TYPES.Organization}
                                    onChange={e => payments.setFundingSource(e.target.value)}/>
                                <div className="label-text">
                                    <div className={cn({
                                        'font-bold': payments.fundingSource === ACCOUNT_TYPES.Organization,
                                    })}>
                                        Organization
                                    </div>
                                    <div className="text-sm">
                                        Balance: &nbsp;<code>${payments.orgCreditsBalanceFormatted}</code>
                                    </div>
                                </div>
                            </label>
                        </div>
                    
                    </div>
                
                </div>
                
                <table className="cost-breakdown">
                    <tbody>
                        <tr>
                            <th>Current Balance</th>
                            <td>${payments.balanceFormatted}</td>
                        </tr>
                        <tr>
                            <th>Per Document Price</th>
                            <td>${vm.unitFormatted}</td>
                        </tr>
                        <tr>
                            <th>No. of Documents</th>
                            <td>&times; {itemCount}</td>
                        </tr>
                        <tr>
                            <th>Total{vm.minCost ? ` (minimum $${vm.minCost})` : ''}</th>
                            <td><span className="whitespace-nowrap">&ndash; ${vm.priceFormatted}</span></td>
                        </tr>
                        <tr className={cn({ 'insufficient-balance-warning': vm.hasInsufficientBalance })}>
                            <th>Balance after Payment</th>
                            <td>
                                {vm.remainingBalanceIsNegative ? (<span>&ndash; $</span>) : '$'}
                                {vm.remainingBalanceFormatted?.replace('-', '')}
                            </td>
                        </tr>
                    </tbody>
                </table>
            
            </div>
            
            <div
                className={cn('alert alert-warning my-4', {
                    hidden: !vm.hasInsufficientBalance,
                })}
                role="alert">
                <MdWarning className="text-2xl" />
                <div>
                    <p>Your balance is insufficient to cover this purchase.</p>
                    {payments.remoteConfig?.stripe?.enabled &&
                    <p>
                        <Link
                            className="text-accent"
                            to={payments.fundingSource === ACCOUNT_TYPES.User
                                ? '/settings/billing'
                                : `/settings/orgs/${vm.currentOrganization.id}/billing`}
                            onClick={vm.closeDialog}>
                            Click here
                        </Link> to add funds to your account.
                    </p>}
                </div>
            </div>
        
        </div>
        
    )
    
}

export default PaymentRequiredFlow
