import { useState } from 'react'
import { ES_INDEX_STRATEGIES } from '@constants'

import Checkbox from '$components/Forms/Checkbox'
import ConfirmationModal from '@components/shared/ConfirmationModal'

const ReIndexCollectionConfirmModal = ({
    viewModel,
}) => {
    
    const [initialOffset, setInitialOffset] = useState(null) // 0
    const [maximumOffset, setMaximumOffset] = useState(null) // 0
    const [onlyReIndexMissingRecords, setOnlyReIndexMissingRecords] = useState(false)
    
    return (
        
        <ConfirmationModal
            className="ReIndexCollectionConfirmModal"
            showModal={viewModel.confirmReindexCollection?.id}
            showHideModal={viewModel.setConfirmReindexCollection}
            title="Re-index Collection"
            subtitle={viewModel.confirmReindexCollection?.name}
            onConfirm={() => viewModel.reindexCollection(
                viewModel.confirmReindexCollection,
                initialOffset,
                maximumOffset,
                onlyReIndexMissingRecords,
            )}
            message={(
                <div>
                    
                    <div className="grid grid-cols-2 gap-4">
                        
                        <div className="text-base">
                            <p>Choose an indexing strategy:</p>
                            <select
                                className="w-auto select mt-2"
                                value={viewModel.confirmReindexStrategy}
                                onChange={e => viewModel.setConfirmReindexStrategy(e.target.value)}>
                                {ES_INDEX_STRATEGIES.map(it => (
                                    <option key={`index-strategy-${it}`} value={it}>
                                        {it}
                                    </option>
                                ))}
                            </select>
                        </div>
                        
                        <div className="text-base flex flex-col items-end">
                            <p>Indexer Options</p>
                            <Checkbox
                                label="Only Missing Records"
                                checked={onlyReIndexMissingRecords}
                                onChange={e => setOnlyReIndexMissingRecords(e.target.checked)} />
                            <label className="flex gap-3 items-center">
                                Initial Offset
                                <input
                                    className="input input-bordered w-40 text-center"
                                    type="number"
                                    value={`${initialOffset ?? ''}`}
                                    placeholder="0"
                                    onChange={e => setInitialOffset(parseInt(e.target.value, 10))} />
                            </label>
                            <label className="flex gap-3 items-center">
                                Maximum Offset
                                <input
                                    className="input input-bordered w-40 text-center"
                                    type="number"
                                    value={`${maximumOffset ?? ''}`}
                                    placeholder="0"
                                    onChange={e => setMaximumOffset(parseInt(e.target.value, 10))} />
                            </label>
                        </div>
                    
                    </div>
                    
                    <div className="mt-4 text-base">
                        <p>
                            Are you sure you want to re-index the
                            {' '}
                            <b>{viewModel.confirmReindexCollection?.name}</b>
                            {' '}
                            collection in ElasticSearch
                            {' '}
                            using the <b>{viewModel.confirmReindexStrategy}</b> strategy??
                        </p>
                        <p className="mt-3">
                            This could take a long time.
                        </p>
                        <p className="mt-3 text-red-400">
                            <b>This could incur additional GCP costs!</b>
                        </p>
                    </div>
                
                </div>
            )} />
        
    )
    
}

export default ReIndexCollectionConfirmModal
