
//
// DO NOT EDIT
// THIS FILE IS GENERATED BY /vite.config.js
//

import routes from './RoutesDynamic.jsx'

const log = new Logger('routes')

if (process.env.NODE_ENV !== 'production')
    log.i('Routes rendered from RoutesDynamic.jsx')

export default routes
