import api from '$lib/api'
import { createQueryString } from '@/utils'

export const getActionCountGroupedByOrg = async () => {
    const res = await api.get('/admin/user-tracking/organization')
    
    return res.data
}

export const getActionsByOrganizationId = async ({ organizationId, startDate, endDate }) => {
    
    const params = createQueryString({ startDate, endDate })
    
    const res = await api.get(`/admin/user-tracking/organization/${organizationId}${params}`)
    
    return res.data
}

export const getActionCountGroupedByUser = async ({ organizationId, startDate, endDate }) => {
    
    const params = createQueryString({ startDate, endDate })
    
    const res = await api.get(`/admin/user-tracking/organization/${organizationId}/users${params}`)
    
    return res.data
}


export const getActionsCountPerMonthByOrgId = async organizationId => {
    
    const res = await api.get(`/admin/user-tracking/organization/${organizationId}/monthly`)
    
    return res.data
}

