import Logger from '@utils/log'
import { memo } from 'react'
import { useWire } from '@forminator/react-wire'
import {
    realtimeUserPresenceCount,
    realtimeOrganizationPresenceCount,
    notifications as storeNotifications,
} from '@store'
import {
    REALTIME_CHANNEL_TYPE_USERS,
    REALTIME_CHANNEL_TYPE_ORGANIZATIONS,
    RT_EVENT_TYPE_UPDATE_NOTIFICATION,
    RT_EVENT_TYPE_DISMISS_NOTIFICATION,
} from '@constants/realtime'
import RealtimeSubscription from '@components/shared/Realtime/RealtimeSubscription'
import deepmerge from 'deepmerge'

const log = new Logger('RealtimeNotifications')

/**
 * Container for all real-time subscriptions
 * 
 * @returns {JSX.Element}
 * @constructor
 */
const RealtimeNotifications = () => {
    
    const userPresenceCount = useWire(realtimeUserPresenceCount)
    const organizationPresenceCount = useWire(realtimeOrganizationPresenceCount)
    const notifications = useWire(storeNotifications)
    
    const onUserPresence = presenceData =>
        userPresenceCount.setValue(presenceData.length)
    
    const onOrganizationPresence = presenceData =>
        organizationPresenceCount.setValue(presenceData.length)
    
    const addNotification = (channelName, message) => notifications.setValue([
        { channelName, message },
        ...notifications.getValue(),
    ])
    
    const handleMessage = (channelName, message) => {
        
        log.d('handleMessage', channelName, message)
        
        switch (message.data.type) {
            
            case RT_EVENT_TYPE_UPDATE_NOTIFICATION: {
                notifications.setValue(
                    notifications.getValue().map(it => {
                        
                        if (it.message.data.id === message.data.data.notificationId)
                            it.message.data.data = deepmerge(it.message.data.data, message.data)
                        
                        return it
                        
                    }),
                )
                break
            }
            
            case RT_EVENT_TYPE_DISMISS_NOTIFICATION: {
                notifications.setValue(notifications.getValue()
                    .filter(it => it.message.data.id !== message.data.data.notificationId))
                break
            }
            
            default:
                addNotification(channelName, message)
            
        }
        
    }
    
    return (<>
        
        <RealtimeSubscription
            channelType={REALTIME_CHANNEL_TYPE_USERS}
            onMessage={handleMessage}
            onPresence={onUserPresence} />
        
        <RealtimeSubscription
            channelType={REALTIME_CHANNEL_TYPE_ORGANIZATIONS}
            onMessage={handleMessage}
            onPresence={onOrganizationPresence} />
    
    </>)
    
}

export default memo(RealtimeNotifications)
