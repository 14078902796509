import { useState, useEffect } from 'react'
import cn from 'classnames'

import { MdClose } from 'react-icons/md'

import './Modal.css'

const Modal = ({
    className,
    contentClassName,
    open,
    setOpen,
    title,
    subtitle,
    children,
    actionClass,
    actionLabel,
    actionOnClick,
    actionDisabled,
    showCancel = true,
    onClose,
    ...props
}) => {
    
    const [visible, setVisible] = useState(false)
    
    useEffect(() => {
        
        if (!open)
            return setVisible(false)
        
        setVisible(true)
        
        return () => setVisible(false)
        
    }, [open])
    
    const close = () => {
        
        setOpen(false)
        
        if (onClose) onClose()
        
    }
    
    if (!open) return null
    
    return (
        
        <div>
            
            <div
                className={cn('modal-wrapper fixed-centered slide-out-from-top', {
                    [className]: className,
                    'transition-all ease-in-out duration-150': true,
                    'opacity-0 -translate-y-6': !visible,
                    'opacity-100': visible,
                })}
                {...props}>
                
                <header>
                    <h5 className="mb-0">
                        {title}
                        {subtitle && <div className="text-sm">{subtitle}</div>}
                    </h5>
                    <button
                        className="btn close-button"
                        onClick={() => setOpen(false)}>
                        <MdClose />
                    </button>
                </header>
                
                <div className={cn('content', contentClassName)}>
                    {children}
                </div>
                
                <div className="actions">
                    
                    <div className="actions-wrap">
                        
                        {showCancel && (
                            <button
                                className="btn"
                                onClick={close}>
                                CANCEL
                            </button>
                        )}
                        
                        {actionLabel && (
                            <button
                                className={cn('btn', actionClass ?? 'btn-success')}
                                disabled={actionDisabled}
                                onClick={() => {
                                    if (actionOnClick) actionOnClick()
                                    close()
                                }}>
                                {actionLabel}
                            </button>
                        )}
                    
                    </div>
                
                </div>
            
            </div>
            
            <div
                className={cn('modal-backdrop', {
                    'transition-all ease-in-out duration-300': true,
                    'opacity-0 ': !visible,
                    'opacity-100': visible,
                })}
                onClick={close} />
        
        </div>
        
    )
    
}

export default Modal
