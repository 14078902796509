// import Logger from '@utils/log'
import ParseAPI from '@api/ParseAPI'
import * as store from '@store'
import { noop } from '@utils'

// const log = new Logger('actions/exportTemplates')

export const getTemplatePlaceholders = async () => {
    
    const res = await ParseAPI.get('orgs/templates/placeholders')
    
    return res.data
    
}

export const createExportTemplate = async (
    orgId,
    type,
    name,
    file,
    onUploadMax = noop,
    onUploadValue = noop,
) => {
    
    const formData = new FormData()
    
    formData.append('file', file)
    formData.append('type', type)
    formData.append('name', name)
    
    const config = {
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        onUploadProgress: progressEvent => {
            if (onUploadMax && onUploadValue) {
                onUploadMax(progressEvent.total || 0)
                onUploadValue(progressEvent.loaded || 0)
            }
        },
    }
    
    const res = await ParseAPI.post(`orgs/${orgId}/templates`, formData, config)
    
    return res.data
    
}

export const getExportTemplatesByOrgId = async orgId => {
    
    const res = await ParseAPI.get(`orgs/${orgId}/templates`)
    
    store.exportTemplates.setValue(res.data)
    
    return res.data
    
}

export const getExportTemplatesByOrgIds = async orgIds => {
    
    return await Promise.all(orgIds.map(it => getExportTemplatesByOrgId(it)))
    
}

export const getExportTemplateById = async (orgId, templateId) => {
    
    const res = await ParseAPI.get(`orgs/${orgId}/templates/${templateId}`)
    
    store.arrayUpsertById('exportTemplates', res.data, (prev, next) => ({
        ...prev,
        ...next,
    }))
    
}

export const updateExportTemplate = async (orgId, templateId, data) => {
    
    const res = await ParseAPI.put(`orgs/${orgId}/templates/${templateId}`, data)
    
    if (res?.data?.id === templateId)
        store.arrayUpsertById('exportTemplates', res.data, (prev, next) => ({
            ...prev,
            ...next,
        }))
    
    return res.data
    
}

export const deleteExportTemplate = async (orgId, templateId) => {
    
    const res = await ParseAPI.delete(`orgs/${orgId}/templates/${templateId}`)
    
    return res.data
    
}

export const createExportTemplatePlaceholder = async (orgId, templateId, key, value) => {
    
    const res = await ParseAPI.post(`orgs/${orgId}/templates/${templateId}/placeholders`, {
        key,
        value,
    })
    
    return res.data
    
}

export const updateExportTemplatePlaceholder = async (orgId, templateId, placeholderId, data) => {
    
    const res = await ParseAPI.put(`orgs/${orgId}/templates/${templateId}/placeholders/${placeholderId}`, data)
    
    return res.data
    
}

export const deleteExportTemplatePlaceholder = async (orgId, templateId, placeholderId) => {
    
    const res = await ParseAPI.delete(`orgs/${orgId}/templates/${templateId}/placeholders/${placeholderId}`)
    
    return res.data
    
}

export const getAllExportTemplateFields = async (orgId, templateId) => {
    
    const res = await ParseAPI.get(`orgs/${orgId}/templates/${templateId}/fields`)
    
    return res.data
    
}

// @todo not sure if this makes sense to have, since you can only add internal fields
export const addExportTemplateField = async (orgId, templateId, fieldId) => {
    
    const res = await ParseAPI.put(`orgs/${orgId}/templates/${templateId}/fields`, {
        fieldId,
    })
    
    return res.data
    
}

export const updateExportTemplateField = async (orgId, templateId, fieldId, order) => {
    
    const res = await ParseAPI.put(`orgs/${orgId}/templates/${templateId}/fields`, {
        fieldId,
        order,
    })
    
    return res.data
    
}

export const removeExportTemplateField = async (orgId, templateId, fieldId) => {
    
    const res = await ParseAPI.delete(`orgs/${orgId}/templates/${templateId}/fields/${fieldId}`)
    
    return res.data
    
}

export const setExportTemplateFields = async (orgId, templateId, fields) => {
    
    const res = await ParseAPI.put(`orgs/${orgId}/templates/${templateId}/fields`, {
        fields,
    })
    
    return res.data
    
}
