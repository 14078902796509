import { useState } from 'react'
import * as actions from '$actions'
import * as store from '$store'
import { checkIfNameExists } from '@/components/shared/utils'

import CustomModal, { ModalBody, ModalFooter, ModalHeader } from '@/components/shared/CustomModal'
import { useWireValue } from '@forminator/react-wire'

const ClonePresetModal = ({
    selectedPreset,
    showChangeClonedPresetName,
    setShowChangeClonedPresetName,
    setShowDocumentsModal,
}) => {
    
    const presets = useWireValue(store.presets)
    const [clonedPresetName, setClonedPresetName] = useState(checkIfNameExists(presets, selectedPreset.name))
    const [isInserting, setIsInserting] = useState(false)
    
    const handleClose = () => {
        setClonedPresetName('')
        setShowChangeClonedPresetName(false)
        setShowDocumentsModal(false)
    }
    
    const clonePreset = async () => {
        setIsInserting(true)
        await actions.clonePreset(selectedPreset.id, checkIfNameExists(presets, clonedPresetName))
        setIsInserting(false)
        handleClose()
    }
    
    return (
        <CustomModal
            open={showChangeClonedPresetName}
            modalHandler={handleClose}>
            <ModalHeader>
                New Preset Name
            </ModalHeader>
            <ModalBody>
                <input
                    type="text"
                    className="w-full input input-bordered"
                    value={clonedPresetName}
                    onChange={e => setClonedPresetName(e.target.value)} />
            </ModalBody>
            <ModalFooter>
                <button
                    className="btn btn-primary btn-outline"
                    onClick={handleClose}>Cancel</button>
                <button
                    className="btn btn-primary"
                    disabled={isInserting}
                    onClick={async () => {
                        await clonePreset()
                        handleClose()
                    }}>Clone</button>
            </ModalFooter>
        </CustomModal>
    )
}

export default ClonePresetModal