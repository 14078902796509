import { useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useWireValue } from '@forminator/react-wire'
import * as store from '$store'
import * as actions from '$actions'

import AnnotatorDocumentReviewsTable from './AnnotatorDocumentReviewsTable'
import AnnotatorAssignedDocsTable from './AnnotatorAssignedDocsTable'

const AnnotatorDetail = () => {
    
    const params = useParams()
    
    const annotators = useWireValue(store.annotators)
    
    const id = useMemo(() => params?.id ?? null, [params])
    const annotator = useMemo(() => annotators?.find(it => it.id === id), [id, annotators])
    
    useEffect(() => {
        if (!id) return
        actions.getAnnotatorById(id)
    }, [id])
    
    if (!annotator) return null
    
    return (
        
        <div className="AnnotatorDetail">
            
            <header>
                <h5 className="text-base text-dashboard-primary opacity-60">
                    ANNOTATOR
                </h5>
                <h2 className="text-2xl">
                    {annotator.firstName} {annotator.lastName}
                </h2>
            </header>
            
            <h5 className="max-w-screen-xl mx-auto mt-10 mb-4">
                Document Reviews ({annotator.documentReviews?.length ?? 0})
            </h5>
            
            <AnnotatorDocumentReviewsTable
                className="max-w-screen-xl mx-auto"
                documentReviews={annotator.documentReviews} />
            
            <h5 className="max-w-screen-xl mx-auto mt-10 mb-4">
                Assigned Documents ({annotator.annotatorAssignedDocuments?.length ?? 0})
            </h5>
            
            <AnnotatorAssignedDocsTable
                className="max-w-screen-xl mx-auto"
                documents={annotator.annotatorAssignedDocuments} />
        
        </div>
        
    )
    
}

export default AnnotatorDetail
